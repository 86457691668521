<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'task-update'"
    v-if="getPermission('task:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Update {{ taskSubject }}</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Task
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="taskForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid v-bar class="create-task-vbar">
          <v-row>
            <v-col md="7">
              <v-row>
                <v-col md="12" class="py-0">
                  <label class="font-weight-700 font-size-16"
                    >Task Subject</label
                  >
                  <v-text-field
                    v-model.trim="taskCreate.subject"
                    dense
                    filled
                    label="Subject"
                    solo
                    flat
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    :rules="[
                      validateRules.required(
                        taskCreate.subject,
                        'Task Subject'
                      ),
                      validateRules.minLength(
                        taskCreate.subject,
                        'Task Subject',
                        1
                      ),
                      validateRules.maxLength(
                        taskCreate.subject,
                        'Task Subject',
                        100
                      ),
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col md="12" class="py-0">
                  <editor v-model="taskCreate.description" />
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <table class="width-100">
                <tr>
                  <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                    Task details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Task number</td>
                  <td
                    colspan="2"
                    class="font-weight-700 font-size-16 pb-2"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>{{ taskCreate.barcode }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="taskCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          taskCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          taskCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Task Cost</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Task Cost"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.number="taskCreate.task_cost"
                      dense
                      filled
                      type="number"
                      min="0"
                      max="999"
                      label="Task Cost"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="limitDecimal($event, taskCreate.task_cost)"
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Hourly Rate</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Hourly Rate"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.number="taskCreate.hourly_rate"
                      dense
                      filled
                      type="number"
                      min="0"
                      max="999"
                      label="Hourly Rate"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Priority</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Priority"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      v-model.trim="taskCreate.priority"
                      :items="taskPriorityList"
                      dense
                      filled
                      :disabled="pageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Priority"
                      solo
                      flat
                      return-object
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Priority Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      :defaultDate.sync="defaultStartDate"
                      :pageLoading.sync="pageLoading"
                      solo
                      :placeholder="'Start Date'"
                      v-on:update:date-picker="setStartDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Due Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Due Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      :defaultDate.sync="defaultDueDate"
                      :pageLoading.sync="pageLoading"
                      solo
                      placeholder="Due Date"
                      v-on:update:date-picker="setDueDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12">
              <AttachmentWithName
                v-on:file:updated="updateAttachment"
                :attachments.sync="attachments"
                :label="true"
                allowUpload
              ></AttachmentWithName>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="task"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "task-update",
  title: "Update Task",
  data() {
    return {
      task: null,
      pageLoading: false,
      barcodeDialog: false,
      barcodeSetting: new Object(),
      taskCreate: new Object({
        subject: null,
        description: null,
        barcode: null,
        reference: null,
        task_cost: null,
        hourly_rate: null,
        priority: null,
        start_date: null,
        attachments: [],
        due_date: null,
      }),
      attachments: [],
      taskPriorityList: [
        {
          id: 1,
          value: "High",
        },
        {
          id: 2,
          value: "Medium",
        },
        {
          id: 3,
          value: "Low",
        },
        {
          id: 0,
          value: "None",
        },
      ],
    };
  },
  components: {
    DatePicker,
    AttachmentWithName,
    BarcodeSetting,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.taskCreate.attachments = param;
    },
    updateOrCreate() {
      const _this = this;
      if (!_this.$refs.taskForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.taskCreate.reference,
        subject: _this.taskCreate.subject,
        description: _this.taskCreate.description,
        task_cost: _this.taskCreate.task_cost,
        hourly_rate: _this.taskCreate.hourly_rate,
        start_date: _this.taskCreate.start_date,
        due_date: _this.taskCreate.due_date,
        priority: _this.taskCreate.priority
          ? _this.taskCreate.priority.id
          : null,
        attachments: _this.taskCreate.attachments,
      });

      _this.$store
        .dispatch(PUT, {
          url: "tasks/" + _this.task,
          data: formData,
        })
        .then(({ data }) => {
          _this.backForce = true;
          _this.$router.push(
            _this.getDefaultRoute("task.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    setStartDate(param) {
      this.taskCreate.start_date = param;
    },
    setDueDate(param) {
      this.taskCreate.due_date = param;
    },
    getTask() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task,
        })
        .then(({ data }) => {
          _this.taskCreate = new Object({
            subject: data.subject,
            description: data.description,
            barcode: data.barcode,
            reference: data.reference,
            hourly_rate: data.hourly_rate,
            task_cost: data.task_cost,
            priority: data.priority,
            start_date: data.start_date,
            attachments: [],
            due_date: data.due_date,
          });
          _this.attachments = data.attachments;
          if (data.status <= 1) {
            _this.goBack();
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Task", route: "task" },
      { title: "Update" },
    ]);

    _this.task = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.task <= 0) {
      _this.goBack();
    }

    _this.getTask();
  },
  computed: {
    taskSubject() {
      return this.taskCreate.subject;
    },
    defaultStartDate() {
      if (this.taskCreate.start_date) {
        return this.taskCreate.start_date;
      }
      return new Date().toISOString().substr(0, 10);
    },
    defaultDueDate() {
      if (this.taskCreate.due_date) {
        return this.taskCreate.due_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
