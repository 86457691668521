import { POST, CLEAR_ERROR } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidateLineItemMixin from "@/core/lib/line-item/validate.line.item.mixin";

export default {
  mixins: [ValidateLineItemMixin],
  methods: {
    CreateLineItems(param) {
      return new Promise((resolve, reject) => {
        try {
          const _this = this;
          let lineItem = param.formData;
          let lineItemFormData = new Array();
          for (let i = 0; i < lineItem.length; i++) {
            lineItemFormData.push({
              id: _this.lodash.toSafeInteger(lineItem[i].id) || null,
              package_id: lineItem[i].package_id,
              group_name: lineItem[i].group_name,
              group_me: lineItem[i].group_me,
              group_primary: lineItem[i].group_primary,
              product:
                _this.lodash.toSafeInteger(lineItem[i].product_id) || null,
              tax: lineItem[i].tax,
              product_type: lineItem[i].product_type,
              description: lineItem[i].description,
              quantity: lineItem[i].quantity,
              quantity_type: lineItem[i].quantity_type,
              discount: lineItem[i].discount,
              optional: lineItem[i].optional,
              rate: lineItem[i].rate,
            });
          }

          let formData = {
            line_items: lineItemFormData,
          };

          formData[param.type] = param.parent;

          _this.formLoading = true;
          _this.$store.dispatch(CLEAR_ERROR, {});

          _this
            .validateLineItems({ lineitems: lineItemFormData })
            .then(() => {
              _this.$store
                .dispatch(POST, {
                  url: "line-item",
                  data: formData,
                })
                .then((response) => {
                  resolve(response);
                })
                .finally(() => {
                  _this.formLoading = false;
                });
            })
            .catch((error) => {
              ErrorEventBus.$emit("update:error", InitializeError(error));
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
