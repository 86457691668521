<template>
  <div
    class="custom-line-item-template line-item-v2"
    v-on:drag="moveDragElement($event)"
  >
    <ul
      style="
        list-style: none;
        background-color: #f3f6f9;
        font-weight: 700;
        font-size: 16px;
      "
      class="mb-0 custom-grey-border"
    >
      <li class="ltx-header py-3">
        <div class="d-flex w-100 orange--text text--darken-4">
          <div style="width: 59%">
            Additional Works ({{ dbLineItem.length }})
          </div>
          <div style="width: 12%" class="text-center">Quantity</div>
          <div style="width: 12%" class="text-center">Rate</div>
          <div style="width: 12%" class="text-center">Total</div>
          <div style="width: 5%" class="text-center"></div>
        </div>
      </li>
    </ul>
    <vue-draggable
      tag="ul"
      v-model="dbLineItem"
      class="my-0"
      :class="{ 'line-item-drag-group': dbLineItem.length }"
      draggable=".line-item-drag-ltx"
      handle=".line-item-drag-icon"
      ghost-class="line-item-ghost"
      animation="200"
      group="line-item-drag-ltx"
    >
      <transition-group
        tag="li"
        type="transition"
        v-for="(row, index) in dbLineItem"
        :key="`ltx-line-item-${index}`"
        class="line-item-drag-ltx"
        :class="{ 'custom-border-bottom-5px': dbLineItem[index + 1] }"
      >
        <div
          :key="`line-item-drag-children-${index}`"
          :id="`line-item-drag-${index}`"
          class="my-2"
        >
          <line-item-level-one
            :key="`line-item-level-one-${index}`"
            :row="row"
            :index="index"
            :has-level-two="
              !!(
                itemInstance.hasProperty(ltxChildLineItem, row.uuid) &&
                ltxChildLineItem[row.uuid].length
              )
            "
            :pagebreak="
              dbLineItem[index - 1] &&
              dbLineItem[index - 1].product_type == 'pagebreak'
            "
          />

          <template
            v-if="
              itemInstance.hasProperty(ltxChildLineItem, row.uuid) &&
              ltxChildLineItem[row.uuid].length
            "
          >
            <template v-for="(crow, cindex) in ltxChildLineItem[row.uuid]">
              <div
                class="pb-2"
                :key="`ltx-label-child-line-item-${index}-${cindex}`"
              >
                <line-item-level-two
                  :item-instance="itemInstance"
                  :key="`line-item-level-two-${index}-${cindex}`"
                  :has-level-three="
                    !!(
                      itemInstance.hasProperty(ltxLevel2ChildLineItem, [
                        row.uuid,
                        crow.uuid,
                      ]) && ltxLevel2ChildLineItem[row.uuid][crow.uuid].length
                    )
                  "
                  :crow="crow"
                  :row-uuid="row.uuid"
                  :cindex="cindex"
                  :product-id="row.product_id"
                  :index="index"
                  :next-index="
                    ltxChildLineItem[row.uuid][cindex + 1] ? true : false
                  "
                  v-on:add-child="addIndexChild($event, cindex)"
                />

                <template
                  v-if="
                    itemInstance.hasProperty(ltxLevel2ChildLineItem, [
                      row.uuid,
                      crow.uuid,
                    ]) && ltxLevel2ChildLineItem[row.uuid][crow.uuid].length
                  "
                >
                  <template
                    v-for="(clrow, clindex) in ltxLevel2ChildLineItem[row.uuid][
                      crow.uuid
                    ]"
                  >
                    <line-item-level-three
                      :item-instance="itemInstance"
                      :key="`line-item-level-three-${index}-${cindex}-${clindex}`"
                      :row-uuid="row.uuid"
                      :crow-uuid="crow.uuid"
                      :clrow="clrow"
                      :cindex="cindex"
                      :clindex="clindex"
                      :product-id="row.product_id"
                      :index="index"
                      :next-index="
                        ltxLevel2ChildLineItem[row.uuid][crow.uuid][clindex + 1]
                          ? false
                          : true
                      "
                      v-on:add-child="addIndexChild($event, cindex)"
                    />
                  </template>
                </template>
              </div>
            </template>

            <div class="d-flex w-100 custom-border-top pt-3 mt-3">
              <div style="width: 5%"></div>
              <div style="width: 90%">
                <v-btn
                  :disabled="!row.product_id"
                  v-on:click="addChildItem(row.uuid, row.product_id)"
                  small
                  depressed
                  color="cyan white--text"
                  class="mb-4 custom-bold-button white--text"
                >
                  <v-icon>mdi-plus</v-icon> Add Additional Work (Same Category)
                </v-btn>
                <br />
                <v-btn
                  :disabled="!row.product_id"
                  v-on:click="row.pagebreak = !row.pagebreak"
                  small
                  depressed
                  color="cyan white--text"
                  class="custom-bold-button white--text"
                >
                  <span v-if="row.pagebreak"
                    ><v-icon>mdi-minus</v-icon> Remove Page Break</span
                  >
                  <span v-else><v-icon>mdi-plus</v-icon> Add Page Break</span>
                </v-btn>
              </div>
              <div style="width: 5%"></div>
            </div>
          </template>

          <div
            v-if="row.pagebreak"
            class="d-flex w-100 custom-border-top mt-4 align-center"
          >
            <div
              style="width: 90%"
              class="text-center d-flex justify-space-around"
            >
              <p class="my-0 color-custom-blue font-weight-700">PAGE BREAK</p>
              <p class="my-0 color-custom-blue font-weight-700">PAGE BREAK</p>
              <p class="my-0 color-custom-blue font-weight-700">PAGE BREAK</p>
            </div>
            <div style="width: 10%" class="text-right">
              <v-btn
                v-on:click="row.pagebreak = false"
                color="red lighten-1"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>

          <div v-if="index != (dbLineItem.length - 1)" class="d-flex w-100">
            <div style="width: 5%"> </div>
            <div style="width: 95%">
              <v-btn
                v-on:click="add_adtitle(index)"
                small
                depressed
                color="orange darken-4 white--text"
                class="custom-bold-button"
              >
                <v-icon>mdi-plus</v-icon> Add Additional Work Title
                (New Title)
              </v-btn>
            </div>
          </div>
        </div>  
      </transition-group>
    </vue-draggable>
    <template v-if="!dbLineItem.length">
      <div
        style="font-weight: 700; font-size: 16px"
        class="custom-grey-border text-center py-2 red--text text--darken-1"
      >
        No Line Item Found
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_ITEM,
  EMIT_VALUE,
  ADD_CHILD_ITEM,
  SET_ADTITLE,
  SET_ADWORK,
  SET_LINE_ITEM,
} from "@/core/services/store/line.item.module";
import { GET } from "@/core/services/store/request.module";
import VueDraggable from "vuedraggable";
import LineItemLevel1 from "@/view/pages/partials/Line-Item/Line-Item-Level-1.vue";
import LineItemLevel2 from "@/view/pages/partials/Line-Item/Line-Item-Level-2.vue";
import LineItemLevel3 from "@/view/pages/partials/Line-Item/Line-Item-Level-3.vue";

export default {
  props: {
    itemInstance: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    "vue-draggable": VueDraggable,
    "line-item-level-one": LineItemLevel1,
    "line-item-level-two": LineItemLevel2,
    "line-item-level-three": LineItemLevel3,
  },
  methods: {
    add_adtitle(index) {
      this.$store.dispatch(ADD_ITEM, index);
    },
    moveDragElement(e) {
      const windowHeight = window.innerHeight;
      if (e.screenY < 300) {
        window.scrollBy({
          top: -100,
          left: 0,
          behavior: "smooth",
        });
      }
      if (e.screenY > windowHeight - 100) {
        window.scrollBy({
          top: 100,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    emitValue() {
      this.$store.dispatch(EMIT_VALUE);
    },
    sequenceChange(param) {
      console.log(param, "sequenceChange");
    },
    getAdtitle() {
      this.$store
        .dispatch(GET, { url: "adtitle/all" })
        .then(({ data }) => {
          this.$store.commit(SET_ADTITLE, data);
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    addIndexChild({ rowUuid, productId }, index) {
      this.$store.dispatch(ADD_CHILD_ITEM, {
        uuid: rowUuid,
        productId: productId,
        indexToInsert: index,
      });
    },
    addChildItem(uuid, productId) {
      this.$store.dispatch(ADD_CHILD_ITEM, {
        uuid,
        productId,
        indexToInsert: -1,
      });
    },
    getAdditionalWorkList() {
      this.$store
        .dispatch(GET, { url: "line-item-services" })
        .then(({ data }) => {
          this.$store.commit(SET_ADWORK, data.line_items);
        })
        .catch((error) => {
          console.log({ error });
        });
    },
  },
  mounted() {
    this.getAdtitle();
    this.getAdditionalWorkList();
  },
  computed: {
    ...mapGetters([
      "ltxLineItem",
      "ltxChildLineItem",
      "ltxLevel2ChildLineItem",
    ]),
    dbLineItem: {
      get() {
        return this.ltxLineItem;
      },
      set(value) {
        this.$store.commit(SET_LINE_ITEM, value);
      },
    },
  },
};
</script>
