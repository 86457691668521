<template>
  <div class="custom-line-item-template">
    <table
      width="100%"
      class="line-item-multiple-table"
      style="table-layout: fixed"
    >
      <thead>
        <tr>
          <th height="40px" width="55%" class="cyan--text pl-4 font-size-16">
            {{ itemTitle }} ({{ line_items.length }})
          </th>
          <th
            height="40px"
            width="15%"
            class="cyan--text font-size-16"
            align="right"
          >
            Quantity
          </th>
          <th
            height="40px"
            width="15%"
            class="cyan--text font-size-16"
            align="right"
          >
            Rate
          </th>
          <th
            height="40px"
            width="15%"
            class="cyan--text font-size-16 pr-4"
            align="right"
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading">
          <tr>
            <td colspan="4">
              <div class="my-10 mx-5 d-flex align-center justify-center">
                <v-progress-circular
                  :width="3"
                  color="cyan"
                  indeterminate
                ></v-progress-circular>
                <p class="font-size-16 mx-5 my-0 font-weight-600">Loading...</p>
              </div>
            </td>
          </tr>
        </template>
        <template v-else-if="line_items.length">
          <template v-for="(row, index) in line_items">
            <tr
              :key="`line-item-package-${index}`"
              class="line-item-package-row"
              :class="{
                'line-item-odd-row': is_odd_no(index),
                'line-item-even-row': is_even_no(index),
              }"
            >
              <td rowspan="2" valign="top" width="55%" class="pl-4">
                <br />
                <v-layout>
                  <v-flex class="text-center mx-2" v-if="row.product_image">
                    <v-avatar size="80">
                      <v-img contain :src="row.product_image"></v-img>
                    </v-avatar>
                  </v-flex>
                  <v-flex class="px-2 custom-grey-border" md12>
                    <v-layout class="my-1">
                      <v-flex>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="custom-status font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="orange"
                            >
                              {{ row.product_type }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize">Type</span>
                        </v-tooltip>
                      </v-flex>
                      <v-flex class="mx-2">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="custom-status font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="cyan"
                            >
                              {{ row.barcode }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize"
                            >{{ row.product_type }} #</span
                          >
                        </v-tooltip>
                      </v-flex>
                      <v-flex md10 class="font-size-16 font-weight-500">{{
                        row.product
                      }}</v-flex>
                    </v-layout>
                    <p
                      class="font-size-16 py-1 font-weight-400 custom-border-top"
                      style="white-space: pre-line"
                    >
                      <span v-html="row.description"></span>
                    </p>
                  </v-flex>
                </v-layout>
                <br />
              </td>
              <td height="100px" valign="top" width="15%" align="right">
                <br />
                <p
                  class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1"
                >
                  <span class="d-flex justify-content-between">
                    <span>{{ formatNumber(row.quantity) }}</span>
                    <span>{{ row.uom }}</span>
                  </span>
                </p>
              </td>
              <td height="100px" valign="top" width="15%" align="right">
                <br />
                <p
                  class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1"
                >
                  {{ formatMoneyTwoDecimal(row.rate) }}
                </p>
                <br />
                <p class="w-100 my-1 text-left font-size-14 font-weight-400">
                  Supplier Cost:&nbsp;&nbsp;<label
                    class="m-0 p-0 font-weight-500"
                    >{{ formatMoneyTwoDecimal(row.supplier_cost) }}</label
                  >
                </p>
                <p class="w-100 my-1 text-left font-size-14 font-weight-400">
                  Profit:
                  <label class="m-0 p-0 font-weight-500">{{
                    formatMoneyTwoDecimal(row.total_profit)
                  }}</label>
                </p>
                <p class="w-100 mt-1 text-left font-size-14 font-weight-400">
                  Markup (in %):
                  <label class="m-0 p-0 font-weight-500"
                    >{{ formatNumber(row.total_markup_percent) }}%</label
                  >
                </p>
              </td>
              <td
                height="100px"
                valign="top"
                width="15%"
                align="right"
                class="pr-4"
              >
                <br />
                <p
                  class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1"
                >
                  {{ formatMoneyTwoDecimal(row.total) }}
                </p>
                <br />
                <p class="w-100 my-1 text-left font-size-14 font-weight-400">
                  Total Supplier Cost:&nbsp;&nbsp;<label
                    class="m-0 p-0 font-weight-500"
                    >{{ formatMoneyTwoDecimal(row.total_company_cost) }}</label
                  >
                </p>
                <p class="w-100 my-3 text-left font-size-14 font-weight-400">
                  Profit (in %):
                  <label class="m-0 p-0 font-weight-500"
                    >{{ formatNumber(row.total_profit_percent) }}%</label
                  >
                </p>
              </td>
            </tr>
            <tr
              :key="`line-item-package-add-${index}`"
              class="line-item-package-row"
              :class="{
                'line-item-odd-row': is_odd_no(index),
                'line-item-even-row': is_even_no(index),
              }"
            >
              <td
                v-if="row.internal_note"
                colspan="3"
                class="custom-grey-border"
              >
                <div class="px-4">
                  <label class="font-weight-700 font-size-16 py-3"
                    >Internal Note</label
                  >
                  <p
                    class="font-size-16 py-1 font-weight-400"
                    style="white-space: pre-line"
                  >
                    <span v-html="row.internal_note"></span>
                  </p>
                </div>
              </td>
            </tr>
            <tr
              v-for="(child, cindex) in row.line_items"
              :key="`line-item-package-${index}-${cindex}`"
              class="line-item-package-row"
              :class="{
                'line-item-odd-row': is_odd_no(index),
                'line-item-even-row': is_even_no(index),
              }"
            >
              <td valign="top" width="55%" class="pl-4">
                <br />
                <v-layout>
                  <v-flex class="text-center mx-2" v-if="child.product_image">
                    <v-avatar size="80">
                      <v-img contain :src="child.product_image"></v-img>
                    </v-avatar>
                  </v-flex>
                  <v-flex class="px-2 custom-grey-border" md12>
                    <v-layout class="my-1">
                      <v-flex>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="custom-status font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="cyan"
                            >
                              {{ get_product_type(child.product_type) }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize">Type</span>
                        </v-tooltip>
                      </v-flex>
                      <v-flex class="mx-2">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="custom-status font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="cyan"
                            >
                              {{ child.barcode }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize"
                            >{{ get_product_type(child.product_type) }} #</span
                          >
                        </v-tooltip>
                      </v-flex>
                      <v-flex md10 class="font-size-16 font-weight-500">{{
                        child.product
                      }}</v-flex>
                    </v-layout>
                    <p
                      class="font-size-16 py-1 font-weight-400 custom-border-top"
                      style="white-space: pre-line"
                    >
                      {{ child.description }}
                    </p>
                    <template v-if="child.is_optional">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            label
                            class="custom-status mr-2 font-weight-600 mb-2 custom-grey-border text-uppercase"
                            text-color="white"
                            color="orange"
                          >
                            Optional
                          </v-chip>
                        </template>
                        <span class="text-capitalize">Marked as Optional</span>
                      </v-tooltip>
                    </template>
                    <template v-if="child.strike">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            label
                            class="custom-status mb-2 font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            color="red"
                          >
                            Removed
                          </v-chip>
                        </template>
                        <span class="text-capitalize">Removed</span>
                      </v-tooltip>
                    </template>
                  </v-flex>
                </v-layout>
                <br />
              </td>
              <td valign="top" width="15%" align="right">
                <br />
                <p
                  class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1"
                >
                  {{ formatNumber(child.quantity) }}
                </p>
              </td>
              <td valign="top" width="15%" align="right"></td>
              <td valign="top" width="15%" align="right" class="pr-4"></td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <p
                class="m-0 my-5 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no
                <span class="text-lowercase">{{ itemTitle }}</span>
                at the moment.
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ObjectPath from "object-path";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "line-item",
  model: {
    prop: "value",
    event: "input",
  },
  mixins: [CommonMixin],
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        if (!this.lodash.isEqual(param, this.line_items)) {
          this.init_line_item(param);
        }
      },
    },
  },
  props: {
    value: {
      type: Array,
      default() {
        return new Array();
      },
    },
    itemTitle: {
      type: String,
      default: "Item",
    },
  },
  data() {
    return {
      line_items: [],
      loading: false,
    };
  },
  methods: {
    get_product_type(type) {
      if (type == "service") {
        return "Additional Work";
      } else {
        return type;
      }
    },
    is_odd_no(index) {
      return Math.abs(index % 2) == 1;
    },
    is_even_no(index) {
      return index % 2 == 0;
    },
    remove_row(index) {
      ObjectPath.del(this.line_items, index);
      this.$nextTick(() => {
        this.emit_value();
      });
    },
    remove_child_row(index, cindex) {
      ObjectPath.del(this.line_items, `${index}.line_items.${cindex}`);
      const line_items = ObjectPath.get(
        this.line_items,
        `${index}.line_items`,
        []
      );

      if (line_items.length < 1) {
        ObjectPath.del(this.line_items, index);
      }

      this.$nextTick(() => {
        this.emit_value();
      });
    },
    init_line_item(param) {
      const line_item = this.lodash.cloneDeep(param);
      if (line_item && line_item.length) {
        for (let i = 0; i < line_item.length; i++) {
          const index = this.lodash.findIndex(this.line_items, {
            product_id: line_item[i].product_id,
          });
          if (index >= 0) {
            this.line_items[index].status = line_item[i].status;
            this.line_items[index].product = line_item[i].product;
            this.line_items[index].product_type = line_item[i].product_type;
            this.line_items[index].product_id = line_item[i].product_id;
            this.line_items[index].description = line_item[i].description;
            this.line_items[index].quantity = line_item[i].quantity;
            this.line_items[index].rate = line_item[i].rate;
            this.line_items[index].total = line_item[i].total;
            this.line_items[index].strike = line_item[i].strike;
            this.line_items[index].company_cost = line_item[i].company_cost;
            this.line_items[index].charges = line_item[i].charges;
            this.line_items[index].profit = line_item[i].profit;
            this.line_items[index].is_optional = line_item[i].is_optional;
            this.line_items[index].line_items = line_item[i].line_items;
          } else {
            this.line_items.push(line_item[i]);
          }
        }
      }
    },
    get_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit = line_item.rate - line_item.company_cost;
      return this.formatMoneyTwoDecimal(profit);
    },
    get_total_company_cost(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const company_cost = line_item.company_cost * line_item.quantity;
      return this.formatMoneyTwoDecimal(company_cost);
    },
    get_total_charges(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.rate * line_item.quantity;
      return this.formatMoneyTwoDecimal(charges);
    },
    get_net_price(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.net_price * line_item.quantity;
      return this.formatMoneyTwoDecimal(charges);
    },
    get_total_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit =
        (line_item.rate - line_item.company_cost) * line_item.quantity;
      return this.formatMoneyTwoDecimal(profit);
    },
    update_value(index, cindex) {
      const line_item = ObjectPath.get(
        this.line_items,
        `${index}.line_items.${cindex}`,
        []
      );
      const { rate, quantity } = line_item;
      line_item.total = rate * quantity;
      ObjectPath.set(
        this.line_items,
        `${index}.line_items.${cindex}`,
        line_item
      );
      this.$nextTick(() => {
        this.emit_value();
      });
    },
    emit_value() {
      this.$nextTick(() => {
        this.$emit("input", this.line_items);
        this.$emit("change", this.line_items);
      });
    },
    get_line_items() {
      this.$store
        .dispatch(QUERY, {
          url: "line-item-services",
          data: {
            type: this.itemType,
            search: this.search,
            alphabet: this.alphabet,
            category: this.category,
          },
        })
        .then((response) => {
          this.line_items = ObjectPath.get(response, "data.line_items", []);
          this.categories = ObjectPath.get(response, "data.categories", []);
          this.emit_value();
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
