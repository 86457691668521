<template>
  <span v-if="tooltip">
    <v-tooltip top content-class="custom-top-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <span
          :class="{ 'text-truncate': truncate }"
          v-html="value()"
          v-on="on"
          v-bind="attrs"
        >
        </span>
      </template>
      <span v-html="getTooltipValue()"> </span>
    </v-tooltip>
  </span>
  <span v-else :class="{ 'text-truncate': truncate }" v-html="value()"> </span>
</template>
<script>
import objectPath from "object-path";
export default {
  name: "show-value",
  title: "Show Value",
  props: {
    object: {
      type: Object,
      default() {
        return new Object();
      },
    },
    objectKey: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    tooltipValue: {
      type: String,
      default: null,
    },
  },
  methods: {
    value() {
      if (objectPath.has(this.object, this.objectKey)) {
        if (objectPath.get(this.object, this.objectKey)) {
          return objectPath.get(this.object, this.objectKey);
        } else {
          return `<em class="text-muted">No ${this.label}</em>`.toLowerCase();
        }
      } else {
        return `<em class="text-muted">No ${this.label}</em>`.toLowerCase();
      }
    },
    getTooltipValue() {
      if (objectPath.has(this.object, this.tooltipValue)) {
        if (objectPath.get(this.object, this.tooltipValue)) {
          return objectPath.get(this.object, this.tooltipValue);
        } else {
          return `<em class="text-muted">No ${this.label}</em>`.toLowerCase();
        }
      } else {
        return `<em class="text-muted">No ${this.label}</em>`.toLowerCase();
      }
    },
  },
};
</script>
