<template>
  <!--begin::Meeting Internal Listing-->
  <v-container fluid class="pa-0">
    <v-container>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllMeetings"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            @keydown.enter="getAllMeetings"
            @keydown.tab="getAllMeetings"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="pageLoading"
                :loading="pageLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="pageLoading"
              :loading="pageLoading"
              v-on:change="getAllMeetings"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" class="d-flex">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="pageLoading"
                :loading="pageLoading"
                v-on:click="getAllMeetings"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template v-if="detail.status < 5">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-on:click="meetingDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Meeting</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="6"
      :pageLoading.sync="pageLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Title</th>
            <th class="simple-table-th">Duration</th>
            <th class="simple-table-th">Location</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th" width="200">Created Time</th>
            <th v-if="false" class="simple-table-th" width="200">
              Last Modified Time
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="updateNotification(row)"
            >
              <td class="simple-table-td">
                <div>{{ row.title }}</div>
              </td>
              <td class="simple-table-td">
                {{ formatDateTime(row.start_date_time) }} -
                {{ formatDateTime(row.end_date_time) }}
              </td>
              <td class="simple-table-td">
                {{ row.location }}
              </td>
              <td class="simple-table-td">
                <v-chip color="cyan white--text" label>{{
                  row.status_text
                }}</v-chip>
              </td>
              <td class="simple-table-td" width="200">
                <TableActivity
                  v-if="!lodash.isEmpty(row.added_by)"
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
              <td v-if="false" class="simple-table-td" width="200">
                <TableActivity
                  v-if="!lodash.isEmpty(row.updated_by)"
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.updated_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.modified_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.updated_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!pageLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no meeting at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <Dialog :commonDialog="meetingDialog" :dialogWidth="dialogWidth">
      <template v-slot:title
        >{{ meetingTitle }} for
        <span class="ml-3 form-title-create-link">{{
          customerName
        }}</span></template
      >
      <template v-slot:body>
        <v-container v-if="meetingDialog" fluid>
          <v-form
            ref="meetingForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate()"
          >
            <v-layout>
              <v-flex md3 class="my-auto">
                <label for="title" class="required m-0">Title</label>
              </v-flex>
              <v-flex md9>
                <v-text-field
                  id="title"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="meeting.title"
                  :rules="[
                    validateRules.required(meeting.title, 'title'),
                    validateRules.minLength(meeting.title, 'title', 2),
                    validateRules.maxLength(meeting.title, 'title', 100),
                  ]"
                  dense
                  filled
                  label="Title"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3 class="my-auto">
                <label for="reference" class="m-0">Reference</label>
              </v-flex>
              <v-flex md9>
                <v-text-field
                  id="reference"
                  v-model.trim="meeting.reference"
                  :rules="[
                    validateRules.minLength(meeting.reference, 'reference', 2),
                    validateRules.maxLength(
                      meeting.reference,
                      'reference',
                      100
                    ),
                  ]"
                  dense
                  filled
                  label="Reference"
                  solo
                  flat
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3 class="my-auto">
                <label for="start-date-time" class="m-0 required"
                  >Start Date Time</label
                >
              </v-flex>
              <v-flex md3>
                <DateTimePicker
                  required
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="start-date-time"
                  label="Start Date Time"
                  :min-date="meetingMinDate"
                  v-model="meeting.start_date_time"
                ></DateTimePicker>
              </v-flex>
              <v-flex md3 class="my-auto text-right">
                <label for="end-date-time" class="pr-4 m-0 required"
                  >End Date Time</label
                >
              </v-flex>
              <v-flex md3>
                <DateTimePicker
                  required
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="end-date-time"
                  label="End Date Time"
                  :min-date="dateFormat(meeting.start_date_time)"
                  v-model="meeting.end_date_time"
                ></DateTimePicker>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3 class="my-auto">
                <label for="location" class="m-0">Location</label>
              </v-flex>
              <v-flex md9>
                <v-menu offset-y elevation="0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="location"
                      v-model.trim="meeting.location"
                      :rules="[
                        validateRules.minLength(
                          meeting.location,
                          'location',
                          2
                        ),
                        validateRules.maxLength(
                          meeting.location,
                          'location',
                          100
                        ),
                      ]"
                      dense
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading || autocompleteLoading"
                      v-on:keyup="getGoogleAddress()"
                      prepend-inner-icon="mdi-map-marker"
                      label="Location"
                      solo
                      flat
                      color="cyan"
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-list v-if="autocompleteList.length">
                    <v-list-item
                      v-for="(address, index) in autocompleteList"
                      two-line
                      elevation="0"
                      class="custom-google-autocomplete-list"
                      :key="index"
                      link
                      v-on:click="selectGoogleAddress(index)"
                    >
                      <v-list-item-icon class="m-0 mr-3 my-auto">
                        <v-icon color="cyan"> mdi-map-marker </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content
                        class="py-1"
                        v-if="!lodash.isEmpty(address.structured_formatting)"
                      >
                        <v-list-item-title class="font-size-14">{{
                          address.structured_formatting.main_text
                        }}</v-list-item-title>
                        <v-list-item-subtitle class="font-size-12">{{
                          address.structured_formatting.secondary_text
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content class="py-1" v-else>
                        <v-list-item-title class="font-size-14">{{
                          address.description
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3 class="my-auto">
                <label for="url" class="m-0">Website URL</label>
              </v-flex>
              <v-flex md9>
                <v-text-field
                  id="url"
                  v-model.trim="meeting.url"
                  :rules="[
                    validateRules.minLength(meeting.url, 'url', 2),
                    validateRules.maxLength(meeting.url, 'url', 100),
                  ]"
                  dense
                  filled
                  label="Website URL"
                  solo
                  flat
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3 class="my-auto">
                <label for="url" class="m-0">Meeting URL</label>
              </v-flex>
              <v-flex md9>
                <v-text-field
                  id="url"
                  v-model.trim="meeting.meeting_url"
                  :rules="[
                    validateRules.minLength(
                      meeting.meeting_url,
                      'meeting_url',
                      2
                    ),
                    validateRules.maxLength(
                      meeting.meeting_url,
                      'meeting_url',
                      100
                    ),
                  ]"
                  dense
                  filled
                  label="Meeting URL"
                  solo
                  flat
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout v-if="false" class="py-2">
              <v-flex md3>
                <label class="mt-3 mb-0">Notifications</label>
              </v-flex>
              <v-flex md9>
                <v-layout
                  v-for="(row, index) in meeting.notifications"
                  :key="index"
                >
                  <v-flex md3>
                    <v-select
                      dense
                      color="cyan"
                      filled
                      v-model="row.notification_type"
                      :items="notificationTypeList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    ></v-select>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      dense
                      filled
                      v-model="row.duration"
                      label="URL"
                      solo
                      flat
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-mask="'##'"
                      color="cyan"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-select
                      dense
                      color="cyan"
                      filled
                      v-model="row.duration_type"
                      :items="durationTypeList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    ></v-select>
                  </v-flex>
                  <v-flex md4>
                    <v-text-field
                      dense
                      filled
                      solo
                      prefix="before at"
                      flat
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="row.time"
                      color="cyan"
                      v-mask="'##:##'"
                      hide-details
                      append-outer-icon="mdi-delete"
                      class="has-delete-outer-icon"
                      v-on:click:append-outer="deleteNotification(index)"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-btn
                  class="custom-bold-button"
                  :disabled="pageLoading"
                  v-if="meeting.notifications.length < 5"
                  :class="{ 'mt-2': meeting.notifications.length }"
                  depressed
                  v-on:click="addNotification()"
                >
                  <v-icon left>mdi-bell-outline</v-icon> Add Notification
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout v-if="false" class="py-2">
              <v-flex md3 class="my-auto">
                <label for="customer-invited" class="m-0"
                  >Customer Invited ?</label
                >
              </v-flex>
              <v-flex md9>
                <v-switch
                  id="customer-invited"
                  v-model="meeting.customer_invited"
                  class="m-0"
                  inset
                  hide-details
                  color="cyan"
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3 class="my-auto">
                <label for="members" class="m-0">Members</label>
              </v-flex>
              <v-flex md9>
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="members"
                  :items="memberList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="meeting.guests"
                  label="Members"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.full_name }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text font-size-12">
                      (+{{ meeting.guests.length - 3 }} others)
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Member Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3>
                <label for="remark" class="mt-3 mb-0">Remarks</label>
              </v-flex>
              <v-flex md9>
                <v-textarea
                  id="remark"
                  v-model.trim="meeting.remark"
                  :rules="[
                    validateRules.minLength(meeting.remark, 'remark', 2),
                    validateRules.maxLength(meeting.remark, 'remark', 100),
                  ]"
                  dense
                  filled
                  label="Remarks"
                  solo
                  flat
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3>
                <label for="status" class="mt-3 mb-0">Status</label>
              </v-flex>
              <v-flex md9>
                <v-select
                  :items="statusDBList"
                  dense
                  color="cyan"
                  solo
                  flat
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model="meeting.status"
                  item-color="cyan"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout class="py-2">
              <v-flex md3 class="my-auto">
                <label for="members" class="m-0">File Attachment</label>
              </v-flex>
              <v-flex md9>
                <AttachmentWithName
                  v-on:file:updated="updateAttachment"
                  :label="true"
                  :attachments.sync="attachments"
                  allowUpload
                ></AttachmentWithName>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="closeDialog()"
          :disabled="pageLoading"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!formValid || pageLoading"
          :loading="pageLoading"
          class="mx-2 custom-bold-button white--text"
          v-on:click="updateOrCreate()"
          color="cyan"
        >
          Save Meeting
        </v-btn>
      </template>
    </Dialog>
  </v-container>
  <!--end::Meeting Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { QUERY, POST, PUT } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DateTimePicker from "@/view/pages/partials/DateTimePicker.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "meeting-internal-list",
  data() {
    return {
      dates: [],
      statusList: [
        {
          value: "all",
          text: "All",
          description: "List contains all meetings",
        },
        {
          value: "completed",
          text: "Completed",
          description: "List contains all completed meetings",
        },
        {
          value: "pending",
          text: "Pending",
          description: "List contains all pending meetings",
        },
        {
          value: "cancelled",
          text: "Cancelled",
          description: "List contains all cancelled meetings",
        },
        {
          value: "re-scheduled",
          text: "Re-Scheduled",
          description: "List contains all re-scheduled meetings",
        },
      ],
      statusDBList: [
        {
          value: "completed",
          text: "Completed",
          description: "List contains all completed meetings",
        },
        {
          value: "pending",
          text: "Pending",
          description: "List contains all pending meetings",
        },
        {
          value: "cancelled",
          text: "Cancelled",
          description: "List contains all cancelled meetings",
        },
        {
          value: "re-scheduled",
          text: "Re-Scheduled",
          description: "List contains all re-scheduled meetings",
        },
      ],
      dataRows: [],
      attachments: [],
      memberList: [],
      pageLoading: true,
      meetingDialog: false,
      meetingTitle: "Create Meeting",
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      meetingId: null,
      meeting: {
        reference: null,
        customer: null,
        //project: null,
        //lead: null,
        //quotation: null,
        title: null,
        start_date_time: null,
        customer_invited: true,
        end_date_time: null,
        location: null,
        remark: null,
        guests: [],
        notifications: [],
        url: null,
        meeting_url: null,
        attachments: [],
      },
      meetingMinDate: null,
      isFocused: false,
      searchAddress: false,
      autocompleteLoading: false,
      autocompleteList: [],
      notificationTypeList: [
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Notification",
          value: "notification",
        },
      ],
      durationTypeList: [
        {
          text: "Days",
          value: "days",
        },
        {
          text: "Weeks",
          value: "weeks",
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "lead",
    },
    typeId: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    customerName: {
      type: String,
      default: null,
    },
  },
  components: {
    Dialog,
    DateTimePicker,
    TableActivity,
    ListingTable,
    AttachmentWithName,
  },
  watch: {},
  methods: {
    dateFormat(date) {
      if (!date) {
        return null;
      }
      return moment(date).format("YYYY-MM-DD");
    },
    updateNotification(row) {
      this.meetingDialog = true;
      this.meetingTitle = `Update Meeting | ${row.title}`;
      this.meetingId = row.id;
      this.attachments = row.meeting_images;
      this.meeting = {
        reference: row.reference || null,
        customer: row.customer || null,
        project: row.project || null,
        lead: row.lead || null,
        //quotation: row.quotation || null,
        customer_invited: row.customer_invited || null,
        title: row.title || null,
        start_date_time: row.start_date_time || null,
        end_date_time: row.end_date_time || null,
        location: row.location || null,
        remark: row.remark || null,
        guests: row.guests ? row.guests.map((rw) => rw.guest) : [],
        notifications: row.notifications || [],
        url: row.url || null,
        meeting_url: row.url || null,
      };

      if (row.status == 1) {
        this.meeting.status = "completed";
      }

      if (row.status == 2) {
        this.meeting.status = "pending";
      }

      if (row.status == 3) {
        this.meeting.status = "cancelled";
      }

      if (row.status == 4) {
        this.meeting.status = "re-scheduled";
      }
    },
    addNotification() {
      this.meeting.notifications.push({
        notification_type: "email",
        duration: 1,
        duration_type: "days",
        time: "09:00",
      });
    },
    deleteNotification(index) {
      this.meeting.notifications.splice(index, 1);
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.meeting.location && _this.meeting.location.length > 1) {
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.meeting.location },
              })
              .then(({ data }) => {
                _this.autocompleteList = data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    selectGoogleAddress(index) {
      this.meeting.location = this.autocompleteList[index].description;
    },
    closeDialog() {
      this.meetingDialog = false;
      this.meetingTitle = "Create Meeting";
      this.meetingId = null;
      this.meeting = {
        reference: null,
        customer: null,
        project: null,
        lead: null,
        // quotation: null,
        customer_invited: null,
        title: null,
        start_date_time: null,
        end_date_time: null,
        location: null,
        remark: null,
        status: null,
        guests: [],
        notifications: [],
        url: null,
      };
    },
    updateOrCreate() {
      const _this = this;
      try {
        const formErrors = _this.validateForm(_this.$refs.meetingForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!_this.$refs.meetingForm.validate()) {
          return false;
        }

        const formData = {
          reference: this.meeting.reference || null,
          customer: this.meeting.customer || null,
          project: this.meeting.project || null,
          lead: this.meeting.lead || null,
          //quotation: this.meeting.quotation || null,
          title: this.meeting.title || null,
          customer_invited:
            _this.lodash.toSafeInteger(this.meeting.customer_invited) || null,
          start_date_time: this.meeting.start_date_time
            ? moment(this.meeting.start_date_time).format("YYYY-MM-DD HH:mm")
            : null,
          end_date_time: this.meeting.end_date_time
            ? moment(this.meeting.end_date_time).format("YYYY-MM-DD HH:mm")
            : null,
          location: this.meeting.location || null,
          remark: this.meeting.remark || null,
          guests: this.meeting.guests || [],
          notifications: this.meeting.notifications || [],
          url: this.meeting.url || null,
          status: this.meeting.status || null,
          meeting_url: this.meeting.meeting_url || null,
          attachments: this.meeting.attachments || null,
        };
        _this.pageLoading = true;
        let requestType = POST;
        let requestURL = `meetings/${_this.type}/${_this.typeId}`;
        if (_this.meetingId) {
          requestType = PUT;
          requestURL = `meetings/${_this.type}/${_this.typeId}/${_this.meetingId}`;
        }
        _this.$store
          .dispatch(requestType, { url: requestURL, data: formData })
          .then(() => {
            _this.closeDialog();
            _this.getAllMeetings();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllMeetings();
    },
    getAllMeetings() {
      const _this = this;
      let filter = {
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.pageLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: `meetings/${_this.type}/${_this.typeId}`,
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data;
            /*  _this.attachments = data && data.meeting_images ? data.meeting_images : []
            console.log() */
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }, _this.timeoutLimit);
    },
    getUsers() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, { url: "user", data: { status: "active" } })
        .then(({ data }) => {
          _this.memberList = data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateAttachment(param) {
      this.meeting.attachments = param;
    },
  },

  mounted() {
    this.getUsers();
    this.getAllMeetings();
    this.meetingMinDate = moment().subtract(7, "days").format("YYYY-MM-DD");
  },
  computed: {
    isCustomer() {
      return this.type === "customer";
    },
    isProject() {
      return this.type === "project";
    },
    isLead() {
      return this.type === "lead";
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
