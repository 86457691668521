<template>
  <div>
    <v-layout class="align-center">
      <v-flex md6 class="px-2">
        <label class="font-size-18 font-weight-600 mb-0">Documents</label>
      </v-flex>
      <v-flex md6 class="text-right my-2">
        <v-btn
          v-if="file_checkbox.length > 1"
          class="mr-2 custom-bold-button"
          color="cyan white--text"
          v-on:click="open_merge_dialog()"
        >
          <v-icon>mdi-table-merge-cells</v-icon> Merge Documents
        </v-btn>
        <v-btn
          v-on:click="upload_dialog = true"
          class="ml-2 custom-bold-button"
          color="cyan white--text"
        >
          <v-icon>mdi-upload</v-icon> Upload Document
        </v-btn>
      </v-flex>
    </v-layout>
    <div class="custom-listing-table">
      <v-simple-table class="simple-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th width="10%" class="simple-table-th">#</th>
              <th width="50%" class="simple-table-th">Name</th>
              <th v-if="false" width="40%" class="simple-table-th">
                Description
              </th>
              <th width="20%" class="simple-table-th">Type</th>
              <th width="20%" class="simple-table-th">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in related_files" :key="index">
              <td width="10%" class="simple-table-td" valign="middle">
                <v-checkbox
                  v-model="file_checkbox"
                  :value="row.id"
                  class="my-0 py-0 mx-0 px-0"
                  color="cyan"
                  hide-details
                ></v-checkbox>
              </td>
              <td width="50%" class="simple-table-td">{{ row.name }}</td>
              <td v-if="false" width="40%" class="simple-table-td">
                {{ row.description }}
              </td>
              <td width="20%" class="simple-table-td">
                {{ get_related_type(row.type) }}
              </td>
              <td width="20%" class="simple-table-td">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="download_related_pdf(row, 'raw_path')"
                      :disabled="!row.raw_path"
                      class="mr-2"
                      color="blue darken-4"
                      icon
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-capitalize">Download {{ row.name }}</span>
                </v-tooltip>
                <v-tooltip top v-if="false" content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="download_related_pdf(row, 'signed_path')"
                      :disabled="!row.signed_path"
                      class="mr-2"
                      color="blue darken-4"
                      icon
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-capitalize"
                    >Download Signed {{ row.name }}</span
                  >
                </v-tooltip>
                <v-tooltip top v-if="false" content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="upload_related_pdf(row)"
                      class="mr-2"
                      color="blue darken-4"
                      icon
                    >
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-capitalize"
                    >Upload Signed {{ row.name }}</span
                  >
                </v-tooltip>
                <v-tooltip top v-if="false" content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="view_related_pdf(row)"
                      :disabled="!row.is_pdf"
                      class="mr-2"
                      color="blue darken-4"
                      icon
                    >
                      <v-icon>mdi-file-pdf</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-capitalize">View {{ row.name }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <Dialog :common-dialog="upload_dialog" dense>
      <template v-slot:title> Upload Document </template>
      <template v-slot:body>
        <div class="px-4">
          <v-form
            ref="upload_form"
            v-model="upload_valid"
            lazy-validation
            v-on:submit.stop.prevent="submit_documents()"
          >
            <table width="100%">
              <tbody>
                <tr v-for="(row, index) in upload_documents" :key="index">
                  <td width="50%" class="px-2 py-2">
                    <v-file-input
                      prepend-inner-icon="mdi-attachment mdi-rotate-90"
                      placeholder="Click here to select file"
                      label="Click here to select file"
                      prepend-icon=""
                      color="cyan"
                      solo
                      flat
                      v-model="row.upload_file"
                      dense
                      v-on:change="update_upload_name(index)"
                      :rules="[
                        validateRules.file_required(row.upload_file, 'File'),
                      ]"
                      hide-details
                    ></v-file-input>
                  </td>
                  <td width="50%" class="px-2 py-2">
                    <v-text-field
                      placeholder="File Name"
                      label="File Name"
                      dense
                      solo
                      flat
                      color="cyan"
                      v-model="row.file_name"
                      hide-details
                      class="has-delete-outer-icon"
                      append-outer-icon="mdi-delete"
                      v-on:click:append-outer="remove_upload_row(index)"
                      :suffix="get_file_extension(row.file_type)"
                      :rules="[
                        validateRules.required(row.file_name, 'File Name'),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td height="10px" colspan="2"></td>
                </tr>
                <tr v-if="upload_documents.length < 5">
                  <td colspan="2" class="px-2">
                    <v-btn
                      color="cyan white--text"
                      small
                      :disabled="upload_loading"
                      v-on:click="add_upload_row()"
                      class="custom-bold-button"
                      >Add More...</v-btn
                    >
                  </td>
                </tr>
                <tr>
                  <td height="10px" colspan="2"></td>
                </tr>
              </tbody>
            </table>
          </v-form>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="submit_documents()"
          :disabled="!upload_valid || upload_loading"
          :loading="upload_loading"
        >
          Submit
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="upload_dialog = false"
          :disabled="upload_loading"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="pdf_view_dialog" dense :dialog-width="dialogWidth">
      <template v-slot:title>
        <v-layout>
          <v-flex md6>
            {{ pdf_view_name }}
          </v-flex>
          <v-flex md6 class="text-right">
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="pdf_view_signed_dialog = true"
            >
              Upload Signed Copy
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="
                pdf_view_src = null;
                pdf_view_dialog = false;
              "
            >
              Close
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <div v-if="pdf_view_src">
          <pdf-viewer
            :src="pdf_view_src"
            :page="pdf_view_page"
            v-on:num-pages="pdf_view_count = $event"
            v-on:page-loaded="pdf_view_page = $event"
          ></pdf-viewer>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="pdf_view_page_prev()"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          outlined
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
        >
          {{ pdf_view_page }} / {{ pdf_view_count }}
        </v-btn>
        <v-btn
          v-on:click="pdf_view_page_next()"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="pdf_view_signed_dialog" dense>
      <template v-slot:title> Upload Signed {{ pdf_view_name }} </template>
      <template v-slot:body>
        <div class="px-4">
          <v-form
            ref="upload_signed_form"
            v-model="upload_signed_valid"
            lazy-validation
            v-on:submit.stop.prevent="submit_pdf_signed_copy()"
          >
            <table width="100%">
              <tr>
                <td width="50%" class="px-2 py-2">
                  <v-file-input
                    prepend-inner-icon="mdi-attachment mdi-rotate-90"
                    placeholder="Click here to select file"
                    label="Click here to select file"
                    prepend-icon=""
                    color="cyan"
                    solo
                    flat
                    v-model="pdf_signed_copy_file"
                    dense
                    v-on:change="update_signed_name()"
                    :rules="[
                      validateRules.file_required(pdf_signed_copy_file, 'File'),
                    ]"
                    hide-details
                    :disabled="pdf_signed_copy_loading"
                    :loading="pdf_signed_copy_loading"
                  ></v-file-input>
                </td>
                <td width="50%" class="px-2 py-2">
                  <v-text-field
                    placeholder="File Name"
                    label="File Name"
                    dense
                    solo
                    flat
                    color="cyan"
                    v-model="pdf_signed_copy_name"
                    hide-details
                    :suffix="get_file_extension(pdf_signed_copy_type)"
                    :rules="[
                      validateRules.required(pdf_signed_copy_name, 'File Name'),
                    ]"
                    :disabled="pdf_signed_copy_loading"
                    :loading="pdf_signed_copy_loading"
                  ></v-text-field>
                </td>
              </tr>
            </table>
          </v-form>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          :disabled="!upload_signed_valid || pdf_signed_copy_loading"
          :loading="pdf_signed_copy_loading"
          v-on:click="submit_pdf_signed_copy()"
        >
          Upload
        </v-btn>
        <v-btn
          :disabled="pdf_signed_copy_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="pdf_view_signed_dialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="merge_dialog" dense :dialog-width="dialogWidth">
      <template v-slot:title> Merge PDF </template>
      <template v-slot:body>
        <div class="px-4 my-3">
          <v-form
            ref="merge_form"
            v-model="merge_form_valid"
            lazy-validation
            v-on:submit.stop.prevent="merge_pdf_submit(0)"
          >
            <table width="100%" style="table-layout: fixed">
              <tr>
                <th width="50%" class="py-2 px-2">
                  <label class="my-0 py-0">Document Name</label>
                </th>
                <th width="30%" class="py-2 px-4">
                  <label class="my-0 py-0">Merge Document</label>
                </th>
                <th width="20%" class="py-2 px-2">
                  <label class="my-0 py-0">Sequence</label>
                </th>
              </tr>
              <tr v-for="(file, index) in file_selected" :key="index">
                <td width="50%" class="py-2">
                  <v-text-field
                    placeholder="Document Name"
                    label="Document Name"
                    dense
                    solo
                    flat
                    color="cyan"
                    v-model="file.name"
                    readonly
                    hide-details
                    :disabled="merge_pdf_loading"
                    :loading="merge_pdf_loading"
                  ></v-text-field>
                </td>
                <td width="30%" class="py-2 px-4">
                  <v-radio-group
                    dense
                    class="mx-0 px-0"
                    row
                    v-model="file.merge_key"
                    :disabled="merge_pdf_loading || !file.signed_path"
                  >
                    <v-radio
                      color="cyan"
                      label="Raw"
                      value="raw_path"
                    ></v-radio>
                    <v-radio
                      color="cyan"
                      label="Signed"
                      value="signed_path"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <td width="20%" class="py-2">
                  <v-text-field
                    :id="`sequence-${file.id}`"
                    placeholder="Sequence"
                    dense
                    solo
                    flat
                    color="cyan"
                    v-model="file.sequence"
                    hide-details
                    :disabled="merge_pdf_loading"
                    :loading="merge_pdf_loading"
                    :rules="[
                      validateRules.required(
                        file.sequence,
                        `Sequence ${file.name}`
                      ),
                    ]"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="3" width="100%" height="20px"></td>
              </tr>
              <tr>
                <td colspan="3" width="100%" class="py-2">
                  <div class="d-flex align-center justify-center">
                    <label
                      class="my-0 py-0 pl-2 mr-2 font-weight-600"
                      for="merged-pdf-document-name"
                      >Merged PDF Document Name</label
                    >
                    <v-text-field
                      id="merged-pdf-document-name"
                      placeholder="Enter Merged PDF Document Name"
                      dense
                      solo
                      flat
                      color="cyan"
                      hide-details
                      class="ml-2"
                      v-model="merge_pdf_name"
                      :disabled="merge_pdf_loading"
                      :loading="merge_pdf_loading"
                      :rules="[
                        validateRules.required(
                          merge_pdf_name,
                          'Merged PDF Document Name'
                        ),
                      ]"
                    ></v-text-field>
                  </div>
                </td>
              </tr>
            </table>
          </v-form>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          :disabled="!merge_form_valid || merge_pdf_loading"
          :loading="merge_pdf_loading"
          v-on:click="merge_pdf_submit(1)"
        >
          Preview
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          :disabled="!merge_form_valid || merge_pdf_loading"
          :loading="merge_pdf_loading"
          v-on:click="merge_pdf_submit(0)"
        >
          Submit
        </v-btn>
        <v-btn
          :disabled="merge_pdf_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="merge_dialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { PATCH, GET, POST } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import PdfViewer from "vue-pdf";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "related-documents",
  data() {
    return {
      related_files: [],
      file_checkbox: [],
      upload_documents: [
        {
          upload_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      upload_loading: false,
      upload_dialog: false,
      upload_valid: true,
      merge_form_valid: true,
      pdf_view_dialog: false,
      pdf_view_loaded: false,
      pdf_view_src: null,
      pdf_view_name: null,
      pdf_view: {},
      pdf_signed_copy_file: null,
      pdf_signed_copy_name: null,
      pdf_signed_copy_type: null,
      pdf_signed_copy_loading: false,
      quote_files: [],
      file_selected: [],
      merge_pdf_name: null,
      pdf_view_count: 1,
      pdf_view_page: 1,
      merge_pdf_loading: false,
      merge_dialog: false,
      merge_pdf_valid: true,
      upload_signed_valid: true,
      pdf_view_signed_dialog: false,
      merge_preview: false,
    };
  },
  props: {
    projectId: {
      type: Number,
      default: 0,
    },
    quotationId: {
      type: Number,
      default: 0,
    },
    preQuotation: {
      type: Boolean,
      default: false,
    },
    quotation: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    PdfViewer,
  },
  methods: {
    update_upload_name(index) {
      const file = this.upload_documents[index].upload_file;
      if (file) {
        this.upload_documents[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.upload_documents[index].file_type = file.name.split(".").pop();
      }
    },
    async submit_documents() {
      const validateStatus = this.$refs.upload_form.validate();

      const formErrors = this.validateForm(this.$refs.upload_form);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      const files = [];

      for (var i = 0; i < this.upload_documents.length; i++) {
        files.push({
          upload_file: await this.convert_base_64(
            this.upload_documents[i].upload_file
          ),
          file_name: this.upload_documents[i].file_name,
          file_type: this.upload_documents[i].file_type,
        });
      }

      this.upload_loading = true;

      let url = "project";
      let type_id = this.projectId;

      if (this.preQuotation) {
        url = "pre-quotation";
        type_id = this.quotationId;
      }

      if (this.quotation) {
        url = "quotation";
        type_id = this.quotationId;
      }

      this.$store
        .dispatch(PATCH, {
          url: `${url}/${type_id}/related-documents`,
          data: { files },
        })
        .then(() => {
          this.get_related_pdf();
          this.upload_dialog = false;
          this.upload_documents = [
            {
              upload_file: null,
              file_name: null,
              file_type: null,
            },
          ];
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.upload_loading = false;
        });
    },
    open_merge_dialog() {
      const selected_files = [];

      const file_name = [];

      for (let i = 0; i < this.file_checkbox.length; i++) {
        const file = this.lodash.find(this.related_files, {
          id: this.file_checkbox[i],
          is_pdf: true,
        });
        if (file) {
          file.sequence = i + 1;
          file.merge_key = "raw_path";
          selected_files.push(file);
          file_name.push(file.name);
        }
      }

      if (selected_files.length < 2) {
        ErrorEventBus.$emit(
          "update:error",
          "Please select atleast 2 PDF to merge."
        );
        return false;
      }

      this.merge_pdf_name = file_name.join(" ");
      this.file_selected = selected_files;
      this.merge_dialog = true;
    },
    merge_pdf_submit(preview) {
      if (this.file_selected.length < 2) {
        ErrorEventBus.$emit(
          "update:error",
          "Please select atleast 2 PDF to merge."
        );
        return false;
      }

      const validateStatus = this.$refs.merge_form.validate();

      const formErrors = this.validateForm(this.$refs.merge_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      this.merge_pdf_loading = true;

      let url = "project";
      let type_id = this.projectId;

      if (this.preQuotation) {
        url = "pre-quotation";
        type_id = this.quotationId;
      }

      if (this.quotation) {
        url = "quotation";
        type_id = this.quotationId;
      }

      this.$store
        .dispatch(POST, {
          url: `${url}/${type_id}/merge-document`,
          data: {
            preview: preview,
            "file-name": this.merge_pdf_name,
            "file-selected": this.file_selected,
          },
        })
        .then(({ data }) => {
          if (preview) {
            window.open(data.pdf_url, "_blank");
          } else {
            this.merge_pdf_name = null;
            this.file_selected = [];
            this.file_checkbox = [];
            this.merge_dialog = false;
            this.get_related_pdf();
          }
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.merge_pdf_loading = false;
        });
    },
    get_related_type(type) {
      if (type == 1) {
        return "Package Sales";
      }
      if (type == 2) {
        return "CaseTrust";
      }
      if (type == 3) {
        return "Quotation";
      }
      if (type == 4) {
        return "Other";
      }
    },
    update_signed_name() {
      const file = this.pdf_signed_copy_file;
      if (file) {
        this.pdf_signed_copy_name = file.name.split(".").slice(0, -1).join(".");
        this.pdf_signed_copy_type = file.name.split(".").pop();
      }
    },
    pdf_view_page_prev() {
      if (this.pdf_view_page > 1) {
        this.pdf_view_page = this.pdf_view_page - 1;
      }
    },
    pdf_view_page_next() {
      if (this.pdf_view_page < this.pdf_view_count) {
        this.pdf_view_page = this.pdf_view_page + 1;
      }
    },
    async submit_pdf_signed_copy() {
      const validateStatus = this.$refs.upload_signed_form.validate();

      const formErrors = this.validateForm(this.$refs.upload_signed_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      const request = {
        upload_file: await this.convert_base_64(this.pdf_signed_copy_file),
        file_name: this.pdf_signed_copy_name,
        file_type: this.pdf_signed_copy_type,
      };

      this.pdf_signed_copy_loading = true;

      let url = "project";
      let type_id = this.projectId;

      if (this.preQuotation) {
        url = "pre-quotation";
        type_id = this.quotationId;
      }

      if (this.quotation) {
        url = "quotation";
        type_id = this.quotationId;
      }

      this.$store
        .dispatch(PATCH, {
          url: `${url}/${type_id}/signed-document`,
          data: {
            file: request,
            related_document: this.pdf_view.id,
          },
        })
        .then(({ data }) => {
          this.related_files = data;
          this.pdf_view_dialog = false;
          this.pdf_view_loaded = false;
          this.pdf_view_signed_dialog = false;
          this.pdf_view_src = null;
          this.pdf_view_name = null;
          this.pdf_view = {};
          this.pdf_signed_copy_file = null;
          this.pdf_signed_copy_name = null;
          this.pdf_signed_copy_type = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pdf_signed_copy_loading = false;
        });
    },
    download_related_pdf(row, type) {
      const url = ObjectPath.get(row, type);
      if (url) {
        window.open(url, "_blank");
      }
    },
    upload_related_pdf(row) {
      this.pdf_view_signed_dialog = true;
      this.pdf_view_name = row.name;
      this.pdf_view = row;
    },
    view_related_pdf(row) {
      this.pdf_view_dialog = true;
      this.pdf_view_loaded = false;
      this.pdf_view_src = row.raw_path;
      this.pdf_view_name = row.name;
      this.pdf_view = row;
      this.pdf_signed_copy_file = null;
      this.pdf_signed_copy_name = null;
      this.pdf_signed_copy_type = null;
    },
    get_related_pdf() {
      let url = "project";
      let type_id = this.projectId;

      if (this.preQuotation) {
        url = "pre-quotation";
        type_id = this.quotationId;
      }

      if (this.quotation) {
        url = "quotation";
        type_id = this.quotationId;
      }

      this.$store
        .dispatch(GET, {
          url: `${url}/${type_id}/related-documents`,
        })
        .then(({ data }) => {
          this.related_files = data;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    remove_upload_row(index) {
      this.upload_documents.splice(index, 1);
      this.$nextTick(() => {
        if (!this.upload_documents.length) {
          this.add_upload_row();
        }
      });
    },
    add_upload_row() {
      this.upload_documents.push({
        accepted_file: null,
        file_name: null,
      });
    },
  },
  mounted() {
    this.get_related_pdf();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
