<template>
  <Dialog :common-dialog="caseTrustDialog" :dialog-width="dialogWidth" dense>
    <template v-slot:title>
      <h1 class="text-truncate custom-page-title m-0">Case Trust</h1>
    </template>
    <template v-slot:body>
      <v-container class="agreement">
        <v-form ref="caseTrustForm" v-model.trim="form_valid" lazy-validation>
          <v-tabs
            active-class="custom-tab-active"
            v-model="tab"
            background-color="transparent"
            color="cyan"
            grow
            class="custom-tab-transparent tab-sticky"
          >
            <v-tab href="#contact"> Contacts </v-tab>
            <v-tab href="#overview"> Overview </v-tab>
            <v-tab href="#package"> Package </v-tab>
            <v-tab href="#standalone"> Standalone </v-tab>
            <v-tab href="#attachment"> Attachment </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="contact">
              <div class="page-paper px-4">
                <br />
                <v-row class="px-3">
                  <v-col md="12">
                    <fieldset class="cm-fieldset required">
                      <span class="cm-legend font-weight-600 red--text"
                        >&nbsp;&nbsp;First Party&nbsp;&nbsp;</span
                      >
                      <v-row>
                        <v-col md="4" class="d-flex align-center py-1">
                          <label class="m-0 required font-weight-600"
                            >Name</label
                          >
                        </v-col>
                        <v-col md="8" class="py-1">
                          <v-text-field
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            placeholder="Name"
                            :readonly="caseTrustDetail"
                            v-model="output.first_party.name"
                            color="cyan"
                            :rules="[
                              validateRules.required(
                                output.first_party.name,
                                'Name'
                              ),
                            ]"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="4" class="d-flex align-center py-1">
                          <label class="m-0 required font-weight-600"
                            >Contact No.</label
                          >
                        </v-col>
                        <v-col md="8" class="py-1">
                          <v-text-field
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            placeholder="Contact No."
                            :readonly="caseTrustDetail"
                            v-model="output.first_party.contact_no"
                            color="cyan"
                            :rules="[
                              validateRules.required(
                                output.first_party.contact_no,
                                'Contact No.'
                              ),
                            ]"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="4" class="d-flex align-center py-1">
                          <label class="m-0 font-weight-600">NRIC No.</label>
                        </v-col>
                        <v-col md="8" class="py-1">
                          <v-text-field
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            color="cyan"
                            placeholder="NRIC No."
                            :readonly="caseTrustDetail"
                            v-model="output.first_party.nric_no"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <br />
                    <fieldset class="cm-fieldset">
                      <span class="cm-legend font-weight-600 black--text"
                        >&nbsp;&nbsp;Second Party&nbsp;&nbsp;</span
                      >
                      <v-row>
                        <v-col md="4" class="d-flex align-center py-1">
                          <label class="m-0 font-weight-600">Name</label>
                        </v-col>
                        <v-col md="8" class="py-1">
                          <v-text-field
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            color="cyan"
                            placeholder="Name"
                            :readonly="caseTrustDetail"
                            v-model="output.second_party.name"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="4" class="d-flex align-center py-1">
                          <label class="m-0 font-weight-600">Contact No.</label>
                        </v-col>
                        <v-col md="8" class="py-1">
                          <v-text-field
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            color="cyan"
                            placeholder="Contact No."
                            :readonly="caseTrustDetail"
                            v-model="output.second_party.contact_no"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="4" class="d-flex align-center py-1">
                          <label class="m-0 font-weight-600">NRIC No.</label>
                        </v-col>
                        <v-col md="8" class="py-1">
                          <v-text-field
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            color="cyan"
                            placeholder="NRIC No."
                            :readonly="caseTrustDetail"
                            v-model="output.second_party.nric_no"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </fieldset>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
            <v-tab-item value="overview">
              <div class="page-paper px-4">
                <br />
                <v-row class="px-3">
                  <v-col md="4" class="d-flex align-center py-1">
                    <label class="m-0 required font-weight-600"
                      >Agreement Date</label
                    >
                  </v-col>
                  <v-col md="8" class="py-1">
                    <Datepicker
                      placeholder="Select Agreement Date"
                      flat
                      solo
                      required
                      :read-only="caseTrustDetail"
                      v-model="output.agreement_date"
                      :default-date="output.agreement_date"
                    ></Datepicker>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-col md="4" class="d-flex align-center py-1">
                    <label class="m-0 required font-weight-600"
                      >Estimated Date</label
                    >
                  </v-col>
                  <v-col md="8" class="py-1">
                    <Datepicker
                      placeholder="Select Estimated Date"
                      flat
                      solo
                      required
                      :read-only="caseTrustDetail"
                      v-model="output.estimated_date"
                      :default-date="output.estimated_date"
                    ></Datepicker>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-col md="4" class="d-flex align-center py-1">
                    <label class="m-0 font-weight-600">Address</label>
                  </v-col>
                  <v-col md="8" class="py-1">
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      hide-details
                      color="cyan"
                      placeholder="Address"
                      v-model="output.address"
                      :readonly="caseTrustDetail"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-col md="4" class="d-flex align-center py-1">
                    <label class="m-0 font-weight-600">Package Sum</label>
                  </v-col>
                  <v-col md="8" class="py-1">
                    <v-text-field
                      prefix="S$"
                      dense
                      filled
                      solo
                      flat
                      hide-details
                      color="cyan"
                      placeholder="Package Sum"
                      v-model="output.package_sum"
                      :readonly="caseTrustDetail"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-col md="4" class="d-flex align-center py-1">
                    <label class="m-0 font-weight-600"
                      >Additional Works Sum</label
                    >
                  </v-col>
                  <v-col md="8" class="py-1">
                    <v-text-field
                      prefix="S$"
                      dense
                      filled
                      solo
                      flat
                      hide-details
                      color="cyan"
                      value="TBC"
                      placeholder="Additional Works Sum"
                      :readonly="caseTrustDetail"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-col md="4" class="d-flex align-center py-1">
                    <label class="m-0 font-weight-600"
                      >Standalone Contract Sum</label
                    >
                  </v-col>
                  <v-col md="8" class="py-1">
                    <v-text-field
                      prefix="S$"
                      dense
                      filled
                      solo
                      flat
                      hide-details
                      color="cyan"
                      placeholder="Standalone Contract Sum"
                      v-model="output.standalone_contract_sum"
                      :readonly="caseTrustDetail"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
            <v-tab-item value="package">
              <div class="page-paper px-4">
                <br />
                <table width="100%" height="100%" cellspacing="2px;">
                  <tr>
                    <td colspan="3" class="t-bold">
                      <v-layout class="align-center">
                        <v-flex>PACKAGE WITH ADDITIONAL WORKS</v-flex>
                        <v-flex class="text-right">
                          <v-btn
                            v-if="!caseTrustDetail"
                            v-on:click="edit_package = !edit_package"
                            small
                            color="cyan white--text custom-bold-button"
                          >
                            <template v-if="edit_package">Save</template>
                            <template v-else>Edit</template>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                  <template v-if="edit_package">
                    <tr v-for="(row, index) in output.package" :key="index">
                      <td>
                        <v-layout v-if="row.col_one">
                          <v-flex md2>{{ index }}.&nbsp;&nbsp;</v-flex>
                          <v-flex md10>
                            <v-text-field
                              dense
                              filled
                              solo
                              flat
                              hide-details
                              color="cyan"
                              v-model="row.col_one"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>
                        <v-text-field
                          dense
                          filled
                          solo
                          flat
                          hide-details
                          color="cyan"
                          v-model="row.col_two"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          dense
                          filled
                          solo
                          flat
                          hide-details
                          color="cyan"
                          v-model="row.col_three"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(row, index) in output.package" :key="index">
                      <td>
                        <v-layout v-if="row.col_one">
                          <v-flex md2>{{ index }}.&nbsp;&nbsp;</v-flex>
                          <v-flex md10>
                            {{ row.col_one }}
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>
                        {{ row.col_two }}
                      </td>
                      <td>
                        {{ row.col_three }}
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </v-tab-item>
            <v-tab-item value="standalone">
              <div class="page-paper px-4">
                <br />
                <table width="100%" height="100%" cellspacing="2px;">
                  <tr>
                    <td colspan="3" class="t-bold">
                      <v-layout class="align-center">
                        <v-flex>STAND-ALONE CONTRACT</v-flex>
                        <v-flex class="text-right">
                          <v-btn
                            v-if="!caseTrustDetail"
                            v-on:click="edit_contract = !edit_contract"
                            small
                            color="cyan white--text custom-bold-button"
                          >
                            <template v-if="edit_contract">Save</template>
                            <template v-else>Edit</template>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                  <template v-if="edit_contract">
                    <tr v-for="(row, index) in output.contract" :key="index">
                      <td>
                        <v-layout v-if="row.col_one">
                          <v-flex md2>{{ index }}.&nbsp;&nbsp;</v-flex>
                          <v-flex md10>
                            <v-text-field
                              dense
                              filled
                              solo
                              flat
                              hide-details
                              color="cyan"
                              v-model="row.col_one"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>
                        <v-text-field
                          dense
                          filled
                          solo
                          flat
                          hide-details
                          color="cyan"
                          v-model="row.col_two"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          dense
                          filled
                          solo
                          flat
                          hide-details
                          color="cyan"
                          v-model="row.col_three"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(row, index) in output.contract" :key="index">
                      <td>
                        <v-layout v-if="row.col_one">
                          <v-flex md2>{{ index }}.&nbsp;&nbsp;</v-flex>
                          <v-flex md10>
                            {{ row.col_one }}
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>
                        {{ row.col_two }}
                      </td>
                      <td>
                        {{ row.col_three }}
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </v-tab-item>
            <v-tab-item value="attachment">
              <v-layout style="min-height: 300px">
                <v-flex
                  md12
                  class="p-4 d-flex justify-center align-center"
                  style="position: relative"
                >
                  <v-img
                    contain
                    max-width="300px"
                    :src="attachment_url"
                  ></v-img>
                  <v-btn
                    v-if="!caseTrustDetail"
                    color="cyan white--text"
                    class="case-trust-select-file"
                    v-on:click="select_file()"
                  >
                    Change
                  </v-btn>
                  <v-file-input
                    ref="select-file"
                    prepend-inner-icon="mdi-attachment mdi-rotate-90"
                    placeholder="Click here to select file"
                    label="Click here to select file"
                    prepend-icon=""
                    color="cyan"
                    solo
                    flat
                    class="my-4 d-none"
                    accept="image/png, image/jpeg, image/jpg"
                    v-model="attachment"
                    dense
                    v-on:change="on_file_change($event)"
                    hide-details
                  ></v-file-input>
                </v-flex>
              </v-layout>
              <p
                v-if="!caseTrustDetail"
                class="text--secondary text-center font-weight-500"
              >
                Note:
                <span class="text-italic"
                  >Only JPEG, JPG, PNG files are allowed.</span
                >
              </p>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <template v-if="!caseTrustDetail">
        <v-btn
          v-if="!quotationDetail"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :disabled="formLoadingCT"
          :loading="formLoadingCT"
          v-on:click="emit_output('quote')"
          >Save Quote
        </v-btn>
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :disabled="!form_valid || formLoadingCT"
          :loading="formLoadingCT"
          v-on:click="emit_output('quote-case-trust')"
        >
          <template v-if="quotationDetail">Save Case Trust</template>
          <template v-else>Save Quote &amp; Case Trust</template>
        </v-btn>
      </template>
      <v-btn
        :disabled="formLoadingCT"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
        >Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Datepicker from "@/view/pages/partials/Datepicker";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { UPLOAD } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Object,
      default() {
        return new Object();
      },
    },
    caseTrustDialog: {
      type: Boolean,
      default: false,
    },
    caseTrustDetail: {
      type: Boolean,
      default: false,
    },
    formLoadingCT: {
      type: Boolean,
      default: false,
    },
    quotationDetail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    Datepicker,
  },
  watch: {
    caseTrustDialog(param) {
      if (param) {
        this.tab = "contact";
        this.$nextTick(() => {
          this.init_value(this.value);
        });
      }
    },
  },
  data() {
    return {
      tab: "contact",
      attachment: null,
      attachment_url: null,
      form_valid: true,
      edit_package: false,
      edit_contract: false,
      output: new Object({
        agreement_date: null,
        first_party: {
          name: null,
          contact_no: null,
          nric_no: null,
        },
        second_party: {
          name: null,
          contact_no: null,
          nric_no: null,
        },
        address: null,
        estimated_date: null,
        package_sum: null,
        additional_work_sum: null,
        standalone_contract_sum: null,
        package: [
          {
            col_one: null,
            col_two: "% payable at various stages",
            col_three: "Amount payable at various stages",
          },
          {
            col_one: "Immediately upon Signing this Agreement",
            col_two: "10% of package or $1,200 (whichever is higher)",
            col_three: null,
          },
          {
            col_one: "Commencement of Work",
            col_two: "10% of package or $1,200 (whichever is higher)",
            col_three: null,
          },
          {
            col_one: "Carpentry Fabrication",
            col_two: "45% of package",
            col_three: null,
          },
          {
            col_one: "Handover Date",
            col_two: "5% of package",
            col_three: "Amount payable at various stages",
          },
          {
            col_one: "Additional Works",
            col_two: "100% of additional works",
            col_three: "To show detailed breakdown at full quotation",
          },
        ],
        contract: [
          {
            col_one: null,
            col_two: "% payable at various stages",
            col_three: "Amount payable at various stages",
          },
          {
            col_one: "Immediately upon Signing this Agreement",
            col_two: "10% of contract",
            col_three: null,
          },
          {
            col_one: "Commencement of Work",
            col_two: "40% of contract",
            col_three: null,
          },
          {
            col_one: "Carpentry Fabrication",
            col_two: "45% of contract",
            col_three: null,
          },
          {
            col_one: "Handover Date",
            col_two: "5% of contract",
            col_three: null,
          },
        ],
      }),
    };
  },
  methods: {
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$emit("start-loading", true);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("stop-loading", true);
          });
      }
    },
    init_value(output) {
      console.log({ output });
      const param = this.lodash.cloneDeep(output);
      if (param && this.lodash.isEmpty(param) === false) {
        this.output.agreement_date = param.agreement_date;
        this.output.first_party.name = param.first_party.name;
        this.output.first_party.contact_no = param.first_party.contact_no;
        this.output.first_party.nric_no = param.first_party.nric_no;
        this.output.second_party.name = param.second_party.name;
        this.output.second_party.contact_no = param.second_party.contact_no;
        this.output.second_party.nric_no = param.second_party.nric_no;
        this.output.address = param.address;
        this.output.attachment = param.attachment;
        this.attachment_url = param.attachment;
        console.log({ attachment_url: this.attachment_url, param });
        this.output.estimated_date = param.estimated_date;
        this.output.package_sum = param.package_sum;
        this.output.additional_work_sum = param.additional_work_sum;
        this.output.standalone_contract_sum = param.standalone_contract_sum;

        if (this.lodash.isArray(param.package) && param.package.length) {
          this.output.package = [];
          for (let i = 0; i < param.package.length; i++) {
            this.output.package.push(param.package[i]);
          }
        }

        if (param.contract && param.contract.length) {
          this.output.contract = [];
          for (let z = 0; z < param.contract.length; z++) {
            this.output.contract.push(param.contract[z]);
          }
        }
      }
      this.$nextTick(() => {
        this.emit_output();
      });
    },
    emit_output(param) {
      if (param == "quote-case-trust") {
        const validateStatus = this.$refs.caseTrustForm.validate();

        const formErrors = this.validateForm(this.$refs.caseTrustForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!validateStatus) {
          return false;
        }
      }

      this.output.attachment = this.attachment_url;

      this.$emit("input", this.output);

      if (param) {
        this.$nextTick(() => {
          this.$emit(param, true);
        });
      }
    },
  },
  mounted() {
    this.attachment_url = this.$assetAPIURL();
    this.init_value(this.value);
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
