<template>
  <DeleteTemplate :deleteDialog="deleteDialog">
    <template v-slot:title>Delete Confirmation</template>
    <template v-slot:text>
      <h3 class="font-size-16 font-weight-500 delete-text">
        <span class="pb-1 d-block"
          >Once you delete this, you won't be able to retrieve this later. Are
          you sure you want to delete ?</span
        >
        <span class="pt-1 d-block"
          >To delete this, type
          <span class="barcode-text">{{ uniqueId }}</span> in below field.</span
        >
      </h3>
    </template>
    <template v-slot:validate>
      <v-form
        ref="deleteForm"
        v-model.trim="deleteValid"
        lazy-validation
        v-on:submit.stop.prevent="deleteItem"
      >
        <v-text-field
          v-model.trim="deleteValidateText"
          :rules="[
            validateRules.required(deleteValidateText, 'Delete #'),
            validateRules.deleteValidation(
              deleteValidateText,
              'Delete #',
              uniqueId
            ),
          ]"
          class="pt-4"
          dense
          filled
          solo
          flat
          persistent-hint
          :hint="'Type ' + uniqueId + ' in above field.'"
          color="cyan"
          label="Delete #"
          @paste.prevent
        ></v-text-field>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="deleteLoading || !deleteValid"
        :loading="deleteLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighten-1"
        v-on:click="deleteItem"
      >
        Yes, Delete it
      </v-btn>

      <v-btn
        :disabled="deleteLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('delete:close', true)"
      >
        No, Keep it
      </v-btn>
    </template>
  </DeleteTemplate>
</template>

<script>
import DeleteTemplate from "@/view/pages/partials/Delete.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { DELETE } from "@/core/services/store/request.module";

export default {
  mixins: [ValidationMixin],
  props: {
    requestUrl: {
      required: true,
      type: String,
      default: "",
    },
    deleteDialog: {
      required: true,
      type: Boolean,
      default: false,
    },
    dataRequest: {
      required: true,
      type: Object,
    },
  },
  components: {
    DeleteTemplate,
  },
  data() {
    return {
      uniqueId: null,
      deleteValid: true,
      deleteLoading: false,
      deleteValidateText: null,
    };
  },
  watch: {
    deleteDialog(param) {
      if (!param) {
        this.generateUniqueId();
      }
    },
  },
  methods: {
    deleteItem() {
      const _this = this;

      if (!_this.$refs.deleteForm.validate()) {
        return false;
      }
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, { url: _this.requestUrl, data: this.dataRequest })
        .then(() => {
          _this.$emit("delete:success", true);
        })
        .catch(() => {
          _this.$emit("delete:fail", true);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    generateUniqueId() {
      const _this = this;
      _this.deleteValidateText = null;
      _this.uniqueId = _this.lodash
        .times(10, () => _this.lodash.random(35).toString(36))
        .join("");
    },
  },
  mounted() {
    this.generateUniqueId();
  },
};
</script>
