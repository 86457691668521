<template>
  <Dialog :commonDialog.sync="dialog">
    <template v-slot:title> Consent Form </template>
    <template v-slot:body>
      <v-container fluid>
        <v-sheet class="agreement">
          <div>
            <p>
              I, {{ attributes.ownerName }} (Name),
              {{ attributes.ownerNRIC }} (NRIC), owner of
              {{ attributes.ownerAddress }} (Address), hereby agree to allow
              {{ attributes.companyName }} to use any photos taken of me with
              {{ attributes.companyName }} services process for advertising
              &amp; promotion purposes in their marketing materials.
            </p>
            <div>
              <p>SIGNED by the abovementioned</p>
              <v-img
                v-if="signature.owner"
                contain
                class="my-4"
                max-height="100"
                :src="signature.owner"
              ></v-img>
              <p
                class="sign-top t-bold mb-4"
                :class="{ 'margin-top-100px': !signature.owner }"
              >
                OWNER
              </p>
              <p class="my-2">Name: {{ attributes.ownerName }}</p>
              <p class="my-2">NRIC No.: {{ attributes.ownerNRIC }}</p>
            </div>
            <p>(Signature)</p>
            <p>Name:</p>
            <p>NRIC:</p>
            <p>Date:</p>
          </div>
        </v-sheet>
        <Dialog :commonDialog.sync="signatureDialog">
          <template v-slot:title> Signature </template>
          <template v-slot:body>
            <v-row>
              <v-col md="10" class="py-0" offset-md="1">
                <div
                  id="signature-pad"
                  ref="signature_div"
                  class="custom-border-grey-dashed"
                >
                  <canvas ref="signature"></canvas>
                </div>
              </v-col>
              <v-col md="10" class="py-0" offset-md="1">
                <p class="float-right m-0">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-right"
                    v-on:click="signatureRef.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-slot:action>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="cyan"
              v-on:click="signatureSave()"
            >
              Submit
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="signatureClose()"
            >
              Cancel
            </v-btn>
          </template>
        </Dialog>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
      >
        Submit
      </v-btn>
      <v-btn class="mx-2 custom-grey-border custom-bold-button"> Cancel </v-btn>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";
export default {
  data() {
    return {
      attributes: {
        startDate: null,
        endDate: null,
        ownerName: null,
        ownerNRIC: null,
        ownerAddress: null,
        amountInAlpha: null,
        amountInNumber: null,
        paymentSchedule: [
          {
            text: null,
            percent: null,
            amount: null,
          },
        ],
      },
      companyName: "Genic Solution Pte Ltd.",
      companyAddress: "8 Burn Road, #07-16 Trivex, Singapore 369977",
      signatureRef: null,
      signature: {
        owner: null,
        company: null,
      },
      signatureDialog: false,
      signatureType: null,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    agreement: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    initSign() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signature"];
          let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = cparentDiv.offsetWidth;
          ccanvas.setAttribute("width", cparentWidth);
          _this.signatureRef = new SignaturePad(ccanvas);
        });
      }, 100);
    },
    signatureSave() {
      if (this.signatureRef.isEmpty()) {
        return false;
      }
      this.signature[this.signatureType] = this.signatureRef.toDataURL();
      this.signatureClose();
    },
    signaturePopup(type) {
      this.signatureDialog = true;
      this.signatureType = type;
      this.initSign();
    },
    signatureClose() {
      this.signatureDialog = false;
      this.signatureType = null;
      this.signatureRef.clear();
      this.signatureRef = null;
    },
  },
  components: {
    Dialog,
  },
};
</script>
