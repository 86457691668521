<template>
  <v-layout class="text-center formatted-date-picker">
    <v-flex md3>
      <select
        placeholder="Day"
        v-on:change="emitValue()"
        v-model="day"
        class="w-100 text-center dd"
        :rules="mandatory ? [() => !!day || 'Day is required'] : []"
      >
        <option :value="day" v-for="(day, index) in dayList" :key="index">
          {{ day }}
        </option>
      </select>
    </v-flex>
    <v-flex md3 class="mx-2">
      <select
        placeholder="Month"
        v-on:change="emitValue()"
        v-model="month"
        class="w-100 text-center mm"
        :rules="mandatory ? [() => !!month || 'Month is required'] : []"
      >
        <option :value="month" v-for="(month, index) in monthList" :key="index">
          {{ month }}
        </option>
      </select>
    </v-flex>
    <v-flex md6>
      <select
        placeholder="Year"
        v-on:change="emitValue()"
        v-model="year"
        class="w-100 text-center yy"
        :rules="mandatory ? [() => !!year || 'Year is required'] : []"
      >
        <option :value="year" v-for="(year, index) in yearList" :key="index">
          {{ year }}
        </option>
      </select>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: "v-doc-date-formatted-picker",
  model: {
    prop: "date",
    event: "input",
  },
  props: {
    date: {
      type: String,
      default: null,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitValue() {
      if (this.day && this.month && this.year) {
        // date_format:Y-m-d
        const formattedDate = this.year + "-" + this.month + "-" + this.day;
        this.$emit("input", formattedDate);
      } else {
        this.$emit("input", null);
      }
    },
    updateDates() {
      for (let i = 1; i <= 31; i++) {
        this.dayList.push(("0" + i).slice(-2));
      }

      for (let i = 1; i <= 12; i++) {
        this.monthList.push(("0" + i).slice(-2));
      }

      const start_year = new Date().getFullYear();
      for (let i = start_year; i > start_year - 10; i--) {
        this.yearList.push(i);
      }
    },
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      dayList: [],
      monthList: [],
      yearList: [],
    };
  },
  mounted() {
    this.updateDates();
  },
};
</script>
