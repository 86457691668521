<template>
  <div class="ct-package-sales-listing">
    <package-sales is-package> </package-sales>
  </div>
</template>

<script>
import CommonQuotation from "@/view/pages/common-quotation/Quotation";

export default {
  name: "listing-common-package-sales",
  data() {
    return {};
  },
  components: {
    "package-sales": CommonQuotation,
  },
};
</script>
