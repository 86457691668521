<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'agreement-create'"
    v-if="getPermission('agreement:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Create new Agreement For
        <p
          class="m-0 form-title-create-link pl-2"
          :class="{
            'text-ellipsis width-350px': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject)"
            >mdi-plus-circle-outline</v-icon
          >
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate()"
        color="cyan"
      >
        Save Agreement
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid v-bar class="create-agreement-vbar agreement">
        <v-form
          ref="agreementForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate()"
        >
          <v-sheet class="mx-20 mb-20">
            <v-row>
              <v-col md="3">
                <label for="accepted-quotation" class="font-weight-600"
                  >Accepted Quotation</label
                >
              </v-col>
              <v-col md="4">
                <v-autocomplete
                  id="accepted-quotation"
                  v-model.trim="quotationObject"
                  :items="quotationList"
                  dense
                  filled
                  color="cyan"
                  placeholder="Select Quotation"
                  item-color="cyan"
                  item-text="barcode"
                  item-value="id"
                  solo
                  flat
                  hide-details
                  return-object
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Accepted Quotation Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <label class="required font-weight-600">Agreement Date</label>
              </v-col>
              <v-col md="4">
                <Datepicker
                  placeholder="Select Date"
                  flat
                  solo
                  required
                  :min-date="agreementMinDate"
                  v-model="attributes.startDate"
                  :default-date="attributes.startDate"
                ></Datepicker>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <label class="required font-weight-600">Owner Name</label>
              </v-col>
              <v-col md="4">
                <v-text-field
                  dense
                  filled
                  solo
                  flat
                  :rules="[
                    validateRules.required(attributes.ownerName, 'Owner Name'),
                  ]"
                  hide-details
                  v-model="attributes.ownerName"
                  color="cyan"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <label class="required font-weight-600">Owner NRIC</label>
              </v-col>
              <v-col md="4">
                <v-text-field
                  dense
                  filled
                  solo
                  :rules="[
                    validateRules.required(attributes.ownerNRIC, 'Owner NRIC'),
                  ]"
                  v-model="attributes.ownerNRIC"
                  v-mask="nricMask"
                  flat
                  hide-details
                  color="cyan"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <label class="required font-weight-600">Owner Address</label>
              </v-col>
              <v-col md="4">
                <v-text-field
                  dense
                  filled
                  solo
                  flat
                  :rules="[
                    validateRules.required(
                      attributes.ownerAddress,
                      'Owner Address'
                    ),
                  ]"
                  v-model="attributes.ownerAddress"
                  hide-details
                  color="cyan"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <label class="required font-weight-600">Total Amount</label>
              </v-col>
              <v-col md="4">
                <v-text-field
                  dense
                  filled
                  solo
                  flat
                  v-mask="'########'"
                  :rules="[
                    validateRules.required(
                      attributes.amountInNumber,
                      'Total Amount'
                    ),
                  ]"
                  hide-details
                  v-model="attributes.amountInNumber"
                  color="cyan"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-layout class="mb-8 mt-10 py-4 page-paper">
              <v-flex class="link" md4 v-on:click="signaturePopup('owner')">
                <v-img
                  v-if="signature.owner"
                  contain
                  class="my-4"
                  max-height="100"
                  max-width="200"
                  :src="signature.owner"
                ></v-img>
                <p
                  class="sign-top t-bold mb-4"
                  :class="{ 'margin-top-100px': !signature.owner }"
                >
                  OWNER
                  <small class="ml-4"
                    ><em class="font-size-14">(click here to sign)</em></small
                  >
                </p>
                <p class="my-2">Name: {{ attributes.ownerName }}</p>
                <p class="my-2">NRIC No.: {{ attributes.ownerNRIC }}</p>
              </v-flex>
              <v-flex md4></v-flex>
              <v-flex class="link" md4 v-on:click="signaturePopup('company')">
                <v-img
                  v-if="signature.company"
                  contain
                  class="my-4"
                  max-height="100"
                  max-width="200"
                  :src="signature.company"
                ></v-img>
                <p
                  class="sign-top t-bold mb-4"
                  :class="{ 'margin-top-100px': !signature.company }"
                >
                  {{ companyName }}
                  <small class="ml-4"
                    ><em class="font-size-14">(click here to sign)</em></small
                  >
                </p>
                <p class="my-2">Name: {{ systemName }}</p>
                <p class="my-2">NRIC No.: {{ systemNRIC }}</p>
              </v-flex>
            </v-layout>
            <div class="page-paper">
              <p class="t-bold mt-8 mb-3 text-center">PAYMENT SCHEDULE</p>
              <table width="100%" height="100%" cellspacing="2px;">
                <tr>
                  <td colspan="3" class="t-bold">
                    <v-layout>
                      <v-flex
                        >GENERAL RENOVATION WORK (BUILDERS’WORK; MECHANICAL,
                        ELECTRICAL, PLUMBING, ETC)</v-flex
                      >
                      <v-flex class="text-right">
                        <v-btn
                          small
                          color="cyan"
                          depressed
                          v-on:click="addRow()"
                          class="custom-bold-button white--text"
                          >Add Row..</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </td>
                </tr>
                <tr>
                  <td>
                    The Agreementor shall be entitled to payment by account by
                    the Owner from time to time of the percentage of the
                    agreement price adjusted or varied in accordance with this
                    agreement at the stage following that is to say:
                  </td>
                  <td>% payable at various stages</td>
                  <td>Amount to be paid at various stages ($)</td>
                </tr>
                <tr
                  v-for="(row, index) in attributes.paymentSchedule"
                  :key="index"
                >
                  <td>
                    <div class="d-flex">
                      <span class="mr-4">{{ index + 1 }}.</span>
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        hide-details
                        v-model="row.text"
                        class="mx-2"
                        color="cyan"
                      >
                      </v-text-field>
                    </div>
                  </td>
                  <td>
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      hide-details
                      v-mask="'###'"
                      v-model="row.percent"
                      v-on:change="updateTotal()"
                      class="mx-2"
                      color="cyan"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    {{ formatMoney(row.amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{ totalPercentage }}%</td>
                  <td>{{ formatMoney(totalAmount) }}</td>
                </tr>
              </table>

              <p class="mt-4">
                * For any variation order (if any), full payment shall be made
                upon confirmation.
              </p>
            </div>
          </v-sheet>
        </v-form>
      </v-container>
      <Dialog :commonDialog.sync="signatureDialog">
        <template v-slot:title> Signature </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              <div
                id="signature-pad"
                ref="signature_div"
                class="custom-border-grey-dashed"
              >
                <canvas ref="signature"></canvas>
              </div>
            </v-col>
            <v-col md="10" class="py-0" offset-md="1">
              <p class="float-right m-0">
                <v-btn
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  class="float-right"
                  v-on:click="signatureRef.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </p>
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="signatureSave()"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="signatureClose()"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Consent
        :consent-dialog="consentDialog"
        :attributes="attributes"
        :company-name="companyName"
        :company-address="companyAddress"
        :owner-signature="signature.owner"
        v-on:submit="updateOrCreate"
        v-on:close="consentDialog = false"
      ></Consent>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import moment from "moment-timezone";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  CLEAR_ERROR,
  QUERY,
  POST,
  PUT,
  GET,
} from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CommonMixin from "@/core/plugins/common-mixin";
import Datepicker from "@/view/pages/partials/Datepicker";
import Dialog from "@/view/pages/partials/Dialog";
import Consent from "@/view/pages/agreement/Consent";
import SignaturePad from "signature_pad";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      formValid: true,
      consentDialog: false,
      formLoading: false,
      pageLoading: false,
      agreementMinDate: null,
      customerObject: {},
      quotationObject: {},
      attributes: {
        startDate: null,
        endDate: null,
        ownerName: null,
        ownerNRIC: null,
        ownerAddress: null,
        amountInAlpha: null,
        amountInNumber: null,
        paymentSchedule: [
          {
            text: null,
            percent: null,
            amount: null,
          },
        ],
      },
      quotationList: [],
      agreement: null,
      customerId: null,
      quotationId: null,
      totalPercentage: null,
      totalAmount: null,
      systemName: null,
      systemNRIC: null,
      agreementId: null,
      agreementTitle: "RESIDENTIAL RENOVATION CONTRACT",
      companyName: "Genic Solution Pte Ltd.",
      companyAddress: "8 Burn Road, #07-16 Trivex, Singapore 369977",
      signatureRef: null,
      signature: {
        owner: null,
        company: null,
      },
      signatureDialog: false,
      signatureType: null,
    };
  },
  watch: {
    quotationObject: {
      deep: true,
      handler(param) {
        if (!this.lodash.isEmpty(param)) {
          this.attributes.ownerName = param.contact_person.full_name;
          this.attributes.ownerAddress = param.billing.property_address;
          this.attributes.amountInAlpha = param.amount_in_alpha;
          this.attributes.amountInNumber = param.taxable_amount;
        }
      },
    },
    "attributes.amountInNumber"() {
      this.calculateValue();
    },
    "attributes.paymentSchedule": {
      deep: true,
      handler() {
        this.calculateValue();
      },
    },
  },
  methods: {
    calculateValue() {
      for (let i = this.attributes.paymentSchedule.length - 1; i >= 0; i--) {
        const value = this.accountingToFixed(
          (this.attributes.paymentSchedule[i].percent / 100) *
            this.attributes.amountInNumber
        );
        this.attributes.paymentSchedule[i].amount = value;
      }
      this.updateTotal();
    },
    getOptions(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "agreements/options",
          data: {
            attributes,
            customer: _this.customerId,
            quotation: _this.quotationId,
          },
        })
        .then(({ data }) => {
          if (data.quotation_list) {
            _this.quotationList = data.quotation_list;
          }
          if (data.customer) {
            _this.customerObject = data.customer;
          }
          if (data.quotation) {
            _this.quotationObject = data.quotation;
          }
          if (data.company) {
            _this.systemName = data.company.company_name;
            _this.systemNRIC = data.company.company_nric;
            _this.companyName = data.company.company_name;
            _this.companyAddress =
              data.company.street_1 +
              ", " +
              data.company.street_2 +
              ", " +
              data.company.landmark +
              "  " +
              data.company.zip_code;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    consentAgreement() {
      if (this.agreement) {
        this.updateOrCreate(null);
      } else {
        const validateStatus = this.$refs.agreementForm.validate();

        const formErrors = this.validateForm(this.$refs.agreementForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        this.consentDialog = true;
      }
    },
    updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.agreementForm.validate();

      const formErrors = _this.validateForm(_this.$refs.agreementForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (!_this.attributes.startDate) {
        _this.attributes.startDate = new Date();
      }

      if (!_this.lodash.isEmpty(_this.quotationObject)) {
        _this.quotationId = _this.quotationObject.id;
      }

      const formData = new Object({
        customer: _this.customerId,
        quotation: _this.quotationId,
        form: _this.agreementId,
        subject: _this.agreementTitle,
        attributes: _this.attributes,
        start_date: _this.attributes.startDate,
        cvalue: _this.attributes.amountInNumber,
        owner_url: _this.signature.owner,
        company_url: _this.signature.company,
      });

      /*if (param) {
        formData.owner_url = param;
      }*/

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let REQUEST_URL = "agreements/agreement";
      let REQUEST_TYPE = POST;

      if (_this.agreement) {
        REQUEST_URL = `agreements/agreement/${_this.agreement}`;
        REQUEST_TYPE = PUT;
      }

      _this.$store
        .dispatch(REQUEST_TYPE, {
          url: REQUEST_URL,
          data: formData,
        })
        .then(({ data }) => {
          _this.agreement = _this.lodash.toSafeInteger(data.id);
          _this.backForce = true;
          _this.$router.push(
            _this.getDefaultRoute("agreement.detail", {
              params: { id: _this.agreement },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    initSign() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signature"];
          let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = cparentDiv.offsetWidth;
          ccanvas.setAttribute("width", cparentWidth);
          _this.signatureRef = new SignaturePad(ccanvas);
        });
      }, 100);
    },
    signatureSave() {
      if (this.signatureRef.isEmpty()) {
        return false;
      }
      this.signature[this.signatureType] = this.signatureRef.toDataURL();
      this.signatureClose();
    },
    signaturePopup(type) {
      this.signatureDialog = true;
      this.signatureType = type;
      this.initSign();
    },
    signatureClose() {
      this.signatureDialog = false;
      this.signatureType = null;
      this.signatureRef.clear();
      this.signatureRef = null;
    },
    updateTotal() {
      let percent = [];
      let amount = [];
      for (let i = this.attributes.paymentSchedule.length - 1; i >= 0; i--) {
        const percentInt =
          this.lodash.toNumber(this.attributes.paymentSchedule[i].percent) || 0;
        const amountInt =
          this.lodash.toNumber(this.attributes.paymentSchedule[i].amount) || 0;
        amount.push(amountInt);
        percent.push(percentInt);
      }
      this.totalPercentage = this.lodash.sum(percent);
      this.totalAmount = this.lodash.sum(amount);
    },
    addRow() {
      this.attributes.paymentSchedule.push({
        text: null,
        percent: null,
        amount: null,
      });
    },
    removeRow(index) {
      this.attributes.paymentSchedule.splice(index, 1);
      this.$nextTick(() => {
        this.updateTotal();
      });
      if (this.attributes.paymentSchedule.length < 1) {
        this.addRow();
      }
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    },
    getAgreement() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "agreements/" + _this.agreement,
        })
        .then(async ({ data }) => {
          this.attributes = data.form.attributes;
          this.quotationId = data.quotation;

          const base64Owner = await this.getBase64FromUrl(data.form.owner_url);
          const base64Company = await this.getBase64FromUrl(
            data.form.company_url
          );

          this.signature = {
            owner: base64Owner,
            company: base64Company,
          };

          this.updateTotal();

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Agreement", route: "agreement" },
            { title: "Update" },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;

          _this.getOptions(["quotation"]);
        });
    },
  },
  components: {
    Dialog,
    Consent,
    Datepicker,
    CreateUpdateTemplate,
  },
  mounted() {
    this.agreementMinDate = moment().format("YYYY-MM-DD");

    this.customerId = this.lodash.toSafeInteger(this.$route.query.customer);
    if (!this.customerId) {
      ErrorEventBus.$emit("update:error", "Customer is required");
      this.goBack();
      return false;
    }

    this.agreement = this.lodash.toSafeInteger(this.$route.params.id) || null;

    if (this.$route.name === "admin.agreement.form.update") {
      this.getAgreement();
    }

    this.quotationId = this.lodash.toSafeInteger(this.$route.query.quotation);

    this.agreementId = this.lodash.toSafeInteger(this.$route.query.agreement);
    if (!this.agreementId) {
      ErrorEventBus.$emit("update:error", "Agreement is required");
      this.goBack();
      return false;
    }

    this.getOptions(["customer", "quotation", "quotation-list"]);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agreement", route: "agreement" },
      { title: "Create" },
    ]);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
