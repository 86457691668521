<template>
  <!--begin::Service Listing-->
  <div class="upload-template" :key="forceRenderKey">
    <ListingTemplate
      :customClass="'lead-listing'"
      v-if="getPermission('supplier-price-list:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto" v-if="false">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_adtitles
                          }}</template>
                          <template v-else>{{
                            item.adtitle_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('supplier-price-list:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="openCreateDialog()"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-upload</v-icon> Upload
                  </v-btn>
                </template>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="supplier-price-listing"
          :basicSearchFields="['name']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <ListingTable
            :columnCount.sync="defaultColShow.length"
            :dataLoading.sync="dataLoading"
            :rowData.sync="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr v-for="(data, index) in rowData" :key="index" link>
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                      >
                        <template v-if="cols.checkbox">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'name'">
                          <p class="m-0 custom-nowrap-ellipsis text-capitalize">
                            <template v-if="data?.name">
                              {{ data?.name }}
                            </template>
                            <em v-else class="text--secondary">
                              no file name
                            </em>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'owner'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <v-chip
                              v-if="
                                !lodash.isEmpty(data.added_by) ||
                                !lodash.isEmpty(data.updated_by)
                              "
                              class="mx-2 my-1 custom-grey-border"
                              color="blue-grey darken-1"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon>mdi-account-circle</v-icon>
                              </v-avatar>
                              <p class="m-0 custom-nowrap-ellipsis">
                                {{ data?.added_by?.display_name }}
                              </p>
                            </v-chip>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'action'">
                          <div class="d-flex">
                            <p class="m-0 custom-nowrap-ellipsis mr-2">
                              <v-icon
                                :disabled="file_loading"
                                :loading="file_loading"
                                size="24"
                                color="blue darken-4"
                                v-on:click="downloadFile(data.file_url)"
                                >mdi-download
                              </v-icon>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <v-icon
                                v-if="
                                  getPermission('supplier-price-list:delete')
                                "
                                size="24"
                                color="red"
                                v-on:click="openDeleteDialog(data.id)"
                                >mdi-delete
                              </v-icon>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'file_size'">
                          <b>{{ data.size }}</b>
                        </template>
                        <template v-else-if="cols.field == 'modified'">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-if="data.created_at || data.modified_at"
                                class="mx-2 my-1 custom-grey-border"
                                color="blue-grey darken-1"
                                text-color="white"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-avatar left>
                                  <v-icon>mdi-clock-outline</v-icon>
                                </v-avatar>
                                <p class="m-0 custom-nowrap-ellipsis">
                                  {{ data.created_at }}
                                </p>
                              </v-chip>
                            </template>
                            <p class="m-0 custom-nowrap-ellipsis">
                              {{ formatDateTime(data.added_at) }}
                            </p>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColDefs.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no supplier price list at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>

        <template v-if="getPermission('supplier-price-list:create')">
          <Dialog :common-dialog="file_dialog" :dialog-width="dialogWidth">
            <template v-slot:title> File Upload </template>
            <template v-slot:body>
              <v-form
                ref="file_form"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="upload_files"
              >
                <v-layout class="align-center">
                  <v-flex md12>
                    <table width="100%">
                      <tbody>
                        <tr v-for="(row, index) in attachments" :key="index">
                          <td width="50%" class="pr-2 py-2">
                            <v-file-input
                              prepend-inner-icon="mdi-attachment mdi-rotate-90"
                              placeholder="Click here to select file"
                              label="Click here to select file"
                              :class="!row.accepted_file ? 'error--text' : ''"
                              prepend-icon=""
                              color="cyan"
                              solo
                              flat
                              v-model="row.accepted_file"
                              dense
                              v-on:change="update_file_name(index)"
                              hide-details
                            ></v-file-input>
                          </td>
                          <td width="50%" class="pl-2 py-2">
                            <v-text-field
                              placeholder="File Name"
                              label="File Name"
                              dense
                              solo
                              flat
                              color="cyan"
                              v-model="row.file_name"
                              :disabled="file_loading"
                              :loading="file_loading"
                              hide-details
                              class="has-delete-outer-icon"
                              append-outer-icon="mdi-delete"
                              :class="!row.file_name ? 'error--text' : ''"
                              v-on:click:append-outer="remove_row(index)"
                              :suffix="get_file_extension(row.file_type)"
                              :rules="[
                                validateRules.required(
                                  row.file_name,
                                  'File Name'
                                ),
                              ]"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td height="10px" colspan="2"></td>
                        </tr>
                        <tr v-if="attachments.length < 5">
                          <td colspan="2">
                            <v-btn
                              :disabled="file_loading"
                              :loading="file_loading"
                              color="cyan white--text"
                              small
                              v-on:click="add_attachment_row()"
                              class="custom-bold-button"
                              >Add More...</v-btn
                            >
                          </td>
                        </tr>
                        <tr>
                          <td height="10px" colspan="2"></td>
                        </tr>
                      </tbody>
                    </table>
                  </v-flex>
                </v-layout>
              </v-form>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="file_loading"
                :loading="file_loading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="upload_files()"
              >
                Save
              </v-btn>
              <v-btn
                :disabled="file_loading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="closeCreateDialog()"
              >
                Cancel
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
    <FileDelete
      :deleteDialog.sync="fileDeleteDialog"
      :requestUrl.sync="fileDeleteRequestUrl"
      v-on:delete:success="(fileDeleteDialog = false), getRows()"
      v-on:delete:close="fileDeleteDialog = false"
    ></FileDelete>
  </div>
  <!--end::Service Listing-->
</template>
  
  <script>
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ObjectPath from "object-path";
import FileDelete from "@/view/pages/partials/Delete-File-Request.vue";
import { SearchEventBus } from "@/core/lib/search.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import draggable from "vuedraggable";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { uploadFilesToS3 } from "@/core/plugins/fileUploader";
import { POST } from "@/core/services/store/request.module";
export default {
  mixins: [CommonMixin, ListingMixin, ValidationMixin],
  name: "supplier-price-list",
  data() {
    return {
      forceRenderKey: Date.now(),
      exportLoading: false,
      file_loading: false,
      file_dialog: false,
      formValid: true,
      pageModule: "supplier-price-listing",
      routeAPI: "supplier-price-list",
      routeName: "supplier-price-list",
      status: "all",
      file: "",
      statusList: [],
      allowedSearchFields: ["file_name", "owner"],
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      updateLoading: false,
      adtitleDetail: {},
      fileDeleteDialog: false,
      file_id: 0,
    };
  },
  components: {
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    FileDelete,
    draggable,
  },
  methods: {
    closeCreateDialog() {
      this.file_dialog = false;
    },
    openDeleteDialog(id) {
      this.file_id = this.lodash.toSafeInteger(id);
      this.fileDeleteDialog = true;
    },
    openCreateDialog() {
      (this.attachments = [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ]),
        (this.file_dialog = true);
    },

    validateAttachments() {
      let errors = [];
      const maxFileSize = 20 * 1024 * 1024;
      this.attachments.forEach((attachment, index) => {
        if (!attachment.accepted_file) {
          errors.push(`Please select a file for attachment row ${index + 1}.`);
        }

        if (!attachment.file_name) {
          errors.push(`File name is required for attachment row ${index + 1}.`);
        }

        if (attachment.accepted_file?.size > maxFileSize) {
          errors.push(
            `The file ${attachment.file_name} exceeds the 20 MB limit.`
          );
        }
      });

      return errors;
    },

    // Upload the files to S3
    async upload_files() {
      const errors = this.validateAttachments();
      if (errors.length > 0) {
        errors.forEach((error) => {
          ErrorEventBus.$emit("update:error", InitializeError(error));
        });
        return false;
      }

      try {
        this.file_loading = true;
        const uploaded = await uploadFilesToS3(this.attachments);

        if (uploaded?.length > 0) {
          await this.saveFiles(uploaded);
        }
      } catch (error) {
        this.logError(error);
      } finally {
        this.file_loading = false;
      }
    },
    async saveFiles(uploaded) {
      try {
        await this.$store.dispatch(POST, {
          url: "save-file",
          data: { attachments: uploaded },
        });

        this.file_dialog = false;
        this.getRows();
      } catch (error) {
        this.logError(error);
      }
    },

    remove_row(index) {
      ObjectPath.del(this.attachments, index);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      if (this.attachments.length < 5) {
        this.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        /*  this.add_attachment_row(); */
      }
    },

    downloadFile(url) {
      this.file_loading = true;
      const link = document.createElement("a");
      link.href = url;
      link.click();
      setTimeout(() => {
        this.file_loading = false;
      }, 1000);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Supplier Price List",
      },
    ]);

    SearchEventBus.$emit("search-module-title", "Supplier Price List");
  },

  beforeMount() {
    const _this = this;

    _this.defaultColDefs = [
      {
        headerName: "File Name",
        field: "name",
        sort: null,
        visible: true,
        fixed: true,
        sortable: true,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Owner",
        field: "owner",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
        width: "200px",
      },

      {
        headerName: "Created Time",
        field: "modified",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "170px",
      },

      {
        headerName: "File Size",
        field: "file_size",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 4,
        width: "170px",
      },
      {
        headerName: "Action",
        field: "action",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 4,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(
      _this.defaultColDefs,
      function (row) {
        return row.visible;
      }
    );

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  computed: {
    fileDeleteRequestUrl() {
      return "supplier-price-list/" + this.file_id;
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>

  