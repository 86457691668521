import moment from "moment-timezone";
import inflect from "i";
import AppConfiguration from "@/core/config/app.config";
import { POST } from "@/core/services/store/request.module";
import { toSafeNumber } from "@/core/plugins/math.lib";
import {
  GET_COUNTRY,
  GET_STATE,
  GET_CITY,
} from "@/core/services/store/common.module";
import LocalService from "@/core/services/local.service";
import accounting from "accounting-js";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

function getTitle(vm) {
  let { title, name } = vm.$options;
  if (title && name) {
    title = inflect().titleize(name) + " | " + inflect().titleize(title);
  }
  if (!title && name) {
    title = inflect().titleize(name);
  }
  if (title) {
    return typeof title === "function" ? title.call(vm) : title;
  }
}

const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export default {
  data() {
    return {
      CurrencySymbol: null,
    };
  },
  methods: {
    intToAlpha(index) {
      if (index < 0) {
        return null;
      }
      return alphabets[index];
    },
    nextAlpha(alpha) {
      if (!alpha.length) {
        return this.intToAlpha(0);
      }
      for (let i = 0; i < alpha.length; i++) {
        const index = this.lodash.findKey(alphabets, function (row) {
          return alpha[i] == row;
        });
        if (i != index) {
          return this.intToAlpha(i);
        }
      }
      return this.intToAlpha(alpha.length);
    },
    isNotEmpty(param) {
      return this.lodash.isEmpty(param) === false;
    },
    formatGPSAddress(address) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(POST, { url: "create-address-from-google", data: address })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            _this.logError(error);
            reject(error);
          });
      });
    },
    updateActiveField(param) {
      this.currentActiveField = param;
    },
    getCountries(param) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let country = _this.lodash.toInteger(param);
          let countryArr = LocalService.getData("country-" + country);
          if (_this.lodash.isEmpty(countryArr) === true) {
            _this.$store
              .dispatch(GET_COUNTRY, country)
              .then((response) => {
                LocalService.saveData("country-" + country, response.data);
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(countryArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getStates(param1, param2) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let country = _this.lodash.toInteger(param1.id);
          let state = _this.lodash.toInteger(param2);
          let stateArr = LocalService.getData("state-" + country + "-" + state);
          if (_this.lodash.isEmpty(stateArr) === true) {
            _this.$store
              .dispatch(GET_STATE, country + "/" + state)
              .then((response) => {
                LocalService.saveData(
                  "state-" + country + "-" + state,
                  response.data
                );
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(stateArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCities(param1, param2) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let state = _this.lodash.toInteger(param1.id);
          let city = _this.lodash.toInteger(param2);
          let cityArr = LocalService.getData("city-" + state + "-" + city);
          if (_this.lodash.isEmpty(cityArr) === true) {
            _this.$store
              .dispatch(GET_CITY, state + "/" + city)
              .then((response) => {
                LocalService.saveData(
                  "city-" + state + "-" + city,
                  response.data
                );
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(cityArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    formatDate: function (date) {
      if (!date) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(date).format(Config.dateFormat);
      }
      return date;
    },
    formatDateTime: function (date) {
      if (!date) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(date).format(Config.dateTimeFormat);
      }
      return date;
    },
    formatNumber: function (input) {
      if (!input) {
        return accounting.formatNumber(0, { precision: 2 });
      }
      if (isNaN(input)) {
        return accounting.formatNumber(0, { precision: 2 });
      }
      let number = toSafeNumber(input);
      return accounting.formatNumber(number, { precision: 2 });
    },
    formatMoney: function (input) {
      if (!input) {
        return accounting.formatMoney(0, this.CurrencySymbol, { precision: 3 });
      }
      if (isNaN(input)) {
        return accounting.formatMoney(0, this.CurrencySymbol, { precision: 3 });
      }
      let number = toSafeNumber(input);
      return accounting.formatMoney(number, {
        symbol: this.CurrencySymbol,
        precision: 3,
        thousand: ",",
        format: {
          pos: "%s %v",
          neg: "%s (%v)",
        },
      });
    },
    accountingUnFormat: function (number) {
      return accounting.unformat(number);
    },
    accountingToFixed: function (number) {
      return accounting.toFixed(number);
    },
    booleanToString(param) {
      switch (param) {
        case true:
          return "Yes";
        case false:
          return "No";
        default:
          "No";
      }
    },
    commitErrors(response) {
      let requestErrors = new Array();
      if (response && response.status === 422) {
        for (let error in response.data) {
          if (response.data[error]) {
            for (let i = response.data[error].length - 1; i >= 0; i--) {
              requestErrors.push(response.data[error][i]);
            }
          }
        }
      } else {
        requestErrors.push(response.toString());
      }
      return requestErrors;
    },
    updatePageTitle() {
      const title = getTitle(this);
      if (title) {
        document.title = title;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    let Config = AppConfiguration.get();
    if (Config) {
      this.CurrencySymbol = Config.currency.symbol;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const previous =
        document.querySelector(".menu-item-active").previousSibling;
      const next = document.querySelector(".menu-item-active").nextSibling;
      previous.classList.add("menu-item-prev");
      next.classList.add("menu-item-next");
    });
  },
};
