var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-new"},[(_vm.allowMinimize)?_c('div',{staticClass:"brand-tools pt-4"},[_c('button',{ref:"kt_aside_toggle",staticClass:"brand-toggle btn btn-sm px-0",attrs:{"id":"kt_aside_toggle"}},[_c('span',{staticClass:"svg-icon svg-icon svg-icon-xl"},[_c('inline-svg',{staticClass:"svg-icon",attrs:{"src":_vm.$assetURL('media/svg/icons/Navigation/Angle-double-left.svg')}})],1)])]):_vm._e(),_c('div',{staticClass:"user-menu",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","min-height":"100px"}},[_c('div',{staticStyle:{"text-align":"center","color":"#fff"}},[_c('v-avatar',{staticStyle:{"background":"white","border":"2px solid #0a5def"},attrs:{"color":"blue","size":"56"}},[_c('v-img',{attrs:{"lazy-src":_vm.$defaultProfileImage,"src":_vm.profileImage}})],1),_c('h5',{staticStyle:{"margin-top":"8px"}},[_vm._v(_vm._s(_vm.user.display_name))]),_c('p',[_vm._v(_vm._s(_vm.user.user_email))])],1)]),_c('ul',{staticClass:"menu-nav"},[_c('div',{staticClass:"before-element"}),_vm._l((_vm.MenuList),function(row,index){return [(_vm.lodash.isArray(row))?[_c('li',{key:index,staticClass:"menu-item menu-item-submenu",class:{
            'menu-item-open menu-item-open-active': _vm.isChildActive(row),
          },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + _vm.getGroupTitle(row) + '.svg')}})],1),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.getGroupTitle(row)))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.getGroupTitle(row)))])])]),_vm._l((row),function(rowd,indexd){return [_c('router-link',{key:indexd,class:{
                    'menu-item-active': _vm.isRouteActive(rowd.menu),
                    'disable-link': _vm.disableLink(rowd.menu),
                  },attrs:{"to":rowd.route},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(rowd.title))])])])]}}],null,true)})]})],2)])])]:_c('router-link',{key:index,class:{
          'menu-item-prev': _vm.isPrevActive(row.order, row.menu),
          'menu-item-next': _vm.isNextActive(row.order, row.menu),
          'menu-item-active': _vm.isRouteActive(row.menu),
          'disable-link': _vm.disableLink(row.menu),
        },attrs:{"to":row.route},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + row.icon + '.svg')}})],1),(row.menu != 'dashboard' && row.menu != 'calendar')?_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.getMenuTitle(row.title)))]):_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(row.title))])])])]}}],null,true)})]}),_c('div',{staticClass:"after-element"})],2),_c('div',{staticClass:"sidebar-bottom-img"},[_c('v-img',{attrs:{"src":_vm.$assetURL('media/custom-svg/networking.png'),"heigh":"100px","width":"100%"}})],1),_c('div',{staticClass:"bottom-bg-svg"})])
}
var staticRenderFns = []

export { render, staticRenderFns }