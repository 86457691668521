<template>
  <CreateUpdateTemplate
    :customClass="'purchase-order-create'"
    v-if="getPermission('purchase-order:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create new Purchase Order</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate('')"
        color="cyan"
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="purchaseOrderForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate('')"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td colspan="2" class="font-weight-700 font-size-16 pb-2">
                    Project Details
                  </td>
                  <td colspan="2" class="font-weight-700 font-size-16 pb-2">
                    Contractor Details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Project #</td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>{{ projectDetails.barcode }}</template>
                  </td>
                  <td class="font-size-16 py-2" width="250">Contractor #</td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.barcode">{{
                      contractorDetails.barcode
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Project Title</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="projectDetails.name">{{
                      projectDetails.name
                    }}</template>
                  </td>
                  <td class="font-size-16 py-2" width="250">Display Name</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.display_name">{{
                      contractorDetails.display_name
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Started At</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="projectDetails.started_at">{{
                      formatDate(projectDetails.started_at)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >Not Started Yet</em
                      ></template
                    >
                  </td>
                  <td class="font-size-16 py-2" width="250">Email</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.default_person">{{
                      contractorDetails.default_person.primary_email
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Email</em></template
                    >
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Deadline</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="projectDetails.deadline">{{
                      formatDate(projectDetails.deadline)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Deadline</em></template
                    >
                  </td>
                  <td class="font-size-16 py-2" width="250">Phone No.</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.default_person">{{
                      contractorDetails.default_person.primary_phone
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Phone No.</em></template
                    >
                  </td>
                </tr>
              </table>
              <template v-if="false">
                <h3 class="font-weight-700 font-size-16 pb-2 mt-2">
                  Quotation
                </h3>
                <v-autocomplete
                  dense
                  filled
                  v-model.number="purchaseOrderCreate.quotation"
                  v-on:change="getQuotationLineItems"
                  :items="quotationList"
                  item-color="cyan"
                  color="cyan"
                  label="Quotation"
                  solo
                  flat
                  return-object
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  item-value="id"
                  :rules="[
                    validateRules.required(
                      purchaseOrderCreate.quotation,
                      'Quotation'
                    ),
                  ]"
                >
                  <template v-slot:selection="{ item }">
                    <span>
                      <span class="font-weight-700 font-size-16 mr-2"
                        >{{ item.quotation_title }} - {{ item.barcode }}</span
                      >
                      <template v-if="item.final_variation == 1">
                        <v-chip
                          class="white--text"
                          color="green lighten-1"
                          x-small
                        >
                          Final Summary Report
                        </v-chip>
                      </template>
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>
                      <span class="font-weight-700 font-size-16 mr-2"
                        >{{ item.quotation_title }} - {{ item.barcode }}</span
                      >
                      <template v-if="item.final_variation == 1">
                        <v-chip
                          class="white--text"
                          color="green lighten-1"
                          x-small
                        >
                          Final Summary Report
                        </v-chip>
                      </template>
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        No Quotation(s) Found.
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </template>
              <!-- <v-layout v-if="!lodash.isEmpty(purchaseOrderCreate.quotation)">
                  <v-flex>
                    <label class="font-weight-700 font-size-16"
                      >Quotation Details</label
                    >
                    <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                      {{ purchaseOrderCreate.quotation.quotation_title }}
                    </p>
                    <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                      {{ purchaseOrderCreate.quotation.attention }}
                    </p>
                  </v-flex>
                </v-layout> -->
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                    Purchase Order details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">
                    Purchase Order number
                  </td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>{{
                      purchaseOrderCreate.barcode
                    }}</template>
                  </td>
                  <td class="font-size-16 pb-2">
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      class="custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="pb-2 font-size-16">SOled Job No.</td>
                  <td colspan="2" class="py-2">
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="purchaseOrderCreate.reference"
                      label="SOled Job No."
                      :rules="[
                        validateRules.minLength(
                          purchaseOrderCreate.reference,
                          'SOled Job No.',
                          1
                        ),
                        validateRules.maxLength(
                          purchaseOrderCreate.reference,
                          'SOled Job No.',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr> -->
                <tr>
                  <td class="pb-2 font-size-16">Reference Number #</td>
                  <td colspan="2" class="py-2">
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="purchaseOrderCreate.supplier_reference"
                      label="Reference Number #"
                      :rules="[
                        validateRules.minLength(
                          purchaseOrderCreate.supplier_reference,
                          'Reference Number #',
                          1
                        ),
                        validateRules.maxLength(
                          purchaseOrderCreate.supplier_reference,
                          'Reference Number #',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="pb-2 font-size-16">Project</td>
                  <td colspan="2" class="py-2">
                    <v-autocomplete
                      v-model.trim="purchaseOrderCreate.project"
                      :items="projectList"
                      dense
                      filled
                      clearable
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      hide-details
                      item-color="cyan"
                      item-text="barcode"
                      item-value="id"
                      label="Project"
                      solo
                      flat
                      v-on:change="
                        getOptions({ project: purchaseOrderCreate.project })
                      "
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr> -->
                <tr>
                  <td class="pb-2 font-size-16">Date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :placeholder="'Date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setPODate"
                      :min-date="currentDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Expected Delivery Date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :placeholder="'Expected Delivery Date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setExpectedDate"
                      :min-date="purchaseOrderCreate.date"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Payment Term</td>
                  <td colspan="2" class="py-2">
                    <v-select
                      dense
                      filled
                      :items="paymentTermList"
                      item-color="cyan"
                      color="cyan"
                      label="Payment Term"
                      solo
                      flat
                      hide-details
                      v-model.number="purchaseOrderCreate.payment_term"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      item-value="id"
                      item-text="text"
                      :rules="[
                        validateRules.required(
                          purchaseOrderCreate.payment_term,
                          'Payment Term'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No Payment Term(s) Found.
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </td>
                </tr>
                <tr v-if="purchaseOrderCreate.payment_term == 5">
                  <td class="pb-2 font-size-16">Due date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :placeholder="'Due date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setDueDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" v-if="getPermission('line-item:create')">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey w-100 lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                    <v-col v-if="false" class="d-flex justify-content-end">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue mr-2"
                      >
                        Show Cost
                      </h3>
                      <v-switch
                        v-model.trim="purchaseOrderCreate.show_cost"
                        inset
                        hide-details
                        color="cyan"
                        class="ml-2 mt-n2"
                      ></v-switch>
                    </v-col>
                  </v-card-title>
                  <v-card-text class="px-6 font-size-16">
                    <v-row dense>
                      <v-col md="12">
                        <PurchaseOrderLineItem
                          :updateData.sync="updateLineItem"
                          isPurchaseOrder
                          :showCost="purchaseOrderCreate.show_cost"
                          :db-line-items="dbLineItems"
                          :pageLoading.sync="pageLoading"
                        ></PurchaseOrderLineItem>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col md="12">
              <PurchaseOrderTermsConditions
                :updateData.sync="updateTermCondition"
                :pageLoading.sync="pageLoading"
              ></PurchaseOrderTermsConditions>
            </v-col>
            <v-col md="12">
              <PurchaseOrderNotesAttachment
                isPurchaseOrder
                :updateData.sync="updateNotesAttachment"
                :pageLoading.sync="pageLoading"
              ></PurchaseOrderNotesAttachment>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="purchase-order"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import PurchaseOrderTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import PurchaseOrderNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { toNumber, filter } from "lodash";
import PurchaseOrderLineItem from "@/view/pages/partials/Line-Item.vue";
import moment from "moment-timezone";
import { currentUser } from "@/core/services/jwt.service";
import ObjectPath from "object-path";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "purchase-order-create",
  title: "Create Task",
  data() {
    return {
      currentDate: null,
      pageLoading: false,
      barcodeDialog: false,
      allcheckbox: false,
      is_admin: false,
      is_id_person: false,
      purchaseOrderDuplicate: 0,
      updateNotesAttachment: new Object(),
      updateTermCondition: new Object(),
      projectList: new Array(),
      projectDetails: new Object(),
      contractorDetails: new Object(),
      updateLineItem: new Array(),
      updateLineItemCalculation: new Object(),
      quotationList: [],
      lineItemlist: [],
      dbLineItems: [],
      lineItemCreated: false,
      paymentTermList: [],
      notesAttachment: null,
      purchaseOrderCreated: false,
      termCondition: null,
      lineItem: null,
      lineItemCalculation: null,
      barcodeSetting: new Object(),
      purchaseOrder: 0,
      project: 0,
      contractor: 0,
      purchaseOrderLineItems: new Array(),
      purchaseOrderCreate: new Object({
        supplier: null,
        barcode: null,
        reference: null,
        supplier_reference: null,
        date: null,
        project: null,
        expected_delivery_date: null,
        due_date: null,
        payment_term: 1,
        show_cost: false,
        admin_remark: null,
        attachments: [],
        term_conditions: null,
        additional_remarks: null,
        discount_type: null,
        discount_value_type: null,
        discount_value: null,
        adjustment: null,
      }),
    };
  },
  components: {
    DatePicker,
    BarcodeSetting,
    CreateUpdateTemplate,
    PurchaseOrderTermsConditions,
    PurchaseOrderNotesAttachment,
    PurchaseOrderLineItem,
  },
  methods: {
    calculateTotal(index) {
      let item = this.lineItem[index];

      if (item) {
        let rate = toNumber(item.rate);
        if (rate < 0) {
          rate = 0;
        }

        let quantity = toNumber(item.quantity);
        if (quantity <= 0) {
          quantity = 1;
        }
        const total = rate * quantity;
        this.lineItem[index].quantity = quantity;
        this.lineItem[index].rate = rate;
        this.lineItem[index].total = total;
      }

      /* if (type == "selected-line-item") {
        this.$nextTick(() => {
          this.calculateGrandTotal();
        });
      } */
    },
    validateCheckbox() {
      const len = filter(this.lineItem, { status: true }).length;
      const default_len = this.lineItem.length;
      if (default_len) {
        if (default_len == len) {
          this.allcheckbox = true;
        } else {
          this.allcheckbox = false;
        }
      } else {
        this.allcheckbox = false;
      }
    },
    checkSelected(line_item) {
      if (this.show_selected) {
        return line_item.status;
      }
      return true;
    },
    getQuotationLineItems(quotation) {
      const _this = this;
      if (quotation.id > 0) {
        _this.pageLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "all-line-item",
            data: { quotation: quotation.id },
          })
          .then(({ data }) => {
            let lineItemdata = _this.lodash.map(data, function (row) {
              return {
                id: null,
                group_name: null,
                package_id: row && row.package_id ? row.package_id : 0,
                group_me: null,
                group_active: false,
                group_primary: false,
                type: 4,
                product: row && row.product ? row.product.name : "",
                barcode: row && row.product ? row.product.barcode : "",
                product_id: row.product.product,
                description: row.description,
                quantity: row.quantity,
                quantity_type: null,
                tax: row.tax,
                status: false,
                company_cost: row.product.company_cost,
                charges: row.product.charges,
                taxObject: null,
                discount: row.discount,
                rate: row.rate,
                total: row.total,
                product_type: row.product.product_type,
              };
            });

            this.dbLineItems = filter(lineItemdata, {
              product_type: "service",
            });

            this.allcheckbox = true;
          })
          .catch((error) => {
            _this.pageLoading = false;
            console.log(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      } else {
        _this.pageLoading = false;
      }
    },
    setPODate(param) {
      this.purchaseOrderCreate.date = param;
    },
    setExpectedDate(param) {
      this.purchaseOrderCreate.expected_delivery_date = param;
    },
    setDueDate(param) {
      this.purchaseOrderCreate.due_date = param;
    },
    getOptions(param) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "purchase-order/options",
          data: { ..._this.$route.query, ...param },
        })
        .then(({ data }) => {
          if (data.project) {
            _this.purchaseOrderCreate.project = data.project.id;
            _this.purchaseOrderCreate.supplier_reference =
              data.project.reference;
            _this.purchaseOrderCreate.reference = data.project.soled_job_no;
            _this.projectDetails = data.project;
          }
          if (data.contractor) {
            _this.contractorDetails = data.contractor;
          }
          _this.projectList = data.projects;
          _this.barcodeSetting = data.barcode_setting;
          _this.purchaseOrderCreate.barcode = data.barcode;
          _this.quotationList = data.quotation;
          _this.paymentTermList = data.payment_term;
          _this.updateTermCondition = new Object({
            term_conditions: _this.barcodeSetting.terms_conditions,
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate(action) {
      try {
        const _this = this;

        const validateStatus = _this.$refs.purchaseOrderForm.validate();

        const formErrors = _this.validateForm(_this.$refs.purchaseOrderForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        let lineItem = _this.lineItem;

        if (_this.is_id_person == true) {
          lineItem = _this.lodash.filter(_this.lineItem, { status: true });
        }

        let validateLineItem = _this.lodash.compact(
          _this.lodash.map(lineItem, function (row) {
            return row.product;
          })
        );

        if (_this.lodash.isEmpty(validateLineItem)) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Select any one line item then try again.")
          );
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        let formData = new Object({
          action,
          quotation: 0, // _this.purchaseOrderCreate.quotation.id,
          //reference: _this.purchaseOrderCreate.reference,
          project: _this.purchaseOrderCreate.project,
          contractor: _this.contractor,
          supplier_reference: _this.purchaseOrderCreate.supplier_reference,
          date: _this.purchaseOrderCreate.date,
          show_cost: _this.purchaseOrderCreate.show_cost,
          expected_delivery_date:
            _this.purchaseOrderCreate.expected_delivery_date,
          due_date: _this.purchaseOrderCreate.due_date,
          payment_term: _this.purchaseOrderCreate.payment_term,
          admin_remark:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.admin_notes
              : null,
          attachments:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.attachments
              : [],
          term_conditions: _this.termCondition,
          additional_remarks:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.description
              : null,
          discount_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountType
              : null,
          discount_value_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValueType
              : null,
          discount_value:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValue
              : null,
          adjustment:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.adjustmentAmount
              : null,
          is_taxed:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.is_taxed
              : null,
          tax_value:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.tax_value
              : null,
        });

        if (!_this.purchaseOrderCreated) {
          try {
            let purchaseOrder = await _this.$store.dispatch(POST, {
              url: "purchase-order",
              data: formData,
            });

            _this.purchaseOrder = _this.lodash.toSafeInteger(
              purchaseOrder.data.id
            );

            _this.purchaseOrderCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.purchaseOrderCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order is not created. Please try again."
              )
            );
          }
        }

        if (
          !_this.lineItemCreated &&
          _this.purchaseOrderCreated &&
          _this.purchaseOrder
        ) {
          try {
            await _this.CreateLineItems({
              type: "purchase_order",
              parent: _this.purchaseOrder,
              formData: lineItem,
            });

            _this.lineItemCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.lineItemCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order Line Item(s) are not created. Please try again."
              )
            );
          }
        }

        _this.formLoading = false;

        if (_this.purchaseOrderCreated && _this.lineItemCreated) {
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.detail", {
              params: {
                id: _this.purchaseOrder,
              },
            })
          );
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error.toString()));
      }
    },
    createLineItems(data) {
      return new Promise((resolve, reject) => {
        try {
          const _this = this;
          const formData = data;

          _this.formLoading = true;
          // _this.$store.dispatch(CLEAR_ERROR, {});
          _this.$store
            .dispatch(POST, {
              url: "line-item",
              data: formData,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getPurchaseOrder(purchaseOrder) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "purchase-order/" + purchaseOrder,
        })
        .then(({ data }) => {
          _this.purchaseOrderCreate.supplier =
            _this.lodash.isEmpty(data.supplier) === false
              ? data.supplier
              : null;
          _this.purchaseOrderCreate.payment_term =
            _this.lodash.isEmpty(data.payment_term) === false
              ? data.payment_term.id
              : null;

          let lineItems = new Array();
          for (let i = 0; i < data.line_items.length; i++) {
            lineItems.push({
              original_product: data.line_items[i].original_product,
              barcode: data.line_items[i].original_product.barcode,
              id: null,
              description: data.line_items[i].description,
              quantity: data.line_items[i].quantity,
              tax: data.line_items[i].tax,
              rate: data.line_items[i].rate,
              total: data.line_items[i].total,
              warranty_data: null,
              warranty_start_date: null,
              warranty_unique_id: null,
              warranty_description: null,
              minimum_rental: null,
              maximum_rental: null,
            });
          }

          _this.updateLineItem = lineItems;

          _this.updateLineItemCalculation = new Object({
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            tax_amount: data.tax_amount,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            discount_value_type: data.discount_value_type,
            adjustment: data.adjustment,
            total: data.total,
          });

          _this.updateNotesAttachment = new Object({
            admin_remark: data.admin_remark,
            client_remark: null,
            notify_admin: 0,
            notify_customer: 0,
            notify_engineer: 0,
          });

          _this.updateTermCondition = new Object({
            term_conditions: data.term_conditions,
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");
    LineItemCalculationEventBus.$off("update:line-item-calculation");
    NoteAttachmentEventBus.$off("update:notes-attachment");
    TermConditionEventBus.$off("update:term-condition");*/
  },
  mounted() {
    const _this = this;
    this.currentDate = moment().format("YYYY-MM-DD");
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Purchase Order", route: "purchase.order" },
      { title: "Create" },
    ]);

    const user = currentUser();
    const role_name = ObjectPath.get(user, "role_name");
    if (this.lodash.toLower(role_name) == "admin") {
      this.is_admin = true;
    }
    if (this.lodash.toLower(role_name) == "id") {
      this.is_id_person = true;
    }

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.notesAttachment = argument;
    });

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      _this.termCondition = argument;
    });

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        console.log(argument);
        _this.lineItemCalculation = argument;
      }
    );
  },
  created() {
    const _this = this;

    _this.purchaseOrderDuplicate = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
    if (_this.purchaseOrderDuplicate > 0) {
      _this.getPurchaseOrder(_this.purchaseOrderDuplicate);
    }
    _this.contractor = _this.lodash.toSafeInteger(
      _this.$route.query.contractor
    );
  },
  computed: {
    defaultStartDate() {
      if (this.purchaseOrderCreate.start_date) {
        return this.purchaseOrderCreate.start_date;
      }
      return new Date().toISOString().substr(0, 10);
    },
    defaultDueDate() {
      if (this.purchaseOrderCreate.due_date) {
        return this.purchaseOrderCreate.due_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
};
</script>
