<template>
  <DetailTemplate
    :customClass="'purchase-order-detail detail-page'"
    v-if="getPermission('purchase-order:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton"
            width="400"
            height="28"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mr-4">
            {{ detail.barcode }}
          </h1>
          <CustomStatus
            :status.sync="detail.status"
            endpoint="purchase-order/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template
        v-if="detail.status == 1 && getPermission('purchase-order:update')"
      >
        <v-btn
          class="mx-2 custom-bold-button white--text"
          v-on:click="raisePo()"
          color="cyan"
        >
          Raise PO
        </v-btn>
      </template>
      <template
        v-if="detail.status == 2 && getPermission('purchase-order:update')"
      >
        <v-btn
          class="mx-2 custom-bold-button white--text"
          v-on:click="sendToContractorDialog = true"
          color="cyan"
        >
          Send To Contractor
        </v-btn>
      </template>
      <template
        v-if="
          (detail.status == 3 || detail.status == 4) &&
          getPermission('purchase-order:update')
        "
      >
        <v-btn
          class="mx-2 custom-bold-button white--text"
          v-on:click="invoiceDialog = true"
          color="cyan"
        >
          Record Invoice
        </v-btn>
      </template>
      <!-- <template v-if="detail.status == 3 && detail.pending_amount > 0">
        <v-btn
          class="mx-2 custom-bold-button white--text"
          v-on:click="paymentDialog = true"
          color="cyan"
        >
          Add Payment
        </v-btn>
      </template> -->
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              v-if="getPermission('purchase-order:' + more.permission)"
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="position: relative"
      > -->
      <v-container fluid>
        <v-row dense>
          <v-col cols="5" class="pb-0">
            <v-container fluid>
              <v-list flat>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('contractor.detail', {
                                  params: {
                                    id: detail.contractor
                                      ? detail.contractor.id
                                      : 0,
                                  },
                                })
                              "
                              >{{
                                detail.contractor
                                  ? detail.contractor.display_name
                                  : "No Contractor"
                              }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('contractor.detail', {
                                params: {
                                  id: detail.contractor
                                    ? detail.contractor.id
                                    : 0,
                                },
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Attention</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28"
                            >mdi-office-building-outline</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <template
                              v-if="
                                detail.contractor &&
                                detail.contractor.company_name
                              "
                            >
                              <router-link
                                :to="
                                  getDefaultRoute('contractor.detail', {
                                    params: {
                                      id: detail.contractor
                                        ? detail.contractor.id
                                        : 0,
                                    },
                                  })
                                "
                                >{{
                                  detail.contractor
                                    ? detail.contractor.company_name
                                    : ""
                                }}</router-link
                              >
                            </template>
                            <template v-else> No Company Name </template>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('contractor.detail', {
                                params: {
                                  id: detail.contractor
                                    ? detail.contractor.id
                                    : 0,
                                },
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Company Name</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <template v-if="contractorEmail">
                              <a :href="'mailto:' + contractorEmail">{{
                                contractorEmail
                              }}</a>
                            </template>
                            <template v-else> No Contractor Email </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contractor Email</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <template v-if="contractorPhone">
                              <a :href="'tel:' + contractorPhone"
                                >+65-{{ contractorPhone }}</a
                              >
                            </template>
                            <template v-else> No Contractor Phone </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contractor Phone</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item class="py-1" v-bind="attrs" v-on="on">
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28"
                            >mdi-home-map-marker</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                          >
                            <template v-if="contractorAddress">
                              <a
                                target="_blank"
                                :href="
                                  'https://maps.google.com/?q=' +
                                  contractorAddress
                                "
                                >{{ contractorAddress }}</a
                              >
                            </template>
                            <template v-else> No Address </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contractor Address</span>
                  </v-tooltip>
                </template>
              </v-list>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 4"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100">
                      <tr>
                        <td class="font-size-18 pt-3 pb-1" width="200">
                          Purchase Order #
                        </td>
                        <td class="font-weight-600 font-size-18 pt-3 pb-1">
                          {{ detail.barcode }}
                        </td>
                      </tr>
                      <tr v-if="detail.project">
                        <td class="font-size-18 py-1" width="200">Project #</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ detail.project.barcode }} -
                          {{ detail.project.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Quotation Ref #
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="detail.quotation">{{
                            detail.quotation.barcode
                          }}</template>
                        </td>
                      </tr>
                      <tr v-if="lodash.isEmpty(detail.payment_term) === false">
                        <td class="font-size-18 py-1" width="200">
                          Payment Term
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="detail.payment_term">{{
                            detail.payment_term.text
                          }}</template>
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >No Payment Term</em
                            ></template
                          >
                        </td>
                      </tr>
                      <tr v-if="lodash.isEmpty(detail.payment_term) === false">
                        <td class="font-size-18 py-1" width="200">
                          No. Of Invoice
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="detail.invoice_count">{{
                            detail.invoice_count
                          }}</template>
                        </td>
                      </tr>
                      <tr v-if="detail.total_invoice_amount > 0">
                        <td class="font-size-18 py-1" width="200">
                          Invoice Amount
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template
                            ><span class="orange--text">{{
                              $accountingJS.formatMoney(
                                detail.total_invoice_amount
                              )
                            }}</span></template
                          >
                        </td>
                      </tr>
                      <tr v-if="detail.total_invoice_pending_amount > 0">
                        <td class="font-size-18 py-1" width="200">
                          Invoice Pending Amount
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template
                            ><span class="red--text">{{
                              $accountingJS.formatMoney(
                                detail.total_invoice_pending_amount
                              )
                            }}</span></template
                          >
                        </td>
                      </tr>
                      <tr v-if="detail.total_invoice_paid_amount > 0">
                        <td class="font-size-18 py-1" width="200">
                          Invoice Paid Amount
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template
                            ><span class="green--text">{{
                              $accountingJS.formatMoney(
                                detail.total_invoice_paid_amount
                              )
                            }}</span></template
                          >
                        </td>
                      </tr>
                      <tr class="custom-border-top">
                        <td class="font-size-18 py-1 pr-4" width="200">
                          Purchase order date
                        </td>
                        <td class="font-size-18 py-1" width="200">
                          Expected delivery date
                        </td>
                        <!-- <td class="font-size-18 px-4 py-1" width="200">
                          Due date
                        </td> -->
                      </tr>
                      <tr>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 pr-4"
                        >
                          {{ formatDate(detail.date) }}
                        </td>
                        <td
                          width="300"
                          class="font-weight-600 font-size-18 py-1"
                        >
                          <template v-if="detail.expected_delivery_date">{{
                            formatDate(detail.expected_delivery_date)
                          }}</template>
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >no Expected delivery date</em
                            ></template
                          >
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="purchaseOrderTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#overview"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/purchase-order.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <v-badge
                  v-if="detail.line_items_count"
                  bordered
                  color="orange darken-4"
                  :content="detail.line_items_count"
                >
                  {{ detail.line_items_count > 1 ? "Line Item" : "Line Item" }}
                </v-badge>
                <template v-else> Line Item </template>
              </v-tab>

              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#image"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/image.svg')" />
                  <!--end::Svg Icon-->
                </span>
                Images
              </v-tab>
              <!-- <v-tab
                v-if="showPendingTab"
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#pending-line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                </span>
                <v-badge
                  v-if="detail.line_items_count"
                  bordered
                  color="orange darken-4"
                  :content="detail.line_items_count"
                >
                  {{ detail.line_items_count > 1 ? "Line Item" : "Line Item" }}
                </v-badge>
                <template v-else> Pending Line Item </template>
              </v-tab> -->
              <!-- <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#received-line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                 
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  
                </span>
                <v-badge
                  v-if="detail.line_items_count"
                  bordered
                  color="orange darken-4"
                  :content="detail.line_items_count"
                >
                  {{ detail.line_items_count > 1 ? "Line Item" : "Line Item" }}
                </v-badge>
                <template v-else> Received Line Item </template>
              </v-tab> -->
              <v-tab
                :disabled="pageLoading"
                v-if="detail && detail.status == 4"
                class="font-size-16 font-weight-600 px-8"
                href="#invoiceDetails"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/invoice.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Invoice Detail
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                v-if="detail && detail.status == 4"
                class="font-size-16 font-weight-600 px-8"
                href="#payments"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/payment.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Payments
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#history"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/history.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                History
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="purchaseOrderTab">
              <v-tab-item value="overview">
                <LineItemOnlyView
                  isPurchaseOrder
                  type="purchase_order"
                  :detail.sync="detail"
                  :showcost.sync="detail.show_cost"
                  :pageLoading.sync="pageLoading"
                ></LineItemOnlyView>
                <TermConditionDetail
                  type="purchase-order"
                  :detail.sync="detail"
                  :canUpdate.sync="canUpdatePurchaseOrder"
                ></TermConditionDetail>
                <InternalNoteAttachmentDetail
                  isPurchaseOrder
                  type="purchase-order"
                  :detail.sync="detail"
                  :canUpdate.sync="canUpdatePurchaseOrder"
                ></InternalNoteAttachmentDetail>
              </v-tab-item>
              <v-tab-item value="image">
                <AttachmentView
                  :attachments="detail.documents"
                  type="purchase_order"
                ></AttachmentView>
              </v-tab-item>
              <v-tab-item value="line-item">
                <LineItemDetail
                  isPurchaseOrder
                  type="purchase_order"
                  :detail.sync="detail"
                  :showcost="detail.show_cost"
                  :pageLoading.sync="pageLoading"
                  v-on:load:parent="getPurchaseOrder"
                  :canUpdate.sync="canUpdatePurchaseOrder"
                ></LineItemDetail>
              </v-tab-item>
              <v-tab-item value="invoiceDetails">
                <InvoiceDetail
                  :detail.sync="detail"
                  v-on:success="loadContent()"
                ></InvoiceDetail>
              </v-tab-item>
              <v-tab-item value="payments">
                <PaymentListingTemplate
                  :detail.sync="detail"
                  type="purchase_order"
                ></PaymentListingTemplate>
              </v-tab-item>
              <v-tab-item value="pending-line-item">
                <PendingLineItem
                  :purchaseOrder.sync="purchaseOrder"
                  v-on:reload:content="getPurchaseOrder"
                ></PendingLineItem>
              </v-tab-item>
              <v-tab-item value="received-line-item">
                <ReceivedLineItem
                  :purchaseOrder.sync="purchaseOrder"
                ></ReceivedLineItem>
              </v-tab-item>
              <v-tab-item value="history">
                <InternalHistoryDetail
                  type="purchase-order"
                  :type_id.sync="detail.id"
                ></InternalHistoryDetail>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- </perfect-scrollbar> -->
      <Dialog
        v-if="invoiceDialog"
        :commonDialog="invoiceDialog"
        :dialogWidth="1024"
      >
        <template v-slot:title>Record Invoice</template>
        <template v-slot:body>
          <v-form
            ref="invoiceForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="invoicegenerated()"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  color="cyan"
                  filled
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="invoiceData.invoice_number"
                  label="Invoice Number #"
                  :rules="[
                    validateRules.required(
                      invoiceData.invoice_number,
                      'Invoice Number #'
                    ),
                    validateRules.minLength(
                      invoiceData.invoice_number,
                      'Invoice Number #',
                      1
                    ),
                    validateRules.maxLength(
                      invoiceData.invoice_number,
                      'Invoice Number #',
                      50
                    ),
                  ]"
                  solo
                  flat
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  color="cyan"
                  type="number"
                  min="0"
                  filled
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="invoiceData.amount"
                  v-on:keyup="checkAmount"
                  v-on:click="checkAmount"
                  v-on:keypress="limitDecimal($event, invoiceData.amount)"
                  label="Invoice Amount"
                  :rules="[
                    validateRules.required(
                      invoiceData.amount,
                      'Invoice Amount'
                    ),
                  ]"
                  solo
                  flat
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <DatePicker
                  :defaultDate.sync="invoiceData.invoice_date"
                  :placeholder="'Invoice Date'"
                  solo
                  :pageLoading.sync="pageLoading"
                  v-on:update:date-picker="setInvoiceDate"
                ></DatePicker>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model.trim="invoiceData.admin_remark"
                  auto-grow
                  dense
                  filled
                  label="Remarks"
                  solo
                  flat
                  row-height="30"
                  color="cyan"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <h4>Attachments</h4>
              </v-col>
              <v-col cols="12">
                <AttachmentWithName
                  v-on:file:updated="updateAttachment"
                  allowUpload
                ></AttachmentWithName>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="invoicegenerated"
            :disabled="!formValid"
            >Record Invoice
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="invoiceDialog = false"
            >Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="sendToContractorDialog">
        <template v-slot:title> Send Confirmation </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              Are you sure, you want to send this PO to contractor?
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="pageLoading"
            :loading="pageLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="green lighten-1"
            v-on:click="updatePurchaseOrderStatus(3)"
          >
            Yes! Send
          </v-btn>
          <v-btn
            :disabled="pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="sendToContractorDialog = false"
          >
            No! Cancel
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import InvoiceDetail from "@/view/pages/partials/Invoice-Detail.vue";
import TermConditionDetail from "@/view/pages/partials/Detail/Term-Condition-Detail.vue";
import InternalNoteAttachmentDetail from "@/view/pages/partials/Detail/Internal-Note-Attachment-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import PendingLineItem from "@/view/pages/purchase-order/Detail/Pending-Line-Item";
import ReceivedLineItem from "@/view/pages/purchase-order/Detail/Received-Line-Item";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH, POST } from "@/core/services/store/request.module";
import LineItemOnlyView from "@/view/pages/partials/Line-Item-Only-View.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";
import PaymentListingTemplate from "@/view/pages/partials/Detail/Payments.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import AttachmentView from "@/view/pages/partials/AttachmentView";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data: () => {
    return {
      purchaseOrder: 0,
      pageLoading: true,
      detail: new Object(),
      sendToContractorDialog: false,
      moreActions: new Array(),
      invoiceDialog: false,
      updateDetailDialog: false,
      invoiceData: {
        admin_remark: null,
        invoice_number: null,
        amount: null,
        invoice_date: null,
        documents: [],
      },
    };
  },
  methods: {
    checkAmount() {
      if (this.invoiceData.amount < 0) {
        this.invoiceData.amount = 0;
      }
    },
    loadContent() {
      this.getPurchaseOrder();
    },
    setInvoiceDate(param) {
      this.invoiceData.invoice_date = param;
    },
    raisePo() {
      this.$router.push(
        this.getDefaultRoute("purchase.order.raise", {
          params: { id: this.purchaseOrder, action: "raise" },
        })
      );
    },
    isValidImage(file_url) {
      try {
        const _this = this;
        let validImageExtension = ["png", "jpg", "jpeg"];
        let fileExtention = _this.lodash.last(file_url.split("."));
        return _this.lodash.includes(
          validImageExtension,
          fileExtention.toLowerCase()
        );
      } catch (error) {
        this.logError(error);
        return false;
      }
    },
    getFileExtension(path) {
      return this.lodash.last(path.split("."));
    },
    updateAttachment(param) {
      this.invoiceData.documents = param;
    },
    getPurchaseOrder() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: "purchase-order/" + _this.purchaseOrder })
        .then(({ data }) => {
          _this.detail = data;
          _this.moreActions = data.more_actions;
        })
        .catch((error) => {
          _this.goBack();
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.create", {
              query: {
                duplicate: _this.detail.id,
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.update", {
              params: {
                id: _this.detail.id,
              },
            })
          );
          break;
        case "mark_as_raised":
          _this.updatePurchaseOrderStatus(2);
          break;
        case "invoice_generated":
          this.invoiceDialog = true;
          break;
        case "mark_as_closed":
          _this.updatePurchaseOrderStatus(3);
          break;
        case "mark_as_cancel":
          _this.updatePurchaseOrderStatus(4);
          break;
        case "download_pdf":
          window.open(
            process.env.VUE_APP_API_URL +
              "purchase-order/" +
              _this.detail.id +
              "/pdf/download",
            "_blank"
          );
          break;
        case "print":
          window.open(
            process.env.VUE_APP_API_URL +
              "purchase-order/" +
              _this.detail.id +
              "/print",
            "_blank"
          );
          break;
      }
    },
    updatePurchaseOrderStatus(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "purchase-order/" + _this.purchaseOrder,
          data: { status },
        })
        .then(() => {
          _this.getPurchaseOrder();
          if (status == 3) {
            _this.sendToContractorDialog = false;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    invoicegenerated() {
      const _this = this;
      const validateStatus = _this.$refs.invoiceForm.validate();
      const formErrors = _this.validateForm(_this.$refs.invoiceForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }
      let formData = this.invoiceData;
      formData.purchase_order = _this.purchaseOrder;
      formData.project =
        this.detail && this.detail.project ? this.detail.project.id : 0;

      _this.$store
        .dispatch(POST, {
          url: "invoice",
          data: formData,
        })
        .then(() => {
          if (_this.detail.status == 2) {
            _this.updatePurchaseOrderStatus(3);
          } else {
            _this.getPurchaseOrder();
          }
          this.invoiceDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    LineItemOnlyView,
    PendingLineItem,
    AttachmentView,
    ReceivedLineItem,
    DetailTemplate,
    CustomStatus,
    LineItemDetail,
    TermConditionDetail,
    InternalNoteAttachmentDetail,
    InternalHistoryDetail,
    AttachmentWithName,
    PaymentListingTemplate,
    InvoiceDetail,
    DatePicker,
  },
  created() {
    const _this = this;
    _this.purchaseOrder = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.purchaseOrder || _this.purchaseOrder <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.purchaseOrderTab = _this.$route.query.tab;
    }
  },
  mounted() {
    const _this = this;
    _this.getPurchaseOrder();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Purchase Order",
        route: "purchase.order",
      },
      {
        title: "Detail",
      },
    ]);
  },
  computed: {
    contractorAddress() {
      let result = new Array();

      if (
        this.detail &&
        this.detail.contractor &&
        this.detail.contractor.billing
      ) {
        if (this.detail.contractor.billing.unit_number) {
          result.push(this.detail.contractor.billing.unit_number);
        }
        if (this.detail.contractor.billing.street_1) {
          result.push(this.detail.contractor.billing.street_1);
        }
        if (this.detail.contractor.billing.street_2) {
          result.push(this.detail.contractor.billing.street_2);
        }
        if (this.detail.contractor.billing.zip_code) {
          result.push(this.detail.contractor.billing.zip_code);
        }
      }
      return result.join(", ");
    },
    contractorEmail() {
      if (
        this.detail &&
        this.detail.contractor &&
        this.detail.contractor.default_person
      ) {
        if (this.detail.contractor.default_person.primary_email) {
          return this.detail.contractor.default_person.primary_email;
        }
      }
      return null;
    },
    contractorPhone() {
      if (
        this.detail &&
        this.detail.contractor &&
        this.detail.contractor.default_person
      ) {
        return this.detail.contractor.default_person.primary_phone;
      }
      return null;
    },
    canUpdatePurchaseOrder() {
      if (this.detail && (this.detail.status == 3 || this.detail.status == 4)) {
        return false;
      }
      return this.getPermission("purchase-order:update");
    },
    showPendingTab() {
      if (this.detail.status == 1) {
        return false;
      }
      if (this.detail.status == 6) {
        return false;
      }
      if (this.detail.pending_line_items > 0) {
        return true;
      }
      return false;
    },
    purchaseOrderTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.purchaseOrderTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "overview";
      },
    },
    defaultInvoiceDate() {
      if (this.invoiceData.invoice_date) {
        return this.invoiceData.invoice_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
};
</script>
