var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"commonDialog":_vm.dialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Record Payment for "+_vm._s(_vm.detail.barcode)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"paymentForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit()}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-layout',[_c('v-flex',{attrs:{"md4":""}},[_c('label',{staticClass:"required",attrs:{"for":"amount-received"}},[_vm._v("Amount Received")])]),_c('v-flex',{attrs:{"md8":""}},[_c('v-text-field',{attrs:{"dense":"","id":"amount-received","color":"cyan","filled":"","type":"number","min":"0","disabled":_vm.pageLoading,"label":"Amount Received","rules":[
                    _vm.validateRules.required(
                      _vm.paymentCreate.received,
                      'Amount Received'
                    ),
                  ],"solo":"","flat":"","hide-details":""},on:{"keyup":_vm.checkAmount,"click":_vm.checkAmount,"keypress":function($event){return _vm.limitDecimal($event, _vm.paymentCreate.received)}},model:{value:(_vm.paymentCreate.received),callback:function ($$v) {_vm.$set(_vm.paymentCreate, "received", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"paymentCreate.received"}})],1)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-layout',[_c('v-flex',{attrs:{"md4":""}},[_c('label',{staticClass:"required",attrs:{"for":"payment-mode"}},[_vm._v("Payment mode")])]),_c('v-flex',{attrs:{"md8":""}},[_c('v-select',{attrs:{"id":"payment-mode","items":_vm.paymentModeList,"dense":"","filled":"","solo":"","flat":"","hide-details":"","rules":[
                    _vm.validateRules.required(
                      _vm.paymentCreate.payment_mode,
                      'Payment mode'
                    ),
                  ],"disabled":_vm.pageLoading,"append-outer-icon":"mdi-cog","label":"Payment mode","color":"cyan","item-text":"text","item-value":"id","item-color":"cyan"},on:{"click:append-outer":function($event){_vm.managePaymentModeDialog = true}},model:{value:(_vm.paymentCreate.payment_mode),callback:function ($$v) {_vm.$set(_vm.paymentCreate, "payment_mode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"paymentCreate.payment_mode"}})],1)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-layout',[_c('v-flex',{attrs:{"md4":""}},[_c('label',{attrs:{"for":"payment-date"}},[_vm._v("Payment Date")])]),_c('v-flex',{attrs:{"md8":""}},[_c('DatePicker',{attrs:{"solo":"","id":"payment-date","min-date":_vm.paymentMinDate,"pageLoading":_vm.pageLoading,"placeholder":"Payment Date"},on:{"update:pageLoading":function($event){_vm.pageLoading=$event},"update:page-loading":function($event){_vm.pageLoading=$event},"update:date-picker":_vm.setPaymentDate}})],1)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-layout',[_c('v-flex',{attrs:{"md4":""}},[_c('label',{attrs:{"for":"transaction-id"}},[_vm._v("Transaction ID")])]),_c('v-flex',{attrs:{"md8":""}},[_c('v-text-field',{attrs:{"id":"transaction-id","dense":"","color":"cyan","filled":"","disabled":_vm.pageLoading,"label":"Transaction ID","rules":[
                    _vm.validateRules.minLength(
                      _vm.paymentCreate.transaction_id,
                      'Transaction ID',
                      1
                    ),
                    _vm.validateRules.maxLength(
                      _vm.paymentCreate.transaction_id,
                      'Transaction ID',
                      100
                    ),
                  ],"solo":"","flat":"","hide-details":""},model:{value:(_vm.paymentCreate.transaction_id),callback:function ($$v) {_vm.$set(_vm.paymentCreate, "transaction_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"paymentCreate.transaction_id"}})],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"note"}},[_vm._v("Leave a note")]),_c('v-textarea',{attrs:{"dense":"","filled":"","solo":"","flat":""},model:{value:(_vm.paymentCreate.remarks),callback:function ($$v) {_vm.$set(_vm.paymentCreate, "remarks", $$v)},expression:"paymentCreate.remarks"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"attachments"}},[_vm._v("Attachments")]),_c('AttachmentWithName',{attrs:{"id":"attachments","allowUpload":""},on:{"file:updated":_vm.updateFiles}})],1)],1)],1)],1),_c('ManagePaymentMode',{attrs:{"commonDialog":_vm.managePaymentModeDialog,"paymentMode":_vm.paymentModeList},on:{"update:commonDialog":function($event){_vm.managePaymentModeDialog=$event},"update:common-dialog":function($event){_vm.managePaymentModeDialog=$event},"update:paymentMode":function($event){_vm.paymentModeList=$event},"update:payment-mode":function($event){_vm.paymentModeList=$event},"close-dialog":function($event){_vm.managePaymentModeDialog = false},"get-payment-mode":function($event){return _vm.getAttributes(['payment_modes'])}}})]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading || _vm.pageLoading},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading || _vm.pageLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.onSubmit}},[_vm._v(" Save Payment ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }