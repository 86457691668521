<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialog"
      :max-width="dialogWidth"
      content-class="contractor-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">
            Select a Contractor
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon dark color="cyan" v-on:click="$emit('resetAll', true)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which contractor would you like to create this for?
              </h5>
              <table width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-contractor"
                      v-model.trim="contractor"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <!-- <template v-if="getPermission('contractor:create')">
                    <td width="50" class="custom-header-blue-text text-center">
                      OR
                    </td>
                    <td>
                      <v-btn
                        class="mx-2 mx-md-4 custom-bold-button white--text"
                        v-on:click="createContractorDialog = true"
                        color="cyan"
                      >
                        <v-icon dark left>mdi-plus</v-icon>Contractor
                      </v-btn>
                    </td>
                  </template>-->
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="position: static"
                >
                  <v-list class="mx-2 mx-md-4" subheader three-line>
                    <v-skeleton-loader
                      v-if="searchLoader"
                      class="custom-skeleton"
                      type="list-item-avatar-two-line"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="contractorList.length > 0">
                        <v-list-item
                          v-on:click="selectContractor(data)"
                          v-for="(data, index) in contractorList"
                          :key="index"
                          link
                        >
                          <v-list-item-avatar>
                            <v-img
                              contain
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(data.profile_logo)"
                              aspect-ratio="1"
                              class="margin-auto"
                              transition="fade-transition"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >{{ data.display_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="font-weight-500 font-size-14"
                            >
                              {{ getDefaultPerson(data.default_person) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="margin-auto">
                            <v-list-item-action-text
                              class="font-weight-500 font-size-13"
                              >{{ data.persons_count }}
                              Contact Person(s)
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >No Contractor(s) Found
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      origin="top center"
      v-model="expenseDialog"
      :max-width="dialogWidth"
      persistent
    >
      <v-card>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-row>
              <v-col
                md="6"
                class="text-center custom-border-right link"
                v-on:click="selectedPersonal()"
              >
                <v-avatar size="200">
                  <v-img
                    contain
                    :src="$assetURL('media/default/no-profile-image.png')"
                  ></v-img>
                </v-avatar>
                <h3
                  class="color-custom-blue font-weight-600 font-size-24 text-uppercase"
                >
                  Personal
                </h3>
              </v-col>
              <v-col
                md="6"
                class="text-center custom-border-left link"
                v-on:click="selectedContractor()"
              >
                <v-btn
                  icon
                  dark
                  color="cyan"
                  class="personal-close-btn"
                  v-on:click="$emit('resetAll', true)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-avatar size="200">
                  <v-img
                    contain
                    :src="$assetURL('media/default/no-profile-image.png')"
                  ></v-img>
                </v-avatar>
                <h3
                  class="color-custom-blue font-weight-600 font-size-24 text-uppercase"
                >
                  Contractor
                </h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Dialog
      v-if="createContractorDialog"
      :commonDialog="createContractorDialog"
      :dialogWidth="1024"
    >
      <template v-slot:title>Create Contractor</template>
      <template v-slot:body>
        <v-form
          ref="createContractorOnly"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createContractorOnly"
        >
          <DialogContactDetails
            :isDialog="true"
            v-on:saveContactDetail="updateContractorDetailOnly"
          >
          </DialogContactDetails>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="createContractorOnly"
          >Save Contractor
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="createContractorDialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { POST, CLEAR_ERROR, QUERY } from "@/core/services/store/request.module";
import DialogContactDetails from "@/view/pages/contractor/create-or-update/Contact-Details.vue";

export default {
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      expenseDialog: false,
      contractor: null,
      timeoutLimit: 500,
      timeout: null,
      contractorArr: new Object(),
      createContractorDialog: false,
      contractorList: new Array(),
      dialog: false,
    };
  },
  props: {
    isExpense: {
      type: Boolean,
      default: false,
    },
    contractorDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    project: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Dialog,
    DialogContactDetails,
  },
  watch: {
    contractorDialog() {
      this.init();
    },
    contractor(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchContractor();
        }, _this.timeoutLimit);
      }
    },
  },
  methods: {
    getContractors() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "project-contractor-list",
              data: { project: _this.project },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    selectedPersonal() {
      this.$emit("selectPersonal", true);
    },
    selectedContractor() {
      this.expenseDialog = false;
      this.dialog = true;
    },
    init() {
      if (!this.isExpense) {
        this.dialog = this.contractorDialog;
      } else {
        this.expenseDialog = true;
      }
    },
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchContractor();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchContractor();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.contractor = null;
        _this.isFocused = false;
      });
    },
    searchContractor() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getContractors(_this.contractor)
        .then((response) => {
          _this.contractorList = response;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searchLoader = false;
          _this.createContractorDialog = false;
        });
    },
    selectContractor(param) {
      console.log(param);
      /* if (
        param.persons_count === 1 &&
        this.lodash.isEmpty(param.single_person) === false
      ) {
        this.$emit("selectContractorPerson", param.single_person.id);
      }
      if (
        param.property_count === 1 &&
        this.lodash.isEmpty(param.single_property) === false
      ) {
        this.$emit("selectContractorProperty", param.single_property.id);
      }
      if (
        param.billing_count === 1 &&
        this.lodash.isEmpty(param.single_billing) === false
      ) {
        this.$emit("selectContractorBilling", param.single_billing.id);
      } */
      this.$emit("selectContractor", param.contractor);
    },
    getProfileImage(imagePath) {
      if (!imagePath) {
        return this.$filePath + "storage/uploads/images/no-image.png";
      }
      return this.$filePath + imagePath;
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    updateContractorDetailOnly(param) {
      this.contractorArr = param;
    },
    createContractorOnly() {
      const _this = this;
      if (!_this.$refs.createContractorOnly.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      const formData = {
        title: _this.contractorArr.salutation,
        display_name: _this.contractorArr.display_name,
        company_name: _this.contractorArr.company_name,
        profile_logo: _this.contractorArr.profile_logo,
        first_name: _this.contractorArr.first_name,
        last_name: _this.contractorArr.last_name,
        group_id: _this.contractorArr.group_id,
        primary: +_this.contractorArr.primary,
        details: _this.contractorArr.details,
      };

      _this.$store
        .dispatch(POST, { url: "contractor-only", data: formData })
        .then(() => {
          _this.searchContractor();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    this.init();
    this.searchContractor();
    if (this.$refs["search-contractor"]) {
      this.$refs["search-contractor"].focus();
    }
  },
  computed: {
    isSearching() {
      return this.contractor && this.contractor.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Contractor...";
    },
    getDefaultImage() {
      return this.$filePath + "storage/uploads/images/no-image.png";
    },
  },
};
</script>
