import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { GET, PATCH } from "@/core/services/store/request.module";
import SignaturePad from "signature_pad";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      //quotationTab: "tab-other",
      quotation: 0,
      invoiceId: 0,
      customerId: 0,
      customerPersonId: 0,
      customerPropertyId: 0,
      customerPropertyDialog: false,
      upload_case_trust_dialog: false,
      salesSignatureDialog: false,
      particuleFieldLoading: false,
      signatureLoading: false,
      createdDatePicker: false,
      sentDatePicker: false,
      openTillPicker: false,
      pageLoading: true,
      deleteValid: false,
      deleteLoading: false,
      deleteDialog: false,
      deleteLineItemDialog: false,
      lineItemDialog: false,
      has_case_trust: false,
      sales_signature: null,
      deleteDetail: {},
      detail: {},
      customer: {},
      property: {},
      billing: {},
      prospect: {},
      quote_package: null,
      contact_person: {},
      new_contact_person: {},
      line_items: {},
      moreActions: [],
      services: [],
      packages: [],
      package_quotation: [],
      variation_orders: [],
      transactionType: "invoice",
      updateDetailDialog: {
        quotation_title: false,
        rating: false,
        reference: false,
        sales: false,
        prepared_by: false,
        date: false,
        sent_date: false,
        open_till: false,
      },
      updateDetail: {
        quotation_title: null,
        rating: null,
        reference: null,
        sales: null,
        prepared_by: null,
        date: null,
        sent_date: null,
        open_till: null,
      },
    };
  },
  watch: {
    deleteLineItemDialog(param) {
      if (!param) {
        this.deleteDetail = new Object();
      }
    },
    $route(to, from) {
      if (
        (from.name === "admin.quotation.detail" ||
          from.name === "engineer.quotation.detail") &&
        (to.name === "admin.quotation.detail" ||
          to.name === "engineer.quotation.detail") &&
        this.lodash.isEqual(to.params, from.params) === false
      ) {
        this.pageLoading = true;
        this.quotation = this.lodash.toSafeInteger(to.params.id);
        this.loadContent();
      }
    },
  },
  methods: {
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.editQuotation();
          break;
        case "upload_case_trust":
          _this.upload_case_trust_dialog = true;
          break;
        case "quotation":
          _this.generateQuotation();
          break;
        case "convert_to_invoice":
          _this.convertToInvoice();
          break;
        case "convert_to_project":
          _this.convertToProject();
          break;
        case "customer_approval":
          _this.redirectToApproval();
          break;
        case "duplicate":
          _this.duplicateQuotation();
          break;
        case "revise":
          _this.reviseQuotation();
          break;
        case "variation":
          _this.variationQuotation();
          break;
        case "send_as_email":
          _this.mail_dialog = true;
          // _this.initSignature();
          break;
        case "signature_link":
          _this.initSignatureLink();
          break;
        case "mark_as_closed":
          _this
            .patchQuotation({ status: 7 })
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "mark_as_cancelled":
          _this.action_dialog = true;
          _this.action_id = _this.quotation;
          _this.action_type = "Cancel";
          _this.action_barcode = _this.detail.barcode;
          break;
        case "regenerate_pdf":
          _this.action_dialog = true;
          _this.action_id = _this.quotation;
          _this.action_type = "Regenerate PDF";
          _this.action_barcode = _this.detail.barcode;
          break;
        case "mark_as_accepted":
          _this.accept_type = "accept";
          _this.accept_dialog = true;
          break;
        case "mark_as_approved":
          _this.accept_type = "approve";
          _this.accept_dialog = true;
          break;
        case "mark_as_rejected":
          _this.reject_dialog = true;
          break;
        case "mark_as_rejected---":
          _this.action_dialog = true;
          _this.action_id = _this.quotation;
          _this.action_type = "Reject";
          _this.action_barcode = _this.detail.barcode;
          break;
        case "mark_as_pending":
          _this
            .patchQuotation({ status: 4 })
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "download_pdf":
          this.download_pdf();
          break;
        case "download_case_trust_pdf":
          this.download_case_trust();
          break;
        case "download_signed_case_trust_pdf":
          this.download_signed_case_trust();
          break;
        case "print":
          this.print_pdf();
          break;
      }
    },
    do_quote_action() {
      let request = new Object();
      if (this.action_type == "Reject") {
        request.status = 3;
      } else if (this.action_type == "Cancel") {
        request.status = 6;
      } else if (this.action_type == "Regenerate PDF") {
        request.regenerate_pdf = 1;
      } else {
        return false;
      }
      this.action_loading = true;
      this.patchQuotation(request)
        .then(() => {
          this.action_dialog = false;
          this.action_id = null;
          this.action_type = null;
          this.action_barcode = null;
          this.loadContent();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.action_loading = false;
        });
    },
    editQuotation() {
      let route = this.getDefaultRoute("pre.quotation.update", {
        params: {
          id: ObjectPath.get(this.detail, "id", 0),
        },
        query: {
          prospect: ObjectPath.get(this.detail, "prospect", 0),
        },
      });
      this.$router.push(route);
    },
    download_pdf() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}pre-quotation/${this.detail.id}/download-pdf?token=${token}`;
      window.open(url, "_blank");
    },
    download_case_trust() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${this.detail.id}/case-trust-pdf?token=${token}`;
      window.open(url, "_blank");
    },
    download_signed_case_trust() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${this.detail.id}/signed-case-trust-pdf?token=${token}`;
      window.open(url, "_blank");
    },
    print_pdf() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${this.detail.id}/print?token=${token}`;
      window.open(url, "_blank");
    },
    generateQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            package: ObjectPath.get(this.detail, "id", 0),
            customer: ObjectPath.get(this.detail, "customer", 0),
            contact_person: ObjectPath.get(this.detail, "contact_person", 0),
            new_contact_person: ObjectPath.get(
              this.detail,
              "new_contact_person",
              0
            ),
            billing: ObjectPath.get(this.detail, "billing", 0),
            property: ObjectPath.get(this.detail, "property", 0),
          },
        })
      );
    },
    variationQuotation() {
      const variation = ObjectPath.get(this.detail, "id", 0);
      const route = this.getDefaultRoute("quotation.create", {
        query: {
          variation,
          "is-variation": 1,
          customer: ObjectPath.get(this.detail, "customer", 0),
          contact_person: ObjectPath.get(this.detail, "contact_person", 0),
          new_contact_person: ObjectPath.get(
            this.detail,
            "new_contact_person",
            0
          ),
          billing: ObjectPath.get(this.detail, "billing", 0),
          property: ObjectPath.get(this.detail, "property", 0),
        },
      });
      this.$router.push(route);
    },
    mark_as_accepted() {
      this.patchQuotation({ status: 2 })
        .then(() => {
          this.loadContent();
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    redirectToApproval() {
      const { customer_link } = this.detail;
      if (customer_link) {
        window.open(customer_link, "_blank").focus();
      }
    },
    convertToInvoice() {
      const _this = this;
      if (_this.getPermission("invoice:create")) {
        _this.transactionType = "invoice";
        _this.customerPropertyDialog = true;
      }
    },
    convertToProject() {
      const _this = this;
      if (_this.getPermission("project:create")) {
        _this.$router.push(
          _this.getDefaultRoute("project.create", {
            query: {
              quotation: _this.detail.id,
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
            },
          })
        );
      }
    },
    resetAll() {
      this.customerId = 0;
      this.customerPersonId = 0;
      this.customerPropertyId = 0;
      this.customerPropertyDialog = false;
    },
    selectCustomerProperty(param) {
      this.customerPropertyId = param;
      this.checkTransaction();
    },
    checkTransaction() {
      const _this = this;
      if (
        _this.customerId > 0 &&
        _this.customerPersonId > 0 &&
        _this.customerPropertyId > 0
      ) {
        if (_this.transactionType == "invoice") {
          _this.$router.push(
            _this.getDefaultRoute("invoice.create", {
              query: {
                quotation: _this.detail.id,
                customer: _this.customerId,
                contact_person: _this.customerPersonId,
                property: _this.customerPropertyId,
              },
            })
          );
        }
      }
    },
    submitSalesSignature() {
      const _this = this;
      try {
        if (_this.sales_signature.isEmpty()) {
          return false;
        }

        _this.signatureLoading = true;

        _this
          .patchQuotation({
            status: 5,
            signature: _this.sales_signature.toDataURL(),
          })
          .then(() => {
            _this.loadContent();
            _this.salesSignatureDialog = false;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.signatureLoading = false;
          });
      } catch (error) {
        _this.logError(error);
        _this.signatureLoading = false;
      }
    },
    initSignature() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["sales_signature"];
          let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = cparentDiv.offsetWidth;
          ccanvas.setAttribute("width", cparentWidth);
          _this.sales_signature = new SignaturePad(ccanvas);
        });
      }, 500);
    },
    initSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: `pre-quotation/${this.quotation}/signature-link`,
        })
        .then((response) => {
          const url = ObjectPath.get(response, "data.signature_link", null);
          if (url) {
            window.open(url, "_blank");
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    duplicateQuotation() {
      let route = this.getDefaultRoute("pre.quotation.create", {
        query: {
          "is-duplicate": 1,
          prospect: ObjectPath.get(this.detail, "prospect", 0),
          duplicate: ObjectPath.get(this.detail, "id", 0),
        },
      });
      this.$router.push(route);
    },
    routeToProject() {
      this.$router.push(
        this.getDefaultRoute("project.detail", {
          params: {
            id: ObjectPath.get(this.project, "id", 0),
          },
          query: {
            tab: "project",
          },
        })
      );
    },
    routeToDetail(row) {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: {
            id: ObjectPath.get(row, "id", 0),
          },
          query: {
            tab: "other",
          },
        })
      );
    },
    reviseQuotation() {
      const _this = this;
      if (_this.getPermission("quotation:update")) {
        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: {
              revise: _this.detail.id,
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
              contact_person: _this.lodash.toSafeInteger(
                _this.detail.contact_person.contact_person
              ),
              billing: _this.lodash.toSafeInteger(_this.detail.billing.billing),
            },
          })
        );
      }
    },
    patchQuotation(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(PATCH, { url: "pre-quotation-particular/" + _this.detail.id, data })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateField(type) {
      const _this = this;
      if (_this.canUpdateQuotation) {
        _this.onEscParticularField();
        _this.updateDetailDialog[type] = true;
        _this.updateDetail[type] = _this.detail[type];
        setTimeout(function () {
          if (_this.$refs[type]) {
            _this.$refs[type].focus();
          }
        }, 50);
      }
    },
    onEscParticularField() {
      this.updateDetailDialog = {
        quotation_title: false,
        rating: false,
        reference: false,
        sales: false,
        prepared_by: false,
        date: false,
        sent_date: false,
        open_till: false,
      };
      this.updateDetail = {
        quotation_title: null,
        rating: null,
        reference: null,
        sales: null,
        prepared_by: null,
        date: null,
        sent_date: null,
        open_till: null,
      };
    },
    updateParticularField() {
      const _this = this;
      _this.particuleFieldLoading = true;
      _this.createdDatePicker = false;
      _this.sentDatePicker = false;
      _this.openTillPicker = false;
      _this
        .patchQuotation(_this.updateDetail)
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.particuleFieldLoading = false;
          _this.onEscParticularField();
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getQuotation()
        .then((response) => {
          _this.moreActions = ObjectPath.get(
            response,
            "quotation.more_actions",
            []
          );
          _this.detail = ObjectPath.get(response, "quotation", {});
          _this.customerId = ObjectPath.get(response, "quotation.customer", 0);
          _this.customerPersonId = ObjectPath.get(
            response,
            "quotation.contact_person",
            0
          );

          _this.has_case_trust = ObjectPath.get(
            response,
            "has_case_trust",
            false
          );

          _this.project = ObjectPath.get(response, "project", {});
          _this.case_trust = ObjectPath.get(response, "case_trust", {});
          _this.quote_package = ObjectPath.get(response, "quote_package");

          _this.prospect = ObjectPath.get(response, "prospect", {});
          _this.customer = ObjectPath.get(response, "customer", {});
          _this.property = ObjectPath.get(response, "property", {});
          _this.billing = ObjectPath.get(response, "billing", {});
          _this.contact_person = ObjectPath.get(response, "contact_person", {});
          _this.new_contact_person = ObjectPath.get(
            response,
            "new_contact_person",
            {}
          );

          _this.attachments = ObjectPath.get(response, "attachments", []);
          _this.services = ObjectPath.get(response, "services", []);
          _this.packages = ObjectPath.get(response, "packages", []);
          _this.package_quotation = ObjectPath.get(
            response,
            "package_quotation",
            []
          );
          _this.variation_orders = ObjectPath.get(
            response,
            "variation_orders",
            []
          );

          _this.detail.line_items_count =
            _this.services.length + _this.packages.length;

          if (_this.isPackage) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Package Sales", route: "package.sales" },
              { title: "Detail" },
              { title: ObjectPath.get(response, "quotation.barcode", null) },
            ]);
          } else {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Quotation", route: "quotation" },
              { title: "Detail" },
              { title: ObjectPath.get(response, "quotation.barcode", null) },
            ]);
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.lineItemDialog = false;
          _this.pageLoading = false;
          _this.onEscParticularField();
          // _this.get_related_pdf();
        });
    },
    getQuotation() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: `pre-quotation/${_this.quotation}` })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getFormattedDate(date) {
      return this.formatDate(date);
    },
  },
  mounted() {
    const _this = this;
    // _this.get_related_pdf();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Quotation",
        route: "quotation",
      },
      {
        title: "Detail",
      },
    ]);
  },
  created() {
    const _this = this;
    _this.quotation = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.quotation || _this.quotation <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.quotationTab = _this.$route.query.tab;
    }
    _this.loadContent();
  },
  computed: {
    canUpdateQuotation() {
      if (
        this.detail &&
        (this.lodash.isEmpty(this.detail.revisions) === false ||
          (this.detail.status != 1 && this.detail.status != 4))
      ) {
        return false;
      }
      return this.getPermission("quotation:update");
    },
    quotationTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.quotationTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
    validSignature() {
      return this.sales_signature ? this.sales_signature.isEmpty() : false;
    },
  },
};
