<template>
  <Dialog :commonDialog.sync="consentDialog">
    <template v-slot:title> Consent Form </template>
    <template v-slot:body>
      <v-container fluid>
        <v-sheet class="agreement">
          <div style="line-height: 26px; letter-spacing: 0.8px">
            <p>
              I, {{ attributes.ownerName }} (Name),
              {{ attributes.ownerNRIC }} (NRIC), owner of
              {{ attributes.ownerAddress }} (Address), hereby agree to allow
              {{ companyName }} to use any photos taken of me with
              {{ companyName }} services process for advertising &amp; promotion
              purposes in their marketing materials.
            </p>
            <template v-if="ownerSignature">
              <v-img
                contain
                position="left center"
                class="my-4"
                max-height="100"
                :src="ownerSignature"
              ></v-img>
              <p>(Signature)</p>
            </template>
            <div v-else v-on:click="signaturecPopup('owner')" class="link">
              <p>SIGNED by the abovementioned</p>
              <v-img
                v-if="signaturec.owner"
                contain
                position="left center"
                class="my-4"
                max-height="100"
                :src="signaturec.owner"
              ></v-img>
              <p
                class="sign-top t-bold mb-4"
                :class="{ 'margin-top-100px': !signaturec.owner }"
              >
                OWNER
                <small class="ml-4"
                  ><em class="font-size-14">(click here to sign)</em></small
                >
              </p>
            </div>
            <p>Name: {{ attributes.ownerName }}</p>
            <p>NRIC: {{ attributes.ownerNRIC }}</p>
            <p>Date: {{ formatDate(date) }}</p>
          </div>
        </v-sheet>
        <Dialog v-if="consentDialog" :commonDialog.sync="signaturecDialog">
          <template v-slot:title> Signature </template>
          <template v-slot:body>
            <v-row>
              <v-col md="10" class="py-0" offset-md="1">
                <div
                  id="signaturec-pad"
                  ref="signaturec_div"
                  class="custom-border-grey-dashed"
                >
                  <canvas ref="signaturec"></canvas>
                </div>
              </v-col>
              <v-col md="10" class="py-0" offset-md="1">
                <p class="float-right m-0">
                  <v-btn
                    content="Click here to clear signaturec"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-right"
                    v-on:click="signaturecRef.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-slot:action>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="cyan"
              v-on:click="signaturecSave()"
            >
              Submit
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="signaturecClose()"
            >
              Cancel
            </v-btn>
          </template>
        </Dialog>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="submitConsent()"
      >
        Submit
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="closeDialog()"
      >
        Cancel
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      signaturecRef: null,
      signaturec: {
        owner: null,
        company: null,
      },
      signaturecDialog: false,
      signaturecType: null,
      date: new Date(),
    };
  },
  props: {
    consentDialog: {
      type: Boolean,
      default: false,
    },
    ownerSignature: {
      type: String,
      default: null,
    },
    companyName: {
      type: String,
      default: null,
    },
    companyAddress: {
      type: String,
      default: null,
    },
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    consentDialog(param) {
      if (param) {
        this.initSign();
      }
    },
    ownerSignature() {
      this.init();
    },
  },
  methods: {
    init() {
      this.signaturec.owner = this.ownerSignature || null;
    },
    submitConsent() {
      this.$emit("submit", this.signaturec.owner);
      this.closeDialog();
    },
    closeDialog() {
      this.signaturecClose();
      this.$emit("close", true);
    },
    initSign() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signaturec"];
          let cparentDiv = document.getElementById("signaturec-pad");
          if (cparentDiv) {
            let cparentWidth = cparentDiv.offsetWidth;
            ccanvas.setAttribute("width", cparentWidth);
            _this.signaturecRef = new SignaturePad(ccanvas);
          }
        });
      }, 500);
    },
    signaturecSave() {
      if (this.signaturecRef.isEmpty()) {
        return false;
      }
      this.signaturec[this.signaturecType] = this.signaturecRef.toDataURL();
      this.signaturecClose();
    },
    signaturecPopup(type) {
      this.signaturecDialog = true;
      this.signaturecType = type;
      this.initSign();
    },
    signaturecClose() {
      this.signaturecDialog = false;
      this.signaturecType = null;
      if (this.signaturecRef) {
        this.signaturecRef.clear();
      }
      this.signaturecRef = null;
    },
  },
  mounted() {
    this.init();
  },
  components: {
    Dialog,
  },
};
</script>
