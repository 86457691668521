<template>
  <div class="card card-custom bg-radial-gradient-danger gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-white">Project Revenue</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Chart-->
      <apexchart
        ref="apexchart"
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="bar"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer bg-white card-rounded flex-grow-1">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col px-8 py-6 mr-8">
            <div class="font-size-sm text-muted font-weight-bold">
              Total Costing
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ formatMoney(counts.sum_total) }}
            </div>
          </div>
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-bold">
              Total In-Progress
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ formatMoney(counts.sum_in_progress) }}
            </div>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col px-8 py-6 mr-8">
            <div class="font-size-sm text-muted font-weight-bold">
              Total On Hold
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ formatMoney(counts.sum_on_hold) }}
            </div>
          </div>
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-bold">
              Total Finished
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ formatMoney(counts.sum_finished) }}
            </div>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "widget-10",
  mixins: [CommonMixin],
  data() {
    return {
      chartOptions: {},
      series: [],
    };
  },
  props: {
    counts: {
      type: Object,
      default() {
        return new Object();
      },
    },
    chart: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.chartOptions.xaxis.categories = this.lodash.map(
          this.chart.categories,
          (row) => row
        );
        this.series = this.chart.series;
        this.$refs["apexchart"].refresh();
      });
    },
  },
  watch: {
    chart: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    // reference; kt_mixed_widget_1_chart

    const _this = this;

    _this.chartOptions = {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded",
          barHeight: "20%",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: _this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: _this.layoutConfig("font-family"),
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: _this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: _this.layoutConfig("font-family"),
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: _this.layoutConfig("font-family"),
        },
        y: {
          formatter: function (val) {
            return _this.formatMoney(val);
          },
        },
      },
      colors: ["#fc8692", "#ffffff"],
      grid: {
        borderColor: _this.layoutConfig("colors.gray.gray-200"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };
  },
};
</script>
