import { POST, QUERY } from "@/core/services/store/request.module";
import LocalService from "@/core/services/local.service";
import { SearchEventBus } from "@/core/lib/search.lib";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import { saveAs } from "file-saver";
import ObjectPath from "object-path";

/*const validQueryParams = [
  "is-package",
  "status",
  "sort_column",
  "sort_order",
  "limit",
  "offset",
];*/

const defaultObjSearch = {
  dates: [],
  prepared_by: [],
  co_designer: [],
  phone_number: null,
};

export default {
  data() {
    return {
      userList: [],
      sourceList: [],
      adwtitleList: [],
      adwCategoryList: [],
      searchParam: null,
      dates: [],
      phone_number: null,
      timeout: null,
      listingSearch: {},
      objSearch: this.lodash.cloneDeep(defaultObjSearch),
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      searchEnabled: false,
      topExtraList: [],
      rowData: [],
      defaultColShow: [],
      defaultColDefs: [],
      queryParams: {},
      sortData: {},
      currentPage: 1,
      totalPages: 0,
      totalSelected: 0,
      paginationPageSize: 0,
      selectedRows: [],
      searchDialog: false,
      pageModule: null,
      routeAPI: null,
      routeName: null,
      routeDetailName: null,
      searchableArray: [],
    };
  },
  watch: {
    selectedRows() {
      this.rowSelected();
    },
    status(params) {
      this.currentPage = 1;
      if (params) {
        this.lodash.assign(this.queryParams, {
          status: params,
        });
        this.pushQueryParams(52);
      }
    },
    defaultColShow() {
      this.updateVisible();
    },
    defaultColDefs() {
      this.updateColState();
    },
    sortData(params) {
      this.lodash.assign(this.queryParams, params);
      this.pushQueryParams(63);
    },
    $route() {
      this.getRows();
    },
  },
  methods: {
    exportCurrentView(type) {
      const _this = this;
      let defaultColDefs = _this.lodash.cloneDeep(_this.defaultColDefs);
      let columns = [];
      for (let i = 0; i < defaultColDefs.length; i++) {
        if (defaultColDefs[i].visible && defaultColDefs[i].field != "id") {
          columns.push({
            field: defaultColDefs[i].field,
            header: defaultColDefs[i].headerName,
          });
        }
      }
      let timeStamp = new Date();
      let requestParams = new Object({ columns });
      _this.lodash.assign(requestParams, _this.$route.query);
      if (!requestParams.status) {
        requestParams.status = "all";
      }
      _this.$store
        .dispatch(POST, {
          url: "export-current-view/" + type,
          data: requestParams,
        })
        .then((response) => {
          let blob = new Blob([response], {
            type: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf8",
          });
          saveAs(
            blob,
            type + "-export-current-view-" + timeStamp.getTime() + ".xls"
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getFilterLabel(field, page_module) {
      let child = [];
      let levelOne = field.split(".");
      let parent = levelOne[0].split("_").join(" ");
      if (levelOne[1]) {
        child.push(levelOne[1].split("_").join(" "));
      }
      if (child.length) {
        let childText = child.join(" ");
        return parent + "[" + childText + "]";
      }

      let output = this.lodash
        .replace(parent, "text", "name")
        .replaceAll("[barcode]", " #")
        .replaceAll("barcode", `${page_module} #`);

      if (this.isPackage) {
        return output
          .replaceAll("Quotation", "Package")
          .replaceAll("quotation", "package");
      }

      if (this.routeAPI == "service") {
        return output
          .replaceAll("Charges", "Price")
          .replaceAll("charges", "Price")
          .replaceAll("Company Cost", "Supplier Cost")
          .replaceAll("company cost", "Supplier Cost");
      }

      return output;
    },
    convertArrayToUrl(array) {
      const urlParams = [];

      array.forEach((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(item[key]);
            urlParams.push(`${encodedKey}=${encodedValue}`);
          }
        }
      });

      return urlParams.join("&");
    },
    filterRows() {
      for (const key in this.objSearch) {
        if (key == "prepared_by") {
          const objSearchArr = [];
          for (let i = 0; i < this.objSearch[key].length; i++) {
            objSearchArr.push({
              prepared_by: this.objSearch[key][i].display_name,
              prepared_by_id: this.objSearch[key][i].id,
            });
          }
          this.listingSearch["prepared_by"] = objSearchArr.map(
            (item) => item["prepared_by"]
          );
          this.listingSearch["prepared_by_id"] = objSearchArr.map(
            (item) => item["prepared_by_id"]
          );
        }
        if (key == "co_designer") {
          const objSearchArr = [];
          for (let i = 0; i < this.objSearch[key].length; i++) {
            objSearchArr.push({
              co_designer: this.objSearch[key][i].display_name,
              co_designer_id: this.objSearch[key][i].id,
            });
          }
          this.listingSearch["co_designer"] = objSearchArr.map(
            (item) => item["co_designer"]
          );
          this.listingSearch["co_designer_id"] = objSearchArr.map(
            (item) => item["co_designer_id"]
          );
        }
      }

      this.listingSearch = this.lodash.pickBy(
        this.listingSearch,
        this.lodash.identity
      );

      this.currentPage = 1;

      if (this.lodash.isEmpty(this.listingSearch) === false) {
        this.removeUnnecessaryQueryParam();
        this.lodash.assign(this.queryParams, this.listingSearch);
        this.pushQueryParams(128);
        this.searchParam = null;
        this.searchDialog = false;
        this.searchEnabled = true;
      }
    },
    clearSearch() {
      this.searchParam = null;
      this.searchDialog = false;
      this.searchEnabled = false;
      this.listingSearch = new Object();
      this.dates = [];
      this.phone_number = null;
      this.objSearch = this.lodash.cloneDeep(defaultObjSearch);
      this.$router
        .replace(
          this.getDefaultRoute(this.routeName, {
            query: { status: "all", t: Number(new Date()) },
          })
        )
        .then(() => {
          this.getRows(202);
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    pushQueryParams() {
      if (!this.listingSearch["dates"]) {
        this.$route.query["dates"] = undefined;
      }
      if (!this.listingSearch["phone_number"]) {
        this.$route.query["phone_number"] = null;
      }
      const queryParam = { ...this.$route.query, ...this.listingSearch };
      this.$router
        .replace(
          this.getDefaultRoute(this.routeName, {
            query: {
              ...queryParam,
              status: this.status,
              t: Number(new Date()),
            },
          })
        )
        .then(() => {
          this.getRows(211);
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    getRows() {
      clearTimeout(this.timeout);
      this.dataLoading = true;
      this.timeout = setTimeout(() => {
        this.getFilteredRows();
      }, 500);
    },
    getFilteredRows() {
      const _this = this;

      let requestParams = {
        current_page: _this.currentPage || 1,
        per_page: _this.paginationPageSize,
      };

      _this.lodash.assign(requestParams, _this.$route.query);

      if (!requestParams.status) {
        requestParams.status = "all";
      } else {
        _this.status = requestParams.status;
      }

      _this.dataLoading = true;
      _this.totalPages = 0;
      _this.currentPage = 0;
      _this.rowData = new Array();
      _this.adwtitleList = new Array();
      _this.adwCategoryList = new Array();
      _this.totalRows = 0;
      _this.rowsOffset = 0;
      _this.$store
        .dispatch(QUERY, { url: this.routeAPI, data: requestParams })
        .then(({ data }) => {
          if (_this.pageModule === "product-listing") {
            _this.topExtraList = [
              {
                title: "Low Stock",
                status_key: "low_stock",
                status_value: 1,
                value: data.low_stock,
              },
            ];
          }
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.rowData = data.rows;
          _this.userList = ObjectPath.get(data, "users", []);
          _this.sourceList = ObjectPath.get(data, "sources", []);
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
          _this.statusList = data.status_list;
          _this.adwtitleList = data.adwtitle_list;
          _this.adwCategoryList = data.category_list;
          _this.searchableArray = data.searchable;
          if (
            _this.searchableArray &&
            _this.lodash.isArray(_this.searchableArray)
          ) {
            _this.searchableArray.push("dates");
          }
          if (
            _this.searchableArray &&
            _this.lodash.isArray(_this.searchableArray)
          ) {
            _this.searchableArray.push("phone_number");
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          if (!_this.$route.query.search) {
            _this.isSearchEnabled();
          }
          _this.selectedRows = [];
          _this.dataLoading = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    },
    rowSelected() {
      this.totalSelected = this.selectedRows.length;
    },
    updateVisible() {
      let columnState = LocalService.getData(`${this.pageModule}-column-state`);
      if (columnState && columnState.length > 0) {
        for (let i = columnState.length - 1; i >= 0; i--) {
          let index = this.defaultColShow.indexOf(columnState[i].field);
          if (Number.isInteger(index) && this.defaultColShow[index]) {
            columnState[i].visible = true;
          } else {
            columnState[i].visible = false;
          }
          if (i === 0) {
            this.defaultColDefs = columnState;
            LocalService.saveData(
              `${this.pageModule}-column-state`,
              columnState
            );
            LocalService.saveData(
              `${this.pageModule}-column-visible`,
              this.defaultColShow
            );
          }
        }
      } else {
        this.updateColState();
      }
    },
    updateSorting(sortObject) {
      let columnState = LocalService.getData(`${this.pageModule}-column-state`);
      if (columnState && columnState.length > 0) {
        for (let i = columnState.length - 1; i >= 0; i--) {
          if (
            columnState[i] &&
            columnState[i].field &&
            columnState[i].field == sortObject.field
          ) {
            if (columnState[i].sort === "asc") {
              columnState[i].sort = "desc";
            } else if (columnState[i].sort === "desc") {
              columnState[i].sort = null;
            } else {
              columnState[i].sort = "asc";
            }
            this.updateMoreActions(sortObject, columnState[i].sort);
          } else {
            columnState[i].sort = null;
          }
          if (i === 0) {
            LocalService.saveData(
              `${this.pageModule}-column-state`,
              columnState
            );
            this.applyColState();
          }
        }
      } else {
        this.updateColState();
      }
    },
    applyColState() {
      let columnState = LocalService.getData(`${this.pageModule}-column-state`);
      if (columnState && columnState.length > 0) {
        this.defaultColDefs = columnState;
      }
      let columnVisible = LocalService.getData(
        `${this.pageModule}-column-visible`
      );
      if (columnVisible && columnVisible.length > 0) {
        this.defaultColShow = columnVisible;
      }
      let moreActionState = LocalService.getData(
        `${this.pageModule}-more-actions`
      );
      if (moreActionState && moreActionState.length > 0) {
        this.moreActions = moreActionState;
      }
      this.sortData = this.sortColumns(columnState);
    },
    updateColState() {
      LocalService.saveData(
        `${this.pageModule}-more-actions`,
        this.moreActions
      );
      LocalService.saveData(
        `${this.pageModule}-column-state`,
        this.defaultColDefs
      );
      LocalService.saveData(
        `${this.pageModule}-column-visible`,
        this.defaultColShow
      );
    },
    closeSearchDialog() {
      this.searchDialog = false;
    },
    submitSearchDialog(params) {
      this.lodash.assign(this.queryParams, params);
      this.pushQueryParams(312);
      this.closeSearchDialog();
    },
    clearSelections() {
      this.selectedRows = [];
    },
    rowClicked(params) {
      this.$router.push(
        this.getDefaultRoute(this.routeDetailName, {
          params: { id: params.id },
          query: { ...this.$route.query },
        })
      );
    },
    updateMoreActions(sortObject, sortType) {
      let moreActionState = LocalService.getData(
        `${this.pageModule}-more-actions`
      );
      if (moreActionState && moreActionState.length > 0) {
        for (let i = moreActionState.length - 1; i >= 0; i--) {
          if (
            moreActionState[i] &&
            moreActionState[i].action &&
            moreActionState[i].action == sortObject.field
          ) {
            moreActionState[i].sort = sortType;
          } else {
            moreActionState[i].sort = null;
          }
          if (i === 0) {
            this.moreActions = moreActionState;
            LocalService.saveData(
              `${this.pageModule}-more-actions`,
              this.moreActions
            );
          }
        }
      }
    },
    sortColumns(columnState) {
      let result = {
        sort_column: undefined,
        sort_order: undefined,
      };
      if (columnState && columnState.length) {
        for (let i = columnState.length - 1; i >= 0; i--) {
          if (columnState[i] && columnState[i].field && columnState[i].sort) {
            result["sort_column"] = columnState[i].field;
            result["sort_order"] = columnState[i].sort;
          }
        }
      }
      return result;
    },
    printRowData(col, row) {
      let result = row[col.field];
      const _this = this;
      if (col.cellRenderer && (result || result === false)) {
        switch (col.cellRenderer) {
          case "formatDate":
            result = _this.formatDate(result);
            break;
          case "formatDateTime":
            result = _this.formatDateTime(result);
            break;
          case "booleanToString":
            result = _this.booleanToString(result);
            break;
          case "toUpper":
            result = _this.lodash.toUpper(result);
            break;
          case "toLower":
            result = _this.lodash.toLower(result);
            break;
          case "customerInfo":
            result = `<div class="customer-info"><b>Name: </b>${row.display_name}<br><b>Company: </b>${row.company_name}<br><b>Email: </b>${row.primary_email}<br><b>Phone: </b>${row.primary_phone}<br></div>`;
            break;
          case "formattedCompanyInfo": {
            let tempArr = [];
            if (row.customer_display_name) {
              tempArr.push(row.customer_display_name);
            }
            if (row.default_person) {
              tempArr.push(row.default_person);
            }
            result = tempArr.join("<br>");
            break;
          }
          case "formattedPropertyInfo": {
            let tempArr = [];
            if (row.unit_no) {
              tempArr.push(row.unit_no);
            }
            if (row.street_1) {
              tempArr.push(row.street_1);
            }
            if (row.street_2) {
              tempArr.push(row.street_2);
            }
            if (row.zip_code) {
              tempArr.push(row.zip_code);
            }
            if (row.city) {
              tempArr.push(row.city);
            }
            if (row.state) {
              tempArr.push(row.state);
            }
            if (row.country) {
              tempArr.push(row.country);
            }
            result = tempArr.join(", ");
            break;
          }
          case "formattedDateTime":
            if (col.child && row[col.field]) {
              result = `<span title="${_this.formatDateTime(result)}">${row[col.child]
                }</span>`;
            }
            break;
          case "getCellVariant": {
            let index = _this.lodash.findIndex(result, function (attr) {
              return attr.variant.toString() === col.variant.toString();
            });
            if (
              Number.isInteger(index) &&
              parseInt(index) >= 0 &&
              _this.lodash.isEmpty(result[index]) === false
            ) {
              result = result[index].value;
            } else {
              result = null;
            }
            break;
          }
        }
      }
      return result;
    },
    removeUnnecessaryQueryParam() {
      /*const _this = this;
      for (let key in _this.queryParams) {
        if (!_this.lodash.includes(validQueryParams, key)) {
          delete _this.queryParams[key];
        }
      }*/
    },
    isSearchEnabled() {
      const _this = this;
      _this.searchEnabled = false;
      if (_this.lodash.isEmpty(_this.$route.query) === false) {
        for (let key in _this.$route.query) {
          if (
            _this.lodash.includes(_this.searchableArray, key) &&
            _this.$route.query[key]
          ) {
            _this.searchEnabled = true;
            _this.listingSearch[key] = _this.$route.query[key];
          }

          if (key == "prepared_by") {
            let prepared_by = _this.$route.query[key];
            if (!Array.isArray(prepared_by) && typeof prepared_by == "string") {
              prepared_by = [prepared_by];
            }
            if (Array.isArray(prepared_by)) {
              _this.objSearch.prepared_by = [];
              for (let i = 0; i < prepared_by.length; i++) {
                _this.objSearch.prepared_by.push({
                  display_name: prepared_by[i],
                  id: this.lodash.toSafeInteger(
                    _this.$route.query["prepared_by_id"][i]
                  ),
                });
              }
              if (_this.objSearch.prepared_by.length) {
                _this.searchEnabled = true;
              }
            }
          }

          if (key == "co_designer") {
            let co_designer = _this.$route.query[key];
            if (!Array.isArray(co_designer) && typeof co_designer == "string") {
              co_designer = [co_designer];
            }
            if (Array.isArray(co_designer)) {
              _this.objSearch.co_designer = [];
              for (let i = 0; i < co_designer.length; i++) {
                _this.objSearch.co_designer.push({
                  display_name: co_designer[i],
                  id: this.lodash.toSafeInteger(
                    _this.$route.query["co_designer_id"][i]
                  ),
                });
              }
              if (_this.objSearch.co_designer.length) {
                _this.searchEnabled = true;
              }
            }
          }
        }
      }

      if (
        !_this.lodash.isEmpty(_this.listingSearch["dates"]) &&
        _this.lodash.isArray(_this.listingSearch["dates"])
      ) {
        _this.dates = _this.listingSearch["dates"];
      }
      if (
        !_this.lodash.isEmpty(_this.listingSearch["phone_number"]) &&
        _this.lodash.isArray(_this.listingSearch["phone_number"])
      ) {
        _this.phone_number = _this.listingSearch["phone_number"];
      }
    },
  },
  mounted() {
    const _this = this;
    if (_this.lodash.isEmpty(_this.$route.query)) {
      _this.lodash.assign(_this.queryParams, { status: "all" });
      _this.pushQueryParams(579);
    }

    _this.isSearchEnabled();

    SearchEventBus.$on("clear-search", () => {
      _this.clearSearch();
    });

    SearchEventBus.$on("advanced-search", () => {
      _this.searchDialog = true;
    });

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getRows();
    });

    SearchEventBus.$on("start-search", (argument) => {
      _this.currentPage = null;
      _this.searchEnabled = false;
      _this.listingSearch = new Object();
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      _this.removeUnnecessaryQueryParam();
      _this.lodash.assign(_this.queryParams, {
        search: argument,
      });
      _this.lodash.assign(_this.listingSearch, {
        search: argument,
      });
      _this.pushQueryParams(608);
    });

    SearchEventBus.$emit("search-template", true);

    let routeName = _this.routeName;
    if (routeName == "pre.quotation") {
      routeName = "quotation";
    }
    SearchEventBus.$emit("search-module-title", routeName);
  },
  beforeDestroy() {
    this.searchEnabled = false;
    SearchEventBus.$emit("search-template", false);
    SearchEventBus.$off("clear-search");
    SearchEventBus.$off("start-search");
    SearchEventBus.$off("advanced-search");
    PaginationEventBus.$off("update:pagination");
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
};
