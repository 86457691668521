<template>
  <div class="topbar-item">
    <v-btn
      :id="pageTipId + '_toggle'"
      class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
      :disabled="pageLoader"
    >
      <v-icon dark left>mdi-lightbulb-on-outline</v-icon>Page Tips
    </v-btn>

    <div
      :id="pageTipId"
      :ref="pageTipId"
      class="offcanvas kt_page_tips offcanvas-right p-10"
      :class="{ 'd-none': !isMounted }"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-500 m-0">
          <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
            <!--begin::Svg Icon-->
            <inline-svg :src="$assetURL('media/custom-svg/page-tips.svg')" />
            <!--end::Svg Icon-->
          </span>
          Page Tips
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          :id="pageTipId + '_close'"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: static"
      >
        <div class="separator separator-dashed mt-2 mb-4"></div>
        <v-row dense class="px-0">
          <v-col md="12" class="px-0">
            <v-text-field
              ref="search"
              v-model.trim="searchModel"
              :background-color="!isFocused ? 'grey lighten-3' : undefined"
              :flat="!isFocused && !isSearching"
              placeholder="Start Typing then press enter"
              autocomplete="off"
              class="mx-2 mx-md-4 rounded-lg"
              dense
              hide-details
              solo
              @blur="resetSearch"
              @focus="isFocused = true"
              @keydown.esc="onEsc"
              @keydown.enter="onEnter"
              @keydown.tab="onTab"
            >
              <template #prepend-inner>
                <v-icon :color="!isFocused ? 'grey' : undefined" class="mr-1">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="12">
            <ul class="py-4 page-tips-ul color-custom-blue">
              <template v-if="pageLoader">
                <li
                  style="list-style: none"
                  v-for="d in 5"
                  :key="d"
                  class="font-weight-500 font-size-14"
                >
                  <v-skeleton-loader class="custom-skeleton" type="text">
                  </v-skeleton-loader>
                </li>
              </template>
              <template v-else>
                <template v-if="pageTips.length">
                  <template v-for="(row, index) in pageTips">
                    <v-hover v-slot="{ hover }" :key="index">
                      <li
                        :class="{ 'page-tips-li-hover': hover }"
                        class="py-1 mx-2 font-weight-500 font-size-14"
                      >
                        <a :href="row.link" target="_blank">{{ row.title }}</a>
                      </li>
                    </v-hover>
                  </template>
                </template>
                <template v-else>
                  <p class="m-0 row-not-found font-weight-500 font-size-12">
                    <img
                      width="24"
                      :src="$assetURL('media/error/empty.png')"
                      class="mx-2 row-not-found-image"
                    />
                    Uhh... There are no tips at the moment.
                  </p>
                </template>
              </template>
            </ul>
          </v-col>
        </v-row>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.kt_page_tips {
  overflow: hidden;
}
</style>

<script>
import KTPageTipsPlugin from "@/assets/js/layout/extended/page-tips.js";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "KTPageTips",
  data: () => ({
    isFocused: false,
    pageLoader: true,
    isMounted: false,
    searchModel: null,
    pageTips: [],
  }),
  props: {
    module: {
      type: String,
      required: true,
    },
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      this.startSearch();
    },
    onTab() {
      this.startSearch();
    },
    startSearch() {
      const _this = this;
      _this.pageLoader = true;
      _this.$store
        .dispatch(QUERY, {
          url: "page-tips/" + _this.module,
          data: {
            search: _this.searchModel,
          },
        })
        .then(({ data }) => {
          _this.pageTips = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoader = false;
        });
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.searchModel = null;
        _this.isFocused = false;
      });
    },
  },
  beforeDestroy() {
    this.isMounted = false;
  },
  mounted() {
    const _this = this;
    _this.startSearch();
    KTPageTipsPlugin.init(_this.$refs[_this.pageTipId], _this.pageTipId);
    _this.isMounted = true;
  },
  computed: {
    pageTipId() {
      return `kt_${this.module}_page_tips`;
    },
    isSearching() {
      return this.searchModel && this.searchModel.length > 0;
    },
  },
};
</script>
