<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :hasChanges.sync="hasChanges"
    :customClass="'property-create create-form'"
    v-if="getPermission('property:create') || getPermission('property:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title text-truncate">
        Property Address For
        <span
          class="form-title-create-link"
          link
          v-on:click="
            resetAll();
            customerDialog = true;
          "
          >{{ customerObject.display_name }}</span
        >{{ getPropertyTitle }}
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        {{ getAction }} Property Address
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="propertyForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <v-row v-bar class="create-property-vbar white-background">
          <v-col cols="7">
            <PropertyAddress
              v-on:savePropertyAddress="updatePropertyAddress"
              v-on:updateMapMarker="updatePosition"
              :propertyData.sync="propertyArr"
              :options.sync="options"
              :defaultPosition.sync="mapCenter"
              raw
            ></PropertyAddress>
          </v-col>
          <v-col cols="5">
            <GmapMap ref="mapRef" :center="mapCenter" :zoom="11">
              <GmapMarker
                :position="mapCenter"
                :draggable="true"
                v-on:dragend="positionChanged"
              />
            </GmapMap>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:extra>
      <template v-if="customerDialog">
        <CustomerDialog
          :customerDialog.sync="customerDialog"
          v-on:closeDialog="customerDialog = false"
          v-on:resetAll="customerDialog = false"
          v-on:selectCustomer="selectCustomer"
        ></CustomerDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { QUERY, PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import PropertyAddress from "@/view/pages/customer/create-or-update/Property-Address.vue";
import PropertyMixin from "@/core/plugins/property-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";

export default {
  mixins: [CommonMixin, PropertyMixin],
  name: "property-create",
  title: "Create Property",
  data() {
    return {
      customer: 0,
      customerDialog: 0,
      property: 0,
      propertyArr: {},
      customerObject: {},
      propertyAddress: {},
      billingAddress: {},
      contactDetail: {},
      contactPerson: [],
      notification: {},
      options: {},
      tabs: [
        { title: "Contact Details", icon: "mdi-phone", key: "details" },
        {
          title: "Contact Persons",
          icon: "mdi-account-multiple",
          key: "persons",
        },
        {
          title: "Property Address",
          icon: "mdi-home-map-marker",
          key: "property",
        },
        {
          title: "Site Address",
          icon: "mdi-credit-card",
          key: "billing",
        },
        {
          title: "Notification & Remarks",
          icon: "mdi-bell-ring",
          key: "notification",
        },
      ],
      mapCenter: { lat: 1.330906, lng: 103.891671, custom: false },
      propertyTab: null,
      backForce: false,
    };
  },
  components: {
    PropertyAddress,
    CustomerDialog,
    CreateUpdateTemplate,
  },
  watch: {
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
    property(param) {
      if (param > 0) {
        this.getPropertyDetail();
      }
    },
  },
  methods: {
    selectCustomer(customer) {
      if (customer > 0) {
        this.hasChanges = false;
        this.$router
          .replace(
            this.getDefaultRoute("property.create", {
              query: { customer },
            })
          )
          .then(() => {
            window.location.reload(true);
          });
      }
    },
    updatePosition(param) {
      if (param) {
        this.mapCenter.custom = true;
        this.mapCenter.lat = param.lat;
        this.mapCenter.lng = param.long;
      }
    },
    positionChanged(param) {
      if (param.latLng) {
        this.mapCenter.custom = false;
        this.mapCenter.lat = param.latLng.lat();
        this.mapCenter.lng = param.latLng.lng();
      }
    },
    updatePropertyAddress(param) {
      this.propertyAddress = param;
    },
    getOptions() {
      const _this = this;
      let data = {
        customer: _this.customer,
      };
      _this.$store
        .dispatch(QUERY, { url: "property/options", data })
        .then(({ data }) => {
          _this.customerObject = data.customer;
          _this.options = data.options;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.propertyForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      const address = [];
      if (_this.lodash.isEmpty(_this.propertyAddress) === false) {
        address.push(_this.propertyAddress);
      }

      if (_this.lodash.isEmpty(address) === false) {
        _this.$store
          .dispatch(PUT, {
            url: "address/" + _this.customer,
            data: { address: address, type: "customer" },
          })
          .then(() => {
            _this.backForce = true;
            _this.$router.push(
              _this.getDefaultRoute("property", { query: { status: "active" } })
            );
          })
          .catch((error) => {
            _this.backForce = false;
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    getPropertyDetail() {
      const _this = this;
      _this
        .getProperty()
        .then((response) => {
          _this.propertyArr = response;
          _this.customer = response.customer ? response.customer.id : 0;
          _this.mapCenter = {
            lat: parseFloat(response.latitude),
            lng: parseFloat(response.longitude),
            custom: true,
          };
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Property", route: "property" },
            { title: "Update" },
            { title: response.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Property", route: "property" },
      { title: "Create" },
    ]);
  },
  created() {
    const _this = this;
    _this.property = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.customer = _this.lodash.toSafeInteger(_this.$route.query.customer);
    if (_this.customer <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getAction() {
      if (this.property) {
        return "Update";
      }
      return "Create";
    },
    getPropertyTitle() {
      const _this = this;
      if (_this.propertyArr && _this.propertyArr.barcode) {
        return " • " + _this.propertyArr.barcode;
      }
      return "";
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
