<template>
  <v-container class="white-background" fluid>
    <v-row class="gantt-chart">
      <v-col md="12">
        <h3 class="mt-4">Project Planner</h3>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button gantt-close-btn"
          v-on:click="goBack"
        >
          Close
        </v-btn>
        <v-layout>
          <v-flex><p>A legend describing the charting follows.</p></v-flex>
          <v-flex><p>Period Highlight:</p></v-flex>
          <v-flex
            ><p class="m-0">
              <span class="py-2 px-3 mr-2 plan-duration"></span>Plan Duration
            </p></v-flex
          >
          <v-flex
            ><p class="m-0">
              <span class="py-2 px-3 mr-2 actual-start"></span>Actual Start
            </p></v-flex
          >
          <v-flex
            ><p class="m-0">
              <span class="py-2 px-3 mr-2 complete-percentage"></span>% Complete
            </p></v-flex
          >
          <v-flex
            ><p class="m-0">
              <span class="py-2 px-3 mr-2 actual-beyond-plan"></span>Actual
              (beyond plan)
            </p></v-flex
          >
          <v-flex
            ><p class="m-0">
              <span
                class="py-2 px-3 mr-2 complete-percentage-beyond-plan"
              ></span
              >% Complete (beyond plan)
            </p></v-flex
          >
        </v-layout>
      </v-col>
      <v-col md="4" class="gantt-table btx-gantt-left">
        <table width="100%">
          <thead>
            <tr>
              <th height="82" rowspan="2">TASK</th>
              <th height="82" rowspan="2">PLAN<br />START</th>
              <th height="82" rowspan="2">PLAN<br />DURATION</th>
              <th height="82" rowspan="2">ACTUAL<br />START</th>
              <th height="82" rowspan="2">ACTUAL<br />DURATION</th>
              <th height="82" rowspan="2">PERCENT<br />COMPLETE</th>
            </tr>
          </thead>
          <tbody v-if="dataLoading">
            <tr v-for="tr in 10" :key="`tbody-tr-${tr}`">
              <td
                valign="middle"
                v-for="td in 6"
                :key="`tbody-td-${td}`"
                height="42"
              >
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="activities.length">
            <tr
              class="btn-gantt-task-rows"
              v-for="(row, index) in activities"
              :key="`tbody-activities-${index}`"
            >
              <td valign="middle" height="42">{{ row.title }}</td>
              <td valign="middle" height="42" align="center">
                {{ row.plan_start }}
              </td>
              <td valign="middle" height="42" align="center">
                {{ row.plan_duration }}
              </td>
              <td valign="middle" height="42" align="center">
                {{ row.actual_start }}
              </td>
              <td valign="middle" height="42" align="center">
                {{ row.actual_duration }}
              </td>
              <td valign="middle" height="42" align="center">
                {{ row.percent_complete }}
              </td>
            </tr>
          </tbody>
          <tfoot v-else>
            <tr>
              <td colspan="6">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-14"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no task at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-col>
      <v-col md="8" class="gantt-table blocks btx-gantt-right">
        <table width="100%" class="table-vscroll">
          <thead v-if="dataLoading">
            <tr>
              <th :colspan="31">PERIODS</th>
            </tr>
            <tr>
              <th v-for="count in 31" :key="`tbody-countd-${count}`">
                {{ count }}
              </th>
            </tr>
            <tr v-for="(row, index) in 10" :key="`thead-index-${index}`">
              <td
                height="42"
                style="min-width: 32px"
                v-for="count in 31"
                :key="`thead-td-${count}`"
              >
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </td>
            </tr>
          </thead>
          <thead v-else-if="activities.length">
            <tr>
              <th :colspan="totalDays.length">PERIODS</th>
            </tr>
            <tr>
              <th
                v-for="(count, dindex) in totalDays"
                :key="`thead-countdf-${dindex}`"
              >
                {{ count }}
              </th>
            </tr>
            <tr
              v-for="(row, index) in activities"
              :key="`thead-index-${index}`"
            >
              <td
                height="42"
                style="min-width: 32px"
                v-for="(count, indexd) in totalDays"
                :key="`thead-count-${index}-${indexd}`"
                :class="getClass(row, count)"
              >
                <div class="main-block">
                  <div class="child-block"></div>
                </div>
              </td>
            </tr>
          </thead>
          <tfoot v-else>
            <tr>
              <th :colspan="31">PERIODS</th>
            </tr>
            <tr>
              <th v-for="count in 31" :key="`thead-count-${count}`">
                {{ count }}
              </th>
            </tr>
            <tr>
              <td colspan="31">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-14"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no task schedule at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>
<style type="text/css">
.gantt-table.blocks table thead tr td {
  border: solid 1px #e2e5ec;
}
.btn-gantt-task-rows {
  border-top: solid 1px #e2e5ec;
  border-bottom: solid 1px #e2e5ec;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET } from "@/core/services/store/request.module";

export default {
  data() {
    return {
      project: 1,
      projectObject: {},
      totalDays: [],
      activities: [],
      dataLoading: true,
    };
  },
  methods: {
    getClass(row, count) {
      let ganttTdClass = "";
      let totalComplete = 0;
      let totalActualDuration = 0;
      let totalPlanDuration = 0;
      let totalActualBeyondPlan = 0;
      let inPlanDuration = false;

      totalPlanDuration = row.plan_start + row.plan_duration;
      if (count >= row.plan_start && count < totalPlanDuration) {
        inPlanDuration = true;
        ganttTdClass = "plan-duration";
      }

      totalActualDuration = row.actual_start + row.actual_duration;
      if (count >= row.actual_start && count < totalActualDuration) {
        ganttTdClass = `${ganttTdClass} actual-start`;

        totalComplete =
          Math.ceil((row.percent_complete / 100) * row.actual_duration) +
          row.actual_start -
          1;

        if (count >= row.actual_start && count <= totalComplete) {
          ganttTdClass = `${ganttTdClass} complete-percentage`;
        }

        totalActualBeyondPlan = row.actual_duration - row.plan_duration;
        if (totalActualBeyondPlan > 0 && !inPlanDuration) {
          if (
            count >= totalComplete &&
            count >= totalActualBeyondPlan &&
            count < totalActualDuration
          ) {
            ganttTdClass = `${ganttTdClass} actual-beyond-plan`;
          }
        }
      }

      return ganttTdClass.trim();
    },
    getProjectTasks() {
      this.$store
        .dispatch(GET, {
          url: `tasks/${this.project}/gantt`,
        })
        .then(({ data }) => {
          this.activities = data.tasks;
          this.totalDays = data.dates;
          this.projectObject = data.project;
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: "Project",
            },
            {
              title: this.projectObject.name,
            },
            {
              title: "Gantt",
            },
          ]);
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
  },
  created() {
    this.project = this.lodash.toSafeInteger(this.$route.params.project);
  },
  mounted() {
    this.getProjectTasks();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Project",
      },
      {
        title: "Dummy Project",
      },
      {
        title: "Gantt",
      },
    ]);
  },
};
</script>
