<template>
  <div class="ct-detail-quotation-sales">
    <detail-quotation-sales is-quotation> </detail-quotation-sales>
  </div>
</template>

<script>
import DetailCommonQuotation from "@/view/pages/common-quotation/Detail-Quotation";

export default {
  name: "detail-common-quotation-sales",
  data() {
    return {};
  },
  components: {
    "detail-quotation-sales": DetailCommonQuotation,
  },
};
</script>
