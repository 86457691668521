<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'agreement-update'"
    v-if="getPermission('agreement:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Update a Agreement For
        <p
          class="m-0 form-title-create-link pl-2"
          :class="{
            'text-ellipsis width-350px': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject)"
            >mdi-plus-circle-outline</v-icon
          >
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Agreement
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="agreementForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid v-bar class="create-agreement-vbar">
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Agreement Subject
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Subject"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="agreementCreate.subject"
                      dense
                      filled
                      label="Subject"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          agreementCreate.subject,
                          'Agreement Subject'
                        ),
                        validateRules.minLength(
                          agreementCreate.subject,
                          'Agreement Subject',
                          1
                        ),
                        validateRules.maxLength(
                          agreementCreate.subject,
                          'Agreement Subject',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="agreementCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          agreementCreate.reference,
                          'Reference #',
                          1
                        ),
                        validateRules.maxLength(
                          agreementCreate.reference,
                          'Reference #',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Project</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Name"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="projectList"
                      label="Project"
                      solo
                      flat
                      :disabled="pageLoading || !!+$route.query.project"
                      :loading="pageLoading"
                      hide-details
                      item-text="name"
                      item-value="id"
                      v-model.trim="agreementCreate.project"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }} -
                            {{ item.name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'Start Date'"
                      v-model="agreementCreate.start_date"
                      :defaultDate.sync="agreementCreate.start_date"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">End Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'End Date'"
                      v-model="agreementCreate.end_date"
                      :defaultDate.sync="defaultEndDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Agreement number
                  </td>
                  <td
                    colspan="2"
                    class="font-weight-700 font-size-16 pb-4"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model.trim="agreementCreate.barcode"
                        label="Agreement number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Agreement Type</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Agreement Type"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="agreementTypeList"
                      label="Agreement Type"
                      solo
                      flat
                      hide-details
                      item-text="text"
                      item-value="value"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageAgreementTypeDialog = true"
                      v-model.trim="agreementCreate.agreement_type"
                      :rules="[
                        validateRules.required(
                          agreementCreate.agreement_type,
                          'Agreement Type'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Agreement Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Agreement Value</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Agreement Value"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="agreementCreate.agreement_value"
                      dense
                      filled
                      label="Agreement Value"
                      solo
                      flat
                      hide-details
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-usd"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="agreementCreate.description" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                :attachments.sync="attachments"
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <ManageAgreementType
        :commonDialog.sync="manageAgreementTypeDialog"
        :agreementType.sync="agreementTypeList"
        v-on:close-dialog="manageAgreementTypeDialog = false"
        v-on:get-agreement-type="getOptions(['agreement_type'])"
      ></ManageAgreementType>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  PUT,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import ManageAgreementType from "@/view/pages/partials/Manage-Agreement-Type.vue";
import moment from "moment-timezone";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "agreement-update",
  title: "Update Agreement",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      backForce: false,
      manageAgreementTypeDialog: false,
      barcodeSetting: new Object(),
      customerObject: new Object(),
      agreementTypeList: new Array(),
      projectList: new Array(),
      attachments: new Array(),
      agreement: null,
      agreementCreate: new Object({
        reference: null,
        customer: null,
        project: null,
        subject: null,
        agreement_value: null,
        agreement_type: null,
        description: null,
        start_date: null,
        end_date: null,
        status: null,
        attachments: [],
      }),
    };
  },
  components: {
    DatePicker,
    FileTemplate,
    ManageAgreementType,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.agreementCreate.attachments = param;
    },
    getOptions(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "agreements/options",
          data: { attributes, customer: _this.agreementCreate.customer },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.customer) === false) {
            _this.customerObject = data.customer;
          }
          if (_this.lodash.isEmpty(data.agreement_type) === false) {
            _this.agreementTypeList = data.agreement_type;
          }
          if (_this.lodash.isEmpty(data.project_list) === false) {
            _this.projectList = data.project_list;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.manageAgreementTypeDialog = false;
        });
    },
    updateOrCreate() {
      const _this = this;
      if (!_this.$refs.agreementForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.agreementCreate.reference || null,
        customer: _this.agreementCreate.customer || null,
        project: _this.agreementCreate.project || null,
        subject: _this.agreementCreate.subject || null,
        agreement_value: _this.agreementCreate.agreement_value || null,
        agreement_type: _this.agreementCreate.agreement_type || null,
        description: _this.agreementCreate.description || null,
        start_date: _this.agreementCreate.start_date || null,
        end_date: _this.agreementCreate.end_date || null,
        status: _this.agreementCreate.status || null,
        attachments: _this.agreementCreate.attachments || [],
      });

      _this.$store
        .dispatch(PUT, {
          url: "agreements/" + _this.agreement,
          data: formData,
        })
        .then(({ data }) => {
          _this.backForce = true;
          _this.$router.push(
            _this.getDefaultRoute("agreement.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.backForce = false;
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getAgreement() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "agreements/" + _this.agreement,
        })
        .then(({ data }) => {
          _this.agreementCreate = new Object({
            barcode: data.barcode,
            reference: data.reference,
            customer: data.customer ? data.customer.id : null,
            project: data.project ? data.project.id : null,
            subject: data.subject,
            agreement_value: data.agreement_value,
            agreement_type: data.agreement_type,
            description: data.description,
            start_date: data.start_date,
            end_date: data.end_date || "",
            attachments: new Array(),
          });
          _this.attachments = data.attachments;

          if (_this.lodash.toSafeInteger(data.line_item)) {
            _this.goBack();
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getOptions(["customer", "agreement_type", "project_list"]);
        });
    },
  },
  created() {
    const _this = this;
    _this.agreementCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    if (_this.agreementCreate.customer <= 0) {
      _this.goBack();
    }
    _this.agreement = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (_this.agreement <= 0) {
      _this.goBack();
    }

    _this.getAgreement();
  },
  mounted() {
    const _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agreement", route: "agreement" },
      { title: "Update" },
    ]);
  },
  computed: {
    defaultEndDate() {
      if (this.agreementCreate.end_date) {
        return this.agreementCreate.end_date;
      }
      return moment().add(30, "days").format("YYYY-MM-DD");
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
