<template>
  <v-container
    fluid
    class="custom-line-item-template pb-0"
    v-if="getPermission('line-item:create')"
  >
    <v-row v-if="allowItemSelection">
      <v-col lg="6" md="4" sm="4" class="pt-0">
        <v-btn-toggle v-model="product_type" color="cyan" group>
          <v-btn
            color="cyan"
            :disabled="searching"
            class="custom-bold-button mx-2 border-left"
            v-on:click="
              openDialog('service', 'Service');
              packageBtn = false;
            "
            value="service"
          >
            Additional Work
          </v-btn>
        </v-btn-toggle>
        <template v-if="packageBtn">
          <v-autocomplete
            v-model.trim="item"
            :items="serverItemList"
            :search-input.sync="serverSearch"
            clearable
            hide-details
            dense
            filled
            flat
            ref="search-item"
            :loading="serverSearchLoading || productLoading"
            label="Start typing to Search..."
            placeholder="Start typing to Search..."
            solo
            no-filter
            item-color="cyan"
            color="cyan"
            :disabled="productLoading"
            item-text="name"
            item-value="id"
            v-on:change="openDialog('package', 'Package')"
            class="remove-border-radius hide-border-bottom"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title v-if="serverSearchLoading">
                  Getting results...
                  <v-progress-linear
                    color="cyan"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span class="text-capitalize"
                    >No {{ lineItemType }} Found</span
                  >
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                :color="lodash.isEmpty(item.primary_image) ? 'cyan' : 'white'"
                size="70"
                class="headline font-weight-light white--text text-uppercase"
              >
                <template v-if="lodash.isEmpty(item.primary_image) === false"
                  ><v-img
                    contain
                    :src="$assetAPIURL(item.primary_image)"
                  ></v-img
                ></template>
                <template v-else>{{ item.name.charAt(0) }}</template>
              </v-list-item-avatar>
              <v-list-item-content class="max-width-600px">
                <v-list-item-title
                  class="font-size-16 text-truncate pb-1 pl-0 text-capitalize"
                  v-text="item.name"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-if="item.description"
                  class="font-size-14 text-truncate pb-1"
                  v-text="item.description"
                ></v-list-item-subtitle>
                <v-list-item-action class="margin-auto">
                  <v-list-item-action-text class="font-weight-500 font-size-13"
                    >{{ item.service_count }}
                    Additional Work(s)
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="border" style="border-width: 2px !important">
        <table class="line-item-multiple-table mt-3" width="100%">
          <thead>
            <tr>
              <th class="text-left" :colspan="showCost ? 2 : 1" width="50%">
                &nbsp;&nbsp;&nbsp;Additional Work
              </th>
              <th v-if="is_admin" class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Supplier Cost
              </th>
              <th class="text-left" :colspan="showCost ? 2 : 1" width="20%">
                &nbsp;&nbsp;&nbsp;Quantity
              </th>
              <th v-if="showCost" class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Rate
              </th>
              <th v-if="showCost" class="text-left" width="15%">
                &nbsp;&nbsp;&nbsp;Total
              </th>
            </tr>
          </thead>
          <tbody v-if="selected_line_items.length">
            <tr
              v-for="(line_item, index) in selected_line_items"
              :key="index"
              class="alternate-listing-row line-item-listing-row"
              :class="{ 'custom-border-top': index > 0 }"
            >
              <td
                valign="top"
                :colspan="showCost ? 2 : 1"
                class="text-left pt-4 pb-2 px-4"
                width="55%"
              >
                <v-text-field
                  dense
                  filled
                  hide-details
                  color="cyan"
                  solo
                  flat
                  readonly
                  v-model="line_item.product"
                >
                  <template v-slot:prepend-inner>
                    <v-layout>
                      <v-flex v-if="is_id_person" md4>
                        <v-checkbox
                          v-on:change="calculateGrandTotal()"
                          v-model="line_item.status"
                          color="cyan"
                          hide-details
                          class="mt-0 pt-0 ctx-line-item-checkbox"
                          style="border: unset"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              label
                              class="custom-status font-weight-600 custom-grey-border text-uppercase"
                              text-color="white"
                              color="cyan"
                            >
                              {{ line_item.barcode }}
                            </v-chip>
                          </template>
                          <span class="text-capitalize"
                            >{{
                              getProductType(line_item.product_type)
                            }}
                            #</span
                          >
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </template>
                  <template v-slot:append>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          small
                          label
                          class="custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="cyan"
                        >
                          {{ getProductType(line_item.product_type) }}
                        </v-chip>
                      </template>
                      <span class="text-capitalize">Type</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-layout v-if="false">
                  <v-flex class="max-width-50px">
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      v-mask="'A'"
                      class="min-width-unset max-width-50px"
                      color="cyan"
                      v-model="line_item.group"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Additional Work"
                      dense
                      filled
                      solo
                      flat
                      readonly
                      color="cyan"
                      v-model="line_item.product"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-textarea
                  auto-grow
                  dense
                  filled
                  flat
                  v-model="line_item.description"
                  label="Description"
                  solo
                  color="cyan"
                  hide-details
                  row-height="10"
                  v-on:change="save_enabled = true"
                ></v-textarea>
                <!--<v-checkbox
                  color="cyan"
                  v-model="line_item.optional"
                  label="Mark as Optional"
                  v-on:change="calculateGrandTotal()"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                ></v-checkbox>-->
              </td>
              <td v-if="is_admin" valign="top" class="pt-4 pb-2" width="15%">
                <v-text-field
                  label="Supplier Cost"
                  dense
                  filled
                  solo
                  flat
                  hide-details
                  type="number"
                  color="cyan"
                  v-model="line_item.company_cost"
                  readonly
                ></v-text-field>
              </td>
              <td
                valign="top"
                :colspan="showCost ? 2 : 1"
                class="text-left pt-4 pb-2"
                width="15%"
              >
                <template v-if="!showCost">
                  <v-layout>
                    <v-flex
                      md10
                      class="my-auto"
                      style="margin: 2px 5px !important"
                    >
                      <v-text-field
                        label="Quantity"
                        dense
                        filled
                        solo
                        flat
                        hide-details
                        type="number"
                        color="cyan"
                        v-on:keyup="calculateTotal(index, 'selected-line-item')"
                        v-on:change="
                          save_enabled = true;
                          calculateTotal(index, 'selected-line-item');
                        "
                        :suffix="line_item.uom"
                        v-model="line_item.quantity"
                        v-mask="'####'"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md2
                      class="my-auto"
                      style="margin: 2px 5px !important"
                    >
                      <v-btn
                        icon
                        color="red lighten-1"
                        v-on:click="deleteLineItem(line_item, index)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-else>
                  <v-text-field
                    label="Quantity"
                    dense
                    filled
                    solo
                    flat
                    hide-details
                    type="number"
                    color="cyan"
                    v-on:keyup="calculateTotal(index, 'selected-line-item')"
                    v-on:change="
                      save_enabled = true;
                      calculateTotal(index, 'selected-line-item');
                    "
                    :suffix="line_item.uom"
                    v-model="line_item.quantity"
                    v-mask="'####'"
                  ></v-text-field>
                </template>
              </td>
              <td
                valign="top"
                :class="showCost ? '' : 'd-none'"
                class="text-left pt-4 pb-2"
                width="15%"
              >
                <v-text-field
                  label="Rate"
                  dense
                  filled
                  solo
                  flat
                  hide-details
                  v-on:keyup="calculateTotal(index, 'selected-line-item')"
                  v-on:change="
                    save_enabled = true;
                    calculateTotal(index, 'selected-line-item');
                  "
                  type="number"
                  color="cyan"
                  v-model="line_item.rate"
                  v-model.trim.number.lazy="line_item.rate"
                  v-on:keypress="limitDecimal($event, line_item.rate)"
                ></v-text-field>
                <label class="w-100 m-0 pt-2 font-size-14"
                  ><span class="font-weight-600"
                    ><template>Supplier Cost: </template></span
                  >{{ formatMoney(line_item.company_cost) }}</label
                >
                <label class="w-100 m-0 font-size-14"
                  ><span class="font-weight-600"
                    ><template>Price: </template></span
                  >{{ formatMoney(line_item.rate) }}</label
                >
                <label class="w-100 m-0 font-size-14"
                  ><span class="font-weight-600">Profit: </span
                  >{{
                    formatMoney(line_item.rate - line_item.company_cost)
                  }}</label
                >
              </td>
              <td
                valign="top"
                :class="showCost ? '' : 'd-none'"
                class="pt-4 pb-2"
                width="15%"
              >
                <v-layout>
                  <v-flex md10>
                    <v-text-field
                      label="Total"
                      dense
                      filled
                      solo
                      color="cyan"
                      flat
                      hide-details
                      type="number"
                      readonly
                      v-model="line_item.total"
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    md2
                    class="my-auto"
                    style="margin: 2px 5px !important"
                  >
                    <v-btn
                      icon
                      color="red lighten-1"
                      v-on:click="deleteLineItem(line_item, index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <label class="w-100 m-0 pt-2 font-size-14"
                  ><span class="font-weight-600"
                    ><template>Supplier Cost: </template></span
                  >{{
                    formatMoney(line_item.company_cost * line_item.quantity)
                  }}</label
                >
                <label class="w-100 m-0 font-size-14"
                  ><span class="font-weight-600"
                    ><template>Price: </template></span
                  >{{ formatMoney(line_item.rate * line_item.quantity) }}</label
                >
                <label class="w-100 m-0 font-size-14"
                  ><span class="font-weight-600">Profit: </span
                  >{{
                    formatMoney(
                      line_item.quantity * line_item.rate -
                        line_item.quantity * line_item.company_cost
                    )
                  }}</label
                >
              </td>
            </tr>
          </tbody>
          <tfoot v-else>
            <tr>
              <td colspan="4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no Additional Work at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
        <table
          v-if="false && showCost"
          class="line-item-multiple-table mt-3"
          width="100%"
        >
          <tbody>
            <tr>
              <td colspan="4"></td>
            </tr>
            <tr>
              <td colspan="4" class="custom-border-top"></td>
            </tr>
            <tr>
              <td valign="middle" width="50%"></td>
              <td
                valign="middle"
                width="20%"
                class="font-size-16 py-3 font-weight-500"
              >
                Sub Total
              </td>
              <td valign="middle" width="15%"></td>
              <td valign="middle" align="right" width="15%">
                {{ formatMoney(subTotalAmount) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Discount
              </td>
              <td valign="middle" class="p-0">
                <v-layout>
                  <v-flex md8>
                    <v-text-field
                      label="Discount"
                      dense
                      filled
                      solo
                      color="cyan"
                      flat
                      type="number"
                      v-model="discountValue"
                      v-on:keyup="
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      v-on:change="
                        save_enabled = true;
                        validateDiscount();
                        calculateGrandTotal();
                      "
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex md4>
                    <v-select
                      :items="discountTypeList"
                      v-model.trim="discountValueType"
                      dense
                      filled
                      flat
                      hide-details
                      solo
                      item-text="type"
                      item-value="value"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="
                        save_enabled = true;
                        validateDiscount();
                        calculateGrandTotal();
                      "
                      class="discount-append-outer"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </td>
              <td valign="middle" align="right">
                {{ formatMoney(discountAmount) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td class="font-size-16 py-3 font-weight-500">Tax [8%]</td>
              <td align="center">
                <v-layout class="align-center">
                  <v-flex md3 class="text-left">
                    <v-checkbox
                      hide-details
                      class="ml-4 my-0 py-0"
                      color="cyan"
                      :true-value="1"
                      :false-value="0"
                      v-model="is_taxed"
                      v-on:change="
                        save_enabled = true;
                        calculateGrandTotal();
                      "
                      :disabled="subTotalAmount < 1"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md9 class="text-left">
                    <template v-if="is_taxed">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            get_taxable_amount_total(0)
                          }}</span>
                        </template>
                        <span class="text-capitalize">Taxable Amount</span>
                      </v-tooltip>
                    </template>
                  </v-flex>
                </v-layout>
              </td>
              <td align="right" valign="middle">
                {{ get_tax_total(0) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Adjustment
              </td>
              <td valign="middle" class="p-0">
                <v-text-field
                  label="Adjustment"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  color="cyan"
                  v-on:keyup="calculateGrandTotal()"
                  v-on:change="
                    save_enabled = true;
                    calculateGrandTotal();
                  "
                  v-model="adjustmentAmount"
                ></v-text-field>
              </td>
              <td valign="middle" align="right">
                {{ formatMoney(adjustmentAmount) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Tax Without Discount
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{ get_tax_total(1) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Grand Total (Without Discount)
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{ get_total_without_discount() }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Grand Total (With Discount)
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{ formatMoney(grandTotalAmount) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>

    <Dialog :common-dialog="dialog" :dialog-width="dialogWidth" dense>
      <template v-slot:title>
        Select {{ product_type_text }}s
        <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon> Line
        Item
      </template>
      <template v-slot:body>
        <v-row class="mx-1">
          <v-col v-if="categories.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                :disabled="searching"
                depressed
                v-on:click="getLineItem(null, 'category')"
                :class="{
                  'cyan--text': null == category,
                  'cyan white--text': null != category,
                }"
                class="custom-bold-button"
                small
                >All</v-btn
              >
              <v-btn
                :disabled="searching"
                depressed
                v-for="(row, index) in categories"
                v-on:click="getLineItem(row, 'category')"
                :class="{
                  'cyan--text': row == category,
                  'cyan white--text': row != category,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row }}</v-btn
              >
            </div>
          </v-col>
          <v-col v-if="alphabets.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                :disabled="searching"
                depressed
                v-on:click="getLineItem(null, 'alphabet')"
                :class="{
                  'cyan--text': null == alphabet,
                  'cyan white--text': null != alphabet,
                }"
                class="custom-bold-button"
                small
                >All</v-btn
              >
              <v-btn
                :disabled="searching"
                depressed
                v-for="(row, index) in alphabets"
                v-on:click="getLineItem(row, 'alphabet')"
                :class="{
                  'cyan--text': row == alphabet,
                  'cyan white--text': row != alphabet,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row }}</v-btn
              >
            </div>
          </v-col>
          <v-col md="12">
            <v-layout>
              <v-flex
                md6
                class="font-weight-700 font-size-17 color-custom-blue"
              >
                <v-btn
                  small
                  depressed
                  :disabled="searching"
                  :class="{
                    'green white--text': show_selected,
                    'white green--text': !show_selected,
                  }"
                  class="font-size-17 font-weight-700"
                  v-on:click="show_selected = !show_selected"
                  >{{ selectedCount() }}</v-btn
                >
                out of
                <v-btn
                  small
                  depressed
                  :disabled="searching"
                  class="font-size-17 font-weight-700"
                  color="white cyan--text"
                  v-on:click="showAll()"
                  >{{ totalCount() }}</v-btn
                >
                {{ product_type_text }} Selected
              </v-flex>
              <v-flex md6>
                <v-text-field
                  v-model="search"
                  label="Search"
                  placeholder="Start typing then press enter to search..."
                  dense
                  filled
                  solo
                  flat
                  hide-details
                  :disabled="searching"
                  color="cyan"
                  append-icon="mdi-magnify"
                  clearable
                  v-on:click:clear="
                    search = null;
                    getLineItem(search, 'search');
                  "
                  v-on:keyup.enter="getLineItem(search, 'search')"
                  v-on:click:append="getLineItem(search, 'search')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <br />
            <div class="custom-border-top">
              <br />
              <table class="line-item-multiple-table" width="100%">
                <thead>
                  <tr>
                    <th class="text-center" width="5%">
                      <v-checkbox
                        :disabled="searching || !lineItems.length"
                        color="cyan"
                        v-model="allcheckbox"
                        hide-details
                        class="m-0 p-0"
                        v-on:change="checkAll($event)"
                      ></v-checkbox>
                    </th>
                    <th
                      class="text-left"
                      :colspan="showCost ? 2 : 1"
                      width="50%"
                    >
                      &nbsp;&nbsp;&nbsp;{{ product_type_text }}
                    </th>
                    <th
                      class="text-left"
                      :class="showCost ? '' : 'd-none'"
                      width="15%"
                    >
                      &nbsp;&nbsp;&nbsp;Rate
                    </th>
                    <th
                      class="text-left"
                      :colspan="showCost ? 2 : 1"
                      width="15%"
                    >
                      &nbsp;&nbsp;&nbsp;Quantity
                    </th>
                    <th
                      class="text-left"
                      :class="showCost ? '' : 'd-none'"
                      width="15%"
                    >
                      &nbsp;&nbsp;&nbsp;Total
                    </th>
                  </tr>
                </thead>
                <tbody v-if="lineItems.length">
                  <template v-for="(line_item, index) in lineItems">
                    <tr v-if="checkSelected(line_item)" :key="index">
                      <td valign="top" class="text-center" width="5%">
                        <v-checkbox
                          color="cyan"
                          hide-details
                          :disabled="searching"
                          v-model="line_item.status"
                          v-on:change="validateCheckbox($event)"
                          class="m-0 p-0 mt-2"
                        ></v-checkbox>
                      </td>
                      <td
                        valign="top"
                        :colspan="showCost ? 2 : 1"
                        class="text-left"
                        width="50%"
                      >
                        <v-text-field
                          :label="product_type_text"
                          dense
                          filled
                          solo
                          flat
                          hide-details
                          :disabled="searching"
                          readonly
                          color="cyan"
                          v-model="line_item.product"
                        ></v-text-field>
                        <v-textarea
                          auto-grow
                          dense
                          filled
                          flat
                          :disabled="searching"
                          v-model="line_item.description"
                          label="Description"
                          solo
                          color="cyan"
                          hide-details
                          row-height="10"
                        ></v-textarea>
                      </td>
                      <td
                        valign="top"
                        :class="showCost ? '' : 'd-none'"
                        class="text-left"
                        width="15%"
                      >
                        <v-text-field
                          label="Rate"
                          dense
                          filled
                          solo
                          v-on:keyup="calculateTotal(index, 'line-item')"
                          v-on:change="calculateTotal(index, 'line-item')"
                          :disabled="searching"
                          flat
                          type="number"
                          color="cyan"
                          v-model="line_item.rate"
                          v-model.trim.number.lazy="line_item.rate"
                          v-on:keypress="limitDecimal($event, line_item.rate)"
                        ></v-text-field>
                      </td>
                      <td
                        valign="top"
                        :colspan="showCost ? 2 : 1"
                        class="text-left"
                        width="15%"
                      >
                        <v-text-field
                          label="Quantity"
                          dense
                          filled
                          solo
                          flat
                          type="number"
                          v-on:keyup="calculateTotal(index, 'line-item')"
                          v-on:change="calculateTotal(index, 'line-item')"
                          :disabled="searching"
                          color="cyan"
                          :suffix="line_item.uom"
                          v-model="line_item.quantity"
                          v-mask="'####'"
                        ></v-text-field>
                      </td>
                      <td
                        valign="top"
                        :class="showCost ? '' : 'd-none'"
                        class="text-left"
                        width="15%"
                      >
                        <v-text-field
                          label="Total"
                          dense
                          filled
                          :disabled="searching"
                          solo
                          color="cyan"
                          flat
                          type="number"
                          readonly
                          v-model="line_item.total"
                        ></v-text-field>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot v-else>
                  <tr>
                    <td colspan="5">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no {{ product_type_text }} at the
                        moment.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="
            product_type = null;
            product_type_text = null;
            lineItems = [];
            categories = [];
            dialog = false;
          "
          :disabled="searching"
          class="custom-grey-border custom-bold-button"
          >Cancel</v-btn
        >
        <v-btn
          color="cyan white--text"
          class="custom-bold-button"
          v-on:click="saveLineItem()"
          :disabled="searching"
          >Save</v-btn
        >
      </template>
    </Dialog>
    <DeleteDialog :delete-dialog="delete_line_item_dialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_line_item_dialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          color="red lighten-1 white--text"
          v-on:click="deleteLineItemConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
  </v-container>
</template>
<script>
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { QUERY, GET, DELETE } from "@/core/services/store/request.module";
import { GET_TAXES } from "@/core/services/store/common.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import Configuration from "@/core/config/app.config";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import {
  filter,
  toSafeInteger,
  find,
  cloneDeep,
  concat,
  uniqBy,
  map,
  toNumber,
} from "lodash";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import { currentUser } from "@/core/services/jwt.service";
import ObjectPath from "object-path";

export default {
  name: "line-items",
  mixins: [ValidationMixin, CommonFunctionMixin, CommonMixin],
  props: {
    updateData: {
      type: [Array, Object],
      default: new Array(),
    },
    showCost: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dbLineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    entityId: {
      type: Number,
      default: 0,
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    applyTax: {
      type: Number,
      default: 0,
    },
    adjustmentValue: {
      type: Number,
      default: 0,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    isUpdateMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: {},
      selectedItems: [],
      delete_line_item_dialog: false,
      is_admin: false,
      is_id_person: false,
      discountAmount: 0,
      adjustmentAmount: 0,
      subTotalAmount: 0,
      delete_line_item: new Object(),
      subProfitTotalAmount: 0,
      taxableAmount: 0,
      discountValue: 0,
      taxAmount: 0,
      grandTotalAmount: 0,
      discountValueType: 1,
      serverItemList: [],
      lineItemType: "package",
      serverSearch: null,
      serverSearchLoading: false,
      is_taxed: false,
      productLoading: true,
      searchTimeout: null,
      searchTimeoutLimit: 500,
      packageBtn: false,
      grandTotalTimeout: null,
      grandTotalTimeoutLimit: 200,
      taxObject: {},
      lineItems: [],
      taxes: [],
      uomList: [],
      discountTypeList: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      packageItems: [],
      packageDialog: false,
      packageTitle: null,
      packageMe: null,
      delete_loading: false,
      packageId: 0,
      discount: 0,
      total_tax: 0,
      update_transaction: false,
      product_type: null,
      product_type_text: null,
      allcheckbox: false,
      categories: [],
      searching: false,
      dialog: false,
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      show_selected: false,
      alphabet: null,
      search: null,
      category: null,
      selected_packages: [],
      selected_line_items: [],
    };
  },
  watch: {
    serverSearch(previousValue, newValue) {
      if (previousValue != newValue) {
        this.getLineItems();
      }
    },
    dbLineItems: {
      deep: true,
      immediate: true,
      handler() {
        this.initUpdate();
      },
    },
  },
  methods: {
    deleteLineItemConfirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, { url: `line-item/${this.delete_line_item.id}` })
        .then(() => {
          this.$emit("load:content", true);
          this.delete_line_item = {};
          this.delete_line_item_dialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    getLineItem(value, type) {
      const _this = this;
      if (type == "category") {
        _this.category = _this.category != value ? value : null;
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "alphabet") {
        _this.alphabet = _this.alphabet != value ? value : null;
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "search") {
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "keyup") {
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(() => {
          _this.findLineItem(false);
        }, _this.timeout_limit);
      }
    },
    deleteLineItem(row, index) {
      if (row.id) {
        this.delete_line_item = row;
        this.delete_line_item_dialog = true;
      } else {
        this.selected_line_items.splice(index, 1);
      }
      this.calculateGrandTotal();
    },
    calculateTotal(index, type) {
      let item = this.selected_line_items[index];
      if (type == "line-item") {
        item = this.lineItems[index];
      }

      if (item) {
        let rate = toNumber(item.rate);
        if (rate < 0) {
          rate = 0;
        }

        let quantity = toNumber(item.quantity);
        if (quantity <= 0) {
          quantity = 1;
        }

        const total = rate * quantity;

        if (type == "line-item") {
          this.lineItems[index].quantity = quantity;
          this.lineItems[index].rate = rate;
          this.lineItems[index].total = total;
        } else {
          this.selected_line_items[index].quantity = quantity;
          this.selected_line_items[index].rate = rate;
          this.selected_line_items[index].total = total;
        }
      }

      if (type == "selected-line-item") {
        this.$nextTick(() => {
          this.calculateGrandTotal();
        });
      }
    },
    openDialog(type, type_text) {
      this.product_type_text = type_text;
      this.selectedItems = [];
      this.product_type = type;
      this.allcheckbox = false;
      this.$nextTick(() => {
        this.findLineItem(true);
      });
    },
    updateChecked(dialog) {
      const line_items = this.selected_line_items;
      let filtered = filter(line_items, {
        product_type: "service",
      });
      for (let i = 0; i < this.lineItems.length; i++) {
        const exist = find(filtered, {
          product_id: this.lineItems[i].product_id,
        });

        if (exist) {
          this.lineItems[i] = exist;
        }
      }

      if (dialog) {
        this.$nextTick(() => {
          this.dialog = true;
          const uniqFiltered = uniqBy(filtered, (row) =>
            [row.product_id].join()
          );
          if (
            uniqFiltered.length &&
            uniqFiltered.length == this.lineItems.length
          ) {
            this.allcheckbox = true;
          }
        });
      }
    },
    findLineItem(dialog) {
      const _this = this;
      if (_this.searching) {
        return false;
      }
      let type_id = null;
      let url = "product/find-line-items";
      _this.searching = true;
      _this.lineItems = [];
      _this.$store
        .dispatch(QUERY, {
          url: url,
          data: {
            type: "service",
            property: _this.propertyId,
            search: _this.search,
            alphabet: _this.alphabet,
            category: _this.category,
            type_id: type_id,
          },
        })
        .then(({ data }) => {
          _this.categories = data.categories;
          let selectedItems = this.selectedItems;
          let lineItemData = map(data.line_items, function (row) {
            row.status =
              selectedItems.length > 0 && selectedItems.includes(row.product_id)
                ? true
                : false;
            return row;
          });
          _this.lineItems = lineItemData;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searching = false;
          _this.updateChecked(dialog);
        });
    },
    initUpdate() {
      this.selected_line_items = [];
      this.selected_packages = [];

      if (this.dbLineItems.length) {
        for (let i = 0; i < this.dbLineItems.length; i++) {
          this.selected_line_items.push({
            status: false,
            id: this.dbLineItems[i].id,
            group: this.dbLineItems[i].group,
            barcode: this.dbLineItems[i].barcode,
            group_primary: this.dbLineItems[i].group_primary,
            product: this.dbLineItems[i].product,
            product_id: this.dbLineItems[i].product_id,
            package_id: this.dbLineItems[i].package_id,
            serial_no: this.dbLineItems[i].serial_no,
            eq_model: this.dbLineItems[i].eq_model,
            location: this.dbLineItems[i].location,
            product_type: this.dbLineItems[i].product_type,
            has_warranty: this.dbLineItems[i].has_warranty,
            to_equipment: this.dbLineItems[i].to_equipment,
            warranty: this.dbLineItems[i].warranty,
            description: this.dbLineItems[i].description,
            company_cost: this.dbLineItems[i].company_cost,
            charges: this.dbLineItems[i].charges,
            rate: this.dbLineItems[i].rate,
            quantity: this.dbLineItems[i].quantity,
            optional: this.dbLineItems[i].optional,
            uom: this.dbLineItems[i].uom,
            total: this.dbLineItems[i].total,
          });
          this.$nextTick(() => {
            this.calculateTotal(i, "selected-line-item");
          });
        }
      }

      if (this.lodash.isEmpty(this.updateData) === false) {
        this.discountValue = this.updateData.discount_value;
        this.adjustmentAmount = this.updateData.adjustment;
        this.discountValueType = this.updateData.discount_value_type;
        this.is_taxed = this.updateData.is_taxed;
      }

      this.apply_tax = this.applyTax;
      this.adjustment = this.adjustmentValue;
      /* this.discount_value = this.discountValue;
      this.discount_value_type = this.discountValueType; */
      this.subTotalAmount = this.updateData.sub_total;
      this.product_type = "service";
      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
      /* this.$nextTick(() => {
        this.findLineItem(false);
      }); */
    },
    selectedCount() {
      return filter(this.lineItems, { status: true }).length;
    },
    totalCount() {
      return this.lineItems.length;
    },
    checkSelected(line_item) {
      if (this.show_selected) {
        return line_item.status;
      }
      return true;
    },
    validateCheckbox() {
      const selectedItemDataArr = filter(this.lineItems, { status: true });
      const selectedItemData = uniqBy(selectedItemDataArr, (row) =>
        [row.product_id].join()
      );
      this.selectedItems = this.lodash.map(selectedItemData, function (row) {
        return row.product_id;
      });
      const len = selectedItemData.length;
      const default_len = this.lineItems.length;
      if (default_len) {
        if (default_len == len) {
          this.allcheckbox = true;
        } else {
          this.allcheckbox = false;
        }
      } else {
        this.allcheckbox = false;
      }
    },
    saveLineItem() {
      const default_line_items = cloneDeep(this.lineItems);

      const selected_line_items = filter(default_line_items, {
        status: true,
      });

      if (!selected_line_items.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            `Select atleast one ${this.product_type_text.toLowerCase()} then try again.`
          )
        );
        return false;
      }

      const selected_services = filter(default_line_items, {
        status: true,
        product_type: "service",
      });

      this.selected_line_items = concat(
        selected_services,
        this.selected_line_items
      );

      this.selected_line_items = uniqBy(this.selected_line_items, "product_id");

      this.product_type = null;
      this.product_type_text = null;
      this.dialog = false;

      this.save_enabled = true;

      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    getProductTypeColor(type) {
      let result = null;
      switch (type) {
        case "service":
          result = "cyan darken-1 white--text";
          break;
        case "package":
          result = "orange darken-1 white--text";
          break;
        case "equipment":
          result = "green darken-1 white--text";
          break;
      }
      return result;
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Additional Work";
          break;
        case "package":
          result = "Package";
          break;
      }
      return result;
    },
    validateDiscount() {
      const sub_total = toNumber(this.sub_total);
      const discount_value = toNumber(this.discount_value);
      const discount_value_type = toNumber(this.discountValueType);
      if (discount_value_type == 2) {
        if (discount_value >= 100) {
          this.discount_value = 0;
        }
      }
      if (discount_value_type == 1) {
        if (discount_value >= sub_total) {
          this.discount_value = 0;
        }
      }

      if (this.discount_value < 0) {
        this.discount_value = 0;
      }
      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    checkAll(event) {
      if (event) {
        for (let i = this.lineItems.length - 1; i >= 0; i--) {
          this.lineItems[i].status = true;
        }
      } else {
        for (let z = this.lineItems.length - 1; z >= 0; z--) {
          this.lineItems[z].status = false;
        }
      }
      this.validateCheckbox();
    },

    setUpdateData(param) {
      const _this = this;
      _this.productLoading = false;
      for (let i = 0; i < param.length; i++) {
        const {
          id,
          barcode,
          group_name,
          package_id,
          group_me,
          group_active,
          group_primary,
          original_product,
          description,
          quantity,
          optional,
          quantity_type,
          tax,
          discount,
          rate,
          total,
        } = param[i];

        _this.selected_line_items.push({
          id,
          barcode,
          group_name,
          package_id,
          group_me,
          group_active,
          group_primary,
          type: "product",
          product: original_product,
          description,
          quantity,
          optional,
          quantity_type,
          tax: tax ? tax.id : null,
          taxObject: tax ?? null,
          discount,
          rate,
          total,
        });
      }

      if (_this.lodash.isEmpty(_this.details) === false) {
        _this.discountAmount = _this.details.discount_amount;
        _this.taxObject =
          _this.lodash.find(_this.taxes, {
            percentage: _this.details.tax_value,
          }) || {};
        _this.discountValue = _this.details.discount_value;
        _this.taxableAmount = _this.details.taxable_amount;
        _this.adjustmentAmount = _this.details.adjustment;
        _this.taxAmount = _this.details.tax_amount;
        _this.discountValueType = _this.details.discount_value_type;
      }

      _this.update_transaction = true;

      _this.updateItemGroup();
    },

    getQtySuffix(product) {
      if (product) {
        return this.lodash.isEmpty(product.unit) === false
          ? product.unit.name
          : "";
      }
      return "";
    },
    isProduct(product) {
      if (product) {
        if (product.product_type == "goods") {
          return true;
        }
        return false;
      }
      return true;
    },
    canUpdateQty(product) {
      if (product) {
        return true;
      }
      return true;
    },
    initPackageDialog(item) {
      const _this = this;

      const lineFilter = this.lodash.filter(
        _this.selected_line_items,
        function (row) {
          return row.group_me;
        }
      );
      const result = this.lodash.uniq(this.lodash.map(lineFilter, "group_me"));
      const sortedResult = this.lodash.orderBy(result);
      this.packageMe = this.nextAlpha(sortedResult);

      _this.$store
        .dispatch(GET, {
          url: `product/get-package-items/${item.id}`,
        })
        .then(({ data }) => {
          _this.packageId = item.id;
          _this.packageItems = data;
          _this.packageDialog = true;
          _this.item = {};
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateLineItem() {
      const _this = this;
      try {
        const item = _this.lodash.cloneDeep(_this.item);
        if (item.product_type == "package") {
          _this.initPackageDialog(item);
          _this.packageTitle = item.name;
          return false;
        }
        const taxObject =
          _this.lodash.isEmpty(item.tax) === false ? item.tax : null;
        const rate = _this.lodash.toNumber(item.selling_cost);
        _this.selected_line_items.push({
          id: null,
          group_name: null,
          package_id: null,
          group_me: null,
          group_active: false,
          group_primary: false,
          type: "product",
          product: item,
          description: item.description,
          optional: 0,
          quantity: 1,
          quantity_type: null,
          tax: taxObject ? taxObject.id : null,
          taxObject: taxObject ?? null,
          discount: 0.0,
          rate: rate ?? 0.0,
          total: rate ?? 0.0,
        });
        _this.item = {};
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
    updateItemGroup() {
      const _this = this;
      try {
        const groups = [];
        for (let i = 0; i < _this.selected_line_items.length; i++) {
          const { product } = _this.selected_line_items[i];
          let rate = _this.lodash.toNumber(product.selling_cost);

          if (!rate) {
            rate = _this.selected_line_items[i].rate;
          }

          if (
            _this.update_transaction &&
            _this.selected_line_items[i].hasOwnProperty("rate")
          ) {
            rate = _this.selected_line_items[i].rate;
          }

          if (_this.selected_line_items[i].group_me) {
            _this.selected_line_items[i].group_me = _this.selected_line_items[
              i
            ].group_me
              .toString()
              .toUpperCase();
            _this.selected_line_items[i].group_primary = true;
            _this.selected_line_items[i].group_active = true;
            if (
              _this.lodash.includes(
                groups,
                _this.selected_line_items[i].group_me
              )
            ) {
              _this.selected_line_items[i].group_primary = false;
              // _this.lineItems[i].rate = 0.0;
            } else {
              groups.push(_this.selected_line_items[i].group_me);
            }
          } else {
            _this.selected_line_items[i].group_primary = false;
            _this.selected_line_items[i].group_active = false;
            _this.selected_line_items[i].rate = rate;
          }
        }
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
    calculateTotalAmount(index) {
      try {
        const item = this.selected_line_items[index] ?? null;
        if (item) {
          const { rate, quantity, taxObject, discount } = item;
          if (!rate) {
            this.selected_line_items[index].total = 0;
            this.calculateGrandTotal();
            return false;
          }
          if (!quantity) {
            this.selected_line_items[index].total = 0;
            this.calculateGrandTotal();
            return false;
          }
          let subTotal = rate * quantity - (discount || 0);
          let taxAmount = 0;
          if (this.taxType == 2) {
            let taxId =
              this.lodash.isEmpty(taxObject) === false ? taxObject.id : null;
            let taxPercentage =
              this.lodash.isEmpty(taxObject) === false
                ? taxObject.percentage
                : 0;
            if (taxPercentage) {
              taxAmount = (subTotal * taxPercentage) / 100;
            }
            this.selected_line_items[index].tax = taxId;
          }
          this.selected_line_items[index].total = subTotal + taxAmount;
          this.calculateGrandTotal();
        }
      } catch (error) {
        this.logError(error);
        this.removeLineItem(index);
      }
    },
    removeLineItem(index, id) {
      const _this = this;
      if (id > 0) {
        _this.$store
          .dispatch(DELETE, { url: "line-item/" + id })
          .then(() => {
            _this.selected_line_items.splice(index, 1);
            _this.calculateGrandTotal();
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.selected_line_items.splice(index, 1);
        _this.calculateGrandTotal();
      }
    },
    calculateGrandTotal() {
      const _this = this;
      const lineItems = _this.lodash.cloneDeep(_this.selected_line_items);
      const packageLineItems = _this.lodash.cloneDeep(_this.selected_packages);
      let subTotalAmount = 0;
      let subProfitTotalAmount = 0;
      let discountAmount = 0;
      let adjustmentAmount = this.adjustmentAmount ?? 0;
      let taxableAmount = 0;
      let taxAmount = 0;
      let grandTotalAmount = 0;
      let taxPercentage = [];

      for (let i = 0; i < lineItems.length; i++) {
        const { rate, quantity, discount, taxObject, product } =
          _this.lodash.cloneDeep(lineItems[i]);
        const subTotal =
          _this.lodash.round(rate, 2) * _this.lodash.toSafeInteger(quantity);
        subTotalAmount =
          _this.lodash.round(subTotalAmount, 2) +
          _this.lodash.round(subTotal, 2);

        subProfitTotalAmount =
          _this.lodash.round(subProfitTotalAmount, 2) +
          (_this.lodash.round(rate, 2) -
            _this.lodash.round(product.purchase_cost, 2));

        if (_this.discountType === 2) {
          discountAmount =
            _this.lodash.round(discountAmount, 2) +
            _this.lodash.round(discount, 2);
        }

        const taxable = _this.lodash.round(
          _this.lodash.round(subTotal, 2) - _this.lodash.round(discount, 2),
          2
        );

        taxableAmount =
          _this.lodash.round(taxableAmount, 2) + _this.lodash.round(taxable, 2);

        if (_this.taxType == 2 && _this.lodash.isEmpty(taxObject) === false) {
          const { percentage } = taxObject;
          taxPercentage.push(percentage);
          if (percentage && percentage > 0) {
            taxAmount =
              _this.lodash.round(taxAmount, 2) +
              _this.lodash.round(
                _this.lodash.round(_this.lodash.round(percentage, 2) / 100, 2) *
                  _this.lodash.round(taxable, 2),
                2
              );
          }
        }
      }

      for (let i = 0; i < packageLineItems.length; i++) {
        const { rate, quantity, discount, taxObject, product } =
          _this.lodash.cloneDeep(packageLineItems[i]);
        const subTotal =
          _this.lodash.round(rate, 2) * _this.lodash.toSafeInteger(quantity);
        subTotalAmount =
          _this.lodash.round(subTotalAmount, 2) +
          _this.lodash.round(subTotal, 2);

        subProfitTotalAmount =
          _this.lodash.round(subProfitTotalAmount, 2) +
          (_this.lodash.round(rate, 2) -
            _this.lodash.round(product.purchase_cost, 2));

        if (_this.discountType === 2) {
          discountAmount =
            _this.lodash.round(discountAmount, 2) +
            _this.lodash.round(discount, 2);
        }

        const taxable = _this.lodash.round(
          _this.lodash.round(subTotal, 2) - _this.lodash.round(discount, 2),
          2
        );

        taxableAmount =
          _this.lodash.round(taxableAmount, 2) + _this.lodash.round(taxable, 2);

        if (_this.taxType == 2 && _this.lodash.isEmpty(taxObject) === false) {
          const { percentage } = taxObject;
          taxPercentage.push(percentage);
          if (percentage && percentage > 0) {
            taxAmount =
              _this.lodash.round(taxAmount, 2) +
              _this.lodash.round(
                _this.lodash.round(_this.lodash.round(percentage, 2) / 100, 2) *
                  _this.lodash.round(taxable, 2),
                2
              );
          }
        }
      }

      if (_this.discountType === 1) {
        if (_this.discountValue >= 0) {
          if (_this.discountValueType === 1) {
            /* Fixed Amount */
            if (_this.discountValue > _this.subTotalAmount) {
              discountAmount = _this.lodash.round(_this.subTotalAmount, 2);
              _this.discountValue = _this.subTotalAmount;
            } else {
              discountAmount = _this.lodash.round(_this.discountValue, 2);
            }
          } else if (_this.discountValueType === 2) {
            /* Percentage */
            let discountVal = 0;
            if (_this.discountValue > 100) {
              discountVal = 100;
              _this.discountValue = 100;
            } else {
              discountVal = _this.lodash.round(_this.discountValue, 2);
            }
            discountAmount =
              (_this.lodash.round(subTotalAmount, 2) * discountVal) / 100;
          }
        } else {
          _this.discountValue = 0;
        }
        taxableAmount =
          _this.lodash.round(subTotalAmount, 2) -
          _this.lodash.round(discountAmount, 2);
      }

      if (_this.is_taxed) {
        taxAmount =
          _this.lodash.round(8 / 100, 2) * _this.lodash.round(taxableAmount, 2);
      }

      grandTotalAmount =
        _this.lodash.round(
          _this.lodash.round(subTotalAmount, 2) +
            _this.lodash.round(taxAmount, 2) +
            _this.lodash.round(adjustmentAmount, 2),
          2
        ) - _this.lodash.round(discountAmount, 2);

      _this.subProfitTotalAmount = _this.lodash.round(subProfitTotalAmount, 2);
      _this.discountAmount = _this.lodash.round(discountAmount, 2);
      _this.adjustmentAmount = _this.lodash.round(adjustmentAmount, 2);
      _this.subTotalAmount = _this.lodash.round(subTotalAmount, 2);
      _this.taxableAmount = _this.lodash.round(taxableAmount, 2);
      _this.taxAmount = _this.lodash.round(taxAmount, 2);
      _this.grandTotalAmount = _this.lodash.round(grandTotalAmount, 2);

      LineItemCalculationEventBus.$emit("update:line-item-calculation", {
        subTotal: _this.subTotalAmount || 0.0,
        subProfitTotal: _this.subProfitTotalAmount || 0.0,
        discountValue: _this.discountValue || 0.0,
        taxType: _this.taxType || null,
        discountType: _this.discountType || null,
        discountValueType: _this.discountValueType || null,
        discountAmount: _this.discountAmount || 0.0,
        tax: _this.taxObject ? _this.taxObject.id : 0.0,
        tax_value: _this.is_taxed ? 8 : 0.0,
        adjustmentAmount: _this.adjustmentAmount || 0.0,
        totalTax: _this.get_tax_total(0),
        is_taxed: _this.is_taxed,
        total: _this.grandTotalAmount || 0.0,
      });

      let updateLineItem = concat(lineItems, packageLineItems);

      if (this.is_id_person) {
        updateLineItem = filter(updateLineItem, { status: true });
      }

      LineItemEventBus.$emit("update:line-item", updateLineItem);
      this.$emit("update:package", this.selected_packages);
    },
    get_total_without_discount() {
      const _this = this;
      let totalTax = 0;
      if (this.is_taxed) {
        const output = this.get_taxable_amount_total(1);
        totalTax = (this.accountingUnFormat(output) / 100) * 8;
      }
      let total = _this.lodash.round(
        _this.lodash.round(_this.subTotalAmount, 2) +
          _this.lodash.round(totalTax, 2) +
          _this.lodash.round(_this.adjustmentAmount, 2),
        2
      );
      return this.formatMoney(total);
    },
    get_tax_total(type) {
      if (this.is_taxed) {
        const output = this.get_taxable_amount_total(type);
        const tax_amount = (this.accountingUnFormat(output) / 100) * 8;
        return this.formatMoney(tax_amount);
      }

      return this.formatMoney();
    },
    get_taxable_amount_total(type) {
      const total = this.subTotalAmount;
      let discount = 0;
      if (type == 0) {
        discount = this.discountAmount;
      }
      const taxable_amount = total - this.accountingUnFormat(discount);
      return this.formatMoney(taxable_amount);
    },
    getLineItems() {
      const _this = this;
      if (!_this.allowItemSelection) {
        return false;
      }
      const search = _this.serverSearch ?? null;
      _this.serverSearchLoading = true;
      _this.serverItemList = [];
      clearTimeout(_this.searchTimeout);
      _this.searchTimeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/get-line-items",
            data: {
              search,
              customer: _this.customer,
              line_item_type: _this.lineItemType,
            },
          })
          .then(({ data }) => {
            _this.serverItemList = data;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.serverSearchLoading = false;
            _this.productLoading = false;
          });
      }, _this.searchTimeoutLimit);
    },
    getCustomLineItems(products) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (!products) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(QUERY, {
              url: "product/get-line-items",
              data: { products },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getTaxes() {
      const _this = this;
      _this.$store
        .dispatch(GET_TAXES)
        .then((response) => {
          _this.taxes = response.data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    addCustomProduct(param) {
      const _this = this;
      try {
        const { item } = _this.lodash.cloneDeep(param);
        const taxObject =
          _this.lodash.isEmpty(item.tax) === false ? item.tax : null;
        const rate = _this.lodash.toNumber(item.selling_cost);
        _this.selected_line_items.push({
          id: null,
          group_name: null,
          package_id: null,
          group_me: null,
          group_active: false,
          group_primary: false,
          type: "product",
          product: item,
          description: item.description,
          optional: 0,
          quantity: 1,
          quantity_type: null,
          tax: taxObject ? taxObject.id : null,
          taxObject: taxObject ?? null,
          discount: 0.0,
          rate: rate ?? 0.0,
          total: rate ?? 0.0,
        });
        _this.item = {};
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  components: {
    DeleteDialog,
    Dialog,
  },
  mounted() {
    this.getLineItems();
    this.getTaxes();
    const user = currentUser();
    const role_name = ObjectPath.get(user, "role_name");
    if (this.lodash.toLower(role_name) == "admin") {
      this.is_admin = true;
    }
    if (this.lodash.toLower(role_name) == "id") {
      this.is_id_person = true;
    }
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    allowItemSelection() {
      if (this.isSingle && !this.isNewItem) {
        return false;
      }
      return true;
    },
    discountType() {
      return this.lineItemConfig
        ? this.lodash.toSafeInteger(this.lineItemConfig.discount_type)
        : 0;
    },
    taxType() {
      return this.lineItemConfig
        ? this.lodash.toSafeInteger(this.lineItemConfig.tax_type)
        : 0;
    },
    showEngineerRate() {
      return this.lineItemConfig
        ? !!+this.lodash.toSafeInteger(this.lineItemConfig.allow_engineer_rate)
        : false;
    },
    allowAdjustment() {
      return this.lineItemConfig
        ? !!+this.lodash.toSafeInteger(this.lineItemConfig.allow_adjustment)
        : false;
    },
  },
  beforeMount() {
    this.lineItemConfig = Configuration.getLineItemConfig();
  },
};
</script>
