<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'agreement-create'"
    v-if="getPermission('agreement:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Create new Agreement For
        <p
          class="m-0 form-title-create-link pl-2"
          :class="{
            'text-ellipsis width-350px': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject)"
            >mdi-plus-circle-outline</v-icon
          >
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="consentForm()"
        color="cyan"
      >
        Save Agreement
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="agreementForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="consentForm()"
      >
        <v-container fluid v-bar class="create-agreement-vbar">
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    <label class="font-weight-700 font-size-16 required"
                      >Agreement Subject</label
                    >
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Subject"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="agreementCreate.subject"
                      dense
                      filled
                      label="Subject"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          agreementCreate.subject,
                          'Agreement Subject'
                        ),
                        validateRules.minLength(
                          agreementCreate.subject,
                          'Agreement Subject',
                          1
                        ),
                        validateRules.maxLength(
                          agreementCreate.subject,
                          'Agreement Subject',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="agreementCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          agreementCreate.reference,
                          'Reference #',
                          1
                        ),
                        validateRules.maxLength(
                          agreementCreate.reference,
                          'Reference #',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Project</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Name"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="projectList"
                      label="Project"
                      solo
                      flat
                      :disabled="pageLoading || !!+$route.query.project"
                      :loading="pageLoading"
                      hide-details
                      item-text="name"
                      item-value="id"
                      v-model.trim="agreementCreate.project"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }} -
                            {{ item.name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'Start Date'"
                      v-model="agreementCreate.start_date"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">End Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'End Date'"
                      v-model="agreementCreate.end_date"
                      :defaultDate.sync="defaultEndDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Agreement number
                  </td>
                  <td class="font-weight-700 font-size-16 pb-4" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model.trim="agreementCreate.barcode"
                        label="Agreement number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                  <td class="font-size-16 pb-4">
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <!--  <tr>
                  <td class="font-size-16 pb-2" width="200">
                    <label class="font-weight-700 font-size-16 required"
                      >Agreement Type</label
                    >
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Agreement Type"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="agreementTypeList"
                      label="Agreement Type"
                      solo
                      flat
                      hide-details
                      item-text="text"
                      item-value="value"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageAgreementTypeDialog = true"
                      v-model.trim="agreementCreate.agreement_type"
                      :rules="[
                        validateRules.required(
                          agreementCreate.agreement_type,
                          'Agreement Type'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Agreement Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>-->
                <tr>
                  <td class="font-size-16 pb-2" width="200">Agreement Value</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Agreement Value"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="agreementCreate.agreement_value"
                      dense
                      filled
                      label="Agreement Value"
                      solo
                      flat
                      hide-details
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-usd"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="
                        limitDecimal($event, agreementCreate.agreement_value)
                      "
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
              v-if="
                agreementCreate.customer && getPermission('line-item:create')
              "
            >
              <v-container fluid class="px-0">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <AgreementLineItem
                          isAgreement
                          :update-data="updateLineItem"
                          :customer.sync="agreementCreate.customer"
                          :pageLoading.sync="pageLoading"
                        ></AgreementLineItem>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="agreementCreate.description" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <ManageAgreementType
        :commonDialog.sync="manageAgreementTypeDialog"
        :agreementType.sync="agreementTypeList"
        v-on:close-dialog="manageAgreementTypeDialog = false"
      ></ManageAgreementType>
      <Consent
        :company-name="companyName"
        :customer-name="customerName"
        :consent-dialog="consentDialog"
        v-on:submit="updateOrCreate"
        v-on:close="consentDialog = false"
      ></Consent>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="agreement"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="
          getOptions(['barcode_setting', 'barcode', 'project_list'])
        "
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Consent from "@/view/pages/agreement/Create-Or-Update/Consent";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import ManageAgreementType from "@/view/pages/partials/Manage-Agreement-Type.vue";
import moment from "moment-timezone";
import AgreementLineItem from "@/view/pages/partials/Line-Item.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "agreement-create",
  title: "Create Agreement",
  data() {
    return {
      agreement: null,
      companyName: null,
      customerName: null,
      pageLoading: false,
      barcodeDialog: false,
      consentDialog: false,
      manageAgreementTypeDialog: false,
      barcodeSetting: new Object(),
      customerObject: new Object(),
      agreementTypeList: new Array(),
      projectList: new Array(),
      updateLineItem: new Array(),
      lineItem: new Array(),
      lineItemCalculation: new Object(),
      agreementCreate: new Object({
        reference: null,
        customer: null,
        quotation: null,
        project: null,
        subject: null,
        agreement_value: null,
        //  agreement_type: null,
        description: null,
        start_date: null,
        end_date: null,
        status: null,
        attachments: [],
      }),
    };
  },
  components: {
    Consent,
    DatePicker,
    FileTemplate,
    BarcodeSetting,
    AgreementLineItem,
    ManageAgreementType,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.agreementCreate.attachments = param;
    },
    getOptions(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "agreements/options",
          data: { attributes, customer: _this.agreementCreate.customer },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.customer) === false) {
            _this.customerObject = data.customer;
            _this.customerName = data.customer.display_name;
          }
          if (_this.lodash.isEmpty(data.barcode_setting) === false) {
            _this.barcodeSetting = data.barcode_setting;
          }
          if (_this.lodash.isEmpty(data.barcode) === false) {
            _this.agreementCreate.barcode = data.barcode;
          }
          if (_this.lodash.isEmpty(data.agreement_type) === false) {
            _this.agreementTypeList = data.agreement_type;
          }
          if (_this.lodash.isEmpty(data.project_list) === false) {
            _this.projectList = data.project_list;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.manageAgreementTypeDialog = false;
        });
    },
    createLineItems() {
      return new Promise((resolve, reject) => {
        try {
          const _this = this;
          let lineItemFormData = new Array();
          for (let i = 0; i < _this.lineItem.length; i++) {
            lineItemFormData.push({
              id: _this.lodash.toSafeInteger(_this.lineItem[i].id) || null,
              package_id: _this.lineItem[i].package_id,
              group_name: _this.lineItem[i].group_name,
              group_me: _this.lineItem[i].group_me,
              group_primary: _this.lineItem[i].group_primary,
              product:
                _this.lodash.toSafeInteger(_this.lineItem[i].product_id) ||
                null,
              tax: _this.lineItem[i].tax,
              product_type: _this.lineItem[i].product_type,
              description: _this.lineItem[i].description,
              quantity: _this.lineItem[i].quantity,
              quantity_type: _this.lineItem[i].quantity_type,
              discount: _this.lineItem[i].discount,
              rate: _this.lineItem[i].rate,
            });
          }

          const formData = {
            agreement: _this.agreement,
            line_items: lineItemFormData,
          };

          _this.formLoading = true;
          _this.$store.dispatch(CLEAR_ERROR, {});
          _this.$store
            .dispatch(POST, {
              url: "line-item",
              data: formData,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    updateOrCreate(consent) {
      const _this = this;

      const validateStatus = _this.$refs.agreementForm.validate();

      const formErrors = _this.validateForm(_this.$refs.agreementForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      let validateLineItem = _this.lodash.compact(
        _this.lodash.map(_this.lineItem, function (row) {
          return row.product;
        })
      );

      if (_this.lodash.isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service/package then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.agreementCreate.reference || null,
        customer: _this.agreementCreate.customer || null,
        quotation: _this.agreementCreate.quotation || null,
        project: _this.agreementCreate.project || null,
        subject: _this.agreementCreate.subject || null,
        agreement_value: _this.agreementCreate.agreement_value || null,
        //  agreement_type: _this.agreementCreate.agreement_type || null,
        description: _this.agreementCreate.description || null,
        start_date: _this.agreementCreate.start_date || null,
        end_date: _this.agreementCreate.end_date || null,
        status: _this.agreementCreate.status || null,
        attachments: _this.agreementCreate.attachments || [],
        tax: _this.lineItemCalculation.tax || null,
        tax_type: _this.lineItemCalculation.taxType || null,
        discount_value: _this.lineItemCalculation.discountValue || null,
        adjustment: _this.lineItemCalculation.adjustmentAmount || null,
        discount_type: _this.lineItemCalculation.discountType || null,
        discount_value_type:
          _this.lineItemCalculation.discountValueType || null,
        consent,
      });

      _this.$store
        .dispatch(POST, {
          url: "agreements",
          data: formData,
        })
        .then(({ data }) => {
          _this.agreement = _this.lodash.toSafeInteger(data.id);
          if (_this.getPermission("line-item:create")) {
            _this
              .createLineItems()
              .then(() => {
                _this.backForce = true;
                _this.$router.push(
                  _this.getDefaultRoute("agreement.detail", {
                    params: { id: _this.agreement },
                  })
                );
              })
              .catch((error) => {
                _this.backForce = false;
                _this.logError(error);
              });
          } else {
            _this.backForce = true;
            _this.$router.push(
              _this.getDefaultRoute("agreement.detail", {
                params: { id: _this.agreement },
              })
            );
          }
        })
        .catch((error) => {
          _this.backForce = false;
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getQuotation() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "quotation/" + _this.agreementCreate.quotation })
        .then(({ data }) => {
          _this.agreementCreate.reference = data.barcode;
          _this.agreementCreate.customer = data.customer.customer;
          _this.agreementCreate.quotation = data.id;
          _this.agreementCreate.project = data.project ? data.project.id : null;
          _this.agreementCreate.subject = data.quotation_title;
          _this.agreementCreate.agreement_value = data.total;
          _this.agreementCreate.description = data.description;
          _this.getLineItems();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    consentForm() {
      const _this = this;

      const validateStatus = _this.$refs.agreementForm.validate();

      const formErrors = _this.validateForm(_this.$refs.agreementForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      let validateLineItem = _this.lodash.compact(
        _this.lodash.map(_this.lineItem, function (row) {
          return row.product;
        })
      );

      if (_this.lodash.isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service/package then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.consentDialog = true;
    },
    getLineItems() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item",
          data: { quotation: _this.agreementCreate.quotation },
        })
        .then(({ data }) => {
          _this.updateLineItem = data.rows.map((row) => {
            return {
              id: null,
              original_product: row.original_product,
              description: row.description,
              quantity: row.quantity,
              quantity_type: row.quantity_type,
              group_me: row.group_me,
              group_active: row.group_active,
              group_primary: row.group_primary,
              tax: row.tax,
              rate: row.rate,
              total: row.total,
            };
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  created() {
    const _this = this;
    _this.agreementCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );

    if (_this.agreementCreate.customer <= 0) {
      _this.goBack();
    }

    _this.agreementCreate.project = _this.lodash.toSafeInteger(
      _this.$route.query.project
    );

    _this.agreementCreate.quotation = _this.lodash.toSafeInteger(
      _this.$route.query.quotation
    );

    if (_this.agreementCreate.quotation) {
      _this.getQuotation();
    }

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );
  },
  mounted() {
    const _this = this;
    _this.getOptions([
      "customer",
      "barcode_setting",
      "barcode",
      //"agreement_type",
      "project_list",
    ]);

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agreement", route: "agreement" },
      { title: "Create" },
    ]);
  },
  computed: {
    defaultEndDate() {
      return moment().add(30, "days").format("YYYY-MM-DD");
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
