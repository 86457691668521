<template>
  <!--begin::Calendar-->
  <div class="resource-calendar-template" v-if="getPermission('calendar:view')">
    <DetailTemplate customSecondClass="pt-0">
      <template v-slot:header-title>
        <h3 class="custom-header-blue-text font-size-28 text-uppercase">
          Resource Calendar
        </h3>
      </template>
      <template v-slot:header-action>
        <v-btn-toggle>
          <v-btn
            class="custom-bold-button my-2"
            v-on:click="$router.push(getDefaultRoute('calendar'))"
          >
            Calendar
          </v-btn>
          <v-btn class="custom-bold-button my-2" color="cyan white--text">
            Resource
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <div v-if="memberLoading" class="block-ui">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="8"
              color="cyan"
            ></v-progress-circular>
          </div>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 75vh; position: static"
          >
            <v-row>
              <v-col md="12">
                <FullCalendar
                  ref="resourceCalendar"
                  :options="calendarOptions"
                />
              </v-col>
            </v-row>
          </perfect-scrollbar>
        </v-container>
        <Dialog :commonDialog.sync="eventResizedDialog">
          <template v-slot:title> Confirmation </template>
          <template v-slot:body> // </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="rescheduleVisit"
            >
              Yes
            </v-btn>
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="closeEventResized"
            >
              No
            </v-btn>
          </template>
        </Dialog>
        <Dialog
          :commonDialog.sync="eventDroppedDialog"
          :dialogWidth.sync="dialogWidth"
        >
          <template v-slot:title> Confirmation </template>
          <template v-slot:body> // </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="reAllocateMember"
            >
              Yes
            </v-btn>
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="closeEventDropped"
            >
              No
            </v-btn>
          </template>
        </Dialog>
      </template>
    </DetailTemplate>
  </div>
  <!--end::Calendar-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import FullCalendar from "@fullcalendar/vue";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
// import Datepicker from "@/view/pages/partials/Datepicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "resource-calendar",
  mixins: [CommonMixin],
  components: {
    DetailTemplate,
    FullCalendar,
    Dialog,
    // DateRangePicker
    // Datepicker
  },
  data() {
    return {
      memberLoading: false,
      eventResizedDialog: false,
      eventDroppedDialog: false,
      eventClickedDialog: false,
      pageLoading: false,
      calendar: null,
      calendarOptions: {
        plugins: [resourceTimelinePlugin, interactionPlugin],
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
          left: "prev today next",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimelineThreeDay,resourceTimelineWeek,resourceTimelineFifteenDay,resourceTimelineThirtyDay",
        },
        aspectRatio: 2.2,
        initialView: "resourceTimelineDay",
        views: {
          resourceTimelineThreeDay: {
            type: "resourceTimeline",
            duration: { days: 3 },
            buttonText: "3 days",
          },
          resourceTimelineFifteenDay: {
            type: "resourceTimeline",
            duration: { days: 15 },
            buttonText: "15 days",
          },
          resourceTimelineThirtyDay: {
            type: "resourceTimeline",
            duration: { days: 30 },
            buttonText: "30 days",
          },
        },
        initialDate: moment().startOf("day").format("YYYY-MM-DD"),
        timeZone: process.env.VUE_APP_TIMEZONE,
        scrollTime: "08:00",
        editable: false,
        selectable: false,
        droppable: false,
        nowIndicator: true,
        eventResize: this.eventResized,
        eventDrop: this.eventDropped,
        eventClick: this.eventClicked,
        drop: this.dropped,
        events: this.getCalendarEvents,
        datesSet: this.dateChanged,
        resourceAreaWidth: "20%",
        resourceAreaColumns: [
          {
            headerContent: "Member Name",
            field: "title",
          },
        ],
        resources: this.getResources,
      },
      resizedEvent: {},
      droppedEvent: {},
    };
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [{ title: "Resource Calendar" }]);
    _this.calendar = this.$refs["resourceCalendar"].getApi();
    /*if (_this.getPermission("calendar:update")) {
      _this.calendar.setOption("editable", true);
      _this.calendar.setOption("selectable", true);
      _this.calendar.setOption("droppable", true);
    }*/
  },
  beforeDestroy() {
    this.calendar.destroy();
  },
  methods: {
    filterDateRange() {},
    loadContent() {},
    updateFilterDate() {},
    eventResized() {},
    rescheduleVisit() {},
    reAllocateMember() {},
    closeEventResized() {
      /*this.eventResizedDialog = false;
      if (this.lodash.isEmpty(this.resizedEvent) === false) {
        this.resizedEvent.revert();
        this.resizedEvent = new Object({});
      }*/
    },
    eventDropped() {
      /*try {
        this.droppedEvent = eventDropped;
        this.eventDroppedDialog = true;
      } catch (error) {
        this.logError(error);
        eventDropped.revert();
      }*/
    },
    closeEventDropped() {
      /*this.eventDroppedDialog = false;
      if (this.lodash.isEmpty(this.droppedEvent) === false) {
        this.droppedEvent.revert();
        this.droppedEvent = new Object({});
      }*/
    },
    eventClicked() {},
    dateChanged() {
      this.reloadCalendar();
    },
    dropped() {},
    reloadCalendar() {
      try {
        if (this.calendar) {
          this.calendar.removeAllEvents();
          this.calendar.refetchResources();
          this.calendar.refetchEvents();
        }
      } catch (error) {
        this.logError(error);
        window.location.reload(true);
      }
    },
    getCalendarEvents(fetchInfo, successCallback, failureCallback) {
      const _this = this;
      _this.memberLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "calendar/all-resource-tasks",
        })
        .then(({ data }) => {
          successCallback(data);
        })
        .catch((error) => {
          failureCallback(error);
        })
        .finally(() => {
          _this.memberLoading = false;
        });
    },
    getResources(fetchInfo, successCallback, failureCallback) {
      const _this = this;
      _this.memberLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "calendar/all-members",
        })
        .then(({ data }) => {
          successCallback(data);
        })
        .catch((error) => {
          failureCallback(error);
        })
        .finally(() => {
          _this.memberLoading = false;
        });
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
