<template>
  <Dialog :commonDialog.sync="commonDialog" :dialogWidth.sync="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex> Manage Lead Statuses </v-flex>
        <v-flex class="text-right">
          <v-btn small class="mx-4" v-on:click="createStatus()">
            <span class="font-size-16 font-weight-600">Add Status</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-container class="py-0">
        <v-row class="py-0">
          <v-col md="12" class="py-0">
            <table class="width-100">
              <tbody>
                <tr>
                  <td
                    align="center"
                    class="px-4 font-size-16 font-weight-600"
                    width="10%"
                  >
                    Color
                  </td>
                  <td
                    align="center"
                    class="px-4 font-size-16 font-weight-600"
                    width="15%"
                  >
                    Text Color
                  </td>
                  <td class="px-4 font-size-16 font-weight-600">Text</td>
                  <td
                    class="px-4 font-size-16 font-weight-600"
                    width="10%"
                  ></td>
                </tr>
                <tr v-for="(type, index) in statusesList" :key="index">
                  <td align="center" class="px-4">
                    <v-menu>
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              :color="type.color"
                              text
                              v-bind="attrs"
                              v-on="{ ...tooltip, ...menu }"
                              style="background-color: #f5f5f5"
                            >
                              <v-icon large>mdi-record</v-icon>
                            </v-btn>
                          </template>
                          <span>Status Color</span>
                        </v-tooltip>
                      </template>
                      <div>
                        <v-color-picker
                          class="ma-2"
                          show-swatches
                          hide-canvas
                          hide-inputs
                          hide-mode-switch
                          hide-sliders
                          v-model="type.color"
                          v-on:update:color="
                            update_color($event, 'color', index)
                          "
                          swatches-max-height="300px"
                        ></v-color-picker>
                      </div>
                    </v-menu>
                  </td>
                  <td align="center" class="px-4">
                    <v-menu>
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              :color="type.textcolor"
                              text
                              v-bind="attrs"
                              v-on="{ ...tooltip, ...menu }"
                              style="background-color: #f5f5f5"
                            >
                              <v-icon large>mdi-record</v-icon>
                            </v-btn>
                          </template>
                          <span>Status Text Color</span>
                        </v-tooltip>
                      </template>
                      <div>
                        <v-color-picker
                          class="ma-2"
                          show-swatches
                          hide-canvas
                          hide-inputs
                          hide-mode-switch
                          hide-sliders
                          v-model="type.textcolor"
                          v-on:update:color="
                            update_color($event, 'textcolor', index)
                          "
                          swatches-max-height="300px"
                        ></v-color-picker>
                      </div>
                    </v-menu>
                  </td>
                  <td>
                    <v-text-field
                      ref="status"
                      v-model.trim="type.text"
                      dense
                      filled
                      label="Status"
                      solo
                      flat
                      class="px-4 py-2"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                  <td width="50">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on:click="deleteStatus(index)"
                          color="deep-orange"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Click here to delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateStatusSetting()"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PATCH } from "@/core/services/store/request.module";

export default {
  props: {
    commonDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    statuses: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
  },
  watch: {
    statuses: {
      deep: true,
      immediate: true,
      handler() {
        this.init();
      },
    },
    commonDialog(param) {
      if (param) {
        this.init();
      }
    },
  },
  data: () => {
    return {
      pageLoading: false,
      statusesList: [
        {
          color: null,
          text_color: null,
          text: null,
        },
      ],
    };
  },
  methods: {
    update_color(param, type, index) {
      this.statusesList[index][type] = param.hex;
    },
    init() {
      console.log({ statuses: this.statuses });
      const output = this.lodash.filter(this.statuses, function (row) {
        return row.value > 0;
      });
      this.statusesList = output;
    },
    updateStatusSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "lead/status",
          data: { status: _this.statusesList },
        })
        .then(() => {
          _this.$emit("close-dialog", true);
          _this.$emit("get-lead-status", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createStatus() {
      this.statusesList.push({
        text: null,
      });
      this.$nextTick(() => {
        const lastRef = this.lodash.last(this.$refs.status);
        if (lastRef && lastRef.$refs) {
          lastRef.$refs.input.focus();
        }
      });
    },
    deleteStatus(index) {
      if (this.statusesList.length > 1) {
        this.statusesList.splice(index, 1);
      }
    },
  },
  components: {
    Dialog,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
