<template>
  <v-col v-if="isActive && getPermission('attachment:view')" class="p-4">
    <div class="d-none">
      <v-text-field
        type="hidden"
        v-model="isUploading"
        :rules="[validateRules.required(!isUploading)]"
      ></v-text-field>
    </div>
    <v-row>
      <v-col lg="6" md="6" class="pt-0 pl-0">
        <template v-if="!documentArray.file">
          <lable
            class="font-weight-700 font-size-16"
            style="margin: 10px"
            v-if="label"
            >Image/File</lable
          >
          <v-file-input
            prepend-inner-icon="mdi-attachment mdi-rotate-90"
            placeholder="Click here to select file"
            label="Click here to select file"
            prepend-icon=""
            color="cyan"
            solo
            flat
            class="mt-2"
            v-model="documentArray.doc_file"
            dense
            :accept="onlyImage ? 'image/png, image/jpeg, image/bmp' : '*'"
            v-on:change="(file) => uploadFile(file)"
            :rules="[
              validateRules.file_required(documentArray.document, 'File'),
            ]"
            hide-details
          ></v-file-input>
        </template>
        <template v-else>
          <lable
            v-if="label"
            class="font-weight-700 font-size-16"
            style="margin: 10px"
            >Image/File</lable
          >
          <v-text-field
            dense
            solo
            flat
            readonly
            color="cyan"
            v-model="documentArray.name"
            hide-details
            class="has-delete-outer-icon cursor-pointer mt-2"
            prepend-inner-icon="mdi-attachment"
            v-on:click="openFile(documentArray)"
          ></v-text-field>
        </template>
      </v-col>
      <v-col lg="6" md="6" class="pt-0 pr-0">
        <template v-if="documentArray.doc_file">
          <lable
            v-if="label"
            class="font-weight-700 font-size-16 mb-1"
            style="margin: 10px"
            >File Name</lable
          >
          <v-text-field
            placeholder="File Name"
            label="File Name"
            dense
            solo
            flat
            :readonly="!allowUpload"
            class="mt-2"
            color="cyan"
            v-model="documentArray.name"
            hide-details
            :rules="[validateRules.required(documentArray.name, 'File Name')]"
          ></v-text-field>
        </template>
        <template v-else>
          <lable
            v-if="label"
            class="font-weight-700 font-size-16"
            style="margin: 10px"
            >File Name</lable
          >
          <v-text-field
            placeholder="File Name"
            label="File Name"
            dense
            solo
            flat
            class="mt-2"
            :readonly="!allowUpload"
            color="cyan"
            v-model="documentArray.name"
            hide-details
            :rules="[validateRules.required(documentArray.name, 'File Name')]"
          ></v-text-field>
        </template>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [FileManagerMixin, ValidationMixin],
  props: {
    attachments: {
      type: Array,
      default() {
        return new Array();
      },
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    label: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    onlyImage: {
      type: Boolean,
      default: false,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowUpload: {
      type: Boolean,
      default: false,
    },
    isMinDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUploading: false,
      has_attachment: 0,
      documentArray: {},
    };
  },
  beforeMount() {
    this.documentArray = {
      document: null,
      name: null,
      add: null,
    };
  },
  watch: {
    documentArray: {
      deep: true,
      immediate: true,
      handler(param) {
        this.updateFiles(param);
      },
    },
    attachments: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 0) {
          this.documentArray = param;
          this.has_attachment = 1;
        }
      },
    },
  },
  mounted() {
    if (this.lodash.isEmpty(this.attachments) === false) {
      this.documentArray = this.attachments;
    }
  },
  methods: {
    openFile(row) {
      window.open(row.file.url, "_blank");
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    init_attachment() {
      this.documentArray = [];
    },
    isValidImage(file_url) {
      try {
        const _this = this;
        let validImageExtension = ["png", "jpg", "jpeg"];
        let fileExtention = _this.lodash.last(file_url.split("."));
        return _this.lodash.includes(
          validImageExtension,
          fileExtention.toLowerCase()
        );
      } catch (error) {
        this.logError(error);
        return false;
      }
    },
    updateFiles(param) {
      console.log(param);
      let filesArray = [];
      if (this.lodash.isEmpty(param) === false) {
        filesArray.push({
          document: param && param.document ? param.document.id : null,
          name: param.name,
        });
      }
      if (this.lodash.isEmpty(filesArray) === false) {
        this.$emit("file:updated", filesArray);
      }
    },
    uploadFile(file) {
      this.isUploading = true;
      this.uploadAttachment(file)
        .then((response) => {
          this.documentArray.doc_file = file;
          this.documentArray.document = response[0];
          this.documentArray.name = response[0].file.name;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
  },
  computed: {
    isActive() {
      return (
        this.allowUpload || this.lodash.isEmpty(this.documentArray) === false
      );
    },
  },
};
</script>
