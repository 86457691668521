import { v4 as uuidv4 } from 'uuid'
import axios from "axios";

const prepareFormData = (file, fields) => {
  const formData = new FormData()

  Object.entries(fields).forEach(([key, value]) => formData.append(key, value))

  const fileName = `${uuidv4()}-${Date.now()}.${getFileExtension(file)}`

  formData.append('file', new File([file], fileName, { type: file.type }))

  return formData
}

const getFileExtension = file => {
  const fileName = typeof file === 'string' ? file : file.name

  return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
}

const getWebkitRelativePath = filePath => {
  const pathParts = filePath.split('/')

  pathParts.pop()

  return pathParts.join('/')
}

const uploadSingleFile = async (data, url, fields) => {
  let file = data.accepted_file;
  const formData = prepareFormData(file, fields)

  await fetch(url, {
    method: "POST",
    body: formData,
  });
  const fileName = formData.get('file').name
  const path = fields['key']?.replace('${filename}', fileName)
  const fileUrl = `${url}/${path}`

  return {
    update_name: data.file_name,
    name: file.name,
    url: fileUrl,
    file_path: path,
    size: file.size,
    mime: file.type,
    extension: getFileExtension(file),
    path: getWebkitRelativePath(file.webkitRelativePath),
  }
}

const fetchS3UploadUrl = async () => {
  const response = await axios.get('s3-upload-url');
  const { data } = response;
  return data.data;

};

export const uploadFilesToS3 = async files => {
  const filteredFiles = files.filter(item => item.accepted_file instanceof File);

  if (!filteredFiles.length) return false

  const { url, fields } = await fetchS3UploadUrl()
  const uploadPromises = filteredFiles.map(file => {
    return uploadSingleFile(file, url, fields);
  });

  return Promise.all(uploadPromises)
}

