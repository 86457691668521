<template>
  <v-card class="mx-auto custom-product-hover-info" max-width="600">
    <div class="product-hover-detail font-size-16 gray-background p-6">
      <table class="width-100">
        <thead>
          <tr class="cursor-pointer" v-on:click="pushToDetail">
            <td colspan="3" class="font-size-18">{{ product.name }}</td>
            <td align="right">
              <v-icon right color="white">mdi-chevron-right</v-icon>
            </td>
          </tr>
        </thead>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          tag="tbody"
          style="max-height: 20vh; position: static"
        >
          <tr>
            <td class="pt-2">Service #</td>
            <td class="pt-2">
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.barcode ? product.barcode : " - " }}
              </p>
            </td>
          </tr>
          <tr v-if="false">
            <td>ISBN</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.isbn ? product.isbn : " - " }}
              </p>
            </td>
            <td>Taxable</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.is_taxable ? "Yes" : "No" }}
              </p>
            </td>
          </tr>
          <tr>
            <td>Category</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.main_category ? product.main_category.text : " - " }}
              </p>
            </td>
            <td>Sub Category</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.sub_category ? product.sub_category.text : " - " }}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <template v-if="product.product_type == 'service'"
                >Price</template
              >
            </td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatMoney(product.charges) }}
              </p>
            </td>
            <td>
              <template v-if="product.product_type == 'service'"
                >Supplier Cost</template
              >
            </td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatMoney(product.company_cost) }}
              </p>
            </td>
          </tr>
        </perfect-scrollbar>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  methods: {
    pushToDetail() {
      /*if (this.product.product_type == "goods") {
        this.$router.push(
          this.getDefaultRoute("product.detail", {
            params: { id: this.product.id }
          })
        );
      } else if (this.product.product_type == "service") {
        this.$router.push(
          this.getDefaultRoute("service.detail", {
            params: { id: this.product.id }
          })
        );
      }*/
    },
  },
};
</script>
