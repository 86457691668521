<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'agreement-create'"
    v-if="getPermission('agreement:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Create new Agreement For
        <p
          class="m-0 form-title-create-link pl-2"
          :class="{
            'text-ellipsis width-350px': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject)"
            >mdi-plus-circle-outline</v-icon
          >
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate()"
        color="cyan"
      >
        Save Agreement
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid v-bar class="create-agreement-vbar resident-agreement">
        <v-form
          ref="agreementForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate()"
        >
          <v-container fluid class="white-background agreement">
            <v-row>
              <v-col offset-md="1" md="10">
                <p class="custom-border-bottom-black width-50 font-weight-700">
                  {{ agreementTitle }}
                </p>
                <div class="d-flex">
                  本人 I
                  <v-text-field
                    dense
                    filled
                    solo
                    flat
                    v-model="attributes.name"
                    class="border-bottom"
                    hide-details
                    color="cyan"
                  >
                  </v-text-field>
                  , 护照号码 Passport /FIN Number :
                  <v-text-field
                    dense
                    filled
                    solo
                    flat
                    v-model="attributes.passport"
                    class="border-bottom"
                    hide-details
                    color="cyan"
                  >
                  </v-text-field
                  >,
                </div>
                <div class="d-flex">
                  公司名字 Company Name：
                  <v-text-field
                    dense
                    filled
                    solo
                    flat
                    v-model="attributes.company"
                    class="border-bottom"
                    hide-details
                    color="cyan"
                  >
                  </v-text-field>
                </div>
                <div class="d-flex">
                  住宿地址 Address：
                  <v-text-field
                    dense
                    filled
                    solo
                    flat
                    v-model="attributes.address"
                    class="border-bottom"
                    hide-details
                    color="cyan"
                  >
                  </v-text-field>
                </div>
                <p class="pt-4 m-0">
                  清楚了解详细资讯如下 hereby acknowledge and understand that:
                </p>
                <p class="pt-2 m-0">
                  1.
                  上班地点与住宿的距离和时间，不得以此为由，出现迟到的问题。<br />The
                  travelling distance and time required from Site Address to
                  Working Place.
                </p>
                <p class="my-4">
                  2.
                  住宿地址为合法并且可以登记在新加坡房屋局（HDB）以及人力部，如果因为无法登记地址或所提
                  供的地址不属实而超过 14
                  天有效期（自入境当天开始），本人将自行承担因无法登记地址而造成在新
                  加坡不能正常办理准证卡以及新加坡政府部门给予的一切处罚。<br />
                  The renovation address provided is legal and able to register
                  in Singapore HDB &amp; MOM. I understand that the address must
                  be registered in MOM within 14 days (start from the arrival
                  date) else I will bear all consequences from relevant
                  authority.
                </p>
                <p class="my-4">
                  3. 所居住房屋必须符合新加坡房屋局（HDB）的要求:
                  任何规格的政府租屋，公寓或任何私人住宅，房
                  东与租客人员总数不得超过 6 人。入境新加坡当天，新加坡中介
                  Inter Island Manpower Pte Ltd 将会去
                  到住宿检查及拍照，如果经过新加坡中介检查到房屋有任何不合法的地方，当天必须更换到新加坡中
                  介 Inter Island Manpower Pte Ltd
                  所找的房子，费用要全数缴纳。<br />
                  The renovation address must meet the requirement of HDB: The
                  maximum occupants in HDB, Condo or any private property must
                  not exceed 6 pax. Inter Island Manpower Pte Ltd will conduct
                  inspection and photo taking on the arrival date, if the
                  renovation address does not meet the Singapore Government
                  regulations, I shall appointed Inter Island Manpower Pte Ltd
                  to source for legal accommodation and bear all the costs.
                </p>
                <p class="my-4">
                  本人在此声明以上的资料全部属实并在此授权新加坡中介公司 Inter
                  Island Manpower Pte Ltd 登记地址
                  如有虚假或不合法，新加坡中介公司 Inter Island Manpower Pte Ltd
                  将会如实呈报给雇主公司，本人将
                  接受人力部和雇主公司给予的任何处分，新加坡中介公司 Inter
                  Island Manpower Pte Ltd 以及雇主公司 将不承担任何责任。<br />
                  I hereby acknowledged that the information provided stated
                  above are true and legit and authorized Inter Island Manpower
                  Pte Ltd to update address on behalf. Inter Island Manpower Pte
                  Ltd will report to employing company and Ministry of Manpower
                  if any false information provided, I will bear all
                  consequences from relevant authority, Inter Island Manpower
                  Pte Ltd and employing company would not be held responsible
                  for any matters arising from my accommodation issues.
                </p>
                <p
                  class="mt-6 link d-flex"
                  :class="{ 'mb-20': !signature.owner }"
                  v-on:click="signaturePopup('owner')"
                >
                  Signature/ Date 签名/日期:
                  <v-img
                    v-if="signature.owner"
                    contain
                    max-height="100"
                    max-width="200"
                    class="ml-4"
                    :src="signature.owner"
                  ></v-img>
                  <em v-else class="ml-6">click here to sign</em>
                </p>
                <p class="my-4 d-flex width-50">
                  Contact No 电话号码:
                  <v-text-field
                    dense
                    filled
                    solo
                    flat
                    v-model="attributes.contact"
                    class="border-bottom"
                    hide-details
                    color="cyan"
                  >
                  </v-text-field>
                </p>
                <p
                  class="my-4 font-weight-700 custom-border-bottom-black width-30"
                >
                  Verified by Operation/Sales Person
                </p>
                <div class="my-4 d-flex">
                  Is the accommodation sourced by Inter Island Manpower?
                  <v-btn-toggle class="ml-6 m-0" v-model="attributes.manpower">
                    <v-btn value="yes"> Yes </v-btn>
                    <v-btn value="no"> No </v-btn>
                  </v-btn-toggle>
                </div>
                <v-layout>
                  <v-flex md6>
                    <p class="my-4 d-flex">
                      Name:
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        v-model="attributes.sales_name"
                        class="border-bottom"
                        hide-details
                        color="cyan"
                      >
                      </v-text-field>
                    </p>
                  </v-flex>
                  <v-flex
                    md6
                    class="link d-flex"
                    v-on:click="signaturePopup('company')"
                  >
                    Signature / Date:
                    <v-img
                      v-if="signature.company"
                      contain
                      max-height="100"
                      max-width="200"
                      :src="signature.company"
                    ></v-img>
                    <em v-else class="ml-6">click here to sign</em>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
            <Dialog :commonDialog.sync="signatureDialog">
              <template v-slot:title> Signature </template>
              <template v-slot:body>
                <v-row>
                  <v-col md="10" class="py-0" offset-md="1">
                    <div
                      id="signature-pad"
                      ref="signature_div"
                      class="custom-border-grey-dashed"
                    >
                      <canvas ref="signature"></canvas>
                    </div>
                  </v-col>
                  <v-col md="10" class="py-0" offset-md="1">
                    <p class="float-right m-0">
                      <v-btn
                        content="Click here to clear signature"
                        v-tippy="{ arrow: true, placement: 'top' }"
                        icon
                        small
                        class="float-right"
                        v-on:click="signatureRef.clear()"
                      >
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </p>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:action>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  v-on:click="signatureSave()"
                >
                  Submit
                </v-btn>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="signatureClose()"
                >
                  Cancel
                </v-btn>
              </template>
            </Dialog>
          </v-container>
        </v-form>
      </v-container>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import moment from "moment-timezone";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  CLEAR_ERROR,
  QUERY,
  POST,
  PUT,
  GET,
} from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      formValid: true,
      consentDialog: false,
      formLoading: false,
      pageLoading: false,
      customerObject: {},
      quotationObject: {},
      attributes: {
        name: null,
        passport: null,
        company: null,
        address: null,
        manpower: "yes",
        sales_name: null,
        contact: null,
      },
      signature: {
        owner: null,
        company: null,
      },
      todayDate: new Date(),
      signatureRef: null,
      signatureType: null,
      signatureDialog: false,
      agreement: null,
      customerId: null,
      quotationId: null,
      agreementId: null,
      agreementTitle: "住宿地址声明书 Site Address Acknowledgement",
    };
  },
  methods: {
    signaturePopup(type) {
      this.signatureDialog = true;
      this.signatureType = type;
      this.initSign();
    },
    initSign() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signature"];
          let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = cparentDiv.offsetWidth;
          ccanvas.setAttribute("width", cparentWidth);
          _this.signatureRef = new SignaturePad(ccanvas);
        });
      }, 100);
    },
    signatureSave() {
      if (this.signatureRef.isEmpty()) {
        return false;
      }
      this.signature[this.signatureType] = this.signatureRef.toDataURL();
      this.signatureClose();
    },
    signatureClose() {
      this.signatureDialog = false;
      this.signatureRef.clear();
      this.signatureRef = null;
      this.signatureType = null;
    },
    getOptions(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "agreements/options",
          data: {
            attributes,
            customer: _this.customerId,
            quotation: _this.quotationId,
          },
        })
        .then(({ data }) => {
          if (data.quotation_list) {
            _this.quotationList = data.quotation_list;
          }
          if (data.customer) {
            _this.customerObject = data.customer;
          }
          if (data.quotation) {
            _this.quotationObject = data.quotation;
          }
          if (data.company) {
            _this.systemName = data.company.company_name;
            _this.systemNRIC = data.company.company_nric;
            _this.companyName = data.company.company_name;
            _this.companyAddress =
              data.company.street_1 +
              ", " +
              data.company.street_2 +
              ", " +
              data.company.landmark +
              "  " +
              data.company.zip_code;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.agreementForm.validate();

      const formErrors = _this.validateForm(_this.$refs.agreementForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (!_this.lodash.isEmpty(_this.quotationObject)) {
        _this.quotationId = _this.quotationObject.id;
      }

      const formData = new Object({
        customer: _this.customerId,
        quotation: _this.quotationId,
        form: _this.agreementId,
        subject: _this.agreementTitle,
        attributes: _this.attributes,
        owner_url: _this.signature.owner,
        company_url: _this.signature.company,
        start_date: new Date(),
      });

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let REQUEST_URL = "agreements/residential-agreement";
      let REQUEST_TYPE = POST;

      if (_this.agreement) {
        REQUEST_URL = `agreements/residential-agreement/${_this.agreement}`;
        REQUEST_TYPE = PUT;
      }

      _this.$store
        .dispatch(REQUEST_TYPE, {
          url: REQUEST_URL,
          data: formData,
        })
        .then(({ data }) => {
          _this.agreement = _this.lodash.toSafeInteger(data.id);
          _this.backForce = true;
          _this.$router.push(
            _this.getDefaultRoute("agreement.detail", {
              params: { id: _this.agreement },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    },
    getAgreement() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "agreements/" + _this.agreement,
        })
        .then(async ({ data }) => {
          this.attributes = data.form.attributes;
          this.quotationId = data.quotation;

          const base64Owner = await this.getBase64FromUrl(data.form.owner_url);
          const base64Company = await this.getBase64FromUrl(
            data.form.company_url
          );

          this.signature = {
            owner: base64Owner,
            company: base64Company,
          };

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Agreement", route: "agreement" },
            { title: "Update" },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;

          _this.getOptions(["quotation"]);
        });
    },
  },
  components: {
    Dialog,
    CreateUpdateTemplate,
  },
  mounted() {
    this.customerId = this.lodash.toSafeInteger(this.$route.query.customer);
    if (!this.customerId) {
      ErrorEventBus.$emit("update:error", "Customer is required");
      this.goBack();
      return false;
    }

    this.agreement = this.lodash.toSafeInteger(this.$route.params.id) || null;

    if (this.$route.name === "admin.agreement.residentialform.update") {
      this.getAgreement();
    }

    this.agreementId = this.lodash.toSafeInteger(this.$route.query.agreement);
    if (!this.agreementId) {
      ErrorEventBus.$emit("update:error", "Agreement is required");
      this.goBack();
      return false;
    }

    this.getOptions(["customer", "quotation", "quotation-list"]);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agreement", route: "agreement" },
      { title: "Create" },
    ]);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
