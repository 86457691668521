<template>
  <!--begin::SubContractor Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllSubContractors"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            @keydown.enter="getAllSubContractors"
            @keydown.tab="getAllSubContractors"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="pageLoading"
                :loading="pageLoading"
                v-on:click="getAllSubContractors"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('subcontractor.create', {
                        query: { contractor: typeId },
                      })
                    )
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New SubContractor</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="6"
      :pageLoading.sync="pageLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Sub Contractor #</th>
            <th class="simple-table-th">Sub Contractor Info</th>
            <th class="simple-table-th">Sub Contractor Address</th>
            <th class="simple-table-th" width="200">Created Time</th>
            <th v-if="false" class="simple-table-th" width="200">
              Last Modified Time
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="pushToDetail(row)"
            >
              <td class="simple-table-td">
                <Barcode
                  route="contractor.detail"
                  :id="row.id"
                  :barcode="row.barcode"
                ></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="contractor-listing-contractor">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Display Name: </b> {{ row.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b> {{ row.company_name }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="lodash.isEmpty(row.default_person) === false"
                  >
                    <b>Email: </b>
                    {{ row.default_person.primary_email }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="lodash.isEmpty(row.default_person) === false"
                  >
                    <b>Phone: </b>
                    {{ row.default_person.primary_phone }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="property-listing-contractor">
                  <p
                    class="m-0 custom-nowrap-ellipsis-four-line word-break"
                    v-if="row.billing"
                  >
                    {{ row.billing.property_address }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="200">
                <TableActivity
                  v-if="!lodash.isEmpty(row.added_by)"
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
              <td v-if="false" class="simple-table-td" width="200">
                <TableActivity
                  v-if="!lodash.isEmpty(row.updated_by)"
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.updated_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.modified_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.updated_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!pageLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no subcontractor at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::SubContractor Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CommonMixin],
  name: "subcontractor-internal-list",
  data() {
    return {
      statusList: [],
      dataRows: [],
      pageLoading: true,
      filter: {
        status: "all",
        search: null,
      },
    };
  },
  props: {
    type: {
      type: String,
      required: true,
      default: "contractor",
    },
    typeId: {
      type: [Number, String],
      required: true,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.getAllSubContractors();
    },
  },
  components: {
    Barcode,
    TableActivity,
    ListingTable,
  },
  methods: {
    pushToDetail(row) {
      this.$router.push(
        this.getDefaultRoute("subcontractor.detail", {
          params: { id: row.id },
        })
      );
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
      }
      _this.getAllSubContractors();
    },
    getAllSubContractors() {
      const _this = this;
      let filter = {
        type: _this.type,
        type_id: _this.typeId,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.pageLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: `subcontractor`,
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.getAllSubContractors();
  },
};
</script>
