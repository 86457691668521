<template>
  <Dialog :common-dialog="manageOrderDialog" :dialog-width="dialogWidth">
    <template v-slot:title> Manage Order </template>
    <template v-slot:body>
      <table width="100%">
        <thead>
          <tr>
            <th
              align="left"
              width="15%"
              class="font-size-18 font-weight-600 px-2 py-1 custom-header-blue-text"
            >
              Default Order
            </th>
            <th
              align="left"
              width="15%"
              class="font-size-18 font-weight-600 px-2 py-1 custom-header-blue-text"
            >
              New Order
            </th>
            <th
              align="left"
              width="70%"
              class="font-size-18 font-weight-600 px-2 py-1 custom-header-blue-text"
            >
              Additional Work Title
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in defaultColDefs"
            :key="`adw-manage-order-${index}`"
          >
            <td
              align="left"
              width="15%"
              class="font-size-18 font-weight-600 px-2 py-1"
            >
              <v-text-field
                :disabled="updateLoading"
                :loading="updateLoading"
                v-model.trim="row.prev_order"
                dense
                filled
                solo
                flat
                readonly
                color="cyan"
                hide-details
              ></v-text-field>
            </td>
            <td
              align="left"
              width="15%"
              class="font-size-18 font-weight-600 px-2 py-1"
            >
              <v-text-field
                :disabled="updateLoading"
                :loading="updateLoading"
                v-model.trim="row.order"
                dense
                filled
                solo
                flat
                color="cyan"
                hide-details
              ></v-text-field>
            </td>
            <td
              align="left"
              width="70%"
              class="font-size-18 font-weight-600 px-2 py-1"
            >
              <v-text-field
                :disabled="updateLoading"
                :loading="updateLoading"
                v-model.trim="row.name"
                dense
                filled
                solo
                readonly
                flat
                color="cyan"
                hide-details
              >
                <template v-slot:prepend>
                  <Barcode
                    :barcode="row.barcode"
                    style="
                      margin-top: 0px !important;
                      margin-bottom: 0px !important;
                      padding: 19px 7px !important;
                    "
                  ></Barcode>
                </template>
              </v-text-field>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="updateLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        color="cyan white--text"
        v-on:click="arrange_order()"
      >
        Preview
      </v-btn>
      <v-btn
        :disabled="updateLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        color="cyan white--text"
        v-on:click="save_order()"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="updateLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
      >
        Cancel
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "adw-manage-order",
  props: {
    manageOrderDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Barcode,
    Dialog,
  },
  watch: {
    manageOrderDialog(param) {
      if (param) {
        this.get_rows();
      } else {
        this.defaultColDefs = [];
      }
    },
  },
  data() {
    return {
      updateLoading: false,
      defaultColDefs: [],
    };
  },
  methods: {
    get_rows() {
      this.updateLoading = true;
      this.$store
        .dispatch(GET, { url: "adtitle/all" })
        .then(({ data }) => {
          this.defaultColDefs = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },
    save_order() {
      this.updateLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "adtitle/order",
          data: { adwtitle: this.defaultColDefs },
        })
        .then(() => {
          this.$emit("refresh", true);
          this.$emit("close", true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },
    arrange_order() {
      this.defaultColDefs = this.lodash.orderBy(
        this.defaultColDefs,
        ["order"],
        ["asc"]
      );
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  mounted() {
    this.get_rows();
  },
};
</script>
