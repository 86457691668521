<template>
  <v-layout v-if="getPermission('service:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <div v-if="errors.length" class="iv-custom-field pt-0 width-100">
        <div
          class="alert fade alert-danger"
          role="alert"
          v-bind:class="{ show: errors.length }"
        >
          <div v-for="(error, i) in errors" :key="i" class="alert-text">
            {{ error }}
          </div>
        </div>
      </div>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="position: static"
      >
        <v-col md="10" offset-md="1" class="pb-0">
          <label class="font-weight-700 font-size-16 width-100"
            >Additional Work Title</label
          >
          <v-autocomplete
            dense
            filled
            color="cyan"
            item-color="cyan"
            :items="awTitleList"
            label="Additional Work Title"
            solo
            flat
            item-text="text"
            item-value="id"
            class="width-100"
            v-model.trim="productCreate.adw_title"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="'No Additional Work Title(s) Found.'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <label class="font-weight-700 font-size-16 width-100 required"
            >Additional Work Name</label
          >
          <v-text-field
            dense
            filled
            label="Name"
            solo
            flat
            color="cyan"
            v-model.trim="productCreate.name"
            :rules="[
              validateRules.required(productCreate.name, 'Name'),
              validateRules.minLength(productCreate.name, 'Name', 1),
              validateRules.maxLength(productCreate.name, 'Name', 255),
            ]"
          ></v-text-field>
          <v-row>
            <v-col cols="6">
              <label class="font-weight-700 font-size-16 width-100"
                >Description</label
              >
              <v-textarea
                auto-grow
                dense
                filled
                color="cyan"
                label="Description"
                placeholder="Description"
                solo
                flat
                row-height="25"
                v-model="productCreate.description"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <label class="font-weight-700 font-size-16 width-100"
                >Internal Note</label
              >
              <v-textarea
                auto-grow
                dense
                filled
                color="cyan"
                label="Internal Note"
                placeholder="Internal Note"
                solo
                flat
                row-height="25"
                v-model="productCreate.things_to_note"
              ></v-textarea>
            </v-col>
          </v-row>
          <div v-if="false" class="d-flex">
            <label class="pr-2 font-weight-700 font-size-16 width-100"
              >Reference</label
            >

            <label class="pl-2 font-weight-700 font-size-16 width-100"
              >Barcode</label
            >
          </div>
          <div v-if="false" class="d-flex">
            <v-text-field
              dense
              filled
              color="cyan"
              label="Reference"
              solo
              flat
              :rules="[
                validateRules.minLength(
                  productCreate.reference,
                  'Reference',
                  1
                ),
                validateRules.maxLength(
                  productCreate.reference,
                  'Reference',
                  100
                ),
              ]"
              class="pr-2 width-100"
              v-model.trim="productCreate.reference"
            ></v-text-field>
            <v-text-field
              dense
              filled
              color="cyan"
              label="Barcode"
              solo
              flat
              class="pl-2 width-100"
              readonly
              v-model.trim="barcode"
            ></v-text-field>
          </div>
          <div class="d-flex">
            <label class="font-weight-700 font-size-16 width-100"
              >Category</label
            >

            <label class="px-4 font-weight-700 font-size-16 width-100"
              >Sub Category</label
            >

            <label class="font-weight-700 font-size-16 width-100">Unit</label>
          </div>
          <div class="d-flex">
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="categoryList"
              label="Category"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageCategoryDialog = true"
              v-on:change="getSubCategory"
              item-text="text"
              item-value="id"
              class="width-100"
              v-model.trim="productCreate.main_category"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Category(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="subCategoryList"
              label="Sub-Category"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageSubCategoryDialog = true"
              item-text="text"
              item-value="id"
              class="px-4 width-100"
              v-model.trim="productCreate.sub_category"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Sub-Category(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="unitList"
              label="Unit"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageUnitDialog = true"
              item-text="text"
              item-value="id"
              class="width-100"
              v-model.trim="productCreate.unit"
              v-on:change="updateUnit()"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Unit(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="false" class="d-flex">
            <label class="custom-form-label">Tax Preference</label>
            <v-radio-group
              class="pt-0 mt-0 mb-2"
              dense
              row
              color="cyan"
              v-model.trim="productCreate.taxable"
            >
              <v-radio label="Taxable" color="cyan" :value="true"></v-radio>
              <v-radio
                label="Non-Taxable"
                color="cyan"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex custom-border-top">
            <v-checkbox
              style="width: 33%"
              v-model="productCreate.level_2"
              class="font-weight-700"
              color="cyan"
              label="Level 2"
            />
            <v-checkbox
              style="width: 33%"
              v-on:change="update_markup()"
              v-model="productCreate.without_markup"
              class="font-weight-700 px-4"
              color="cyan"
              label="Custom Price (without markup)"
            />
          </div>
          <div class="d-flex">
            <label class="font-weight-700 font-size-16 width-100 red--text"
              >Supplier Cost
              <template v-if="!decimalStatus">(Decimal Not Allow)</template>
            </label>
            <label
              class="px-4 font-weight-700 font-size-16 width-100"
              :class="{ disabled: productCreate.without_markup }"
              >Markup</label
            >
            <label class="font-weight-700 font-size-16 width-100"
              >Price
              <template v-if="!decimalStatus">(Decimal Not Allow)</template>
            </label>
          </div>
          <div class="d-flex">
            <v-text-field
              dense
              filled
              color="cyan"
              label="Supplier Cost"
              solo
              flat
              class="width-100"
              type="number"
              min="0"
              max="9999"
              prepend-inner-icon="mdi-currency-usd"
              v-model.trim="productCreate.company_cost"
              v-on:change="update_markup()"
              v-on:keypress="
                !decimalStatus
                  ? withoutDecimal($event)
                  : limitDecimal($event, productCreate.company_cost)
              "
            ></v-text-field>
            <div class="d-flex px-4 width-100">
              <v-select
                dense
                filled
                solo
                flat
                style="max-width: 50px"
                color="cyan"
                item-color="cyan"
                :items="markupTypeList"
                :disabled="productCreate.without_markup"
                v-model="productCreate.markup_type"
                v-on:change="update_markup()"
              >
              </v-select>
              <v-text-field
                dense
                filled
                color="cyan"
                label="markup"
                solo
                flat
                type="number"
                min="0"
                max="9999"
                :disabled="productCreate.without_markup"
                v-on:keypress="limitDecimal($event, productCreate.markup)"
                :prepend-inner-icon="
                  productCreate.markup_type == 'percentage'
                    ? 'mdi-percent'
                    : 'mdi-currency-usd'
                "
                v-model.trim="productCreate.markup"
              ></v-text-field>
            </div>
            <v-text-field
              dense
              filled
              color="cyan"
              label="Price"
              solo
              flat
              class="width-100"
              type="number"
              min="0"
              max="9999"
              :disabled="!productCreate.without_markup"
              v-on:keypress="
                !decimalStatus
                  ? withoutDecimal($event)
                  : limitDecimal($event, productCreate.charges)
              "
              prepend-inner-icon="mdi-currency-usd"
              v-model.trim="productCreate.charges"
            ></v-text-field>
          </div>

          <div
            class="custom-border-top pt-5"
            v-if="getPermission('attachment:create')"
          >
            <label class="custom-form-label width-100"
              >Images
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-2" v-bind="attrs" v-on="on"
                    >mdi-progress-question</v-icon
                  >
                </template>
                <span>More than 5 images are not allowed</span>
              </v-tooltip>
            </label>
            <div>
              <!-- <FileTemplate
                allowUpload
                isMinDisplay
                :onlyImage="true"
                :attachments.sync="productCreate.product_images"
                v-on:file:updated="updateImages"
              ></FileTemplate> -->
              <AttachmentWithName
                v-on:file:updated="updateImages"
                :label="true"
                :attachments.sync="productCreate.product_images"
                allowUpload
                :onlyImage="true"
              ></AttachmentWithName>
            </div>
          </div>
        </v-col>
      </perfect-scrollbar>
    </v-col>
    <ManageUnit
      :dialog.sync="manageUnitDialog"
      :unit.sync="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
    <ManageCategory
      :dialog.sync="manageCategoryDialog"
      :category.sync="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
    <ManageSubCategory
      :dialog.sync="manageSubCategoryDialog"
      :category.sync="categoryList"
      :subcategory.sync="allSubCategory"
      v-on:close-dialog="manageSubCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageSubCategory>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";
// import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageSubCategory from "@/view/pages/partials/Manage-Product-SubCategory.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import {
  QUERY,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";

export default {
  name: "product-create",
  mixins: [CommonMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      decimalStatus: true,
      manageUnitDialog: false,
      awTitleList: new Array(),
      brandList: new Array(),
      categoryList: new Array(),
      markup: 0,
      subCategoryList: new Array(),
      allSubCategory: new Array(),
      manufacturerList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageCategoryDialog: false,
      manageSubCategoryDialog: false,
      markupTypeList: [
        {
          text: "%",
          value: "percentage",
        },
        {
          text: "$",
          value: "amount",
        },
      ],
      productCreate: {
        adw_title: null,
        name: null,
        reference: null,
        description: null,
        things_to_note: null,
        main_category: null,
        sub_category: null,
        level_2: false,
        without_markup: false,
        markup_type: "percentage",
        tax: null,
        markup: 0,
        tax_exemption: null,
        product_type: "service",
        company_cost: 0,
        taxable: false,
        charges: 0,
        attachments: [],
        product_images: [],
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  components: {
    // TinyMCE,
    ManageCategory,
    ManageUnit,
    ManageSubCategory,
    AttachmentWithName,
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        console.log({ param });
        this.productCreate.unit = param.uom_id;
        this.getSubCategory();
      },
    },
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
    "productCreate.company_cost"() {
      this.update_markup();
    },
    "productCreate.markup"() {
      this.update_markup();
    },
  },
  methods: {
    update_markup() {
      if (this.productCreate.without_markup) {
        return false;
      }
      let company_cost = 0;

      if (this.productCreate.markup_type == "percentage") {
        company_cost =
          this.lodash.toNumber(
            (this.lodash.toNumber(this.productCreate.company_cost) *
              this.lodash.toNumber(this.productCreate.markup)) /
              100
          ) + this.lodash.toNumber(this.productCreate.company_cost);
      } else if (this.productCreate.markup_type == "amount") {
        company_cost =
          this.lodash.toNumber(this.productCreate.company_cost) +
          this.lodash.toNumber(this.productCreate.markup);
      }

      this.productCreate.charges = this.decimalStatus
        ? this.lodash.toNumber(company_cost).toFixed(2)
        : Math.round(company_cost);
    },
    updateUnit() {
      let productUOM = this.lodash.find(this.unitList, {
        id: this.productCreate.unit,
      });
      this.productCreate.uom_id = productUOM ? productUOM.id : null;
      this.productCreate.uom_value = productUOM ? productUOM.value : null;
      this.productCreate.uom_text = productUOM ? productUOM.text : null;
    },
    updateImages(param) {
      this.productCreate.attachments = param;
    },
    getSubCategory() {
      const _this = this;
      if (_this.productCreate.main_category) {
        _this.$store
          .dispatch(QUERY, {
            url: "product/sub-category",
            data: { category: _this.productCreate.main_category },
          })
          .then((response) => {
            if (
              _this.lodash.isEmpty(response.data) === false &&
              response.data
            ) {
              _this.subCategoryList = response.data;
            }
          })
          .catch((error) => {
            _this.$store.dispatch(UPDATE_ERROR, error);
          });
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type },
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.allsubcategory
          ) {
            _this.allSubCategory = response.data.allsubcategory;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.uomlist
          ) {
            _this.unitList = response.data.uomlist;
          }

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.adwlist
          ) {
            _this.awTitleList = response.data.adwlist;
          }

          if (
            !_this.productId &&
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode
          ) {
            _this.barcode = response.data.barcode;
          }

          if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
            _this.barcodeSetting = response.data.barcode_setting;

            if (!_this.productCreate.description) {
              _this.productCreate.description =
                _this.barcodeSetting.description;
            }

            if (!_this.productCreate.markup) {
              _this.productCreate.markup = _this.barcodeSetting.markup
                ? _this.barcodeSetting.markup
                : 0;
            }

            _this.decimalStatus =
              _this.barcodeSetting.decimal == 0 ? false : true;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>
