<template>
  <div>
    <div class="d-flex w-100 mt-4">
      <div style="width: 10%" class="text-right">
        <v-icon style="margin-top: 50px" class="mr-4">mdi-chevron-right</v-icon>
      </div>
      <div style="width: 49%">
        <v-autocomplete
          dense
          filled
          color="cyan"
          item-color="cyan"
          placeholder="Level 2"
          solo
          :items="ltxAdLevel2List[productId]"
          flat
          :menu-props="{ contentClass: 'level-2-autocomplete-list' }"
          hide-details
          v-model="clrow.product_id"
          v-on:change="
            updateAdLevel2($event, rowUuid, crowUuid, clindex);
            emitValue();
          "
          class="width-100"
        />
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          placeholder="Description"
          solo
          flat
          class="mt-2"
          hide-details
          row-height="6"
          v-model="clrow.description"
          :readonly="!clrow.product_id"
          v-on:keyup="emitValue()"
          v-on:change="emitValue()"
        />
      </div>
      <div style="width: 12%">
        <v-layout class="line-item-uom">
          <v-flex style="width: 60px">
            <v-text-field
              dense
              filled
              hide-details
              color="cyan"
              solo
              flat
              type="number"
              v-on:keypress="is_number($event)"
              placeholder="Quantity"
              v-model="clrow.quantity"
              v-on:change="emitValue()"
              :readonly="!clrow.product_id"
            />
          </v-flex>
          <v-flex style="width: 100px">
            <v-select
              :items="ltxUomList"
              dense
              filled
              flat
              hide-details
              solo
              v-model="clrow.uom"
              placeholder="UOM"
              label="UOM"
              color="cyan"
              item-color="cyan"
              v-on:change="emitValue()"
              :readonly="!clrow.product_id"
            />
          </v-flex>
        </v-layout>
      </div>
      <div style="width: 12%">
        <v-text-field
          dense
          filled
          hide-details
          color="cyan"
          solo
          flat
          prefix="$"
          type="number"
          v-on:keypress="is_number($event)"
          placeholder="Rate"
          v-model="clrow.rate"
          v-on:change="emitValue()"
          :readonly="!clrow.product_id"
        />

        <p class="w-100 my-2 text-left font-size-16 font-weight-500">
          Supplier Cost: <br />
          <template v-if="clrow.edit_supplier_cost">
            <v-text-field
              dense
              :ref="`clrow-supplier-price-${index}-${cindex}-${clindex}`"
              filled
              hide-details
              color="cyan"
              solo
              flat
              prefix="$"
              type="number"
              v-on:keypress="is_number($event)"
              placeholder="Supplier Cost"
              v-model="clrow.company_cost"
              v-on:change="
                clrow.edit_supplier_cost = false;
                emitValue();
              "
              v-on:keyup="emitValue()"
              v-on:blur="clrow.edit_supplier_cost = false"
              :readonly="!clrow.product_id"
            />
          </template>
          <template v-else>
            <div class="d-flex align-center">
              <label class="m-0 p-0 font-weight-600">{{
                formatMoney(clrow.company_cost)
              }}</label>
              <v-btn
                v-on:click="
                  clrow.edit_supplier_cost = true;
                  editChildOneSupplier(index, cindex, clindex);
                "
                small
                icon
                color="cyan"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </p>
        <p class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500">
          Profit:
          <label class="m-0 p-0 font-weight-600">
            {{ formatMoney(clrow.profit) }}
          </label>
        </p>
        <p class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500">
          Markup (in %):
          <label class="m-0 p-0 font-weight-600">
            {{ formatNumber(clrow.markup_percent) }} %</label
          >
        </p>
      </div>
      <div style="width: 17%">
        <v-text-field
          dense
          filled
          hide-details
          color="cyan"
          solo
          flat
          readonly
          class="has-delete-outer-icon"
          append-outer-icon="mdi-delete"
          v-on:click:append-outer="
            deleteLevel2ChildRow(rowUuid, crowUuid, clindex)
          "
          placeholder="Total"
          :value="formatMoney(clrow.total)"
        />
        <p class="w-100 my-3 text-left font-size-16 font-weight-500">
          Total Supplier Cost:<br /><label class="m-0 p-0 font-weight-600">
            {{ formatMoney(clrow.total_company_cost) }}
          </label>
        </p>
        <p class="w-100 mt-11 mb-0 text-left font-size-16 font-weight-500">
          Profit (in %):
          <label class="m-0 p-0 font-weight-600">
            {{ formatNumber(clrow.profit_percent) }} %</label
          >
        </p>
      </div>
    </div>

    <div class="d-flex w-100">
      <div style="width: 10%"></div>
      <div class="text-right pr-4" style="width: 24.5%; position: relative">
        <v-checkbox
          v-if="ltxSummaryReport"
          hide-details
          class="my-2 py-0 d-inline-block"
          color="cyan"
          label="Is Removed?"
          :true-value="1"
          :false-value="0"
          v-model="clrow.strike"
          v-on:change="
            clrow.is_optional = 0;
            emitValue();
          "
        />
        <template v-if="nextIndex">
          <v-btn
            :disabled="!productId"
            v-on:click="$emit('add-child', { rowUuid, productId })"
            small
            depressed
            style="position: absolute; bottom: 0; left: 0"
            color="cyan white--text"
            class="custom-bold-button white--text"
          >
            <v-icon>mdi-plus</v-icon> Add Additional Work (Same Category)
          </v-btn>
        </template>
      </div>
      <div class="text-right pr-4" style="width: 24.5%">
        <v-checkbox
          hide-details
          class="my-2 py-0 d-inline-block"
          color="cyan"
          label="Is Optional?"
          :true-value="1"
          :false-value="0"
          v-model="clrow.is_optional"
          v-on:change="
            clrow.strike = 0;
            emitValue();
          "
        />
      </div>
      <div style="width: 41%">
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          placeholder="Internal Note"
          solo
          flat
          class="my-2"
          hide-details
          v-model="clrow.internal_note"
          v-on:change="emitValue()"
          v-on:keyup="emitValue()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import {
  EMIT_VALUE,
  UPDATE_ADLEVEL2,
  DELETE_LEVEL2,
} from "@/core/services/store/line.item.module";

export default {
  mixins: [CommonMixin],
  data() {
    return {};
  },
  props: {
    clrow: {
      type: Object,
      default() {
        return {};
      },
    },
    rowUuid: {
      type: String,
      default: null,
    },
    crowUuid: {
      type: String,
      default: null,
    },
    cindex: {
      type: Number,
      default: 0,
    },
    clindex: {
      type: Number,
      default: 0,
    },
    nextIndex: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    editChildOneSupplier(index, cindex, clindex) {
      setTimeout(() => {
        this.$refs[
          `clrow-supplier-price-${index}-${cindex}-${clindex}`
        ].$refs.input.focus();
      }, 100);
    },
    updateAdLevel2(level2Id, rowUuid, crowUuid, clindex) {
      if (!level2Id) {
        this.clrow.description = null;
        this.clrow.rate = 0;
        this.clrow.company_cost = 0;
        this.clrow.total_company_cost = 0;
        this.clrow.total = 0;
      }
      this.$store.dispatch(UPDATE_ADLEVEL2, {
        level2Id,
        rowUuid,
        crowUuid,
        clindex,
        productId: this.productId,
      });
    },
    emitValue() {
      this.$store.dispatch(EMIT_VALUE);
    },
    deleteLevel2ChildRow(rowUuid, uuid, index) {
      this.$store.dispatch(DELETE_LEVEL2, { rowUuid, uuid, index });
    },
  },
  computed: {
    ...mapGetters([
      "ltxAdSelectList",
      "ltxUomList",
      "ltxLevel2ChildLineItem",
      "ltxSummaryReport",
      "ltxAdLevel2List",
    ]),
  },
};
</script>
