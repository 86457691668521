<template>
  <DetailTemplate
    :customClass="'agreement-detail detail-page'"
    v-if="getPermission('agreement:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ agreementTitle }}
          </h1>
          <v-layout>
            <v-flex
              ><CustomChip
                color="cyan"
                text-color="white"
                :text="agreementDetail.status_text"
                tooltip-text="Status"
              ></CustomChip
            ></v-flex>
            <template
              v-if="agreementDetail.agreement || agreementDetail.resident"
            >
              <v-flex
                ><CustomChip
                  :color="getCSTColor(agreementDetail.customer_status)"
                  text-color="white"
                  :text="agreementDetail.customer_status_text"
                  tooltip-text="Customer Status"
                ></CustomChip
              ></v-flex>
              <v-flex
                ><CustomChip
                  :color="getCondiColor(agreementDetail.mail_status)"
                  text-color="white"
                  :text="agreementDetail.mail_status_text"
                  tooltip-text="Is Mail Sent ?"
                ></CustomChip
              ></v-flex>
            </template>
          </v-layout>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="
              !agreementDetail.line_item &&
              agreementDetail.status != 2 &&
              agreementDetail.customer_status != 2
            "
            link
            v-on:click="updateMoreAction('edit')"
          >
            <v-list-item-icon class="margin-auto-zero mr-3 my-2">
              <v-icon class="icon-default-blue">mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-500 font-size-14"
              >Edit</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="!agreementDetail.line_item"
            link
            v-on:click="updateMoreAction('delete')"
          >
            <v-list-item-icon class="margin-auto-zero mr-3 my-2">
              <v-icon class="icon-default-blue">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-500 font-size-14"
              >Delete</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="
              agreementDetail.agreement_url &&
              agreementDetail.agreement &&
              !agreementDetail.mail_status
            "
            link
            v-on:click="sendMailDialog = true"
          >
            <v-list-item-icon class="margin-auto-zero mr-3 my-2">
              <v-icon class="icon-default-blue">mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-500 font-size-14"
              >Send Mail</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-tabs
              active-class="custom-tab-active"
              v-model="agreementTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#other"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/agreement.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                v-if="
                  agreementDetail.agreement_url || agreementDetail.consent_url
                "
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#files"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/inventory.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Files
              </v-tab>
              <template
                v-if="
                  agreementDetail.agreement === false &&
                  !agreementDetail.line_item &&
                  getPermission('line-item:view')
                "
              >
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Item
                </v-tab>
              </template>
              <template
                v-if="
                  agreementDetail.agreement === false &&
                  getPermission('attachment:view')
                "
              >
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#attachment"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/attachment.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Attachment
                </v-tab>
              </template>
              <template
                v-if="
                  agreementDetail.agreement === false &&
                  getPermission('history:view')
                "
              >
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="agreementTab">
              <v-tab-item value="other">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Overview
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-container fluid class="p-0">
                        <v-row>
                          <v-col md="6">
                            <table width="100%">
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Agreement Subject
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ agreementDetail.subject }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Reference #
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template v-if="agreementDetail.reference">
                                    {{ agreementDetail.reference }}
                                  </template>
                                  <template v-else>
                                    <em class="text--secondary"
                                      >No Reference #</em
                                    >
                                  </template>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Customer #
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(
                                        agreementDetail.customer
                                      ) == false
                                    "
                                    >{{
                                      agreementDetail.customer.barcode
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Customer #</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Customer Company
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(
                                        agreementDetail.customer
                                      ) == false
                                    "
                                    >{{
                                      agreementDetail.customer.display_name
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Customer Company</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="200"
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Customer Property
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="
                                      lodash.isEmpty(agreementDetail.form) ==
                                      false
                                    "
                                    >{{
                                      agreementDetail.form.attributes
                                        .ownerAddress
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Customer Property</em
                                    ></template
                                  >
                                </td>
                              </tr>
                            </table>
                          </v-col>
                          <v-col md="6">
                            <table width="100%">
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Agreement #
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  {{ agreementDetail.barcode }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Agreement Type
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="agreementDetail.agreement_type"
                                    >{{
                                      agreementDetail.agreement_type
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Agreement Type</em
                                    ></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Agreement Value
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template
                                    v-if="agreementDetail.agreement_value"
                                    >{{
                                      $accountingJS.formatMoney(
                                        agreementDetail.agreement_value
                                      )
                                    }}</template
                                  >
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Agreement Value
                                    </em></template
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  width="300 "
                                  class="pb-2 font-size-18 text-capitalize"
                                >
                                  Duration
                                </td>
                                <td
                                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                >
                                  <template v-if="agreementDetail.start_date">{{
                                    formatDate(agreementDetail.start_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Start Date</em
                                    ></template
                                  >
                                  -
                                  <template v-if="agreementDetail.end_date">{{
                                    formatDate(agreementDetail.end_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No End Date</em
                                    ></template
                                  >
                                </td>
                              </tr>
                            </table>
                          </v-col>
                          <v-col
                            v-if="agreementDetail.description"
                            md="12"
                            class="py-0"
                            content="Description"
                            v-tippy="{ placement: 'top-start' }"
                          >
                            <read-more
                              class="custom-read-more custom-border-grey-dashed font-weight-600 font-size-18 p-4 custom-gray-color"
                              more-str="read more"
                              :text="
                                agreementDetail.description ||
                                '<em>No Description</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item
                value="files"
                v-if="
                  agreementDetail.agreement_url || agreementDetail.consent_url
                "
              >
                <v-container>
                  <v-row>
                    <v-col
                      class="text-center custom-grey-border link"
                      v-on:click="downloadLink(agreementDetail.agreement_url)"
                      md="2"
                      v-if="agreementDetail.agreement_url"
                    >
                      <v-icon large color="cyan">mdi-download</v-icon>
                      <div class="pt-5 font-size-18 font-weight-600">
                        Document
                      </div>
                    </v-col>
                    <v-col
                      class="text-center custom-grey-border link ml-10"
                      v-on:click="downloadLink(agreementDetail.consent_url)"
                      md="2"
                      v-if="agreementDetail.consent_url"
                    >
                      <v-icon large color="cyan">mdi-download</v-icon>
                      <div class="pt-5 font-size-18 font-weight-600">
                        Consent Form
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <template
                v-if="
                  agreementDetail.agreement === false &&
                  !agreementDetail.line_item
                "
              >
                <v-tab-item value="line-item">
                  <template v-if="lodash.isEmpty(agreementDetail) === false">
                    <LineItemDetail
                      isAgreement
                      type="agreement"
                      :detail.sync="agreementDetail"
                      :pageLoading.sync="pageLoading"
                      v-on:load:parent="getAgreement"
                    ></LineItemDetail>
                  </template>
                </v-tab-item>
              </template>
              <v-tab-item
                v-if="agreementDetail.agreement === false"
                value="attachment"
              >
                <v-container fluid>
                  <FileTemplate
                    allowUpload
                    :allowDelete="false"
                    :attachments.sync="attachments"
                    v-on:file:updated="updateAttachment"
                  ></FileTemplate>
                </v-container>
              </v-tab-item>
              <v-tab-item
                v-if="agreementDetail.agreement === false"
                value="history"
              >
                <template v-if="lodash.isEmpty(agreementDetail) === false">
                  <InternalHistoryDetail
                    type="agreement"
                    :type_id.sync="agreementDetail.id"
                  ></InternalHistoryDetail>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <AgreementDelete
        :deleteDialog.sync="agreementDeleteDialog"
        :requestUrl.sync="agreementDeleteRequestUrl"
        v-on:delete:success="agreementDeleteSuccess"
        v-on:delete:close="agreementDeleteDialog = false"
      ></AgreementDelete>
      <Dialog :commonDialog.sync="sendMailDialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-row>
            <v-col md="12">
              <h4 class="font-weight-600 font-size-18">
                Are you sure, you want send email for confirmation to customer ?
              </h4>
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="sendMail()"
          >
            Yes ! Send
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="sendMailDialog = false"
          >
            No ! Cancel
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH, POST } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import AgreementDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import CustomChip from "@/view/pages/partials/CustomChip.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "agreement-detail",
  title: "Detail Agreement",
  data() {
    return {
      agreement: null,
      agreementTab: null,
      sendMailDialog: false,
      agreementDeleteDialog: false,
      attachments: new Array(),
      pageLoading: false,
      agreementDetail: new Object({
        barcode: null,
        reference: null,
        line_item: null,
        customer: new Object(),
        form: new Object(),
        project: new Object(),
        subject: null,
        renewed: false,
        agreement_url: null,
        consent_url: null,
        agreement_value: null,
        agreement_type: null,
        description: null,
        start_date: null,
        end_date: null,
        status: null,
        attachments: new Array(),
      }),
    };
  },
  components: {
    Dialog,
    CustomChip,
    DetailTemplate,
    LineItemDetail,
    InternalHistoryDetail,
    AgreementDelete,
    FileTemplate,
  },
  methods: {
    getCSTColor(status) {
      let result = null;
      switch (status) {
        case 0:
          result = "orange lighten-1";
          break;
        case 1:
          result = "green lighten-1";
          break;
        case 2:
          result = "red lighten-1";
          break;
      }
      return result;
    },
    getCondiColor(status) {
      let result = null;
      switch (status) {
        case false:
          result = "orange lighten-1";
          break;
        case true:
          result = "green lighten-1";
          break;
      }
      return result;
    },
    downloadLink(url) {
      window.open(url, "_blank");
    },
    sendMail() {
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: `agreements/${_this.agreement}/send-mail`,
        })
        .then(() => {
          _this.sendMailDialog = false;
          _this.getAgreement();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    agreementDeleteSuccess() {
      this.agreementDeleteDialog = false;
      this.goBack();
    },
    updateAttachment(attachments) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "agreements/" + _this.agreement,
          data: { attachments },
        })
        .then(() => {
          _this.getAgreement();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          if (_this.agreementDetail.resident === true) {
            _this.$router.push(
              _this.getDefaultRoute("agreement.residentialform.update", {
                query: {
                  customer: _this.agreementDetail.customer.id,
                  agreement: 2,
                },
                params: {
                  id: _this.agreement,
                },
              })
            );
          } else if (_this.agreementDetail.agreement === true) {
            _this.$router.push(
              _this.getDefaultRoute("agreement.form.update", {
                query: {
                  customer: _this.agreementDetail.customer.id,
                  agreement: 1,
                },
                params: {
                  id: _this.agreement,
                },
              })
            );
          } else {
            _this.$router.push(
              _this.getDefaultRoute("agreement.update", {
                query: { customer: _this.agreementDetail.customer.id },
                params: {
                  id: _this.agreement,
                },
              })
            );
          }
          break;
        case "delete":
          _this.agreementDeleteDialog = true;
          break;
        case "download":
          _this.downloadAgreement();
          break;
      }
    },
    downloadAgreement() {},
    getAgreement() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "agreements/" + _this.agreement,
        })
        .then(({ data }) => {
          _this.agreementDetail = data;
          _this.attachments = data.attachments;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agreement", route: "agreement" },
      { title: "Detail" },
    ]);

    _this.agreement = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.agreement <= 0) {
      _this.goBack();
    }

    _this.getAgreement();
  },
  computed: {
    agreementDeleteRequestUrl() {
      return "agreements/" + this.agreement;
    },
    agreementTitle() {
      return this.agreementDetail.subject;
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
