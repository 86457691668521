<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Quotations</span>
        <span
          class="text-muted mt-3 font-weight-bold font-size-sm text-capitalize"
          >Latest {{ statusText }} Quotations</span
        >
      </h3>
      <div class="card-toolbar">
        <Dropdown
          v-on:update:status="updateStatus"
          :list="statusList"
          :btn-text="statusText"
          btn-class="btn btn-info font-weight-bold text-capitalize font-size-sm mr-3"
        ></Dropdown>
        <a
          class="btn btn-danger font-weight-bold font-size-sm"
          v-on:click="$emit('create:quotation', true)"
          >Create Quotation</a
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Quotation</span>
                </th>
                <th style="min-width: 250px">Customer</th>
                <th style="min-width: 100px">Dates</th>
                <th style="min-width: 100px">Amount</th>
                <th style="min-width: 100px">Rating</th>
                <th style="min-width: 101px"></th>
              </tr>
            </thead>
            <tbody v-if="dataRows.length">
              <template v-for="(row, i) in dataRows">
                <tr v-bind:key="i">
                  <td class="pl-0 pt-8">
                    <div class="mx-4 quotation-listing-customer">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Quotation Title: </b>{{ row.quotation_title }}
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis-two-line"
                        v-if="row.property"
                      >
                        <b>Property: </b>
                        <template v-if="row.property.unit_no"
                          >{{ row.property.unit_no }},
                        </template>
                        <template v-if="row.property.street_1"
                          >{{ row.property.street_1 }},
                        </template>
                        <template v-if="row.property.street_2"
                          >{{ row.property.street_2 }},
                        </template>
                        <template v-if="row.property.zip_code"
                          >{{ row.property.zip_code }}
                        </template>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="quotation-listing-customer">
                      <p class="m-0 custom-nowrap-ellipsis" v-if="row.customer">
                        <b>Company: </b> {{ row.customer.display_name }}
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="row.contact_person"
                      >
                        <b>Email: </b> {{ row.contact_person.primary_email }}
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="row.contact_person"
                      >
                        <b>Phone: </b> {{ row.contact_person.primary_phone }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="quotation-listing-customer">
                      <p class="m-0 custom-nowrap-ellipsis" v-if="row.date">
                        <b>Created: </b> {{ formatDate(row.date) }}
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="row.open_till"
                      >
                        <b>Open Till: </b> {{ formatDate(row.open_till) }}
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="row.sent_date"
                      >
                        <b>Sent: </b> {{ formatDate(row.sent_date) }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="quotation-listing-amount">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Profit: </b>
                        {{ $accountingJS.formatMoney(row.total_profit) }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Total: </b>
                        {{ $accountingJS.formatMoney(row.total) }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <v-rating
                      v-model="row.rating"
                      background-color="orange lighten-3"
                      color="orange"
                      readonly
                    ></v-rating>
                  </td>
                  <td class="pr-0 text-right">
                    <router-link
                      :to="{
                        name: 'admin.quotation.detail',
                        params: { id: row.id },
                      }"
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      >View Quotation</router-link
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr>
                <td colspan="6">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no quotation at the moment.
                  </p>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import Dropdown from "@/view/content/Dropdown";
import CommonMixin from "@/core/plugins/common-mixin";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "quotation-list-dashboard",
  mixins: [CommonMixin],
  data() {
    return {
      statusText: "Accepted",
      statusList: [],
      dataRows: [],
      dataLoading: false,
      status: "accepted",
    };
  },
  methods: {
    updateStatus(status) {
      this.status = status;
      this.statusText = status;
      this.getAllQuotations();
    },
    getAllQuotations() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.status || "all",
      };

      _this.dataLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "quotation",
          data: filter,
        })
        .then(({ data }) => {
          _this.dataRows = data.rows;
          _this.statusList = data.status_list;
        })
        .catch((error) => {
          _this.dataRows = [];
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
  },
  components: {
    Dropdown,
  },
  mounted() {
    this.getAllQuotations();
  },
};
</script>
