<template>
  <v-container fluid>
    <CreateUpdateTemplate
      :routePreventDialog.sync="routePreventDialog"
      :customClass="'project-create create-form'"
      v-if="getPermission('project:create')"
    >
      <template v-slot:header-title>
        <v-skeleton-loader
          v-if="pageLoading"
          class="custom-skeleton custom-skeleton-full-width"
          type="button"
        ></v-skeleton-loader>
        <h1 v-else class="form-title d-flex">
          Project Costing For
          <span
            class="m-0 form-title-create-link pl-2"
            :class="{
              'custom-nowrap-ellipsis': !lodash.isEmpty(customerObject),
            }"
            link
          >
            <template v-if="lodash.isEmpty(customerObject)"
              >Client Name</template
            >
            <template v-else>{{ customerObject.company_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customerObject)"
              >mdi-plus-circle-outline</v-icon
            >
          </span>
        </h1>
      </template>
      <template v-slot:header-action>
        <v-btn
          v-on:click="goBack"
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          v-on:click="onSubmit"
          color="cyan"
        >
          Save Project Costing
        </v-btn>
      </template>
      <template v-slot:body>
        <v-form
          ref="projectCostingForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 75vh; position: static"
          >
            <v-container fluid>
              <v-row>
                <v-col md="6">
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Reference #</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.reference"
                        dense
                        filled
                        color="cyan"
                        label="Reference #"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        solo
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Customer</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.customer"
                        dense
                        filled
                        color="cyan"
                        label="Customer"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        solo
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Customer PO No.</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.customer_po_no"
                        dense
                        filled
                        color="cyan"
                        label="Customer PO No."
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        solo
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Customer PO Date</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <DatePicker
                        v-model.trim="costingObject.customer_po_date"
                        solo
                        flat
                        showDetails
                      ></DatePicker>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Quotation #</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-autocomplete
                        dense
                        filled
                        v-model.number="costingObject.quotation_barcode"
                        :items="quotationList"
                        item-color="cyan"
                        color="cyan"
                        label="Quotation #"
                        solo
                        flat
                        return-object
                        v-on:change="updateProjectValue"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        item-value="id"
                        item-text="barcode"
                        :rules="[
                          validateRules.required(
                            costingObject.quotation_barcode,
                            'Quotation #'
                          ),
                        ]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              No Quotation(s) Found.
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6">
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Summary Report</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.vo"
                        dense
                        filled
                        color="cyan"
                        label="Summary Report"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        solo
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Project Name</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.project_name"
                        dense
                        filled
                        color="cyan"
                        label="Project Name"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        solo
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Project Value (PO)</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.project_po"
                        dense
                        filled
                        color="cyan"
                        label="Project Value (PO)"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        solo
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Project Value (VO)</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.project_vo"
                        dense
                        filled
                        color="cyan"
                        label="Project Value (VO)"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        solo
                        flat
                        type="number"
                        min="0"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <label class="font-weight-600 font-size-16"
                        >Total Price</label
                      >
                    </v-flex>
                    <v-flex md8>
                      <v-text-field
                        v-model.trim="costingObject.total_price"
                        dense
                        filled
                        color="cyan"
                        label="Total Price"
                        readonly
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        solo
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="12">
                  <v-simple-table class="simple-table">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="40px">S.No.</th>
                          <th class="simple-table-th">Description</th>
                          <th class="simple-table-th" width="200px">
                            Amount (SGD)
                          </th>
                          <th class="simple-table-th" width="200px" colspan="2">
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <v-btn
                              :disabled="formLoading || pageLoading"
                              :loading="formLoading"
                              class="custom-bold-button white--text"
                              v-on:click="addItem"
                              color="cyan"
                            >
                              Add Row
                            </v-btn>
                          </td>
                          <td
                            class="simple-table-td font-size-16"
                            colspan="2"
                            align="right"
                          >
                            Total Price :
                          </td>
                          <td
                            class="simple-table-td font-size-16"
                            width="200px"
                          >
                            {{
                              $accountingJS.formatMoney(
                                costingObject.total_price || 0
                              )
                            }}
                          </td>
                          <td
                            class="simple-table-td"
                            colspan="2"
                            width="200px"
                          ></td>
                        </tr>
                        <tr v-for="(item, index) in costingItems" :key="index">
                          <td class="simple-table-td">{{ index + 1 }}</td>
                          <td class="simple-table-td">
                            <v-text-field
                              v-model.trim="item.description"
                              dense
                              filled
                              color="cyan"
                              label="Description"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </td>
                          <td class="simple-table-td" width="200px">
                            <v-text-field
                              v-model.trim="item.amount"
                              dense
                              filled
                              color="cyan"
                              label="Amount (SGD)"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              solo
                              flat
                              type="number"
                              min="0"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td class="simple-table-td" width="150px">
                            <v-text-field
                              v-model.trim="item.remarks"
                              dense
                              filled
                              color="cyan"
                              label="Remarks"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </td>
                          <td class="simple-table-td" width="50px">
                            <template v-if="costingItems.length > 1">
                              <v-btn
                                icon
                                :disabled="formLoading || pageLoading"
                                :loading="formLoading"
                                v-on:click="removeItem(index)"
                                color="red lighten-1"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="simple-table-td font-size-16"
                            colspan="4"
                            align="right"
                          >
                            Total Cost :
                          </td>
                          <td
                            class="simple-table-td font-size-16"
                            width="200px"
                          >
                            {{ $accountingJS.formatMoney(totalCost) }}
                          </td>
                          <td
                            class="simple-table-td"
                            colspan="2"
                            width="200px"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            class="simple-table-td font-size-16"
                            colspan="4"
                            align="right"
                          >
                            Profit :
                          </td>
                          <td
                            class="simple-table-td font-size-16"
                            width="200px"
                          >
                            {{ $accountingJS.formatMoney(profit) }}
                          </td>
                          <td
                            class="simple-table-td"
                            colspan="2"
                            width="200px"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            class="simple-table-td font-size-16"
                            colspan="4"
                            align="right"
                          >
                            Profit Percentage :
                          </td>
                          <td
                            class="simple-table-td font-size-16"
                            width="200px"
                          >
                            {{ $accountingJS.formatNumber(profitPercentage) }}%
                          </td>
                          <td
                            class="simple-table-td"
                            colspan="2"
                            width="200px"
                          ></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </perfect-scrollbar>
        </v-form>
      </template>
    </CreateUpdateTemplate>
  </v-container>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { QUERY, POST, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  name: "project-costing-create",
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      project: 0,
      projectCosting: 0,
      projectObject: {},
      customerObject: {},
      quotationList: [],
      costingObject: {
        customer: null,
        customer_po_no: null,
        customer_po_date: null,
        quotation_barcode: null,
        vo: null,
        project_name: null,
        project_po: null,
        project_vo: null,
        total_price: null,
      },
      pageLoading: true,
      formLoading: false,
      costingItems: [
        {
          description: null,
          amount: null,
          remarks: null,
        },
      ],
    };
  },
  watch: {
    "costingObject.project_po"() {
      this.calculateTotalPrice();
    },
    "costingObject.project_vo"() {
      this.calculateTotalPrice();
    },
  },
  components: {
    CreateUpdateTemplate,
    DatePicker,
  },
  methods: {
    calculateTotalPrice() {
      let project_po = this.lodash.toNumber(this.costingObject.project_po);
      let project_vo = this.lodash.toNumber(this.costingObject.project_vo);
      if (!isNaN(project_po) && !isNaN(project_vo)) {
        this.costingObject.total_price = project_po + project_vo;
      }
    },
    updateProjectValue(param) {
      this.costingObject.project_po = param.total || null;
    },
    addItem() {
      this.costingItems.push({
        description: null,
        amount: null,
        remarks: null,
      });
    },
    removeItem(index) {
      this.costingItems.splice(index, 1);
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.projectCostingForm.validate()) {
        return false;
      }

      const lineItems = [];

      for (let i = 0; i < _this.costingItems.length; i++) {
        lineItems.push({
          description: _this.costingItems[i].description,
          remarks: _this.costingItems[i].remarks,
          amount: _this.costingItems[i].amount,
        });
      }

      const formData = {
        project: _this.project,
        quotation: _this.costingObject.quotation_barcode
          ? _this.costingObject.quotation_barcode.id
          : null,
        customer_po_no: _this.costingObject.customer_po_no || null,
        customer_po_date: _this.costingObject.customer_po_date || null,
        vo: _this.costingObject.vo || null,
        project_vo: _this.costingObject.project_vo || null,
        items: lineItems,
      };

      let requestMethod = POST;
      let requestURL = "project/costing";
      if (_this.projectCosting > 0) {
        requestMethod = PUT;
        requestURL = "project/costing/" + _this.projectCosting;
      }
      _this.formLoading = true;
      _this.$store
        .dispatch(requestMethod, {
          url: requestURL,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("project.costing.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getProjectCosting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "project/costing/" + _this.projectCosting,
        })
        .then(({ data }) => {
          _this.costingObject.quotation_barcode = data.quotation;
          _this.costingObject.customer_po_no = data.customer_po_no;
          _this.costingObject.customer_po_date = data.customer_po_date;
          _this.costingObject.vo = data.vo;
          _this.costingObject.project_vo = data.project_vo;
          _this.costingObject.project_po = data.project_po;
          _this.costingItems = [];
          for (let i = 0; i < data.items.length; i++) {
            _this.costingItems.push({
              description: data.items[i].description,
              amount: data.items[i].amount,
              remarks: data.items[i].remarks,
            });
          }
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Project", route: "project" },
            { title: "Costing" },
            { title: "Update" },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getAttributes() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "project/costing/attributes",
          data: { project: _this.project },
        })
        .then(({ data }) => {
          _this.projectObject = data.project;
          _this.customerObject = data.customer;
          _this.quotationList = data.quotation;
          _this.costingObject.reference = _this.projectObject.reference;
          _this.costingObject.customer = _this.customerObject.company_name;
          _this.costingObject.project_name = _this.projectObject.name;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getAttributes();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Project", route: "project" },
      { title: "Costing" },
      { title: "Create" },
    ]);
  },
  created() {
    this.project = this.lodash.toSafeInteger(this.$route.query.project) || 0;
    if (this.project <= 0) {
      this.goBack();
    }

    this.projectCosting = this.lodash.toSafeInteger(this.$route.params.id) || 0;
    if (this.projectCosting > 0) {
      this.getProjectCosting();
    }
  },
  computed: {
    totalCost() {
      let cost = 0;
      for (let i = this.costingItems.length - 1; i >= 0; i--) {
        cost += this.lodash.toNumber(this.costingItems[i].amount);
      }
      return this.lodash.toNumber(cost) || 0;
    },
    profit() {
      let profit = this.costingObject.total_price - this.totalCost;
      if (!isNaN(profit)) {
        return this.lodash.toNumber(profit);
      }
      return 0;
    },
    profitPercentage() {
      let profit_percentage =
        (this.profit * 100) / this.costingObject.total_price;
      if (!isNaN(profit_percentage)) {
        return this.lodash.toNumber(profit_percentage);
      }
      return 0;
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  },
};
</script>
