<template>
  <v-container fluid class="white-background main-create-page">
    <v-layout>
      <v-flex>
        <h1 class="form-title text-truncate">
          {{ get_title(true) }} For
          <span
            class="form-title-create-link"
            link
            v-if="!lodash.isEmpty(customer)"
            >{{ customer.display_name }}</span
          >
        </h1>
      </v-flex>
    </v-layout>
    <v-form
      ref="proposal_form"
      v-model="form_status"
      lazy-validation
      v-on:submit.stop.prevent="update_or_create('quote')"
    >
      <v-layout class="mx-8 my-4">
        <v-flex md7 sm7>
          <table width="100%">
            <tr>
              <td>
                <label
                  for="proposal-title"
                  class="required font-weight-600 font-size-16"
                  >{{ get_title() }} Title</label
                >
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field
                  id="proposal-title"
                  dense
                  filled
                  :disabled="form_loading"
                  :loading="form_loading"
                  color="cyan"
                  :label="`${get_title()} Title`"
                  solo
                  flat
                  v-model="proposal.title"
                  :rules="[
                    validateRules.required(
                      proposal.title,
                      `${get_title()} Title`
                    ),
                  ]"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  for="proposal-description"
                  class="font-weight-600 font-size-16"
                  >Description</label
                >
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  id="proposal-description"
                  auto-grow
                  dense
                  filled
                  :disabled="form_loading"
                  :loading="form_loading"
                  color="cyan"
                  label="Description"
                  solo
                  flat
                  row-height="25"
                  v-model="proposal.description"
                ></v-textarea>
              </td>
            </tr>
          </table>
          <v-layout>
            <v-flex v-if="false" md4>
              <label class="font-size-16 font-weight-600"
                >Service Address&nbsp;&nbsp;&nbsp;<v-icon
                  v-if="false"
                  color="cyan"
                  small
                  >mdi-pencil</v-icon
                ></label
              >
              <p class="font-size-16 m-0">
                <template v-if="!lodash.isEmpty(property)">{{
                  property.property_address
                }}</template>
              </p>
            </v-flex>
            <template v-if="!lodash.isEmpty(billing)">
              <v-flex class="mr-2">
                <label class="font-size-16 font-weight-600"
                  >Site Address&nbsp;&nbsp;&nbsp;<v-icon
                    v-if="false"
                    color="cyan"
                    small
                    >mdi-pencil</v-icon
                  ></label
                >
                <p class="font-size-16 m-0">
                  {{ billing.property_address }}
                </p>
              </v-flex>
            </template>
            <template v-if="!lodash.isEmpty(contact_person)">
              <v-flex class="mx-2">
                <label class="font-size-16 font-weight-600"
                  >Contact Person&nbsp;&nbsp;&nbsp;<v-icon
                    v-if="false"
                    color="cyan"
                    small
                    >mdi-pencil</v-icon
                  ></label
                >
                <p class="font-size-16 m-0">{{ contact_person.full_name }}</p>
                <p class="font-size-16 m-0">
                  {{ contact_person.primary_phone }}
                </p>
                <p class="font-size-16 m-0">
                  {{ contact_person.primary_email }}
                </p>
              </v-flex>
            </template>
            <template v-if="!lodash.isEmpty(new_contact_person)">
              <v-flex class="ml-2">
                <label class="font-size-16 font-weight-600"
                  >Contact Person&nbsp;&nbsp;&nbsp;<v-icon
                    v-if="false"
                    color="cyan"
                    small
                    >mdi-pencil</v-icon
                  ></label
                >
                <p class="font-size-16 m-0">
                  {{ new_contact_person.full_name }}
                </p>
                <p class="font-size-16 m-0">
                  {{ new_contact_person.primary_phone }}
                </p>
                <p class="font-size-16 m-0">
                  {{ new_contact_person.primary_email }}
                </p>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
        <v-flex md5 sm5 class="ml-10">
          <table width="100%">
            <tr>
              <td colspan="2" class="custom-border-bottom">
                <label class="font-weight-600 font-size-16"
                  >{{ get_title(true) }} Details</label
                >
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                {{ get_title(true) }} number
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <template v-if="proposal.barcode">{{
                  proposal.barcode
                }}</template>
                <template v-else>{{ barcode }}</template>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                {{ get_title(true) }} Date
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <date-picker
                  solo
                  v-model="proposal.date"
                  :min-date="today_date"
                ></date-picker>
              </td>
            </tr>
            <tr v-if="false">
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Rate opportunity
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-rating
                  background-color="orange lighten-3"
                  color="orange"
                  :disabled="form_loading"
                  v-model="proposal.rating"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Reference #
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Reference #"
                  solo
                  hide-details
                  flat
                  :disabled="form_loading"
                  :loading="form_loading"
                  v-model="proposal.reference"
                ></v-text-field>
              </td>
            </tr>
            <tr v-if="isPackage">
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Project
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  label="Project"
                  solo
                  flat
                  item-color="cyan"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :disabled="form_loading"
                  :loading="form_loading"
                  :items="project_list"
                  v-model="proposal.project"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>No Project Found.</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip
                      small
                      outlined
                      label
                      color="chip-custom-blue"
                      class="font-size-16 font-weight-500"
                      >{{ item.barcode }}</v-chip
                    >
                    <p
                      class="ml-2 font-size-16 font-weight-500 text-truncate mb-0"
                      style="max-width: 250px"
                    >
                      {{ item.name }}
                    </p>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip
                      small
                      outlined
                      label
                      color="chip-custom-blue"
                      class="font-size-16 font-weight-500"
                      >{{ item.barcode }}</v-chip
                    >
                    <p
                      class="ml-2 font-size-16 font-weight-500 text-truncate mb-0"
                      style="max-width: 150px"
                    >
                      {{ item.name }}
                    </p>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr v-if="false && !isPackage">
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Sales representative
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  label="Sales representatives"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  :disabled="form_loading"
                  :loading="form_loading"
                  :items="sales_executive_list"
                  v-model="proposal.sales_person"
                  v-on:change="update_sales()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >No Sales Representative Found.</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Prepared by
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-text-field
                  dense
                  filled
                  hide-details
                  color="cyan"
                  label="Prepared by"
                  solo
                  flat
                  :disabled="form_loading"
                  :loading="form_loading"
                  v-model="proposal.prepared_by"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                CO-Designer
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  label="CO-Designer"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  :disabled="form_loading"
                  :loading="form_loading"
                  :items="sales_executive_list"
                  v-model="proposal.assistant"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >No CO-Designer Found.</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr v-if="false">
              <td height="50px" class="font-weight-500 font-size-16">
                Email Notification
              </td>
              <td height="50px" class="font-weight-600 font-size-16">
                <v-layout>
                  <v-flex md6>
                    <v-checkbox
                      hide-details
                      class="my-0 py-0"
                      color="cyan"
                      label="Admin"
                      :true-value="1"
                      :false-value="0"
                      :disabled="form_loading"
                      v-model="proposal.admin_notify"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md6>
                    <v-checkbox
                      hide-details
                      class="my-0 py-0"
                      color="cyan"
                      label="Customer"
                      :true-value="1"
                      :false-value="0"
                      :disabled="form_loading"
                      v-model="proposal.customer_notify"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
          </table>
        </v-flex>
      </v-layout>
      <v-layout class="mx-8 my-4">
        <v-flex md12>
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Line Items
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16 custom-line-item-template">
              <div>
                <v-btn
                  color="cyan"
                  small
                  outlined
                  class="custom-bold-button ml-2"
                  v-on:click="package_dialog = true"
                >
                  Package
                </v-btn>
              </div>
              <br />
              <line-item-package
                :item-instance="itemInstance"
                :uom-list="uomList"
                :is-package="isPackage"
                v-model="packages"
                item-title="Package"
                :disabled="form_loading"
                :update-mode="update_mode"
              ></line-item-package>
              <br />
              <line-item
                :item-instance="itemInstance"
                :uom-list="uomList"
                color="orange--text text--darken-4"
                v-if="!isPackage"
                :is-package="isPackage"
                v-model="line_items"
                item-title="Additional Works"
                :disabled="form_loading"
                :update-mode="update_mode"
              ></line-item>
              <v-row>
                <v-col md6>
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td height="50px" align="left" width="100%">
                        <v-btn
                          v-on:click="add_adtitle()"
                          small
                          depressed
                          color="cyan white--text"
                          class="custom-bold-button white--text"
                        >
                          <v-icon>mdi-plus</v-icon> Add Additional Work Title
                        </v-btn>
                      </td>
                    </tr>
                  </table>
                  <table v-if="false" width="100%" style="table-layout: fixed">
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Supplier Cost</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ get_total_supplier_costing() }}</label
                            >
                          </template>
                          <span>Sum of All Line Item Total Supplier Cost</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Profit</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ get_total_profit() }}</label
                            >
                          </template>
                          <span>Sub Total - Total Supplier Cost</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Quotation Mark Up (in %)</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ get_proposal_markup() }}%</label
                            >
                          </template>
                          <span>(Sub Total / Total Supplier Cost) - 1</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Quotation Profit (in %)</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ get_proposal_profit() }}%</label
                            >
                          </template>
                          <span>Total Profit / Sub Total</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col md6>
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Sub Total</label
                        >
                      </td>
                      <td height="50px" align="center" width="35%"></td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.sub_total)
                        }}</label>
                      </td>
                    </tr>
                    <tr v-if="!isPackage">
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Optional Total</label
                        >
                      </td>
                      <td height="50px" align="center" width="35%"></td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.optional_total)
                        }}</label>
                      </td>
                    </tr>
                    <tr class="qt-discount">
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Discount
                        </label>
                      </td>
                      <td height="50px" align="center" width="35%">
                        <v-layout class="ml-4">
                          <v-flex md6>
                            <v-text-field
                              dense
                              filled
                              color="cyan"
                              label="Value"
                              solo
                              flat
                              v-model="ltxCalculation.discount_value"
                              type="number"
                              v-on:keypress="is_number($event)"
                              hide-details
                              clearable
                              :disabled="
                                form_loading || ltxCalculation.sub_total < 1
                              "
                              :loading="form_loading"
                            />
                          </v-flex>
                          <v-flex md6>
                            <v-select
                              :items="discount_type_list"
                              dense
                              filled
                              flat
                              hide-details
                              solo
                              item-text="type"
                              item-value="value"
                              color="cyan"
                              item-color="cyan"
                              v-model="ltxCalculation.discount_type"
                              :disabled="
                                form_loading || ltxCalculation.sub_total < 1
                              "
                              class="discount-append-outer"
                              :loading="form_loading"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.discount_amount)
                        }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Tax [{{ formatNumber(ltxCalculation.tax_value) }}%]
                        </label>
                      </td>
                      <td height="50px" align="center" width="35%">
                        <v-layout class="align-center">
                          <v-flex md3 class="text-left">
                            <v-checkbox
                              readonly
                              hide-details
                              class="ml-4 my-0 py-0"
                              color="cyan"
                              :true-value="1"
                              :false-value="0"
                              v-model="ltxCalculation.tax_applied"
                              :disabled="
                                form_loading || ltxCalculation.sub_total < 1
                              "
                            />
                          </v-flex>
                          <v-flex md9 class="text-left">
                            <template v-if="ltxCalculation.tax_applied">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="cursor-pointer font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoney(ltxCalculation.taxable_amount)
                                    }}</label
                                  >
                                </template>
                                <span class="text-capitalize"
                                  >Taxable Amount</span
                                >
                              </v-tooltip>
                            </template>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.tax_amount)
                        }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Adjustment</label
                        >
                      </td>
                      <td height="50px" align="center" width="35%">
                        <v-text-field
                          dense
                          filled
                          color="cyan"
                          label="Adjustment"
                          solo
                          flat
                          class="ml-4"
                          v-model="ltxCalculation.adjustment"
                          type="number"
                          v-on:keypress="is_number($event)"
                          hide-details
                          clearable
                          :disabled="
                            form_loading || ltxCalculation.sub_total < 1
                          "
                          :loading="form_loading"
                        />
                      </td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.adjustment)
                        }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Grand Total
                        </label>
                      </td>
                      <td height="50px" align="center" width="35%"></td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.total)
                        }}</label>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout v-if="false" class="mx-8 my-4">
        <v-flex md12>
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Terms &amp; Conditions
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <tiny-mce
                :disabled="form_loading"
                v-model="proposal.term_conditions"
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout class="mx-8 my-4">
        <v-flex md12>
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Internal Notes &amp; Attachments
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-layout>
                <v-flex class="mr-4" md6>
                  <label class="font-size-16 font-weight-600"
                    >Internal Note</label
                  >
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Internal Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="form_loading"
                    :loading="form_loading"
                    :items="adminNoteList"
                    v-model="proposal.admin_note_id"
                    v-on:change="update_admin_note()"
                  >
                  </v-autocomplete>
                  <tiny-mce
                    :disabled="form_loading"
                    v-model="proposal.admin_note"
                  />
                </v-flex>
                <v-flex md6>
                  <label class="font-size-16 font-weight-600"
                    >Client Note
                    <span class="text--secondary font-weight-500"
                      >(visible on PDF)</span
                    ></label
                  >
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Client Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="form_loading"
                    :loading="form_loading"
                    :items="clientNoteList"
                    v-model="proposal.client_note_id"
                    v-on:change="update_client_note()"
                  >
                  </v-autocomplete>
                  <tiny-mce
                    :disabled="form_loading"
                    v-model="proposal.client_note"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-4">
                <v-flex md6 class="mr-4">
                  <label class="font-size-16 font-weight-600"
                    >Attachments</label
                  >
                  <table width="100%">
                    <tbody>
                      <tr
                        v-for="(row, index) in proposal.attachments"
                        :key="index"
                      >
                        <td width="50%" class="pr-2 py-2">
                          <v-file-input
                            prepend-inner-icon="mdi-attachment mdi-rotate-90"
                            placeholder="Click here to select file"
                            label="Click here to select file"
                            prepend-icon=""
                            color="cyan"
                            solo
                            flat
                            v-model="row.accepted_file"
                            dense
                            v-on:change="update_file_name(index)"
                            hide-details
                          ></v-file-input>
                        </td>
                        <td width="50%" class="pl-2 py-2">
                          <v-text-field
                            placeholder="File Name"
                            label="File Name"
                            dense
                            solo
                            flat
                            color="cyan"
                            v-model="row.file_name"
                            hide-details
                            class="has-delete-outer-icon"
                            append-outer-icon="mdi-delete"
                            v-on:click:append-outer="remove_row(index)"
                            :suffix="get_file_extension(row.file_type)"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td height="10px" colspan="2"></td>
                      </tr>
                      <tr v-if="proposal.attachments.length < 5">
                        <td colspan="2">
                          <v-btn
                            color="cyan white--text"
                            small
                            v-on:click="add_attachment_row()"
                            class="custom-bold-button"
                            >Add More...</v-btn
                          >
                        </td>
                      </tr>
                      <tr>
                        <td height="10px" colspan="2"></td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-4"> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout class="mx-8 mt-8 mb-4">
        <v-flex class="text-right">
          <v-btn
            v-on:click="goBack()"
            class="mx-2 custom-grey-border custom-bold-button"
            depressed
            :disabled="form_loading"
          >
            Cancel
          </v-btn>
          <v-btn
            class="ml-2 custom-bold-button white--text"
            color="cyan"
            :loading="form_loading"
            :disabled="form_loading || !form_status"
            v-on:click="update_or_create('quote')"
          >
            <template v-if="update_mode">Update</template>
            <template v-else>Save</template> {{ get_title(true) }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <line-item-package-search-dialog
      :is-package="isPackage"
      :update-mode="update_mode"
      v-model="packages"
      item-title="Package"
      item-type="package"
      :item-dialog="package_dialog"
      v-on:close="package_dialog = false"
    ></line-item-package-search-dialog>
    <line-item-search-dialog
      :is-package="isPackage"
      :update-mode="update_mode"
      v-model="line_items"
      item-title="Additional Work"
      item-type="service"
      :item-dialog="service_dialog"
      v-on:close="service_dialog = false"
    ></line-item-search-dialog>
    <prevent-dialog :common-dialog="route_prevent_dialog" :dialogWidth="600">
      <template v-slot:body>
        <v-layout>
          <v-flex md2 class="text-center">
            <!--begin::Svg Icon-->
            <inline-svg
              width="50"
              :src="$assetURL('media/custom-svg/attention-circle.svg')"
            />
            <!--end::Svg Icon-->
          </v-flex>
          <v-flex md10>
            <p class="font-weight-700">
              Are you sure you want to leave this page?
            </p>
            <v-layout>
              <v-flex>
                <v-btn
                  class="mr-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  small
                  v-on:click="stay_on_page()"
                >
                  Stay on this page
                </v-btn>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button"
                  small
                  v-on:click="leave_page()"
                >
                  Leave this page
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </prevent-dialog>
  </v-container>
</template>

<script>
import LineItemPackageSearchDialog from "@/view/pages/partials/Line-Item-Package-Search-Dialog";
import LineItemSearchDialog from "@/view/pages/partials/Line-Item-Search-Dialog";
import PreventDialog from "@/view/pages/partials/Dialog";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemPackage from "@/view/pages/partials/Line-Item-Package";
import {
  QUERY,
  POST,
  PUT,
  GET,
  UPLOAD,
} from "@/core/services/store/request.module";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import DatePicker from "@/view/pages/partials/Datepicker";
import ValidationMixin from "@/core/plugins/validation-mixin";
import LineItem from "@/view/pages/partials/Line-Item-NewV1";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ObjectPath from "object-path";
import moment from "moment-timezone";
import { toNumber, sum } from "lodash";

import {
  ADD_ITEM,
  CLEAR_LINE_ITEM,
  /*SET_PACKAGE,
  SET_LINE_ITEM,
  SET_CHILD_ITEM,
  SET_LEVEL2_CHILD_ITEM,
  GET_CHILD_LIST,
  SET_UOM_LIST,
  SET_DISCOUNT,*/
} from "@/core/services/store/line.item.module";

import ManageLineItem from "@/core/plugins/line.item.plugin";
import { mapGetters } from "vuex";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-proposal",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uomList: [],
      adminNoteList: [],
      clientNoteList: [],
      packages: [],
      line_items: [],
      itemInstance: {},
      back_force: false,
      form_status: true,
      form_loading: false,
      service_dialog: false,
      package_dialog: false,
      case_trust_dialog: false,
      route_prevent_dialog: false,
      update_mode: false,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      today_date: null,
      barcode: null,
      customer: {},
      property: {},
      billing: {},
      contact_person: {},
      new_contact_person: {},
      project_list: [],
      sales_executive_list: [],
      proposal_id: 0,
      customer_id: 0,
      is_duplicate: null,
      duplicate_id: 0,
      package_id: 0,
      proposal: {
        action: "quote",
        customer: 0,
        barcode: null,
        property: 0,
        billing: 0,
        project: 0,
        variation: 0,
        assistant: 0,
        variation_proposal: 0,
        is_variation: 0,
        date: null,
        contact_person: 0,
        new_contact_person: 0,
        admin_note_id: 0,
        client_note_id: 0,
        title: null,
        description: null,
        rating: 1,
        reference: null,
        sales_person: 0,
        sales: null,
        prepared_by: null,
        prepared_by_id: null,
        admin_notify: 0,
        customer_notify: 0,
        discount_value: null,
        discount_type: 1,
        is_taxed: 1,
        adjustment: null,
        term_conditions: null,
        client_note: null,
        admin_note: null,
        case_trust: {
          agreement_date: null,
          first_party: {
            name: null,
            contact_no: null,
            nric_no: null,
          },
          second_party: {
            name: null,
            contact_no: null,
            nric_no: null,
          },
          address: null,
          estimated_date: null,
          package_sum: null,
          additional_work_sum: null,
          standalone_contract_sum: null,
          package: [
            {
              col_one: null,
              col_two: "% payable at various stages",
              col_three: "Amount payable at various stages",
            },
            {
              col_one: "Immediately upon Signing this Agreement",
              col_two: "10% of package or $1,200 (whichever is higher)",
              col_three: null,
            },
            {
              col_one: "Commencement of Work",
              col_two: "10% of package or $1,200 (whichever is higher)",
              col_three: null,
            },
            {
              col_one: "Carpentry Fabrication",
              col_two: "45% of package",
              col_three: null,
            },
            {
              col_one: "Handover Date",
              col_two: "5% of package",
              col_three: "Amount payable at various stages",
            },
            {
              col_one: "Additional Works",
              col_two: "100% of additional works",
              col_three: "To show detailed breakdown at full proposal",
            },
          ],
          contract: [
            {
              col_one: null,
              col_two: "% payable at various stages",
              col_three: "Amount payable at various stages",
            },
            {
              col_one: "Immediately upon Signing this Agreement",
              col_two: "10% of contract",
              col_three: null,
            },
            {
              col_one: "Commencement of Work",
              col_two: "40% of contract",
              col_three: null,
            },
            {
              col_one: "Carpentry Fabrication",
              col_two: "45% of contract",
              col_three: null,
            },
            {
              col_one: "Handover Date",
              col_two: "5% of contract",
              col_three: null,
            },
          ],
        },
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      total_supplier_cost: 0,
      total_profit: 0,
      sub_total: 0,
      proposal_attachments: [],
    };
  },
  components: {
    "tiny-mce": TinyMCE,
    "line-item": LineItem,
    "date-picker": DatePicker,
    "prevent-dialog": PreventDialog,
    "line-item-package": LineItemPackage,
    "line-item-search-dialog": LineItemSearchDialog,
    "line-item-package-search-dialog": LineItemPackageSearchDialog,
  },
  methods: {
    add_adtitle() {
      this.$store.dispatch(ADD_ITEM);
    },
    get_total_supplier_costing() {
      let _this = this;

      let total_supplier_cost = [];

      for (let i = 0; i < _this.packages.length; i++) {
        let total_cost =
          toNumber(_this.packages[i].company_cost) *
          toNumber(_this.packages[i].quantity);
        total_supplier_cost.push(total_cost);
      }

      for (let z = 0; z < _this.line_items.length; z++) {
        let child_items = _this.line_items[z].child_items;
        for (let y = 0; y < child_items.length; y++) {
          let total_cost =
            toNumber(child_items[y].company_cost) *
            toNumber(child_items[y].quantity);
          total_supplier_cost.push(total_cost);
          let items = child_items[y].items;
          for (let x = 0; x < items.length; x++) {
            let total_cost =
              toNumber(items[x].company_cost) * toNumber(items[x].quantity);
            total_supplier_cost.push(total_cost);
          }
        }
      }

      this.total_supplier_cost = sum(total_supplier_cost);

      return _this.formatMoney(this.total_supplier_cost);
    },
    get_total_profit() {
      this.sub_total = this.get_total_sum(0);

      this.total_profit = this.sub_total - this.total_supplier_cost;

      return this.formatMoney(this.total_profit);
    },
    get_proposal_markup() {
      let output = 0;
      if (this.total_supplier_cost > 0) {
        output = (this.sub_total / this.total_supplier_cost - 1) * 100;
      }

      return this.formatNumber(output);
    },
    get_proposal_profit() {
      let output = (this.total_profit / this.sub_total) * 100;

      return this.formatNumber(output);
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.proposal.admin_note_id,
      });
      if (output) {
        this.proposal.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.proposal.client_note_id,
      });
      if (output) {
        this.proposal.client_note = output.description;
      }
    },
    get_title() {
      return "Proposal";
    },
    update_sales() {
      const sales_executive = this.lodash.find(this.sales_executive_list, {
        id: this.proposal.sales_person,
      });
      if (sales_executive) {
        this.proposal.sales = sales_executive.full_name;
      }
    },
    remove_row(index) {
      ObjectPath.del(this.proposal.attachments, index);
      this.$nextTick(() => {
        if (!this.proposal.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      if (this.proposal.attachments.length < 5) {
        this.proposal.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.proposal.attachments[index].accepted_file;
      if (file) {
        this.proposal.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.proposal.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    get_total_sum() {
      let list_one = [];

      let list_two = list_one.concat(this.line_items);

      let list_three = list_two.concat(this.packages);

      let not_optional = this.lodash.filter(list_three, { is_optional: 0 });

      let non_stike = this.lodash.filter(not_optional, { strike: 0 });

      let child_sum = this.lodash.map(this.line_items, "child_sum");

      let result = this.lodash.map(non_stike, "total");

      return this.lodash.sum([...result, ...child_sum]);
    },
    get_optional_sum() {
      let list_one = [];

      let list_two = list_one.concat(this.line_items);

      let list_three = list_two.concat(this.packages);

      let optional_self = this.lodash.filter(list_three, { is_optional: 1 });

      let optional_child = this.lodash.filter(list_three, { is_optional: 0 });

      let non_stike = this.lodash.filter(optional_self, { strike: 0 });

      let child_sum = this.lodash.map(non_stike, "child_sum");

      let result = this.lodash.map(non_stike, "total");

      let optional_child_sum = this.lodash.map(
        optional_child,
        "optional_child_sum"
      );

      return this.lodash.sum([...child_sum, ...result, ...optional_child_sum]);
    },
    get_sub_total() {
      const output = this.get_total_sum(0);
      return this.formatMoney(output);
    },
    get_optional_total() {
      const output = this.get_optional_sum();
      return this.formatMoney(output);
    },
    get_optional_sub_total() {
      const output_one = this.get_total_sum(0);
      const output_two = this.get_total_sum(1);
      return this.formatMoney(output_one + output_two);
    },
    get_tax_total() {
      if (this.proposal.is_taxed) {
        const output = this.get_taxable_amount_total();
        const tax_amount = (this.accountingUnFormat(output) / 100) * 8;
        return this.formatMoney(tax_amount);
      }

      return this.formatMoney();
    },
    get_tax_without_discount_total() {
      if (this.proposal.is_taxed) {
        const output = this.get_sub_total();
        const tax_amount = (this.accountingUnFormat(output) / 100) * 8;
        return this.formatMoney(tax_amount);
      }

      return this.formatMoney();
    },
    get_discount_total(evt) {
      const discount_type = this.proposal.discount_type;
      const discount_value = this.lodash.toNumber(this.proposal.discount_value);
      if (discount_value > 0) {
        const output = this.get_total_sum(0);
        if (discount_type == 1) {
          if (discount_value > output) {
            if (evt) {
              this.proposal.discount_value = output;
              evt.preventDefault();
              return this.formatMoney(this.proposal.discount_value);
            }
          }
          return this.formatMoney(discount_value);
        } else if (discount_type == 2) {
          if (discount_value > 100) {
            if (evt) {
              this.proposal.discount_value = 100;
              evt.preventDefault();
              return this.formatMoney(this.proposal.discount_value);
            }
          }
          const discount_amount = (output / 100) * discount_value;
          return this.formatMoney(discount_amount);
        }
      }
      return this.formatMoney();
    },
    calc_adjustment_total() {
      this.$nextTick(() => {
        const adjustment = this.lodash.toNumber(this.proposal.adjustment);
        const output = this.get_total_sum(0);
        if (adjustment > output) {
          this.proposal.adjustment = output ? output.toFixed(2) : 0;
        } else {
          this.proposal.adjustment = adjustment ? adjustment.toFixed(2) : 0;
        }
      });
    },
    get_taxable_amount_total() {
      const total = this.get_total_sum(0);
      const discount = this.get_discount_total(null);
      const taxable_amount = total - this.accountingUnFormat(discount);
      return this.formatMoney(taxable_amount);
    },
    get_grand_total() {
      const taxable_amount = this.get_taxable_amount_total();
      const tax_amount = this.get_tax_total();
      const adjustment = this.proposal.adjustment;
      const grand_total =
        this.accountingUnFormat(taxable_amount) +
        this.accountingUnFormat(tax_amount) +
        this.accountingUnFormat(adjustment);
      return this.formatMoney(grand_total);
    },
    get_grand_without_discount_total() {
      let total = this.get_total_sum(0);
      let adjustment = this.proposal.adjustment;
      let tax_amount = 0;
      if (this.proposal.is_taxed) {
        tax_amount = (this.accountingUnFormat(total) / 100) * 8;
      }
      let grand_total =
        this.accountingUnFormat(total) +
        this.accountingUnFormat(tax_amount) +
        this.accountingUnFormat(adjustment);
      return this.formatMoney(grand_total);
    },
    get_attributes() {
      if (!this.customer_id) {
        return false;
      }

      this.$store
        .dispatch(QUERY, {
          url: "proposal/options",
          data: { customer: this.customer_id },
        })
        .then((response) => {
          this.barcode = ObjectPath.get(response, "data.barcode");
          this.customer = ObjectPath.get(response, "data.customer");
          this.uomList = ObjectPath.get(response, "data.uomlist");
          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list"
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list"
          );
          this.sales_executive_list = ObjectPath.get(
            response,
            "data.sales_user_list"
          );

          if (!this.update_mode) {
            let defaultClientObject = this.lodash.find(this.clientNoteList, {
              primary: 1,
            });
            if (defaultClientObject) {
              this.proposal.client_note_id = defaultClientObject.id;
              this.proposal.client_note = defaultClientObject.description;
            }

            let defaultAdminObject = this.lodash.find(this.adminNoteList, {
              primary: 1,
            });
            if (defaultAdminObject) {
              this.proposal.admin_note_id = defaultAdminObject.id;
              this.proposal.admin_note = defaultAdminObject.description;
            }
          }

          /*this.proposal.admin_note = ObjectPath.get(
            response,
            "data.option.admin_note"
          );
          this.proposal.client_note = ObjectPath.get(
            response,
            "data.option.client_note"
          );*/
          this.proposal.term_conditions = ObjectPath.get(
            response,
            "data.option.terms_conditions"
          );

          this.proposal.prepared_by = ObjectPath.get(
            response,
            "data.user_name",
            null
          );
          this.proposal.prepared_by_id = ObjectPath.get(
            response,
            "data.user_id",
            0
          );
        })
        .catch((error) => {
          this.logError(error);
          this.back_force = true;
          this.goBack();
        })
        .finally(() => {
          // this.pageLoading = false;
        });
    },
    stay_on_page() {
      PreventDialogEventBus.$emit("stay-on-page", true);
    },
    leave_page() {
      PreventDialogEventBus.$emit("leave-page", true);
    },
    get_proposal(proposal_id) {
      this.$store
        .dispatch(GET, { url: `proposal/${proposal_id}` })
        .then((response) => {
          this.proposal.barcode = ObjectPath.get(
            response,
            "data.proposal.barcode",
            null
          );
          this.proposal.customer = ObjectPath.get(
            response,
            "data.proposal.customer",
            0
          );
          this.proposal.variation = ObjectPath.get(
            response,
            "data.proposal.variation",
            0
          );
          this.proposal.variation_proposal = ObjectPath.get(
            response,
            "data.proposal.variation_proposal",
            0
          );
          this.proposal.property = ObjectPath.get(
            response,
            "data.proposal.property",
            0
          );
          this.proposal.admin_note_id = ObjectPath.get(
            response,
            "data.proposal.admin_note_id",
            0
          );
          this.proposal.client_note_id = ObjectPath.get(
            response,
            "data.proposal.client_note_id",
            0
          );
          this.proposal.billing = ObjectPath.get(
            response,
            "data.proposal.billing",
            0
          );
          this.proposal.project = ObjectPath.get(
            response,
            "data.proposal.project",
            0
          );
          this.proposal.date = ObjectPath.get(
            response,
            "data.proposal.date",
            null
          );
          this.proposal.contact_person = ObjectPath.get(
            response,
            "data.proposal.contact_person",
            0
          );
          this.proposal.new_contact_person = ObjectPath.get(
            response,
            "data.proposal.new_contact_person",
            0
          );
          this.proposal.title = ObjectPath.get(
            response,
            "data.proposal.proposal_title",
            null
          );
          this.proposal.description = ObjectPath.get(
            response,
            "data.proposal.description",
            null
          );
          this.proposal.rating = ObjectPath.get(
            response,
            "data.proposal.rating",
            1
          );
          this.proposal.reference = ObjectPath.get(
            response,
            "data.proposal.reference",
            null
          );
          this.proposal.sales_person = ObjectPath.get(
            response,
            "data.proposal.sales_person",
            null
          );
          this.proposal.sales = ObjectPath.get(
            response,
            "data.proposal.sales",
            null
          );
          this.proposal.prepared_by = ObjectPath.get(
            response,
            "data.proposal.prepared_by",
            null
          );
          this.proposal.prepared_by_id = ObjectPath.get(
            response,
            "data.proposal.prepared_by_id",
            null
          );
          this.proposal.assistant = ObjectPath.get(
            response,
            "data.proposal.assistant",
            null
          );
          this.proposal.admin_notify = ObjectPath.get(
            response,
            "data.proposal.notify_admin",
            0
          );
          this.proposal.customer_notify = ObjectPath.get(
            response,
            "data.proposal.notify_customer",
            0
          );
          this.proposal.discount_value = ObjectPath.get(
            response,
            "data.proposal.discount_value",
            0
          );
          this.proposal.discount_type = ObjectPath.get(
            response,
            "data.proposal.discount_type",
            1
          );
          this.proposal.is_taxed = ObjectPath.get(
            response,
            "data.proposal.is_taxed",
            0
          );
          this.proposal.adjustment = ObjectPath.get(
            response,
            "data.proposal.adjustment",
            0
          );
          this.proposal.term_conditions = ObjectPath.get(
            response,
            "data.proposal.term_conditions",
            null
          );
          this.proposal.client_note = ObjectPath.get(
            response,
            "data.proposal.client_remark",
            null
          );
          this.proposal.admin_note = ObjectPath.get(
            response,
            "data.proposal.admin_remark",
            null
          );
          this.proposal.is_variation = ObjectPath.get(
            response,
            "data.proposal.is_variation",
            0
          );
          this.packages = ObjectPath.get(response, "data.packages", []);
          this.line_items = ObjectPath.get(response, "data.services", []);

          console.log({ line_items: this.line_items });

          const page_title = this.get_title();
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: page_title },
            { title: "Update" },
            { title: this.proposal.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
          this.back_force = true;
          this.goBack();
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
    upload_files(form_data) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: form_data })
          .then(({ data }) => {
            const output = this.lodash.map(data, "id");
            resolve(output);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async update_or_create(action) {
      const validateStatus = this.$refs.proposal_form.validate();

      const formErrors = this.validateForm(this.$refs.proposal_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (this.packages.length < 1 && this.line_items.length < 1) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Atleast one line item is required.")
        );
        return false;
      }

      this.form_loading = true;

      this.proposal_attachments = [];

      let attachments = ObjectPath.get(this.proposal, "attachments");

      if (attachments.length) {
        let do_update = false;
        let form_data = new FormData();
        for (let i = 0; i < attachments.length; i++) {
          let accepted_file = ObjectPath.get(attachments[i], "accepted_file");
          if (accepted_file instanceof File) {
            if (!do_update) {
              do_update = true;
            }
            form_data.append(`files[${i}]`, accepted_file, accepted_file.name);
          }
        }

        if (do_update) {
          this.proposal_attachments = await this.upload_files(form_data);
        }
      }

      const request = {
        action: action ? action : this.proposal.action,
        customer: this.customer_id,
        variation: this.proposal.variation,
        variation_proposal: this.proposal.variation_proposal,
        property: this.proposal.property,
        assistant: this.proposal.assistant,
        billing: this.proposal.billing,
        project: this.proposal.project,
        date: this.proposal.date,
        contact_person: this.proposal.contact_person,
        new_contact_person: this.proposal.new_contact_person,
        title: this.proposal.title,
        admin_note_id: this.proposal.admin_note_id,
        client_note_id: this.proposal.client_note_id,
        description: this.proposal.description,
        rating: this.proposal.rating,
        reference: this.proposal.reference,
        sales_person: this.proposal.sales_person,
        sales: this.proposal.sales,
        prepared_by: this.proposal.prepared_by,
        prepared_by_id: this.proposal.prepared_by_id,
        admin_notify: this.proposal.admin_notify,
        customer_notify: this.proposal.customer_notify,
        discount_value: this.proposal.discount_value,
        discount_type: this.proposal.discount_type,
        is_taxed: this.proposal.is_taxed,
        adjustment: this.proposal.adjustment,
        term_conditions: this.proposal.term_conditions,
        client_note: this.proposal.client_note,
        admin_note: this.proposal.admin_note,
        attachments: this.proposal_attachments,
        services: this.line_items,
        packages: this.packages,
        package: this.package_id,
        duplicate: this.duplicate_id,
        "is-variation": this.proposal.is_variation,
        "is-package": this.lodash.toSafeInteger(this.isPackage),
        supplier_cost: this.total_supplier_cost,
        profit: this.total_profit,
        proposal_markup: (this.sub_total / this.total_supplier_cost - 1) * 100,
        proposal_profit: (this.total_profit / this.sub_total) * 100,
      };

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "proposal";
      if (this.proposal_id && this.proposal_id > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "proposal/" + this.proposal_id;
      }

      this.$store
        .dispatch(REQUEST_TYPE, {
          url: REQUEST_URL,
          data: request,
        })
        .then((response) => {
          this.back_force = true;
          this.proposal_id = ObjectPath.get(response, "data.id", 0);
          this.$nextTick(() => {
            this.$router.replace(
              this.getDefaultRoute("proposal.detail", {
                params: { id: this.proposal_id },
              })
            );
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
  },
  created() {
    this.$store.commit(CLEAR_LINE_ITEM);
    this.itemInstance = new ManageLineItem();
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "ltxCalculation",
      "ltxPackage",
      "ltxLineItem",
      "ltxChildLineItem",
      "ltxLevel2ChildLineItem",
      "globalLoading",
    ]),
    loaderLogo() {
      return process.env.VUE_APP_BASE_URL + this.layoutConfig("loader.logo");
    },
  },
  mounted() {
    this.itemInstance?.init();

    this.get_attributes();

    if (!this.proposal_id) {
      this.proposal_id = ObjectPath.get(this.$route, "params.id", 0);
    }

    const page_title = this.get_title();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: page_title },
      { title: "Create" },
    ]);

    const route_name = ObjectPath.get(this.$route, "name", null);

    if (route_name == "admin.proposal.update") {
      this.form_loading = true;
      this.get_proposal(this.proposal_id);
      this.update_mode = true;
    }
  },
  beforeMount() {
    this.today_date = moment().format("YYYY-MM-DD");

    this.proposal_id = ObjectPath.get(this.$route, "params.id", 0);
    this.customer_id = ObjectPath.get(this.$route, "query.customer", 0);
    if (!this.customer_id) {
      this.back_force = true;
      this.goBack();
    }
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.back_force) {
      next();
    } else {
      _this.route_prevent_dialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.route_prevent_dialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.route_prevent_dialog = false;
        next();
      });
    }
  },
};
</script>
