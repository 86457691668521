<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Task
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 70vh; position: static"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Task #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateTaskSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Task Remarks
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateTaskSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-textarea
                v-model.trim="setting.remarks"
                auto-grow
                dense
                filled
                label="Remarks"
                solo
                flat
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Presets
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateTaskPreset()"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <div
                class="px-4 py-2 custom-border-grey-dashed"
                v-for="(row, index) in presets"
                :key="`task-div-${index}`"
                :class="{ 'mt-4': index > 0 }"
              >
                <table width="100%" :key="`task-table-${index}`">
                  <tr :key="`task-${index}`">
                    <td width="10%" class="py-1">
                      <label :for="`task-${index}`"
                        >{{ index + 1 }}. Task</label
                      >
                    </td>
                    <td width="90%" colspan="2" class="py-1">
                      <div class="d-flex">
                        <v-text-field
                          v-model="row.title"
                          :id="`task-${index}`"
                          dense
                          filled
                          label="Enter task name"
                          solo
                          flat
                          hide-details
                          color="cyan"
                        ></v-text-field>
                        <v-btn
                          v-if="presets.length - 1 == index"
                          color="cyan white--text"
                          depressed
                          tile
                          v-on:click="push_task()"
                          style="min-height: 40px; !important"
                          small
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          color="red lighten-1 white--text"
                          depressed
                          tile
                          v-on:click="remove_task(index)"
                          style="min-height: 40px; !important"
                          small
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                  <template v-for="(crow, cindex) in row.checklist">
                    <tr :key="`task-checklist-${index}-${cindex}`">
                      <td width="10%"></td>
                      <td width="10%">
                        <label :for="`task-checklist-${index}-${cindex}`"
                          >{{ cindex + 1 }}. Checklist
                        </label>
                      </td>
                      <td width="80%" class="py-1">
                        <div class="d-flex">
                          <v-text-field
                            :id="`task-checklist-${index}-${cindex}`"
                            dense
                            v-model="crow.value"
                            filled
                            label="Enter checklist name"
                            solo
                            flat
                            hide-details
                            color="cyan"
                          ></v-text-field>
                          <v-btn
                            v-if="row.checklist.length - 1 == cindex"
                            color="cyan white--text"
                            depressed
                            tile
                            v-on:click="push_checklist(index)"
                            style="min-height: 40px; !important"
                            small
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            color="red lighten-1 white--text"
                            depressed
                            tile
                            v-on:click="remove_checklist(index, cindex)"
                            style="min-height: 40px; !important"
                            small
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-task-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        remarks: null,
      },
      presets: [
        {
          title: null,
          checklist: [
            {
              value: null,
            },
          ],
        },
      ],
    };
  },
  methods: {
    push_task() {
      this.presets.push({
        title: null,
        checklist: [
          {
            value: null,
          },
        ],
      });
    },
    push_checklist(index) {
      this.presets[index].checklist.push({
        value: null,
      });
    },
    remove_task(index) {
      this.presets.splice(index, 1);
    },
    remove_checklist(index, cindex) {
      this.presets[index].checklist.splice(cindex, 1);
    },
    updateTaskPreset() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "task/preset",
          data: { preset: _this.presets },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getTaskPreset() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "task/preset" })
        .then(({ data }) => {
          _this.presets = data;
          if (!data.length) {
            this.push_task();
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getTaskSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/task" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateTaskSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/task", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getTaskSetting();
    this.getTaskPreset();
  },
};
</script>
