<template>
  <v-layout class="mt-6 customer-basic-details">
    <v-flex md3>
      <div class="px-10 pt-10">
        <v-img
          contain
          :lazy-src="$defaultProfileImage"
          :src="get_profile_image"
          max-height="200"
          max-width="200"
          height="200px"
          width="200px"
          class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
        >
        </v-img>
        <div class="margin-auto">
          <v-btn
            class="my-4 custom-bold-button width-100"
            outlined
            small
            v-on:click="selectImage"
            color="cyan"
            :loading="image_loading"
            :disabled="image_loading"
          >
            <template v-if="get_profile_image">Change Image</template>
            <template v-else>Upload Image</template>
          </v-btn>
          <div class="d-none">
            <v-file-input
              ref="uploadImage"
              v-on:change="updateProfileImage($event)"
              :rules="imageRules"
              accept="image/png, image/jpeg, image/jpg"
            ></v-file-input>
          </div>
        </div>
      </div>
    </v-flex>
    <v-flex md9 class="px-4">
      <v-row>
        <v-col md="12" class="py-0">
          <p class="middle-header-background px-4 mb-0">Basic Details</p>
        </v-col>
        <v-col md="12" class="py-0 mt-4">
          <table width="100%" style="table-layout: fixed">
            <tr>
              <td width="15%" class="px-2">
                <label
                  for="first-name"
                  class="my-0 font-size-16 font-weight-500 required"
                  >First Name</label
                >
              </td>
              <td width="35%" class="px-2">
                <v-text-field
                  id="first-name"
                  v-model.trim="basic_detail.first_name"
                  :rules="[
                    validateRules.required(
                      basic_detail.first_name,
                      'first name'
                    ),
                  ]"
                  dense
                  filled
                  label="First Name"
                  solo
                  flat
                  color="cyan"
                  class="required-field"
                  hide-details
                  v-on:keyup="updateDisplayName()"
                ></v-text-field>
              </td>
              <td width="15%" class="px-2">
                <label
                  for="phone-number"
                  class="my-0 font-size-16 font-weight-500 required"
                  >Phone Number</label
                >
              </td>
              <td width="35%" class="px-2">
                <PhoneTextField
                  id="phone-number"
                  v-model="basic_detail.phone_number"
                  :value="basic_detail.phone_number"
                  required
                  hide-details
                >
                </PhoneTextField>
              </td>
            </tr>
          </table>
        </v-col>
        <v-col md="12" class="py-0 mt-4">
          <p class="middle-header-background px-4 mb-0">Other Details</p>
        </v-col>
        <v-col md="12" class="py-0 mt-4">
          <table width="100%" style="table-layout: fixed">
            <tr>
              <td width="15%" class="px-2">
                <label for="last-name" class="my-0 font-size-16 font-weight-500"
                  >Last Name</label
                >
              </td>
              <td width="35%" class="px-2">
                <v-text-field
                  id="last-name"
                  v-model.trim="basic_detail.last_name"
                  dense
                  filled
                  label="Last Name"
                  solo
                  flat
                  color="cyan"
                  hide-details
                  v-on:keyup="updateDisplayName()"
                ></v-text-field>
              </td>
              <td width="15%" class="px-2">
                <label
                  for="company-name"
                  class="my-0 font-size-16 font-weight-500"
                  >Company Name</label
                >
              </td>
              <td width="35%" class="px-2">
                <v-text-field
                  id="company-name"
                  v-model.trim="basic_detail.company_name"
                  dense
                  filled
                  label="Company Name"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td width="15%" class="px-2">
                <label
                  for="display-name"
                  class="my-0 font-size-16 font-weight-500"
                  >Display Name</label
                >
              </td>
              <td width="35%" class="px-2">
                <v-text-field
                  id="display-name"
                  v-model.trim="basic_detail.display_name"
                  dense
                  filled
                  label="Display Name"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-text-field>
              </td>
              <td v-if="false" width="15%" class="px-2">
                <label for="nric-no" class="my-0 font-size-16 font-weight-500"
                  >NRIC No.</label
                >
              </td>
              <td v-if="false" width="35%" class="px-2">
                <v-text-field
                  id="nric-no"
                  v-model.trim="basic_detail.nric_no"
                  dense
                  filled
                  label="NRIC No."
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td width="15%" class="px-2">
                <label for="website" class="my-0 font-size-16 font-weight-500"
                  >Website</label
                >
              </td>
              <td width="35%" class="px-2">
                <v-text-field
                  id="website"
                  v-model.trim="basic_detail.website"
                  dense
                  filled
                  label="Website"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-text-field>
              </td>
              <td width="15%" class="px-2">
                <label for="group-id" class="my-0 font-size-16 font-weight-500"
                  >Group</label
                >
              </td>
              <td width="35%" class="px-2">
                <v-select
                  id="group-id"
                  v-model="basic_detail.group_id"
                  :items="group_list"
                  dense
                  filled
                  item-text="text"
                  item-value="id"
                  item-color="cyan"
                  label="Group"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-select>
              </td>
            </tr>
          </table>
        </v-col>
        <v-col md="12" class="py-0 mt-4">
          <p class="middle-header-background px-4 mb-0">Contact Details</p>
        </v-col>
        <v-col md="12" class="py-0 mt-4">
          <table width="70%" style="table-layout: fixed">
            <tr
              v-for="(row, index) in email_details"
              :key="`basic-email-row-${index}`"
            >
              <td width="10%" class="px-2" v-if="false">
                <label
                  for="`basic-email-type-${index}`"
                  class="my-0 font-size-16 font-weight-500"
                  >Type</label
                >
              </td>
              <td width="20%" class="px-2" v-if="false">
                <v-select
                  :id="`basic-email-type-${index}`"
                  v-model.trim="row.title"
                  :items="emailTypeList"
                  dense
                  filled
                  item-text="text"
                  item-value="value"
                  item-color="cyan"
                  label="Type"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-select>
              </td>
              <td width="30%" class="px-2">
                <label
                  for="`basic-email-${index}`"
                  class="my-0 font-size-16 font-weight-500"
                  >Email</label
                >
              </td>
              <td width="50%" class="px-2">
                <v-text-field
                  :id="`basic-email-${index}`"
                  v-model.trim="row.value"
                  :rules="[validateRules.validEmail(row.value, 'email')]"
                  dense
                  filled
                  label="Email"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-text-field>
              </td>
              <td width="10%">
                <v-btn
                  v-if="index > 0"
                  class="mx-2"
                  color="red lighten-1"
                  dark
                  fab
                  small
                  depressed
                  tile
                  v-on:click="removeEmailDetail()"
                >
                  <v-icon dark> mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  class="mx-2"
                  color="cyan"
                  dark
                  fab
                  small
                  depressed
                  tile
                  v-on:click="pushEmailDetail()"
                >
                  <v-icon dark> mdi-plus</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr
              v-for="(row, index) in contact_details"
              :key="`basic-contact-row-${index}`"
            >
              <td width="10%" class="px-2" v-if="false">
                <label
                  for="`basic-contact-type-${index}`"
                  class="my-0 font-size-16 font-weight-500"
                  >Type</label
                >
              </td>
              <td width="20%" class="px-2" v-if="false">
                <v-select
                  :id="`basic-contact-type-${index}`"
                  v-model.trim="row.title"
                  :items="contactTypeList"
                  dense
                  filled
                  item-text="text"
                  item-value="value"
                  item-color="cyan"
                  label="Type"
                  solo
                  flat
                  color="cyan"
                  hide-details
                ></v-select>
              </td>
              <td width="30%" class="px-2">
                <label
                  for="`basic-contact-${index}`"
                  class="my-0 font-size-16 font-weight-500"
                  >Phone Number</label
                >
              </td>
              <td width="30%" class="px-2">
                <PhoneTextField
                  :id="`basic-contact-${index}`"
                  v-model="row.value"
                  :value="row.value"
                  hide-details
                >
                </PhoneTextField>
              </td>
              <td width="10%">
                <v-btn
                  v-if="index > 0"
                  class="mx-2"
                  color="red lighten-1"
                  dark
                  fab
                  small
                  depressed
                  tile
                  v-on:click="removeContactDetail()"
                >
                  <v-icon dark> mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  class="mx-2"
                  color="cyan"
                  dark
                  fab
                  small
                  depressed
                  tile
                  v-on:click="pushContactDetail()"
                >
                  <v-icon dark> mdi-plus</v-icon>
                </v-btn>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-flex>
  </v-layout>
</template>

<script>
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  name: "customer-basic-details",
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  data() {
    return {
      basic_detail: {
        primary: false,
        salutation: "mr",
        first_name: null,
        last_name: null,
        company_name: null,
        display_name: null,
        profile_logo: null,
        phone_number: null,
        group_id: 2,
        phone: null,
        website: null,
        security_code: null,
        unit_number: null,
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        country: null,
        state: null,
        city: null,
        details: [],
      },
      image_loading: false,
      group_list: [],
      email_details: [
        {
          title: "main",
          value: null,
          type: 1,
          id: null,
        },
      ],
      contact_details: [
        {
          title: "main",
          value: null,
          type: 2,
          id: null,
        },
      ],
    };
  },
  components: {
    PhoneTextField,
  },
  props: {
    prospect: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    prospect: {
      deep: true,
      immediate: true,
      handler() {
        this.initProspect();
      },
    },
    basic_detail: {
      deep: true,
      immediate: true,
      handler() {
        this.emitDetails();
      },
    },
    email_details: {
      deep: true,
      immediate: true,
      handler() {
        this.updateDetails();
      },
    },
    contact_details: {
      deep: true,
      immediate: true,
      handler() {
        this.updateDetails();
      },
    },
  },
  methods: {
    initProspect() {
      if (!this.prospect?.id) {
        return false;
      }
      this.basic_detail.first_name = this.prospect.first_name;
      this.basic_detail.last_name = this.prospect.last_name;
      let display_name = [];
      if (this.prospect.first_name) {
        display_name.push(this.prospect.first_name);
      }
      if (this.prospect.last_name) {
        display_name.push(this.prospect.last_name);
      }
      this.basic_detail.display_name = display_name.join(" ");
      this.basic_detail.nric_no = this.prospect.nric_no;
      this.basic_detail.company_name = this.prospect.company;
      this.basic_detail.phone_number = this.prospect.phone_number;
      this.basic_detail.website = this.prospect.website;
      this.basic_detail.unit_number = this.prospect.unit_no;
      this.basic_detail.address_line_1 = this.prospect.address_1;
      this.basic_detail.address_line_2 = this.prospect.address_2;
      this.basic_detail.postal_code = this.prospect.postal_code;
      this.basic_detail.country = this.prospect.country;
      this.contact_details[0].value = this.prospect.phone_number;
      if (this.prospect.email) {
        this.email_details = [
          { title: "main", value: this.prospect.email, type: 1, id: null },
        ];
      }
      if (this.prospect.alt_phone_number) {
        this.contact_details = [
          {
            title: "main",
            value: this.prospect.alt_phone_number,
            type: 2,
            id: null,
          },
        ];
      }
    },
    emitDetails() {
      this.$emit("save-details", this.basic_detail);
    },
    updateDetails() {
      const _this = this;

      _this.basic_detail.details = [];

      for (let i = 0; i < _this.email_details.length; i++) {
        if (_this.email_details[i]) {
          delete _this.email_details[i].default;
          _this.basic_detail.details.push(_this.email_details[i]);
        }
      }

      for (let z = 0; z < _this.contact_details.length; z++) {
        if (_this.contact_details[z]) {
          delete _this.contact_details[z].default;
          _this.basic_detail.details.push(_this.contact_details[z]);
        }
      }
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      _this.image_loading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.basic_detail.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.image_loading = false;
        });
    },
    updateDisplayName() {
      const output = [];
      if (this.basic_detail.first_name) {
        output.push(this.basic_detail.first_name);
      }
      if (this.basic_detail.last_name) {
        output.push(this.basic_detail.last_name);
      }
      this.basic_detail.display_name = output.join(" ");
    },
    getGroupList() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "contractor/group-list" })
        .then(({ data }) => {
          _this.group_list = data.group_list;
          _this.basic_detail.security_code = data.security_code;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    pushEmailDetail() {
      this.email_details.push({
        title: "main",
        value: null,
        type: 1,
        id: null,
      });
    },
    removeEmailDetail(index) {
      this.email_details.splice(index, 1);
    },
    pushContactDetail() {
      this.contact_details.push({
        title: "main",
        value: null,
        type: 2,
        id: null,
      });
    },
    removeContactDetail(index) {
      this.contact_details.splice(index, 1);
    },
  },
  mounted() {
    this.getGroupList();
  },
  computed: {
    get_profile_image() {
      return ObjectPath.get(this.basic_detail, "profile_logo.file.url");
    },
  },
};
</script>
