var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('ListingTable',{attrs:{"rowData":_vm.attachments,"columnCount":4},on:{"update:rowData":function($event){_vm.attachments=$event},"update:row-data":function($event){_vm.attachments=$event}},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"simple-table-th",attrs:{"width":"100"}}),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Name")]),(
              _vm.type == 'product' || _vm.type == 'property' || _vm.type == 'package'
            )?_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v(" Primary ")]):_vm._e(),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Action")])])])]},proxy:true},{key:"tbody",fn:function(){return [_c('tbody',{staticClass:"custom-border-bottom custom-border-top"},[(_vm.attachments.length > 0)?_vm._l((_vm.attachments),function(document,index){return _c('tr',{key:index},[_c('td',{staticClass:"simple-table-td",on:{"click":function($event){return _vm.downloadAPIFile(document.id, document.file.name, index)}}},[_c('div',{staticClass:"custom-mime-content text-center"},[(_vm.isValidImage(document.file.path))?[_c('img',{attrs:{"height":"100px","width":"100px","src":document.file.url,"alt":document.file.name}})]:[_c('span',{staticClass:"svg-icon svg-icon-md custom-mime-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL(
                          'media/mime/' +
                            _vm.getFileExtension(document.file.path) +
                            '.svg'
                        )}})],1)]],2)]),_c('td',{staticClass:"simple-table-td"},[_vm._v(" "+_vm._s(document.name)+" ")]),(
                _vm.type == 'product' || _vm.type == 'property' || _vm.type == 'package'
              )?_c('td',{staticClass:"simple-table-td",attrs:{"width":"100"}},[(document && document.primary)?[_c('v-chip',{attrs:{"color":"cyan lighten-5","label":""}},[_c('i',{staticClass:"flaticon2-correct text-success font-size-h5 mr-2"}),_vm._v(" Primary ")]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2"},'v-icon',attrs,false),on),[_vm._v("mdi-progress-question")])]}}],null,true)},[_c('span',[_vm._v("This image will be displayed in"),_c('br'),_vm._v("all your transactions for this item.")])])]:[_c('v-btn',{staticClass:"text-white font-size-14",attrs:{"disabled":_vm.pageLoading || _vm.isPageLoading,"loading":_vm.primaryLoading,"text":"","small":"","color":"cyan"},on:{"click":function($event){return _vm.setAsPrimary(document.id)}}},[_vm._v("Set as primary")])]],2):_vm._e(),_c('td',{staticClass:"simple-table-td",attrs:{"width":"100"}},[(_vm.getPermission('attachment:delete'))?[_c('v-btn',{staticClass:"mx-2 custom-bold-button",attrs:{"color":"red lighten-1","small":"","loading":_vm.isDeleting(index),"disabled":_vm.primaryLoader || _vm.isDeleting(index),"icon":""},on:{"click":function($event){return _vm.deleteAPIFile(document.id, index, _vm.attachments)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]:_vm._e()],2)])}):_c('tr',[_c('td',{attrs:{"colspan":_vm.type == 'product' || _vm.type == 'property' || _vm.type == 'package'
                ? 4
                : 3}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no Attachment at the moment. ")])])])],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }