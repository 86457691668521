<template>
  <v-container class="custom-page-header-count pt-0 px-0">
    <v-layout>
      <template v-if="dataLoading">
        <template v-for="(data, index) in dataLoadingList">
          <v-flex
            :key="`loading-${index}`"
            class="py-0"
            :class="{
              'mr-1': index == 0,
              'ml-1': !dataLoadingList[index + 1],
              'mx-1': index != 0 && dataLoadingList[index + 1],
            }"
          >
            <div
              :class="
                'card m-0 top_card card-custom gutter-b border-' +
                data.headercolor
              "
            >
              <div class="card-body p-0">
                <div class="card-rounded-bottom mid_part">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <span class="symbol-label">
                        <span
                          :class="
                            'svg-icon svg-icon-xl svg-icon-' + data.headercolor
                          "
                        >
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/' + moduleType + '.svg'
                              )
                            "
                          />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex flex-column text-right">
                      <h5
                        class="text-dark-75 font-weight-bold mt-2 min-width-150px"
                      >
                        <v-skeleton-loader
                          class="custom-skeleton"
                          type="text"
                        ></v-skeleton-loader>
                      </h5>
                      <span
                        :class="
                          'qnt_val font-weight-bolder text-' + data.headercolor
                        "
                      >
                        <v-progress-circular
                          indeterminate
                          :color="data.headercolor"
                        ></v-progress-circular>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
      </template>
      <template v-else>
        <template v-for="(data, index) in statusList">
          <v-flex
            :key="`status-${index}`"
            class="py-0"
            v-if="data.top_visible"
            :class="{
              'mr-1': index == 0,
              'ml-1': !statusList[index + 1],
              'mx-1': index != 0 && statusList[index + 1],
            }"
          >
            <div
              :class="
                'card m-0 top_card card-custom gutter-b border-' +
                data.headercolor
              "
            >
              <div class="card-body p-0">
                <div class="card-rounded-bottom mid_part">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <span class="symbol-label">
                        <span
                          :class="
                            'svg-icon svg-icon-xl svg-icon-' + data.headercolor
                          "
                        >
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/' + moduleType + '.svg'
                              )
                            "
                          />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex flex-column text-right bTitle">
                      <router-link
                        :to="
                          getDefaultRoute(moduleType, {
                            query: { status: data.value },
                          })
                        "
                        v-slot="{
                          href,
                          route,
                          navigate,
                          isActive,
                          isExactActive,
                        }"
                      >
                        <a :href="href" v-on:click="navigate">
                          <h5 class="text-dark-75 font-weight-bold mt-2">
                            {{ data.text }}
                          </h5>
                          <template v-if="data.value == 'all'">
                            <span
                              :class="
                                'qnt_val font-weight-bolder text-' +
                                data.headercolor
                              "
                              >{{ data[allkey] }}</span
                            >
                          </template>
                          <template v-else>
                            <span
                              :class="
                                'qnt_val font-weight-bolder text-' +
                                data.headercolor
                              "
                              >{{ data[countkey] }}</span
                            >
                          </template>
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
        <template v-for="(data, index) in extraList">
          <v-flex
            :key="`extra-${index}`"
            class="py-0"
            :class="{
              'mr-1': index == 0,
              'ml-1': !extraList[index + 1],
              'mx-1': index != 0 && extraList[index + 1],
            }"
          >
            <div
              :class="'card m-0 top_card card-custom gutter-b border-primary'"
            >
              <div class="card-body p-0">
                <div class="card-rounded-bottom mid_part">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <span class="symbol-label">
                        <span :class="'svg-icon svg-icon-xl svg-icon-primary'">
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/' + moduleType + '.svg'
                              )
                            "
                          />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex flex-column text-right bTitle">
                      <router-link
                        :to="
                          getDefaultRoute(moduleType, {
                            query: { status: data.status_key },
                          })
                        "
                        v-slot="{
                          href,
                          route,
                          navigate,
                          isActive,
                          isExactActive,
                        }"
                      >
                        <a :href="href" v-on:click="navigate">
                          <h5 class="text-dark-75 font-weight-bold mt-2">
                            {{ data.title }}
                          </h5>
                          <span
                            :class="'qnt_val font-weight-bolder text-primary'"
                            >{{ data.value }}</span
                          >
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    dataLoading: {
      type: [Boolean],
      required: true,
      default: false,
    },
    moduleType: {
      type: [String],
      required: true,
      default: "customer",
    },
    allkey: {
      type: [String],
      required: true,
      default: "all_customers",
    },
    countkey: {
      type: [String],
      required: true,
      default: "customer_status_count",
    },
    statusList: {
      type: [Array, Object],
      required: true,
      default() {
        return new Array();
      },
    },
    extraList: {
      type: [Array],
      default() {
        return new Array();
      },
    },
  },
  data() {
    return {
      dataLoadingList: [
        {
          headercolor: "primary",
        },
        {
          headercolor: "primary",
        },
        {
          headercolor: "primary",
        },
      ],
    };
  },
};
</script>
