<template>
  <v-chip
    v-on:click="pushToDetail"
    class="font-weight-600 custom-barcode"
    :class="{
      'cursor-pointer': id,
      'cursor-default': !id,
      'd-inline': small,
      'mt-2 mb-2': !dense,
    }"
    :color="get_color_code()"
    outlined
    label
  >
    {{ barcode }}
  </v-chip>
</template>

<script>
export default {
  props: {
    barcode: {
      type: String,
      required: true,
    },
    route: {
      type: String,
    },
    id: {
      type: Number,
    },
    small: {
      type: Boolean,
    },
    isVariation: {
      type: Boolean,
    },
    isPackage: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },
  methods: {
    get_color_code() {
      if (this.isVariation) {
        return "green";
      }
      if (this.isPackage) {
        return "orange";
      }
      return "chip-custom-blue";
    },
    pushToDetail() {
      let detailID = this.lodash.toSafeInteger(this.id);
      if (this.route && detailID > 0) {
        this.$router.push(
          this.getDefaultRoute(this.route, {
            params: { id: detailID },
          })
        );
      }
    },
  },
};
</script>
