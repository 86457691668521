export const safeAdd = (...input) => {
  const result = input.reduce(
    (acc, val) => parseFloat(acc) + parseFloat(val),
    0
  );
  return Number.isFinite(result) ? formatToThreeDecimal(result) : 0;
};

export const safeSubtract = (a, b) => {
  const result = parseFloat(a) - parseFloat(b);
  return Number.isFinite(result) ? formatToThreeDecimal(result) : 0;
};

export const safeMultiply = (a, b) => {
  const result = parseFloat(a) * parseFloat(b);
  return Number.isFinite(result) ? formatToThreeDecimal(result) : 0;
};

export const safeDivide = (a, b) => {
  const result = parseFloat(a) / parseFloat(b);
  return Number.isFinite(result) ? formatToThreeDecimal(result) : 0;
};

export const isSafeInteger = (input) => {
  let result = parseFloat(input);
  return Number.isFinite(result) ? true : false;
};

export const toSafeNumber = (input) => {
  let result = parseFloat(input);
  return Number.isFinite(result) ? formatToThreeDecimal(result) : 0;
};

export const formatToThreeDecimal = (input) => {
  let result = Number(input.toFixed(3));
  return Number.isFinite(result) ? result : 0;
};
