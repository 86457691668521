<template>
  <div class="mt-4">
    <v-container fluid class="pb-0">
      <v-layout class="visits-search-bar">
        <v-flex md="2" class="pb-2 mr-2 mx-width-400">
          <v-text-field
            v-model.trim="filter_search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            @keydown.enter="getPurchaseOrderInvoices()"
            @keydown.tab="getPurchaseOrderInvoices()"
            v-on:change="getPurchaseOrderInvoices()"
            :loading="pageLoading"
            :disabled="pageLoading"
            color="cyan"
          ></v-text-field>
        </v-flex>
        <v-flex md="2" class="pb-2 mx-2 mx-width-400">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Invoice Date Range"
                hide-details
                solo
                :loading="pageLoading"
                :disabled="pageLoading"
                clearable
                prepend-inner-icon="mdi-calendar"
                v-on:click:clear="clearFilter('dates')"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="pageLoading"
              v-on:change="getPurchaseOrderInvoices"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <!-- <v-flex md="2" class="pb-2 mx-2 mx-width-400">
        <v-autocomplete
          hide-details
          v-model.trim="filter_status"
          :items="statusList"
          dense
          flat
          filled
          label="Sales Person"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading"
          :loading="pageLoading"
          v-on:change="getPurchaseOrderInvoices"
          item-value="status"
          item-text="text"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Status(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-content class="py-0">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
              >
                <template v-if="item.value == 'all'"
                  >{{ item.text }} ({{ item.all_ticket_visits }})</template
                >
                <template v-else
                  >{{ item.text }} ({{
                    item.ticket_visit_status_count
                  }})</template
                >
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.text }}</v-list-item-title
              >
              <v-list-item-title
                class="font-weight-500 text--secondary font-size-16"
                >{{ item.description }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action class="align-self-center">
              <v-list-item-subtitle
                class="text-lowercase font-weight-500 font-size-14"
              >
                <template v-if="item.value == 'all'">{{
                  item.all_ticket_visits
                }}</template>
                <template v-else>{{
                  item.ticket_visit_status_count
                }}</template></v-list-item-subtitle
              >
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-flex> -->
        <!-- <v-flex
          v-if="!internal || engineerId"
          md="2"
          class="pb-2 mx-2 mx-width-400"
        >
          <v-autocomplete
            hide-details
            v-model.trim="filter_contractor"
            clearable
            :items="contractorList"
            dense
            flat
            filled
            label="Contractor"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="pageLoading || contractorLoading"
            :loading="contractorLoading || pageLoading"
            item-text="display_name"
            item-value="id"
            v-on:change="getPurchaseOrderInvoices()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Contractor(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar>
                <v-img
                  :lazy-src="$defaultProfileImage"
                  :src="$assetAPIURL(item.profile_logo)"
                  aspect-ratio="1"
                  class="margin-auto"
                  transition="fade-transition"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.display_name }}</v-list-item-title
                >
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.company_name }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action
                v-if="item.default_person"
                class="align-self-center"
              >
                <v-list-item-subtitle
                  class="text-lowercase pb-2 font-weight-500 font-size-14"
                  >{{ item.default_person.primary_email }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  class="text-lowercase font-weight-500 font-size-14"
                  >{{ item.default_person.primary_phone }}</v-list-item-subtitle
                >
              </v-list-item-action>
            </template>
          </v-autocomplete>
        </v-flex> -->
        <div md="1" class="px-4 pr-0 pb-2 d-flex mx-2 mx-width-400">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                depressed
                :disabled="pageLoading"
                v-on:click="getPurchaseOrderInvoices"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search {{ purchaseType }}</span>
          </v-tooltip>
          <v-tooltip v-if="!internal" top content-class="custom-top-tooltip">
            <template
              v-if="purchaseType == 'new'"
              v-slot:activator="{ on, attrs }"
            >
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                depressed
                :disabled="pageLoading"
                v-on:click="createPurchaseRequest()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create Request</span>
          </v-tooltip>
        </div>
      </v-layout>
    </v-container>
    <template>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="position: static; padding-bottom: 60px"
      >
        <ListingTable
          :columnCount.sync="defaultColShow.length"
          :dataLoading.sync="dataLoading"
          :rowData.sync="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <th class="simple-table-th" width="100">Invoice #</th>
                <th class="simple-table-th" width="300">Purchase Order #</th>
                <th class="simple-table-th" width="300">Amount</th>
                <th class="simple-table-th" width="200">Dates</th>
                <th class="simple-table-th" width="200">Status</th>
                <th class="simple-table-th" width="200">Created Time</th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom custom-border-top">
              <template v-if="rowData.length > 0">
                <tr v-for="(data, index) in rowData" :key="index" link>
                  <td class="simple-table-td" v-on:click="rowClicked(data)">
                    <Barcode
                      :barcode="data.barcode"
                      :id="data.purchase_order.id"
                    ></Barcode>
                  </td>
                  <td class="simple-table-td" v-on:click="rowClicked(data)">
                    <template v-if="data.purchase_order">
                      <Barcode
                        :barcode="data.purchase_order.barcode"
                        :id="data.purchase_order.id"
                      ></Barcode>
                    </template>
                  </td>
                  <td class="simple-table-td" v-on:click="rowClicked(data)">
                    <div class="purchase-order-listing-amount">
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="
                          data.purchase_order && data.purchase_order.total > 0
                        "
                      >
                        <b>PO Total: </b>
                        <span class="red--text">{{
                          $accountingJS.formatMoney(data.purchase_order.total)
                        }}</span>
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="data.total > 0"
                      >
                        <b>Invoice Total: </b>
                        <span class="orange--text">{{
                          $accountingJS.formatMoney(data.total)
                        }}</span>
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="data.paid_amount > 0"
                      >
                        <b>Invoice Recieve Amount: </b>
                        <span class="green--text">{{
                          $accountingJS.formatMoney(data.paid_amount)
                        }}</span>
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="data.pending_amount > 0"
                      >
                        <b>Invoice Pending Amount: </b>
                        <span class="red--text">{{
                          $accountingJS.formatMoney(data.pending_amount)
                        }}</span>
                      </p>
                    </div>
                  </td>
                  <td class="simple-table-td" v-on:click="rowClicked(data)">
                    <p
                      class="m-0 custom-nowrap-ellipsis"
                      v-if="data.invoice_date"
                    >
                      <b>Invoice Date: </b>
                      {{ formatDate(data.invoice_date) }}
                    </p>
                    <p
                      class="m-0 custom-nowrap-ellipsis"
                      v-if="data.purchase_order"
                    >
                      <b>Expected Delivery Date: </b>
                      {{
                        formatDate(data.purchase_order.expected_delivery_date)
                      }}
                    </p>
                  </td>
                  <td class="simple-table-td" v-on:click="rowClicked(data)">
                    <v-chip
                      v-if="data.status_text == 'Paid'"
                      class="ma-2 white--text"
                      color="green lighten-1"
                      label
                    >
                      {{ data.status_text }}
                    </v-chip>
                    <v-chip
                      v-if="data.status_text == 'Unpaid'"
                      class="ma-2 white--text"
                      color="red lighten-1"
                      label
                    >
                      {{ data.status_text }}
                    </v-chip>
                    <v-chip
                      v-if="data.status_text == 'Partially Paid'"
                      class="ma-2 white--text"
                      color="orange lighten-1"
                      label
                    >
                      {{ data.status_text }}
                    </v-chip>
                  </td>
                  <td class="simple-table-td" v-on:click="rowClicked(data)">
                    <TableActivity
                      v-if="!lodash.isEmpty(data.added_by)"
                      :data.sync="data"
                    >
                      <template v-slot:display_name>
                        {{ data.added_by.display_name }}
                      </template>
                      <template v-slot:date_time>
                        {{ data.created_at }}
                      </template>
                      <template v-slot:format_date_time>
                        {{ formatDateTime(data.added_at) }}
                      </template>
                    </TableActivity>
                  </td>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td :colspan="defaultColDefs.length" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no invoice at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
      </perfect-scrollbar>
      <ListingFooter
        :dataLoading.sync="dataLoading"
        :showingFrom.sync="showingFrom"
        :showingTo.sync="showingTo"
        :totalRows.sync="totalRows"
        :currentPage.sync="currentPage"
        :totalPages.sync="totalPages"
      ></ListingFooter>
    </template>
    <DeleteDialog :delete-dialog="deleteVisitDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteVisitLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteVisitDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="deleteVisitLoading"
          :loading="deleteVisitLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteVisitConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <template v-if="contractorDialog">
      <ContractDialog
        :contractorDialog.sync="contractorDialog"
        v-on:closeDialog="contractorDialog = false"
        v-on:resetAll="contractorDialog = false"
        v-on:selectContractor="selectContractor"
      ></ContractDialog>
    </template>
    <template v-if="projectDialog">
      <ProjectDialog
        :projectDialog.sync="projectDialog"
        v-on:closeDialog="projectDialog = false"
        v-on:resetAll="projectDialog = false"
        v-on:selectProject="selectProject"
      ></ProjectDialog>
    </template>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { QUERY, DELETE } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { saveData } from "@/core/services/local.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import ContractDialog from "@/view/pages/partials/Select-Contractor.vue";
import ProjectDialog from "@/view/pages/partials/Select-Project.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "Purchase-Order-listing-template",
  mixins: [CommonMixin],
  props: {
    purchaseType: {
      type: String,
      required: true,
      default: "newRequest",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    engineerId: {
      type: Number,
      default: 0,
    },
    quotationId: {
      type: Number,
      default: 0,
    },
    invoiceId: {
      type: Number,
      default: 0,
    },
    entityId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  components: {
    DeleteDialog,
    Barcode,
    ListingFooter,
    ContractDialog,
    ProjectDialog,
    TableActivity,
    ListingTable,
  },
  data() {
    return {
      routeDetailName: "purchase.order.detail",
      selectedRows: [],
      dataLoading: true,
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 15,
      pageLoading: false,
      contractorLoading: false,
      propertyLoading: false,
      contractorDialog: false,
      projectDialog: false,
      engineerLoading: false,
      isRowLoading: {},
      dates: [],
      statusList: [],
      customerList: [],
      propertyList: [],
      engineerList: [],
      rowData: [],
      contractorList: [],
      filter_search: null,
      filter_contractor: null,
      filter_engineer: null,
      filter_quotation: null,
      filter_invoice: null,
      filter_status: null,
      filter_property: null,
      timeout: null,
      timeoutLimit: 500,
      entity: 0,
      customer: 0,
      contractor: 0,
      project: 0,
      customerPerson: 0,
      customerProperty: 0,
      entityList: [],
      entityObject: {},
      deleteVisitId: null,
      deleteVisitDialog: false,
      deleteVisitLoading: false,
      deleteVisitBarcode: null,
    };
  },
  mounted() {
    const _this = this;
    _this.getPurchaseOrderInvoices();
    _this.$nextTick(() => {
      if (!_this.internal || _this.engineerId) {
        _this.getPurchaseOrderInvoices();
        _this.getContractorList();
      }
    });
  },
  beforeMount() {
    const _this = this;
    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: "Invoice #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Purchase Order #",
        field: "purchase_order",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
        width: "200px",
      },
      {
        headerName: "Amount",
        field: "amount",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "300px",
      },
      {
        headerName: "Dates",
        field: "date",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 4,
        width: "200px",
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 6,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: true,
        order: 7,
        width: "170px",
      },
    ];

    console.log({ defaultColDefs: _this.defaultColDefs });

    const defaultColDefs = _this.lodash.filter(
      _this.defaultColDefs,
      function (row) {
        return row.visible;
      }
    );

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    // _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  methods: {
    rowClicked(params) {
      if (params.purchase_order) {
        this.$router.push(
          this.getDefaultRoute(this.routeDetailName, {
            params: { id: params.purchase_order.id },
            query: { tab: "invoiceDetails" },
          })
        );
      }
    },
    selectContractor(contractor) {
      if (contractor > 0) {
        this.contractor = contractor;
        this.contractorDialog = false;
        if (this.project == 0) {
          this.projectDialog = true;
        } else {
          this.$router.push(
            this.getDefaultRoute("purchase.order.create", {
              query: { contractor: contractor, project: this.project },
            })
          );
        }
      }
    },
    selectProject(project) {
      if (project > 0) {
        this.project = project;
        this.projectDialog = false;
        if (project > 0 && this.contractor > 0) {
          this.$router.push(
            this.getDefaultRoute("purchase.order.create", {
              query: { contractor: this.contractor, project },
            })
          );
        } else {
          this.contractorDialog = true;
        }
      }
    },
    deleteVisit({ visit_id, visit_barcode }) {
      this.deleteVisitId = visit_id;
      this.deleteVisitDialog = true;
      this.deleteVisitBarcode = visit_barcode;
    },
    deleteVisitConfirm() {
      this.deleteVisitLoading = true;
      this.$store
        .dispatch(DELETE, { url: `visit/${this.deleteVisitId}` })
        .then(() => {
          this.deleteVisitId = null;
          this.deleteVisitDialog = false;
          this.deleteVisitBarcode = null;
          this.getPurchaseOrderInvoices();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteVisitLoading = false;
        });
    },
    createPurchaseRequest() {
      if (!this.project) {
        this.projectDialog = true;
      }
    },
    initInternal() {
      this.entity = this.lodash.toSafeInteger(this.entityId);
      this.customer = this.lodash.toSafeInteger(this.customerId);
      this.filter_quotation = this.lodash.toSafeInteger(this.quotationId);
      this.filter_invoice = this.lodash.toSafeInteger(this.invoiceId);
      this.filter_engineer = this.lodash.toSafeInteger(this.engineerId);
      this.filter_customer = this.customer;
      if (this.filter_engineer) {
        this.$nextTick(() => {
          this.getPurchaseOrderInvoices();
          this.getStatusList();
        });
      }
      if (this.entity && this.customer && this.filter_customer) {
        this.$nextTick(() => {
          this.getPurchaseOrderInvoices();
          this.getStatusList();
          this.getPropertyList();
        });
      }
    },
    updateEntity(row) {
      this.entityObject = row;
      this.getPurchaseOrderInvoices();
      this.getContractorList();
      this.propertyList = [];
    },
    selectCustomerDialog() {
      this.contractorDialog = true;
    },
    resetAll() {
      this.entity = 0;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.contractorDialog = false;
    },
    /* selectProject(param) {
      this.projectId = param;
    }, */
    selectContact(param) {
      this.contractId = param;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("job.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getPurchaseOrderInvoices();
    },
    getPurchaseOrderInvoices() {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];

      _this.pageLoading = true;

      let current_page = _this.currentPage;
      let per_page = _this.perPage;
      let filterData = {
        current_page,
        per_page,
        status: "all",
        search: _this.lodash.toString(_this.filter_search) || null,
        daterange: _this.dates,
        contractor: _this.filter_contractor,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
      };
      _this
        .getInvoiceList(filterData)
        .then((data) => {
          _this.rowData = data.rows;
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
          _this.dataLoading = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    getInvoiceList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "invoice",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      return tempArr.join(", ");
    },
    getEngineers() {
      const _this = this;
      _this.engineerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "engineer-list",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              _this.engineerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.engineerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.engineerLoading = false;
          reject(error);
        }
      });
    },
    getContractors() {
      const _this = this;
      _this.contractorLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "contractor-list",
              data: { entity: _this.entityObject.id, all: true },
            })
            .then(({ data }) => {
              saveData("_btacl_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              _this.contractorLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.contractorLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.contractorLoading = false;
          reject(error);
        }
      });
    },
    getCustomerProperties(customer) {
      const _this = this;
      _this.propertyLoading = true;
      _this.filter_property = null;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "property-list/" + (customer || ""),
              data: { entity: _this.entityObject.id, all: true },
            })
            .then(({ data }) => {
              saveData("_btacpl_" + customer + "_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.propertyLoading = false;
            });
        } catch (error) {
          _this.propertyLoading = false;
          reject(error);
        }
      });
    },
    getEngineerList() {
      const _this = this;

      _this
        .getEngineers()
        .then((engineer) => {
          _this.engineerList = engineer;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getContractorList() {
      const _this = this;

      if (_this.contractorLoading) {
        return false;
      }

      _this
        .getContractors()
        .then((contractor) => {
          _this.contractorList = contractor;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getPropertyList();
        });
    },
    getPropertyList(param) {
      const _this = this;

      if (_this.propertyLoading) {
        return false;
      }

      _this.propertyList = [];
      _this
        .getCustomerProperties(param)
        .then((status) => {
          _this.propertyList = status;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getStatusList() {
      const _this = this;

      let filter = {
        daterange: _this.dates,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
        search: _this.lodash.toString(_this.filter_search) || null,
        invoice: _this.lodash.toSafeInteger(_this.filter_invoice) || null,
        quotation: _this.lodash.toSafeInteger(_this.filter_quotation) || null,
        customer: _this.lodash.toSafeInteger(_this.filter_customer) || null,
        engineer: _this.lodash.toSafeInteger(_this.filter_engineer) || null,
        status: _this.lodash.toSafeInteger(_this.filter_status) || null,
        property: _this.lodash.toSafeInteger(_this.filter_property) || null,
        type: _this.purchaseType,
      };

      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, { url: "visit/status", data: filter })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let rowData = this.lodash.map(this.rowData, function (row) {
        if (!row.header) return row;
      });

      rowData = this.lodash.without(rowData, undefined);

      return this.rowsOffset + (rowData ? rowData.length : 0);
    },
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
  beforeDestroy() {
    /*PaginationEventBus.$off("update:pagination");
    PaginationEventBus.$off("update:per-page");*/
  },
  created() {
    const _this = this;

    const statusText = _this.lodash.toLower(_this.$route.query.status) || "all";

    if (statusText) {
      switch (statusText) {
        case "all":
          _this.filter_status = 0;
          break;
        case "open":
          _this.filter_status = 1;
          break;
        case "cancel":
          _this.filter_status = 5;
          break;
      }
    }

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getPurchaseOrderInvoices();
    });

    PaginationEventBus.$on("update:per-page", (param) => {
      _this.perPage = param;
    });
  },
};
</script>
