<template>
  <div>
    <div class="d-flex w-100" :class="{ 'custom-border-bottom': hasLevelTwo }">
      <div style="width: 5%" class="pt-5 text-center">
        <v-icon class="line-item-drag-icon cursor-move color-custom-blue"
          >mdi-drag</v-icon
        >
        <br />
        <label class="px-0 mx-0 py-0 mt-2 my-0font-weight-600 font-size-18">
          VO <span v-if="pagebreak">{{ index }}.</span
          ><span v-else>{{ index + 1 }}.</span>
        </label>
      </div>
      <div style="width: 54%">
        <v-autocomplete
          dense
          filled
          color="cyan"
          item-color="cyan"
          :items="ltxAdTitleList"
          placeholder="Additional Work Title"
          solo
          flat
          :readonly="hasLevelTwo"
          hide-details
          item-text="name"
          item-value="id"
          class="my-2 width-100"
          v-model.trim="row.product_id"
          v-on:change="
            updateAdtitle($event, index);
            levelFilter($event);
            emitValue();
          "
        />
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          placeholder="Description"
          solo
          flat
          class="my-2"
          hide-details
          row-height="10"
          v-model="row.description"
          v-on:keyup="emitValue()"
          v-on:change="emitValue()"
        />
        <v-layout class="align-center">
          <v-flex md6 class="text-left">
            <v-btn
              :disabled="!row.product_id"
              v-on:click="addChildItem(row.uuid, row.product_id)"
              small
              depressed
              color="cyan white--text"
              class="mb-4 mt-2 custom-bold-button white--text"
            >
              <v-icon>mdi-plus</v-icon> Add Additional Work (Same Category)
            </v-btn>
            <br />
            <v-btn
              :disabled="!row.product_id"
              v-on:click="
                row.pagebreak = !row.pagebreak;
                emitValue();
              "
              small
              depressed
              color="cyan white--text"
              class="mb-4 custom-bold-button white--text"
            >
              <span v-if="row.pagebreak"
                ><v-icon>mdi-minus</v-icon> Remove Page Break</span
              >
              <span v-else><v-icon>mdi-plus</v-icon> Add Page Break</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
      <div style="width: 41%" class="text-right">
        <v-btn
          v-on:click="
            deleteAdtitle(index);
            emitValue();
          "
          color="red lighten-1"
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  EMIT_VALUE,
  UPDATE_ADTITLE,
  LEVEL_FILTER,
  ADD_CHILD_ITEM,
  DELETE_ADTITLE,
} from "@/core/services/store/line.item.module";

export default {
  data() {
    return {};
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    pagebreak: {
      type: Boolean,
      default: false,
    },
    hasLevelTwo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateAdtitle(productId, index) {
      this.$store.dispatch(UPDATE_ADTITLE, { productId, index });
    },
    levelFilter(productId) {
      this.$store.dispatch(LEVEL_FILTER, { productId });
    },
    emitValue() {
      this.$store.dispatch(EMIT_VALUE);
    },
    addChildItem(uuid, productId) {
      this.$store.dispatch(ADD_CHILD_ITEM, {
        uuid,
        productId,
        indexToInsert: -1,
      });
    },
    deleteAdtitle(index) {
      this.$store.dispatch(DELETE_ADTITLE, index);
    },
  },
  computed: {
    ...mapGetters(["ltxAdTitleList"]),
  },
};
</script>
