<template>
  <!--begin::Agreement Internal Listing-->
  <v-container fluid class="pt-0">
    <v-container class="p-0 m-0">
      <v-row>
        <v-col md="3">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_agreements
                  }}</template>
                  <template v-else>{{ item.agreement_status_count }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                v-on:click="getRows()"
                :disabled="dataLoading"
                :loading="dataLoading"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="5"
      :dataLoading="dataLoading"
      :rowData="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Package</th>
            <th class="simple-table-th" width="200">Customer</th>
            <th class="simple-table-th" width="200">Status</th>
            <th class="simple-table-th" width="200">Download</th>
            <th class="simple-table-th" width="200">Created Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr :key="index" v-for="(row, index) in dataRows">
              <td class="simple-table-td">
                <Barcode
                  :barcode="row.package.barcode"
                  route="package.sales.detail"
                  :id="row.package.id"
                ></Barcode>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Title: </b>{{ row.package.quotation_title }}
                </p>
              </td>
              <td class="simple-table-td">
                <div class="agreement-listing-customer" v-if="row.customer">
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.default_person"
                  >
                    <b>Name: </b>
                    {{ row.customer.default_person.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.customer">
                    <b>Company: </b>
                    {{ row.customer.company_name }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.default_person"
                  >
                    <b>Email: </b>
                    {{ row.customer.default_person.primary_email }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.default_person"
                  >
                    <b>Phone: </b>
                    {{ row.customer.default_person.primary_phone }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <CustomChip
                  color="cyan"
                  text-color="white"
                  small
                  :text="row.status_text"
                  tooltip-text="Status"
                ></CustomChip>
              </td>
              <td class="simple-table-td">
                <div
                  v-on:click.stop.prevent="downloadFile(row)"
                  class="custom-mime-content agreement-download-icon"
                >
                  <span class="svg-icon svg-icon-lg custom-mime-icon mr-4">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/mime/pdf.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </td>
              <td class="simple-table-td">
                <TableActivity v-if="!lodash.isEmpty(row.added_by)" :data="row">
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no case trust at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Agreement Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import { QUERY } from "@/core/services/store/request.module";
import CustomChip from "@/view/pages/partials/CustomChip.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "agreement-internal-list",
  props: {
    type: {
      type: String,
      default: "",
    },
    createAggrement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataLoading: false,
      customerId: 0,
      dataRows: [],
      statusList: [],
      filter: {
        status: "all",
        search: null,
      },
    };
  },
  components: {
    Barcode,
    CustomChip,
    ListingTable,
    TableActivity,
  },
  methods: {
    downloadFile(row) {
      if (row.pdf_url) {
        window.open(row.pdf_url, "_blank");
      }
    },
    clearFilter(key) {
      this.filter[key] = null;
    },
    getRows() {
      const _this = this;

      _this.dataLoading = true;

      const request_params = {
        current_page: 1,
        per_page: 5000,
        status: _this.filter.status,
        search: _this.filter.search,
        customer: _this.customerId,
      };

      _this.$store
        .dispatch(QUERY, {
          url: "agreements",
          data: request_params,
        })
        .then(({ data }) => {
          _this.dataRows = data.rows;
          _this.statusList = data.status_list;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    },
    createAgreement() {
      let query = {};
      query[this.type] = this.$route.params.id;
      this.$router.push(this.getDefaultRoute("agreement.create", { query }));
    },
  },
  mounted() {
    this.getRows();
  },
  beforeMount() {
    this.customerId = this.$route.params.id;
  },
};
</script>
