<template>
  <DetailTemplate customClass="bug-detail detail-page">
    <template v-slot:header-title>
      <h1 class="form-title d-flex">{{ bugDetail.title }}</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="12">
            <table class="width-100">
              <tbody>
                <tr>
                  <td style="vertical-align: top" width="200">
                    <label class="font-size-16 font-weight-600" for="bug-title"
                      >Title</label
                    >
                  </td>
                  <td class="font-size-16 font-weight-600 text--secondary">
                    {{ bugDetail.title }}
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: top" width="200">
                    <label class="font-size-16 font-weight-600" for="bug-title"
                      >Priority</label
                    >
                  </td>
                  <td class="font-size-16 font-weight-600 text--secondary">
                    {{ bugDetail.priority_text }}
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: top" width="200">
                    <label class="font-size-16 font-weight-600" for="bug-title"
                      >Status</label
                    >
                  </td>
                  <td class="font-size-16 font-weight-600 text--secondary">
                    {{ bugDetail.status_text }}
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: top" width="200">
                    <label class="font-size-16 font-weight-600" for="bug-title"
                      >Description</label
                    >
                  </td>
                  <td
                    class="font-size-16 font-weight-600 text--secondary"
                    v-html="bugDetail.description"
                  ></td>
                </tr>
                <tr>
                  <td style="vertical-align: top" width="200">
                    <label class="font-size-16 font-weight-600" for="bug-title"
                      >Attachments</label
                    >
                  </td>
                  <td class="font-size-16 font-weight-600 text--secondary">
                    <FileTemplate
                      :allowDelete="false"
                      :attachments.sync="bugDetail.attachments"
                    ></FileTemplate>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET } from "@/core/services/store/request.module";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";

export default {
  name: "bug-detail",
  data() {
    return {
      bugId: null,
      bugDetail: new Object({}),
    };
  },
  components: {
    DetailTemplate,
    FileTemplate,
  },
  methods: {
    getBugDetail() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "bug/" + _this.bugId })
        .then(({ data }) => {
          _this.bugDetail = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  computed: {
    bugDetailTitle() {
      return "hello world";
    },
  },
  mounted() {
    this.getBugDetail();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bug", route: "bug" }]);
  },
  created() {
    const _this = this;
    _this.bugId = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.bugId || _this.bugId <= 0) {
      _this.$router.go(-1);
    }
  },
};
</script>
