<template>
  <!--begin::Doc Listing-->
  <div class="doc-template">
    <ListingTemplate
      :customClass="'doc-listing'"
      v-if="getPermission('doc:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('doc:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6" class="pt-0">
                <h1 class="form-title d-flex margin-auto font-size-28">
                  All Docs
                  <!-- <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_docs
                          }}</template>
                          <template v-else>{{
                            item.doc_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select> -->
                </h1>
              </v-col>
              <v-col cols="6" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('doc:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="selectFileDialog()"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false && !isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'doc',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <template v-if="false">
                  <v-menu content-class="custom-menu-list" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading || exportLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        :loading="exportLoading"
                      >
                        <v-icon dark>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in moreActions">
                        <v-list-item
                          :key="index"
                          link
                          :class="{ 'line-active': item.sort }"
                          v-on:click="moreAction(item.action, item.sort)"
                        >
                          <v-list-item-title>
                            <v-icon v-if="item.icon" small left color="cyan">{{
                              item.icon
                            }}</v-icon>
                            {{ item.title }}
                            <template v-if="item.sort">
                              <v-icon
                                v-if="item.sort == 'asc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-ascending</v-icon
                              >
                              <v-icon
                                v-if="item.sort == 'desc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-descending</v-icon
                              >
                            </template>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips module="doc"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Doc"
          :basicSearchFields="[
            'barcode',
            'reference',
            'doc_title',
            'sales',
            'prepared_by',
            'attention',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <ListingTable
            :columnCount.sync="defaultColShow.length"
            :dataLoading.sync="dataLoading"
            :rowData.sync="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr v-for="(data, index) in rowData" :key="index" link>
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          !cols.checkbox
                            ? routeToDetail(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template v-if="cols.checkbox">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode
                            route="doc.detail"
                            :barcode="data.barcode"
                            :id="data.id"
                          ></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="doc-listing-status">
                            <CustomChip
                              :text="data.status_text"
                              color="cyan"
                              text-color="white"
                            ></CustomChip>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'name'">
                          <div class="doc-listing-name">
                            {{ data.name }}
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'recipients'">
                          <div
                            class="doc-listing-recipients text-lowercase"
                            v-html="data.recipients_text"
                          ></div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColDefs.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no doc at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="fileDialog" :dialogWidth="dialogWidth">
          <template v-slot:title>
            <v-layout>
              <v-flex>{{ dialogTitle }}</v-flex>
              <v-flex class="text-right">
                <v-btn
                  icon
                  :disabled="dataLoading || fileProcessing"
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="fileDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:body>
            <v-container fluid>
              <template v-if="fileProcessed">
                <v-form
                  ref="docForm"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="continueFile()"
                >
                  <v-row>
                    <v-col class="py-0" md="4"
                      ><label
                        for="email"
                        class="m-0 font-weight-700 font-size-16 required"
                        >Email</label
                      ></v-col
                    >
                    <v-col class="py-0" md="4"
                      ><label
                        for="first-name"
                        class="m-0 font-weight-700 font-size-16"
                        >First Name</label
                      ></v-col
                    >
                    <v-col class="py-0" md="4"
                      ><label
                        for="last-name"
                        class="m-0 font-weight-700 font-size-16"
                        >Last Name</label
                      ></v-col
                    >
                  </v-row>
                  <v-row v-for="(row, index) in doc_recipients" :key="index">
                    <v-col md="4">
                      <v-text-field
                        :id="`email-${index}`"
                        v-model="row.email"
                        dense
                        filled
                        label="Email"
                        solo
                        :disabled="filePrLoading"
                        :loading="filePrLoading"
                        flat
                        hide-details
                        color="cyan"
                        :rules="[
                          validateRules.required(row.email, 'Email'),
                          validateRules.validEmail(row.email, 'Email'),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        :id="`first-name-${index}`"
                        v-model="row.first_name"
                        dense
                        filled
                        label="First Name"
                        solo
                        :disabled="filePrLoading"
                        :loading="filePrLoading"
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        :id="`last-name-${index}`"
                        v-model="row.last_name"
                        dense
                        filled
                        label="Last Name"
                        solo
                        :disabled="filePrLoading"
                        :loading="filePrLoading"
                        flat
                        append-outer-icon="mdi-delete"
                        class="has-delete-outer-icon"
                        v-on:click:append-outer="remove_recipients(index)"
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn
                    v-on:click="add_recipients()"
                    color="cyan white--text"
                    small
                    class="custom-bold-button"
                    ><v-icon left>mdi-plus</v-icon> Add Recipient</v-btn
                  >
                </v-form>
              </template>
              <div
                class="doc-file-select-block custom-border-grey-dashed"
                v-else
              >
                <template v-if="fileProcessing">
                  <p class="file-processing font-size-16 font-weight-600">
                    Processing...
                  </p>
                  <v-progress-linear
                    indeterminate
                    color="cyan"
                  ></v-progress-linear>
                </template>
                <template v-else>
                  <p
                    v-if="fileLoading"
                    class="file-uploading font-size-16 font-weight-600"
                  >
                    Uploading...
                  </p>
                  <v-btn
                    :disabled="fileLoading"
                    :loading="fileLoading"
                    color="cyan"
                    v-on:click="selectFile()"
                    class="text-white custom-bold-button"
                    ><v-icon left>mdi-upload</v-icon>Select File</v-btn
                  >
                  <v-file-input
                    accept="application/pdf"
                    ref="fileInput"
                    class="d-none"
                    v-on:change="handleFileUpload"
                  ></v-file-input>
                </template>
              </div>
            </v-container>
          </template>
          <template v-if="fileProcessed" v-slot:action>
            <v-btn
              :disabled="
                dataLoading || filePrLoading || fileLoading || fileProcessing
              "
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="skipSubmit()"
            >
              Skip
            </v-btn>
            <v-btn
              :disabled="
                !formValid ||
                dataLoading ||
                filePrLoading ||
                fileLoading ||
                fileProcessing
              "
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="continueFile()"
            >
              Continue
            </v-btn>
          </template>
        </Dialog>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Doc Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PATCH,
  PUT,
  POST,
  UPLOAD,
  DOWNLOAD,
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import CustomChip from "@/view/pages/partials/CustomChip.vue";

export default {
  mixins: [CommonMixin, ListingMixin, ValidationMixin],
  name: "doc-list",
  data() {
    return {
      exportLoading: false,
      fileLoading: false,
      fileProcessing: false,
      fileProcessed: false,
      filePrLoading: false,
      fileDialog: false,
      dates: [],
      pageModule: "doc-listing",
      routeAPI: "docs",
      routeName: "docs",
      routeDetailName: "docs.detail",
      status: "all",
      moreActions: [
        {
          title: "Export Doc(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },
        {
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        /*{
          title: "Send as Email",
          icon: "mdi-email-send",
          action: "sent"
        },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },
      ],
      dialogTitle: "Get started",
      doc_recipients: [
        {
          email: null,
          first_name: null,
          last_name: null,
        },
      ],
      docData: {},
    };
  },
  components: {
    PageTips,
    draggable,
    CustomChip,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    routeToDetail(row) {
      this.$router.push(
        this.getDefaultRoute("detail.doc", {
          params: { id: row.id },
        })
      );
    },
    add_recipients() {
      this.doc_recipients.push({
        email: null,
        first_name: null,
        last_name: null,
      });
    },
    remove_recipients(index) {
      this.doc_recipients.splice(index, 1);
      if (this.doc_recipients.length <= 0) {
        this.add_recipients();
      }
    },
    selectFileDialog() {
      this.fileDialog = true;
    },
    selectFile() {
      this.$refs["fileInput"].$refs["input"].click();
    },
    handleFileUpload(file) {
      let formData = new FormData();
      formData.append("file", file, file.name);
      this.fileLoading = true;
      this.$store
        .dispatch(UPLOAD, {
          url: "docs/upload",
          data: formData,
        })
        .then(({ data }) => {
          this.docData = data;
          this.processFile();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.fileLoading = false;
        });
    },
    processFile() {
      this.fileProcessing = true;
      this.$store
        .dispatch(POST, {
          url: "docs/process",
          data: this.docData,
        })
        .then(() => {
          this.fileProcessed = true;
          this.dialogTitle = "Add recipients";
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.fileProcessing = false;
        });
    },
    skipSubmit() {
      this.$router.push(
        this.getDefaultRoute("create.doc", {
          params: { id: this.docData.id, skip: 1 },
        })
      );
    },
    continueFile() {
      if (!this.$refs.docForm.validate()) {
        return false;
      }
      this.filePrLoading = true;
      this.$store
        .dispatch(PUT, {
          url: "docs/recipients",
          data: { ...this.docData, recipients: this.doc_recipients },
        })
        .then(({ data }) => {
          this.$router.push(
            this.getDefaultRoute("create.doc", {
              params: { id: data.id },
            })
          );
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.filePrLoading = false;
        });
    },
    exportDocs() {
      this.exportLoading = true;
      let fileName = new Date();
      this.$store
        .dispatch(DOWNLOAD, {
          url: "doc/export",
          data: this.queryParams,
        })
        .then(({ data }) => {
          saveAs(data, "docs-report-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.bulkDocExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("doc");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateBulkAction(param) {
      const _this = this;
      switch (param) {
        case "draft":
          _this.bulkDocUpdate({
            docs: _this.selectedRows,
            status: 1,
          });
          break;
        case "accepted":
          _this.bulkDocUpdate({
            docs: _this.selectedRows,
            status: 2,
          });
          break;
        case "rejected":
          _this.bulkDocUpdate({
            docs: _this.selectedRows,
            status: 3,
          });
          break;
        case "pending":
          _this.bulkDocUpdate({
            docs: _this.selectedRows,
            status: 4,
          });
          break;
        case "sent":
          _this.bulkDocUpdate({
            docs: _this.selectedRows,
            status: 5,
          });
          break;
        case "cancelled":
          _this.bulkDocUpdate({
            docs: _this.selectedRows,
            status: 6,
          });
          break;
        case "closed":
          _this.bulkDocUpdate({
            docs: _this.selectedRows,
            status: 7,
          });
          break;
      }
    },
    bulkDocUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "doc",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkDocExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "docs/export",
        })
        .then(({ data }) => {
          saveAs(data, "docs-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Doc",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      /*{
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px"
      },*/
      {
        headerName: "Doc #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "100px",
      },
      {
        headerName: "Name",
        field: "name",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
        width: "300px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 5,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: true,
        order: 6,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(
      _this.defaultColDefs,
      function (row) {
        return row.visible;
      }
    );

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
