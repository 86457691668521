<template>
  <div class="ct-detail-package-sales">
    <detail-package-sales is-package> </detail-package-sales>
  </div>
</template>

<script>
import DetailCommonQuotation from "@/view/pages/common-quotation/Detail-Quotation";

export default {
  name: "detail-common-package-sales",
  data() {
    return {};
  },
  components: {
    "detail-package-sales": DetailCommonQuotation,
  },
};
</script>
