<template>
  <div class="ct-package-sales">
    <create-package-sales is-package> </create-package-sales>
  </div>
</template>

<script>
import CreateCommonQuotation from "@/view/pages/common-quotation/Create-Quotation";

export default {
  name: "create-common-package-sales",
  data() {
    return {};
  },
  components: {
    "create-package-sales": CreateCommonQuotation,
  },
};
</script>
