<template>
  <v-row class="customer-detail custom-border-top">
    <v-col md="12" sm="12">
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="table-row-divider"
      >
      </v-skeleton-loader>
      <v-simple-table v-else class="simple-table">
        <template v-slot:default>
          <thead>
            <tr>
              <td class="font-size-15 font-weight-600">Site Address #</td>
              <td class="font-size-15 font-weight-600">Address</td>
            </tr>
          </thead>
          <tbody>
            <template v-if="billingAddress.length > 0">
              <tr v-for="(address, index) in billingAddress" :key="index">
                <td class="font-size-14 font-weight-500 text-uppercase">
                  <Barcode :barcode="address.barcode"></Barcode>
                </td>
                <td class="font-size-14 font-weight-500">
                  {{ address.property_address }}
                </td>
              </tr>
            </template>
            <tr v-else-if="!pageLoading">
              <td colspan="2">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no Site Address at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  name: "billing-address",
  title: "Customer",
  mixins: [CommonMixin],
  data() {
    return {
      billingAddress: [],
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Barcode,
  },
  watch: {
    customer() {
      this.assignData();
    },
  },
  methods: {
    getAddress(address) {
      let addressArr = [];
      if (address.unit) {
        addressArr.push(address.unit);
      }
      if (address.street_1) {
        addressArr.push(address.street_1);
      }
      if (address.street_2) {
        addressArr.push(address.street_2);
      }
      if (address.zip_code) {
        addressArr.push(address.zip_code);
      }
      if (this.lodash.isEmpty(address.city) === false) {
        addressArr.push(address.city.name);
      }
      if (this.lodash.isEmpty(address.state) === false) {
        addressArr.push(address.state.name);
      }
      if (this.lodash.isEmpty(address.country) === false) {
        addressArr.push(address.country.name);
      }
      return addressArr.join(", ");
    },
    assignData() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customer.address) === false) {
        let customerAddress = _this.lodash.map(
          _this.customer.address,
          function (data) {
            if (data.type === 2) return data;
          }
        );
        _this.billingAddress = _this.lodash.without(customerAddress, undefined);
      }
    },
  },
  mounted() {
    this.assignData();
  },
};
</script>
