<template>
  <DetailTemplate :customClass="'payment-detail detail-page'">
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="28"
        type="text"
      >
      </v-skeleton-loader>
      <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
        {{ detail.barcode }}
        <v-chip
          v-if="detail.status_text == 'Draft'"
          class="ma-2 white--black"
          color="gray"
          label
        >
          {{ detail.status_text }}
        </v-chip>
        <v-chip
          v-if="detail.status_text == 'Paid'"
          class="ma-2 white--text"
          color="green lighten-1"
          label
        >
          {{ detail.status_text }}
        </v-chip>
        <v-chip
          v-if="detail.status_text == 'Unpaid'"
          class="ma-2 white--text"
          color="red lighten-1"
          label
        >
          {{ detail.status_text }}
        </v-chip>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container v-bar class="detail-payment-vbar" fluid>
        <v-row dense>
          <v-col cols="5" class="pb-0">
            <v-container fluid>
              <v-list flat>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('contractor.detail', {
                                  params: {
                                    id: contractor.id || 0,
                                  },
                                })
                              "
                              >{{ contractor.display_name }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('contractor.detail', {
                                params: {
                                  id: contractor.id || 0,
                                },
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Attention</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28"
                            >mdi-office-building-outline</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <template
                              v-if="contractor && contractor.company_name"
                            >
                              <router-link
                                :to="
                                  getDefaultRoute('contractor.detail', {
                                    params: {
                                      id: contractor ? contractor.id : 0,
                                    },
                                  })
                                "
                                >{{
                                  contractor ? contractor.company_name : ""
                                }}</router-link
                              >
                            </template>
                            <template v-else> No Company Name </template>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('contractor.detail', {
                                params: {
                                  id: contractor ? contractor.id : 0,
                                },
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Company Name</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <template v-if="contractorEmail">
                              <a :href="'mailto:' + contractorEmail">{{
                                contractorEmail
                              }}</a>
                            </template>
                            <template v-else> No Contractor Email </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contractor Email</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <template v-if="contractorPhone">
                              <a :href="'tel:' + contractorPhone"
                                >+65-{{ contractorPhone }}</a
                              >
                            </template>
                            <template v-else> No Contractor Phone </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contractor Phone</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item class="py-1" v-bind="attrs" v-on="on">
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28"
                            >mdi-home-currency-usd</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                          >
                            <a
                              target="_blank"
                              :href="
                                'https://maps.google.com/?q=' + BillingDetail
                              "
                              >{{ BillingDetail }}</a
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Site Address</span>
                  </v-tooltip>
                </template>
              </v-list>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 4"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100">
                      <tr>
                        <td class="font-size-18 pt-3 pb-1" width="200">
                          Payment #
                        </td>
                        <td class="font-weight-600 font-size-18 pt-3 pb-1">
                          {{ detail.barcode }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Reference #
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template
                            v-if="
                              detail.invoice && detail.invoice.invoice_number
                            "
                            >{{ detail.invoice.invoice_number }}</template
                          >
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >No Reference</em
                            ></template
                          >
                        </td>
                      </tr>
                      <tr v-if="lodash.isEmpty(detail.payment_mode) === false">
                        <td class="font-size-18 py-1" width="200">
                          Payment due
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('payment_mode')"
                        >
                          <template v-if="updateDetailDialog.payment_mode">
                            <v-select
                              :items="paymentModeList"
                              ref="payment_mode"
                              v-model.trim="updateDetail.payment_mode"
                              dense
                              filled
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              label="Payment due"
                              color="cyan"
                              item-text="text"
                              item-value="id"
                              item-color="cyan"
                              @keydown.esc="onEscParticularField"
                              @change="updateParticularField"
                            ></v-select>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdatePayment,
                              }"
                            >
                              <template v-if="detail.payment_mode">{{
                                detail.payment_mode.text
                              }}</template>
                              <template v-else
                                ><em class="text--secondary font-weight-500"
                                  >No Payment Mode</em
                                ></template
                              >
                            </span>
                            <UpdateTooltip
                              v-if="canUpdatePayment"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr class="custom-border-top">
                        <td class="font-size-18 py-1" width="200">
                          Payment date
                        </td>
                        <td class="font-size-18 py-1" width="200">
                          Invoice Date
                        </td>
                      </tr>
                      <tr class="custom-border-top">
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1"
                          v-on:dblclick="updateField('payment_date')"
                        >
                          <template v-if="updateDetailDialog.payment_date">
                            <v-menu
                              v-model="paymentDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="payment_date"
                                  dense
                                  filled
                                  flat
                                  label="Payment Date"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="
                                    getFormattedDate(updateDetail.payment_date)
                                  "
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.payment_date"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="paymentDatePicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdatePayment,
                              }"
                            >
                              <template v-if="detail.payment_date">{{
                                formatDate(detail.payment_date)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary font-weight-500"
                                  >No Payment Date</em
                                ></template
                              >
                            </span>
                            <UpdateTooltip
                              v-if="canUpdatePayment"
                            ></UpdateTooltip>
                          </template>
                        </td>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1"
                        >
                          {{
                            lodash.isEmpty(detail.invoice) === false
                              ? formatDate(detail.invoice.invoice_date)
                              : "-"
                          }}
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="paymentTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#overview"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/purchase-order.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#image"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/image.svg')" />
                  <!--end::Svg Icon-->
                </span>
                Images
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="paymentTab">
              <v-tab-item value="overview">
                <v-col cols="12" v-if="detail.invoice">
                  <v-card class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Invoice Information
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-row>
                        <v-col md="4" cols="12" class="pt-0">
                          <h4
                            class="color-custom-blue text-uppercase font-size-16 font-weight-600"
                          >
                            Invoice #
                          </h4>
                          <p
                            class="m-0 font-size-16 font-weight-500 subtitle custom-grey-border p-4 line-height-1-4"
                          >
                            {{ detail.invoice.barcode }}
                          </p>
                        </v-col>
                        <v-col md="4" cols="12" class="pt-0">
                          <h4
                            class="color-custom-blue text-uppercase font-size-16 font-weight-600"
                          >
                            Invoice Date
                          </h4>
                          <p
                            class="m-0 font-size-16 font-weight-500 subtitle custom-grey-border p-4 line-height-1-4"
                          >
                            {{
                              detail.invoice.invoice_date
                                ? formatDate(detail.invoice.invoice_date)
                                : "-"
                            }}
                          </p>
                        </v-col>
                        <v-col md="4" cols="12" class="pt-0">
                          <h4
                            class="color-custom-blue text-uppercase font-size-16 font-weight-600"
                          >
                            Invoice Amount
                          </h4>
                          <p
                            class="m-0 font-size-16 font-weight-500 subtitle custom-grey-border p-4 line-height-1-4"
                          >
                            {{
                              detail.invoice.total
                                ? $accountingJS.formatMoney(
                                    detail.invoice.total
                                  )
                                : "-"
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Payment Information
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-row>
                        <v-col md="4" cols="12" class="pt-0">
                          <h4
                            class="color-custom-blue text-uppercase font-size-16 font-weight-600"
                          >
                            Amount Pending
                          </h4>
                          <p
                            class="m-0 font-size-16 font-weight-500 subtitle custom-grey-border p-4 line-height-1-4"
                          >
                            {{
                              detail.invoice.pending_amount
                                ? $accountingJS.formatMoney(
                                    detail.invoice.pending_amount
                                  )
                                : "-"
                            }}
                          </p>
                        </v-col>
                        <v-col md="4" cols="12" class="pt-0">
                          <h4
                            class="color-custom-blue text-uppercase font-size-16 font-weight-600"
                          >
                            Amount Received
                          </h4>
                          <p
                            class="m-0 font-size-16 font-weight-500 subtitle custom-grey-border p-4 line-height-1-4"
                            v-on:dblclick="updateField('amount')"
                          >
                            <template v-if="updateDetailDialog.amount">
                              <v-text-field
                                ref="amount"
                                v-model.trim="updateDetail.amount"
                                dense
                                filled
                                color="cyan"
                                label="Amount Received"
                                :rules="[
                                  validateRules.minLength(
                                    updateDetail.amount,
                                    'Amount Received',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    updateDetail.amount,
                                    'Amount Received',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                                hide-details
                                :loading="particuleFieldLoading"
                                :disabled="particuleFieldLoading"
                                @keydown.esc="onEscParticularField"
                                @keydown.enter="updateParticularField"
                              ></v-text-field>
                            </template>
                            <template v-else>
                              <span
                                class="d-inline-block"
                                :class="{
                                  'custom-bottom-border': canUpdatePayment,
                                }"
                              >
                                <template v-if="detail.amount">{{
                                  $accountingJS.formatMoney(detail.amount)
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary font-weight-500"
                                    >No Amount Received</em
                                  ></template
                                >
                              </span>
                              <UpdateTooltip
                                v-if="canUpdatePayment"
                              ></UpdateTooltip>
                            </template>
                          </p>
                        </v-col>
                        <v-col md="4" cols="12" class="pt-0">
                          <h4
                            class="color-custom-blue text-uppercase font-size-16 font-weight-600"
                          >
                            Transaction ID
                          </h4>
                          <p
                            class="m-0 font-size-16 font-weight-500 subtitle custom-grey-border p-4 line-height-1-4"
                            v-on:dblclick="updateField('transaction_id')"
                          >
                            <template v-if="updateDetailDialog.transaction_id">
                              <v-text-field
                                ref="transaction_id"
                                v-model.trim="updateDetail.transaction_id"
                                dense
                                filled
                                color="cyan"
                                label="Transaction ID"
                                :rules="[
                                  validateRules.minLength(
                                    updateDetail.transaction_id,
                                    'Transaction ID',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    updateDetail.transaction_id,
                                    'Transaction ID',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                                hide-details
                                :loading="particuleFieldLoading"
                                :disabled="particuleFieldLoading"
                                @keydown.esc="onEscParticularField"
                                @keydown.enter="updateParticularField"
                              ></v-text-field>
                            </template>
                            <template v-else>
                              <span
                                class="d-inline-block"
                                :class="{
                                  'custom-bottom-border': canUpdatePayment,
                                }"
                              >
                                <template v-if="detail.transaction_id">{{
                                  detail.transaction_id
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary font-weight-500"
                                    >No Transaction ID</em
                                  ></template
                                >
                              </span>
                              <UpdateTooltip
                                v-if="canUpdatePayment"
                              ></UpdateTooltip>
                            </template>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
              <v-tab-item value="image">
                <AttachmentView
                  :attachments="detail.documents"
                  type="purchase_order"
                ></AttachmentView>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:footer> </template>
  </DetailTemplate>
</template>

<script>
import DetailPaymentMixin from "@/core/lib/payment/detail.payment.mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
//import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import UpdateTooltip from "@/view/pages/partials/UpdateTooltip.vue";
import AttachmentView from "@/view/pages/partials/AttachmentView";

export default {
  mixins: [DetailPaymentMixin],
  components: {
    UpdateTooltip,
    DetailTemplate,
    AttachmentView,
    //CustomStatus,
  },
  created() {
    const _this = this;
    if (_this.$route.query && _this.$route.query.tab) {
      _this.paymentTab = _this.$route.query.tab;
    }
  },
  computed: {
    paymentTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.paymentTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "overview";
      },
    },
    contractorEmail() {
      if (this.contractor && this.contractor.default_person) {
        if (this.contractor.default_person.primary_email) {
          return this.contractor.default_person.primary_email;
        }
      }
      return null;
    },
    contractorPhone() {
      if (this.contractor && this.contractor.default_person) {
        return this.contractor.default_person.primary_phone;
      }
      return null;
    },
  },
};
</script>
