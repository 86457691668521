<template>
  <Dialog :commonDialog.sync="consentDialog">
    <template v-slot:title> Consent Form </template>
    <template v-slot:body>
      <v-container fluid>
        <v-form
          ref="consentForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submitConsent()"
        >
          <v-sheet class="agreement">
            <div>
              <p class="d-flex">
                I,
                <v-text-field
                  dense
                  filled
                  solo
                  flat
                  hide-details
                  :rules="[() => !!attributes.ownerName || 'Name is required']"
                  v-model="attributes.ownerName"
                  class="max-width-400px mx-2 border-bottom"
                  color="cyan"
                >
                </v-text-field>
                (Name),
                <v-text-field
                  dense
                  filled
                  solo
                  v-model="attributes.ownerNRIC"
                  v-mask="nricMask"
                  flat
                  hide-details
                  :rules="[() => !!attributes.ownerNRIC || 'NRIC is required']"
                  class="max-width-200px mx-2 border-bottom"
                  color="cyan"
                >
                </v-text-field>
                (NRIC), owner of
                <v-text-field
                  dense
                  filled
                  solo
                  flat
                  v-model="attributes.ownerAddress"
                  hide-details
                  :rules="[
                    () => !!attributes.ownerAddress || 'Address is required',
                  ]"
                  class="mx-2 border-bottom"
                  color="cyan"
                >
                </v-text-field>
                (Address), hereby agree to allow {{ companyName }} to use any
                photos taken of me with {{ companyName }} services process for
                advertising &amp; promotion purposes in their marketing
                materials.
              </p>
              <div class="link" v-on:click="signaturecPopup('owner')">
                <p>SIGNED by the abovementioned</p>
                <v-img
                  v-if="signaturec.owner"
                  contain
                  position="left center"
                  class="my-4"
                  max-height="100"
                  :src="signaturec.owner"
                ></v-img>
                <p
                  class="sign-top t-bold mb-4"
                  :class="{ 'margin-top-100px': !signaturec.owner }"
                >
                  OWNER
                  <small class="ml-4"
                    ><em class="font-size-14">(click here to sign)</em></small
                  >
                </p>
              </div>
              <p>Name: {{ attributes.ownerName }}</p>
              <p>NRIC: {{ attributes.ownerNRIC }}</p>
              <p>Date: {{ formatDate(date) }}</p>
            </div>
          </v-sheet>
        </v-form>
        <Dialog v-if="consentDialog" :commonDialog.sync="signaturecDialog">
          <template v-slot:title> Signature </template>
          <template v-slot:body>
            <v-row>
              <v-col md="10" class="py-0" offset-md="1">
                <div
                  id="signaturec-pad"
                  ref="signaturec_div"
                  class="custom-border-grey-dashed"
                >
                  <canvas ref="signaturec"></canvas>
                </div>
              </v-col>
              <v-col md="10" class="py-0" offset-md="1">
                <p class="float-right m-0">
                  <v-btn
                    content="Click here to clear signaturec"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-right"
                    v-on:click="signaturecRef.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-slot:action>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="cyan"
              v-on:click="signaturecSave()"
            >
              Submit
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="signaturecClose()"
            >
              Cancel
            </v-btn>
          </template>
        </Dialog>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="submitConsent()"
      >
        Submit
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="closeDialog()"
      >
        Cancel
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      signaturecRef: null,
      signaturec: {
        owner: null,
        company: null,
      },
      signaturecDialog: false,
      signaturecType: null,
      date: new Date(),
      attributes: {
        startDate: null,
        endDate: null,
        ownerName: null,
        ownerNRIC: null,
        ownerAddress: null,
        amountInAlpha: null,
        amountInNumber: null,
        paymentSchedule: [
          {
            text: null,
            percent: null,
            amount: null,
          },
        ],
      },
    };
  },
  props: {
    consentDialog: {
      type: Boolean,
      default: false,
    },
    companyName: {
      type: String,
      default: null,
    },
    customerName: {
      type: String,
      default: null,
    },
    customerProperty: {
      type: String,
      default: null,
    },
  },
  watch: {
    consentDialog(param) {
      if (param) {
        this.initSign();
      }
    },
    customerName(param) {
      this.attributes.ownerName = param;
    },
    customerProperty(param) {
      this.attributes.ownerAddress = param;
    },
  },
  methods: {
    submitConsent() {
      const _this = this;

      const validateStatus = _this.$refs.consentForm.validate();

      const formErrors = _this.validateForm(_this.$refs.consentForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      const formData = {
        owner_url: _this.signaturec.owner,
        attributes: _this.attributes,
      };
      _this.$emit("submit", formData);
      _this.closeDialog();
    },
    closeDialog() {
      this.signaturecClose();
      this.$emit("close", true);
    },
    initSign() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signaturec"];
          let cparentDiv = document.getElementById("signaturec-pad");
          if (cparentDiv) {
            let cparentWidth = cparentDiv.offsetWidth;
            ccanvas.setAttribute("width", cparentWidth);
            _this.signaturecRef = new SignaturePad(ccanvas);
          }
        });
      }, 500);
    },
    signaturecSave() {
      if (this.signaturecRef.isEmpty()) {
        return false;
      }
      this.signaturec[this.signaturecType] = this.signaturecRef.toDataURL();
      this.signaturecClose();
    },
    signaturecPopup(type) {
      this.signaturecDialog = true;
      this.signaturecType = type;
      this.initSign();
    },
    signaturecClose() {
      this.signaturecDialog = false;
      this.signaturecType = null;
      if (this.signaturecRef) {
        this.signaturecRef.clear();
      }
      this.signaturecRef = null;
    },
  },
  mounted() {
    if (this.customerName) {
      this.attributes.ownerName = this.customerName;
    }
    if (this.customerProperty) {
      this.attributes.ownerAddress = this.customerProperty;
    }
  },
  components: {
    Dialog,
  },
};
</script>
