<template>
  <v-container fluid class="custom-line-item-template">
    <v-form
      ref="lineItemForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="receivePendingItem"
    >
      <table class="width-100">
        <thead>
          <tr>
            <th class="font-size-16 pb-2">Product</th>
            <th width="300" class="pl-5 font-size-16 pb-2">
              To Be Received Qty.
            </th>
            <th width="300" class="pl-5 font-size-16 pb-2">Receive Qty.</th>
          </tr>
          <tr>
            <td colspan="5" class="custom-border-bottom first-border"></td>
          </tr>
        </thead>
        <tbody v-if="formData.length > 0">
          <tr v-for="(item, index) in formData" :key="index">
            <td class="font-size-16 font-weight-500">
              <div class="detail-line-item py-4">
                <v-chip
                  small
                  label
                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                  text-color="white"
                  color="cyan darken-1"
                >
                  Product
                </v-chip>
                <p class="m-0 pt-1 text-capitalize custom-nowrap-ellipsis">
                  {{ item.product.name }}
                </p>
              </div>
            </td>
            <td>
              <v-text-field
                v-model.trim.number="item.to_be_received_quantity"
                dense
                filled
                flat
                color="cyan"
                :loading="productLoading"
                :disabled="productLoading || pageLoading"
                label="Qty."
                hide-details
                solo
                :rules="[
                  validateRules.required(item.to_be_received_quantity, 'Qty.'),
                  validateRules.validateQty(
                    item.to_be_received_quantity,
                    9999,
                    'Qty.'
                  ),
                ]"
                readonly
                class="remove-border-radius px-2 remove-border-radius hide-border-bottom"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.trim.number="item.quantity"
                dense
                filled
                flat
                color="cyan"
                :loading="productLoading"
                :disabled="productLoading || pageLoading"
                label="Qty."
                type="number"
                min="0"
                :max="item.to_be_received_quantity"
                hide-details
                solo
                :rules="[
                  validateRules.required(item.quantity, 'Qty.'),
                  validateRules.validateQty(
                    item.quantity,
                    item.to_be_received_quantity,
                    'Qty.'
                  ),
                ]"
                class="remove-border-radius px-2"
              ></v-text-field>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3">
              <p
                class="m-0 py-4 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no line item pending for receiving at the
                moment.
              </p>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="formData.length > 0">
          <tr>
            <td colspan="5" class="custom-border-top">
              <div>
                <v-btn
                  :loading="productLoading || pageLoading"
                  :disabled="!formValid || pageLoading"
                  class="mx-2 my-2 custom-bold-button white--text"
                  color="cyan"
                  v-on:click="receivePendingItem"
                  ><v-icon small left>mdi-format-align-left</v-icon>
                  Save
                </v-btn>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </v-form>
  </v-container>
</template>

<script>
import { GET, PUT } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [ValidationMixin],
  name: "pending-line-item",
  props: {
    purchaseOrder: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      formValid: true,
      productLoading: false,
      pageLoading: true,
      pendingLineItem: new Array(),
      formData: new Array(),
    };
  },
  methods: {
    getPendingLineItems() {
      const _this = this;
      _this.pageLoading = true;
      _this.formData = new Array();
      _this.pendingLineItem = new Array();
      _this.$store
        .dispatch(GET, {
          url: "purchase-order/" + _this.purchaseOrder + "/pending-receives",
        })
        .then(({ data }) => {
          _this.pendingLineItem = data;
          if (_this.lodash.isEmpty(_this.pendingLineItem) == false) {
            for (let i = _this.pendingLineItem.length - 1; i >= 0; i--) {
              _this.formData.push({
                id: _this.pendingLineItem[i].id,
                quantity: null,
                product: _this.pendingLineItem[i].product,
                to_be_received_quantity: _this.pendingLineItem[i].quantity,
              });
            }
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    receivePendingItem() {
      const _this = this;

      if (!_this.$refs.lineItemForm.validate()) {
        return false;
      }

      let formData = new Array();
      for (let i = _this.formData.length - 1; i >= 0; i--) {
        formData.push({
          id: _this.formData[i].id,
          quantity: _this.formData[i].quantity,
        });
      }
      _this.$store
        .dispatch(PUT, {
          url: "purchase-order/" + _this.purchaseOrder + "/receive",
          data: { line_item: formData },
        })
        .then(() => {
          _this.getPendingLineItems();
          _this.$emit("reload:content");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {});
    },
  },
  mounted() {
    this.getPendingLineItems();
  },
};
</script>
