<template>
  <v-container fluid>
    <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <h3 class="font-weight-700 custom-headline color-custom-blue">
          Line Items
        </h3>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <LineItemDetail
          :isQuotation.sync="isQuotation"
          :isContract.sync="isContract"
          :pageLoading.sync="pageLoading"
          :type.sync="type"
          :showcost="showcost"
          :detail.sync="detail"
          :canUpdate.sync="canUpdate"
        ></LineItemDetail>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
export default {
  name: "line-item-only-view",
  components: {
    LineItemDetail,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    showcost: {
      type: Boolean,
      default: true,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
};
</script>
