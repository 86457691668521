<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    customClass="prospect-create"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex" v-if="isUpdate">Update Prospect</h1>
      <h1 class="form-title d-flex" v-else>Create new Prospect</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        <template v-if="isUpdate">Update Prospect</template>
        <template v-else>Save Prospect</template>
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="prospectForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid v-bar class="create-prospect-vbar">
          <v-row>
            <v-col md="12" class="py-0">
              <p class="middle-header-background px-4 mb-0">Basic Details</p>
            </v-col>
            <v-col md="12" class="py-0">
              <v-row>
                <v-col md="6">
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="prospect_title"
                        class="font-weight-600 pl-4 font-size-16 required"
                        >First Name</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.first_name"
                        label="First Name"
                        :rules="[
                          validateRules.required(
                            prospectCreate.first_name,
                            'First Name'
                          ),
                          validateRules.minLength(
                            prospectCreate.first_name,
                            'First Name',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.first_name,
                            'First Name',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="phone_number"
                        class="font-weight-600 pl-4 font-size-16 required"
                        >Customer Phone No.</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <PhoneTextField
                        id="phone_number"
                        v-model="prospectCreate.phone_number"
                        :value="prospectCreate.phone_number"
                        required
                        hideDetails
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                      </PhoneTextField>
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6">
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16 required"
                        >Source</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="sourceList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.source"
                        label="Source"
                        solo
                        flat
                        append-outer-icon="mdi-cog"
                        v-on:click:append-outer="
                          manageProspectSourceDialog = true
                        "
                        item-color="cyan"
                        hide-details
                        :rules="[
                          validateRules.required(
                            prospectCreate.source,
                            'Source'
                          ),
                        ]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Source Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16 required"
                        >Prospect Value</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.lead_value"
                        dense
                        filled
                        label="Prospect Value"
                        solo
                        flat
                        hide-details
                        type="number"
                        min="0"
                        prepend-inner-icon="mdi-currency-usd"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        v-on:keypress="
                          limitDecimal($event, prospectCreate.lead_value)
                        "
                        :rules="[
                          validateRules.required(
                            prospectCreate.lead_value,
                            'Prospect Value'
                          ),
                        ]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="12" class="py-0">
              <p class="middle-header-background px-4 mb-0">Other Details</p>
            </v-col>
            <v-col md="12" class="py-0">
              <v-row>
                <v-col md="6">
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="alternate-number"
                        class="font-weight-600 pl-4 font-size-16"
                        >(Alternate) Phone No.</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <PhoneTextField
                        id="alternate-number"
                        v-model="prospectCreate.alt_phone_number"
                        :value="prospectCreate.alt_phone_number"
                        hideDetails
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                      </PhoneTextField>
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Assigned</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="userList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.assigned_user"
                        label="Assigned"
                        solo
                        flat
                        item-color="cyan"
                        item-text="full_name"
                        item-value="id"
                        hide-details
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.assigned_user,
                            'Prospect Subject',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.assigned_user,
                            'Prospect Subject',
                            100
                          ),
                        ]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="prospect_title"
                        class="font-weight-600 pl-4 font-size-16"
                        >Prospect Subject</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        id="prospect_title"
                        v-model.trim="prospectCreate.subject"
                        dense
                        filled
                        label="Subject"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.subject,
                            'Prospect Subject',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.title,
                            'Prospect Subject',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="prospect_title"
                        class="font-weight-600 pl-4 font-size-16"
                        >Last Name</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.last_name"
                        label="Last Name"
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.last_name,
                            'Last Name',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.last_name,
                            'Last Name',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="prospect_title"
                        class="font-weight-600 pl-4 font-size-16"
                        >Customer Company</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.company"
                        dense
                        filled
                        label="Company"
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.company,
                            'Company',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.company,
                            'Company',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="prospect_title"
                        class="font-weight-600 pl-4 font-size-16"
                        >Customer Email</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.email"
                        dense
                        filled
                        label="Email"
                        :rules="[
                          validateRules.validEmail(
                            prospectCreate.email,
                            'Email'
                          ),
                          validateRules.minLength(
                            prospectCreate.email,
                            'Email',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.email,
                            'Email',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label
                        for="website"
                        class="font-weight-600 pl-4 font-size-16"
                        >Customer Website</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        id="website"
                        v-model.trim="prospectCreate.website"
                        dense
                        filled
                        label="Website"
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.website,
                            'Website',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.website,
                            'Website',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Last Contacted Date
                      </label>
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <DatePicker
                        clearable
                        :pageLoading="pageLoading"
                        solo
                        :placeholder="'Last Contacted Date'"
                        :default-date="prospectCreate.last_contact"
                        :min-date="prospectCreate.lead_date"
                        :nullable="true"
                        v-model="prospectCreate.last_contact"
                      ></DatePicker>

                      <!--  <DatePicker
                        solo
                        clearable
                        :pageLoading.sync="pageLoading"
                        placeholder="Last Contacted Date"
                        v-model="prospectCreate.last_contact"
                        :min-date="prospectCreate.lead_date"
                      ></DatePicker> -->
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false" class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Prospect Date</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <DatePicker
                        solo
                        :pageLoading.sync="pageLoading"
                        placeholder="Prospect Date"
                        v-model="prospectCreate.prospect_date"
                        :min-date="minDate"
                      ></DatePicker>
                    </v-flex>
                  </v-layout>

                  <v-layout v-if="false" class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Status</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="statusList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.status"
                        label="Status"
                        solo
                        flat
                        item-color="cyan"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Status Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6">
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Prospect Number</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <div class="d-inline-flex">
                        <v-skeleton-loader
                          v-if="pageLoading"
                          class="custom-skeleton"
                          type="text"
                        ></v-skeleton-loader>
                        <template v-else>
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            readonly
                            v-model.trim="prospectCreate.barcode"
                            label="Prospect Number"
                            solo
                            flat
                            hide-details
                            class="remove-border cursor-default font-weight-600"
                          ></v-text-field>
                        </template>
                        <v-btn
                          text
                          small
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          class="mx-2 my-auto custom-bold-button"
                          v-on:click="barcodeDialog = true"
                          color="cyan"
                        >
                          Change
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Reference #</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.reference"
                        label="Reference #"
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.reference,
                            'Reference',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.reference,
                            'Reference',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false" class="align-center my-4">
                    <v-flex v-if="false" md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >NRIC No.</label
                      >
                    </v-flex>
                    <v-flex v-if="false" md8 class="mx-4">
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.nric_no"
                        label="NRIC No."
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.nric_no,
                            'NRIC No.',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.nric_no,
                            'NRIC No.',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Block</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.unit_no"
                        dense
                        filled
                        label="Block"
                        solo
                        flat
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.unit_no,
                            'Block',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.unit_no,
                            'Block',
                            100
                          ),
                        ]"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Street</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.address_1"
                        dense
                        filled
                        label="Street"
                        solo
                        flat
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.address_1,
                            'Street',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.address_1,
                            'Street',
                            100
                          ),
                        ]"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Unit No.</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.address_2"
                        dense
                        filled
                        label="Unit No."
                        solo
                        flat
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.address_2,
                            'Unit No.',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.address_2,
                            'Unit No.',
                            100
                          ),
                        ]"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Postal Code</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.postal_code"
                        dense
                        filled
                        label="Postal Code"
                        v-mask="'######'"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Country</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-text-field
                        v-model.trim="prospectCreate.country"
                        dense
                        filled
                        label="Country"
                        :rules="[
                          validateRules.minLength(
                            prospectCreate.country,
                            'Country',
                            1
                          ),
                          validateRules.maxLength(
                            prospectCreate.country,
                            'Country',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="false" class="align-center my-4">
                    <v-flex md4>
                      <label class="font-weight-600 pl-4 font-size-16"
                        >Category</label
                      >
                    </v-flex>
                    <v-flex md8 class="mx-4">
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="categoryList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="prospectCreate.category"
                        label="Category"
                        solo
                        flat
                        append-outer-icon="mdi-cog"
                        v-on:click:append-outer="
                          manageProspectCategoryDialog = true
                        "
                        item-color="cyan"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Category Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="12" class="py-0">
                  <editor v-model="prospectCreate.description" />
                </v-col>
                <v-col md="7">
                  <!-- <FileTemplate
                    allowUpload
                    v-on:file:updated="updateAttachment"
                  ></FileTemplate> -->
                  <AttachmentWithName
                    v-on:file:updated="updateAttachment"
                    :label="true"
                    :isMultiple="true"
                    allowUpload
                  ></AttachmentWithName>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <ManageProspectSource
        :common-dialog="manageProspectSourceDialog"
        :sources="sourceList"
        v-on:close-dialog="manageProspectSourceDialog = false"
        v-on:get-prospect-source="getOptions()"
      ></ManageProspectSource>
      <ManageProspectStatus
        :common-dialog="manageProspectStatusDialog"
        :statuses="statusList"
        v-on:close-dialog="manageProspectStatusDialog = false"
        v-on:get-prospect-status="getOptions()"
      ></ManageProspectStatus>
      <ManageProspectCategory
        :common-dialog="manageProspectCategoryDialog"
        :categories="categoryList"
        v-on:close-dialog="manageProspectCategoryDialog = false"
        v-on:get-prospect-category="getOptions()"
      ></ManageProspectCategory>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="prospect"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  PUT,
  GET,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
//import FileTemplate from "@/view/pages/partials/FileTemplate";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageProspectSource from "@/view/pages/partials/Manage-Lead-Source.vue";
import ManageProspectCategory from "@/view/pages/partials/Manage-Lead-Category.vue";
import ManageProspectStatus from "@/view/pages/partials/Manage-Lead-Status.vue";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";
import { currentUser } from "@/core/services/jwt.service.js";
import ObjectPath from "object-path";
import { toSafeInteger } from "lodash";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "prospect-create",
  title: "Create Prospect",
  data() {
    return {
      pageLoading: false,
      backForce: false,
      barcodeDialog: false,
      manageProspectSourceDialog: false,
      manageProspectCategoryDialog: false,
      manageProspectStatusDialog: false,
      barcodeSetting: new Object(),
      statusList: new Array(),
      sourceList: new Array(),
      extraList: new Array(),
      categoryList: new Array(),
      userList: new Array(),
      prospectCreate: new Object({
        subject: null,
        first_name: null,
        last_name: null,
        name: null,
        nric_no: null,
        company: null,
        email: null,
        phone_number: null,
        alt_phone_number: null,
        website: null,
        prospect_date: null,
        last_contact: new Date().toISOString().substr(0, 10),
        status: null,
        source: null,
        barcode: null,
        reference: null,
        unit_no: null,
        address_1: null,
        address_2: null,
        postal_code: null,
        zip: null,
        country: "Singapore",
        category: null,
        lead_value: null,
        assigned_user: null,
        description: null,
        attachments: [],
        city: null,
        state: null,
        // extra: null
      }),
      minDate: new Date().toISOString().substr(0, 10),
      isUpdate: false,
      prospect_id: 0,
    };
  },
  watch: {
    "prospectCreate.first_name"() {
      this.prospectCreate.name =
        this.prospectCreate.first_name +
        " " +
        (this.prospectCreate.last_name || "");
    },
    "prospectCreate.last_name"() {
      this.prospectCreate.name =
        this.prospectCreate.first_name +
        " " +
        (this.prospectCreate.last_name || "");
    },
  },
  components: {
    ManageProspectSource,
    ManageProspectCategory,
    ManageProspectStatus,
    DatePicker,
    PhoneTextField,
    //FileTemplate,
    BarcodeSetting,
    CreateUpdateTemplate,
    editor: TinyMCE,
    AttachmentWithName,
  },
  methods: {
    getProspect() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "prospects/" + _this.prospect_id,
        })
        .then((output) => {
          _this.prospectCreate.subject = ObjectPath.get(output, "data.subject");
          _this.prospectCreate.name = ObjectPath.get(output, "data.name");
          _this.prospectCreate.first_name = ObjectPath.get(
            output,
            "data.first_name"
          );
          _this.prospectCreate.last_name = ObjectPath.get(
            output,
            "data.last_name"
          );
          _this.prospectCreate.company = ObjectPath.get(output, "data.company");
          _this.prospectCreate.nric_no = ObjectPath.get(output, "data.nric_no");
          _this.prospectCreate.email = ObjectPath.get(output, "data.email");
          _this.prospectCreate.alt_phone_number = ObjectPath.get(
            output,
            "data.alt_phone_number"
          );
          _this.prospectCreate.phone_number = ObjectPath.get(
            output,
            "data.phone_number"
          );
          _this.prospectCreate.website = ObjectPath.get(output, "data.website");
          _this.prospectCreate.prospect_date = ObjectPath.get(
            output,
            "data.prospect_date"
          );
          _this.prospectCreate.last_contact = ObjectPath.get(
            output,
            "data.last_contact"
          );
          _this.prospectCreate.lead_date = ObjectPath.get(
            output,
            "data.lead_date"
          );
          _this.prospectCreate.status = ObjectPath.get(output, "data.status");
          _this.prospectCreate.source = ObjectPath.get(
            output,
            "data.source.id",
            0
          );
          _this.prospectCreate.barcode = ObjectPath.get(output, "data.barcode");
          _this.prospectCreate.reference = ObjectPath.get(
            output,
            "data.reference"
          );
          _this.prospectCreate.unit_no = ObjectPath.get(output, "data.unit_no");
          _this.prospectCreate.address_1 = ObjectPath.get(
            output,
            "data.address_1"
          );
          _this.prospectCreate.address_2 = ObjectPath.get(
            output,
            "data.address_2"
          );
          _this.prospectCreate.postal_code = ObjectPath.get(
            output,
            "data.postal_code"
          );
          _this.prospectCreate.country = ObjectPath.get(output, "data.country");
          _this.prospectCreate.category = ObjectPath.get(
            output,
            "data.category.id",
            0
          );
          _this.prospectCreate.lead_value = ObjectPath.get(
            output,
            "data.lead_value"
          );
          _this.prospectCreate.assigned_user = ObjectPath.get(
            output,
            "data.assigned_user.id",
            0
          );
          _this.prospectCreate.description = ObjectPath.get(
            output,
            "data.description"
          );
          _this.prospectCreate.attachments = ObjectPath.get(
            output,
            "data.attachments"
          );

          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Prospect", route: "prospect" },
            { title: "Update" },
            { title: _this.prospectCreate.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateAttachment(param) {
      this.prospectCreate.attachments = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "prospects/options",
        })
        .then(({ data }) => {
          _this.barcodeSetting = data.barcode_setting;
          if (!_this.isUpdate) {
            _this.prospectCreate.barcode = data.barcode;
          }
          _this.statusList = _this.lodash.filter(
            data.status_list,
            function (row) {
              return row.value < 6;
            }
          );
          _this.sourceList = data.sources;
          // _this.extraList = data.extras;
          _this.categoryList = data.categories;
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.prospectForm.validate();

      const formErrors = _this.validateForm(_this.$refs.prospectForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        subject: _this.prospectCreate.subject || null,
        name: _this.prospectCreate.name || null,
        first_name: _this.prospectCreate.first_name || null,
        last_name: _this.prospectCreate.last_name || null,
        company: _this.prospectCreate.company || null,
        nric_no: _this.prospectCreate.nric_no || null,
        email: _this.prospectCreate.email || null,
        phone_number: _this.prospectCreate.phone_number || null,
        alt_phone_number: _this.prospectCreate.alt_phone_number || null,
        website: _this.prospectCreate.website || null,
        prospect_date: _this.prospectCreate.prospect_date || null,
        last_contact: _this.prospectCreate.last_contact || null,
        status: _this.prospectCreate.status || null,
        source: _this.prospectCreate.source || null,
        barcode: _this.prospectCreate.barcode || null,
        reference: _this.prospectCreate.reference || null,
        unit_no: _this.prospectCreate.unit_no || null,
        address_1: _this.prospectCreate.address_1 || null,
        address_2: _this.prospectCreate.address_2 || null,
        postal_code: _this.prospectCreate.postal_code || null,
        country: _this.prospectCreate.country || null,
        category: _this.prospectCreate.category || null,
        lead_value: _this.prospectCreate.lead_value || null,
        assigned_user: _this.prospectCreate.assigned_user || null,
        description: _this.prospectCreate.description || null,
        attachments: _this.prospectCreate.attachments || [],
      });

      let requestType = POST;
      let requestURL = "prospects";

      if (_this.isUpdate) {
        requestType = PUT;
        requestURL = `prospects/${_this.prospect_id}`;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: formData })
        .then(({ data }) => {
          _this.backForce = true;
          _this.$router.push(
            _this.getDefaultRoute("prospect.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.backForce = false;
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;

    const user = currentUser();

    if (user.super_admin == 0) {
      _this.prospectCreate.assigned_user = user.id;
    }

    _this.getOptions();
    if (_this.isUpdate) {
      _this.getProspect();
      _this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Prospect", route: "prospect" },
        { title: "Update" },
      ]);
    } else {
      _this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Prospect", route: "prospect" },
        { title: "Create" },
      ]);
    }
  },
  created() {
    const route_name = ObjectPath.get(this.$route, "name");
    const prospect_id = toSafeInteger(
      ObjectPath.get(this.$route, "params.id", 0)
    );
    if (route_name == "admin.prospect.update") {
      if (prospect_id > 0) {
        this.prospect_id = prospect_id;
        this.isUpdate = true;
      } else {
        this.goBack();
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
