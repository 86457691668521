<template>
  <v-container fluid class="white-background main-create-page create-doc-view">
    <v-row>
      <v-col md="12" class="custom-border-bottom py-0 pb-3">
        <v-layout>
          <v-flex class="my-auto">
            <h1
              class="form-title font-weight-700 custom-nowrap-ellipsis text-capitalize mb-0"
            >
              {{ getTitle() }}
            </h1>
            <p class="m-0 text--secondary" v-if="!lodash.isEmpty(detail)">
              <v-chip
                v-if="false"
                small
                label
                content="Status"
                v-tippy
                class="mr-2 text-uppercase"
                text-color="white"
                color="cyan"
              >
                {{ detail.status_text }}
              </v-chip>
              <v-icon v-if="false" x-small color="grey darken-2"
                >mdi-record</v-icon
              >
              <v-chip
                small
                label
                content="Last Modified Time"
                v-tippy
                class="mr-2 text-uppercase"
                color="cyan"
                text-color="white"
              >
                {{ detail.modified_at }}
              </v-chip>
              <template v-if="detail.expiry_date">
                <v-icon x-small color="grey darken-2">mdi-record</v-icon>
                <v-chip
                  small
                  label
                  content="Document Expiry Date"
                  v-tippy
                  class="ml-2 text-uppercase"
                  text-color="white"
                  color="cyan"
                >
                  {{ formatDate(detail.expiry_date) }}
                </v-chip>
              </template>
            </p>
          </v-flex>
          <v-flex class="my-auto text-right">
            <v-btn
              v-if="!pagesBlock"
              color="cyan white--text"
              class="custom-bold-button"
              v-on:click="pagesBlock = true"
              >View Pages</v-btn
            >
            <v-btn
              v-if="!contentBlock && !propertyBlock"
              color="cyan white--text"
              class="ml-4 custom-bold-button"
              v-on:click="contentBlock = true"
              >View Contents</v-btn
            >
            <v-btn
              color="cyan white--text"
              class="ml-4 custom-bold-button"
              v-on:click="sendDocument()"
              >Send Document</v-btn
            >
            <v-btn
              v-if="false"
              color="cyan white--text"
              class="ml-4 custom-bold-button"
              v-on:click="saveDocument('view')"
              >View</v-btn
            >
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan white--text"
                  class="ml-4 custom-bold-button"
                  >More...</v-btn
                >
              </template>
              <v-list>
                <v-list-item
                  link
                  v-if="detail.recipients && detail.recipients.length"
                  v-on:click="recipientDialog = true"
                >
                  <v-list-item-title class="font-size-16 py-2 font-weight-500"
                    ><v-icon left>mdi-account-cog</v-icon>Manage
                    Recipients</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link v-on:click="doAction('update:expiry')">
                  <v-list-item-title class="font-size-16 py-2 font-weight-500"
                    ><v-icon left>mdi-clock-outline</v-icon
                    >Expiration</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  v-if="false"
                  link
                  v-on:click="doAction('download:document')"
                >
                  <v-list-item-title class="font-size-16 py-2 font-weight-500"
                    ><v-icon left>mdi-download</v-icon
                    >Download</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  link
                  class="delete-link"
                  v-on:click="doAction('delete:document')"
                >
                  <v-list-item-title class="font-size-16 py-2 font-weight-500"
                    ><v-icon left>mdi-delete</v-icon>Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn v-on:click="goBack()" class="custom-bold-button ml-4"
              >Close</v-btn
            >
          </v-flex>
        </v-layout>
      </v-col>
      <v-col md="2" v-if="pagesBlock">
        <v-layout class="custom-border-bottom">
          <v-flex class="my-auto"
            ><p class="m-0 font-size-16 font-weight-500">Pages</p></v-flex
          >
          <v-flex class="text-right"
            ><v-btn icon v-on:click="closeBlock('pages')"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-flex
          >
        </v-layout>
        <div class="doc-left-block">
          <v-layout
            v-for="(row, ilkey) in detail.pages"
            :key="ilkey"
            class="my-4"
          >
            <v-flex md1 class="text-center">{{ ilkey + 1 }}.</v-flex>
            <v-flex
              md9
              class="doc-page-thumbnails"
              v-on:click="scrollPage(row.page)"
            >
              <v-img contain :src="row.image" :lazy-src="$defaultImage"></v-img>
            </v-flex>
            <v-flex md2>
              <v-btn icon v-on:click="removePage(row.page, row.image)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </div>
      </v-col>
      <v-col :md="getCenterMD()" class="custom-border-left custom-border-right">
        <div class="doc-center-block">
          <template v-for="(row, ickey) in detail.pages">
            <component
              :key="ickey"
              class="doc-list-pages"
              :data-page-no="ickey + 1"
              :id="`doc-page-${ickey + 1}`"
              v-on:update:property="updateProperty"
              :page-no="row.page"
              :doc-ele="doc_ele"
              :image="row.image"
              :recipients="recipients"
              :is="docTemplate"
            />
          </template>
        </div>
      </v-col>
      <v-col
        md="2"
        v-if="!lodash.isEmpty(detail) && (contentBlock || propertyBlock)"
      >
        <template v-if="!detail.recipients">
          <v-btn
            class="w-100 custom-grey-border custom-bold-button"
            color="cyan white--text"
            v-on:click="recipientDialog = true"
          >
            Manage Recipients
          </v-btn>
        </template>
        <template v-else>
          <template v-if="contentBlock && !propertyBlock">
            <v-layout class="custom-border-bottom">
              <v-flex class="my-auto"
                ><p class="m-0 font-size-16 font-weight-500">
                  Contents
                </p></v-flex
              >
              <v-flex class="text-right"
                ><v-btn icon v-on:click="closeBlock('contents')"
                  ><v-icon>mdi-close</v-icon></v-btn
                ></v-flex
              >
            </v-layout>
            <div class="doc-right-block">
              <div
                draggable="true"
                class="layout custom-border-grey-dashed my-2 py-4 px-2"
                v-for="(content, index) in doc_contents"
                v-on:dragstart="dragStartEvent($event, content)"
                :key="index"
              >
                <v-flex md2
                  ><v-icon>{{ content.icon }}</v-icon></v-flex
                >
                <v-flex md10
                  ><p class="m-0 font-size-16 font-weight-500">
                    {{ content.title }}
                  </p></v-flex
                >
              </div>
            </div>
          </template>
          <template v-if="propertyBlock && !contentBlock">
            <v-layout class="custom-border-bottom">
              <v-flex class="my-auto"
                ><p class="m-0 font-size-16 font-weight-500">
                  {{ property.title }}
                </p></v-flex
              >
              <v-flex class="text-right"
                ><v-btn icon v-on:click="closeBlock('property')"
                  ><v-icon>mdi-close</v-icon></v-btn
                ></v-flex
              >
            </v-layout>
            <div class="doc-right-block doc-property">
              <template v-if="false">
                <p class="font-size-14 text--secondary m-0 my-3">RECIPIENT</p>
                <v-menu offset-y bottom max-height="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      content="Who needs to fill out this?"
                      v-tippy
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      class="w-100 text-truncate custom-bold-button d-inline-grid"
                    >
                      <template v-if="property.uuid && elementProps.user">
                        <template v-if="elementProps.user.first_name"
                          >{{ elementProps.user.first_name }}
                          {{ elementProps.user.last_name }}</template
                        >
                        <template v-else>{{
                          elementProps.user.email
                        }}</template>
                      </template>
                      <template v-else>
                        <v-icon left>mdi-account-plus</v-icon>
                        Assign
                      </template>
                    </v-btn>
                  </template>

                  <v-list two-line>
                    <v-list-item-group
                      v-on:change="submitProperty()"
                      v-model="elementProps.user"
                      color="cyan"
                    >
                      <v-list-item
                        v-for="(row, ind) in recipients"
                        :key="ind"
                        :value="row"
                      >
                        <v-list-item-avatar
                          class="m-0 my-1 mr-3 page-element-user-avatar"
                        >
                          {{ pageElementUserAvatar(row) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <template v-if="row.first_name">
                              {{ row.first_name }}
                              {{ row.last_name ? row.last_name : "" }}
                            </template>
                            <template v-else>{{ row.email }}</template>
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            row.email
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
              <p class="font-size-14 text--secondary m-0 my-3">PLACEHOLDER</p>
              <v-text-field
                dense
                filled
                v-on:change="submitProperty()"
                placeholder="Placeholder"
                hide-details
                v-model="elementProps.placeholder"
                color="cyan"
              ></v-text-field>
              <p
                class="font-size-14 text--secondary m-0 my-3 pt-4 custom-border-top-1px"
              >
                SETTINGS
              </p>
              <v-checkbox
                label="Required"
                class="m-0 p-0"
                color="cyan"
                v-on:change="submitProperty()"
                v-model="elementProps.required"
                hide-details
              ></v-checkbox>
              <p
                class="font-size-14 text--secondary m-0 mt-3 pt-4 custom-border-top-1px"
              >
                ACTIONS
              </p>
              <v-list>
                <v-list-item link v-on:click="makeDuplicateElement()">
                  <v-list-item-icon class="m-0 my-auto mr-3">
                    <v-icon>mdi-content-duplicate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Duplicate</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link v-on:click="deleteElement()">
                  <v-list-item-icon class="m-0 my-auto mr-3">
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </template>
        </template>
      </v-col>
    </v-row>
    <Dialog dense :commonDialog="sendDialog" :dialogWidth="dialogWidth">
      <template v-slot:tab>
        <v-tabs
          v-model="documentTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#email"
            >Send via email</v-tab
          >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#share"
            >Share via link</v-tab
          >
        </v-tabs>
      </template>
      <template v-slot:body>
        <v-container class="p-0" fluid>
          <v-tabs-items v-model="documentTab">
            <v-tab-item value="email">
              <v-container class="px-10" fluid>
                <v-form
                  ref="mail_form"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="sendMail()"
                >
                  <v-layout class="mb-4">
                    <v-flex md3 class="my-auto">
                      <label class="font-weight-500 font-size-16 required"
                        >Recipients</label
                      >
                    </v-flex>
                    <v-flex md9>
                      <v-combobox
                        chips
                        :items="autocompleteEmails()"
                        v-model="email.recipients"
                        placeholder="Recipients"
                        label="Recipients"
                        multiple
                        flat
                        v-on:change="validateTagEmail(email.recipients)"
                        hide-details
                        solo
                        :rules="[
                          validateRules.required(
                            email.recipients,
                            'Recipients'
                          ),
                        ]"
                      >
                        <template v-slot:append-outer>
                          <v-chip
                            v-on:click="emailCCShow = !emailCCShow"
                            class="append-outer-chip"
                            label
                            :color="emailCCShow ? 'green' : ''"
                            :text-color="emailCCShow ? 'white' : ''"
                          >
                            <span class="font-weight-500 font-size-16">CC</span>
                          </v-chip>
                        </template>
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            label
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click:close="removeEmail(item, 'recipients')"
                          >
                            <span class="font-weight-500 font-size-16">{{
                              item
                            }}</span>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="emailCCShow" class="my-4">
                    <v-flex md3 class="my-auto">
                      <label class="font-weight-500 font-size-16">CC</label>
                    </v-flex>
                    <v-flex md9>
                      <v-combobox
                        v-model="email.cc"
                        chips
                        :items="autocompleteEmails()"
                        placeholder="CC"
                        label="CC"
                        multiple
                        flat
                        v-on:change="validateTagEmail(email.cc)"
                        hide-details
                        solo
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            label
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click:close="removeEmail(item, 'cc')"
                          >
                            <span class="font-weight-500 font-size-16">{{
                              item
                            }}</span>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-flex>
                  </v-layout>
                  <v-layout class="my-4">
                    <v-flex md3 class="my-auto">
                      <label class="font-weight-500 font-size-16 required"
                        >Subject</label
                      >
                    </v-flex>
                    <v-flex md9>
                      <v-text-field
                        v-model="email.subject"
                        dense
                        filled
                        label="Subject"
                        placeholder="Subject"
                        solo
                        flat
                        hide-details
                        color="cyan"
                        :rules="[
                          validateRules.required(email.subject, 'Subject'),
                        ]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="my-4">
                    <v-flex md12>
                      <TinyMCE v-model="email.message"></TinyMCE>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-4">
                    <v-flex md12>
                      <FileTemplate
                        v-on:file:updated="updateAttachment"
                        allowUpload
                      ></FileTemplate>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-tab-item>
            <v-tab-item value="share">
              <v-container class="px-10" fluid>
                <v-form>
                  <v-layout class="mb-4">
                    <v-flex md3 class="my-auto">
                      <label class="font-weight-500 font-size-16 required"
                        >Recipients</label
                      >
                    </v-flex>
                    <v-flex md9>
                      <v-combobox
                        chips
                        :items="autocompleteEmails()"
                        v-model="share.recipients"
                        placeholder="Recipients"
                        label="Recipients"
                        :disabled="generateLoading"
                        :loading="generateLoading"
                        multiple
                        flat
                        color="cyan"
                        v-on:change="validateTagEmail(share.recipients)"
                        hide-details
                        solo
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            label
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click:close="removeShareEmail(item, 'recipients')"
                          >
                            <span class="font-weight-500 font-size-16">{{
                              item
                            }}</span>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mb-4 custom-border-top">
                    <v-flex md12 class="mt-4 bg-grey-green">
                      <div class="d-flex">
                        <div class="my-auto p-10">
                          <v-icon x-large left class="font-size-60"
                            >mdi-link mdi-rotate-45</v-icon
                          >
                        </div>
                        <div class="my-auto py-6">
                          <h3>Generate links to share the document</h3>
                          <p>
                            Once links are generated, the document will be in
                            Sent status.
                          </p>
                          <em
                            class="d-block mb-3 deep-orange--text text--lighten-1"
                            v-if="!share.recipients.length"
                            >Select at least one recipient to view and/or sign
                            your document.</em
                          >
                          <v-btn
                            color="cyan"
                            v-on:click="generateLinks()"
                            :disabled="
                              generateLoading || !share.recipients.length
                            "
                            class="custom-bold-button white--text"
                            >Generate Links</v-btn
                          >
                        </div>
                      </div>
                      <v-progress-linear
                        v-if="generateLoading"
                        color="cyan"
                        indeterminate
                        height="6"
                      ></v-progress-linear>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mb-4 custom-border-top">
                    <v-flex
                      md12
                      class="pt-4"
                      :class="{
                        'block-share-recipients':
                          !share.recipients.length || !linkGenerated,
                      }"
                    >
                      <h3 class="font-size-16 font-weight-600">
                        INDIVIDUAL LINKS
                      </h3>
                      <p class="font-size-16">
                        Links are unique for each recipient. Make sure that the
                        intended recipients/signers are the only ones accessing
                        the document through their link.
                      </p>
                      <table width="100%">
                        <tbody>
                          <tr v-if="!generatedLinks.length">
                            <td class="px-3" width="100">sample@example.com</td>
                            <td class="px-3">
                              <v-text-field
                                dense
                                filled
                                class="w-100"
                                label="URL"
                                solo
                                flat
                                :value="getURLPlaceholder()"
                                hide-details
                                color="cyan"
                                readonly
                              ></v-text-field>
                            </td>
                            <td class="px-3" width="50">
                              <v-btn
                                text
                                color="cyan"
                                class="custom-bold-button white--text"
                                >Copy</v-btn
                              >
                            </td>
                          </tr>
                          <tr
                            v-for="(row, index) in generatedLinks"
                            :key="index"
                          >
                            <td class="px-3" width="100">{{ row.email }}</td>
                            <td class="px-3 py-2">
                              <v-text-field
                                dense
                                filled
                                class="w-100"
                                label="URL"
                                solo
                                flat
                                :ref="`copy-text-${index}`"
                                :value="row.link"
                                hide-details
                                color="cyan"
                                readonly
                              ></v-text-field>
                            </td>
                            <td class="px-3" width="50">
                              <v-btn
                                text
                                color="cyan"
                                class="custom-bold-button white--text"
                                v-on:click="copyText(`copy-text-${index}`)"
                                >Copy</v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="sendDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
        <v-btn
          v-on:click="sendMail()"
          :disabled="!formValid"
          v-if="documentTab == 'email'"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          <v-icon left>mdi-send mdi-rotate-315</v-icon>Send
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog="expiryDialog">
      <template v-slot:title> Update Expiry Date </template>
      <template v-slot:body>
        <v-container class="p-0" fluid>
          <v-layout>
            <v-flex md3 class="my-auto">
              <label class="required font-weight-600 font-size-16"
                >Expiry Date</label
              >
            </v-flex>
            <v-flex md9>
              <DatePicker
                solo
                :disabled="expiryLoading"
                v-model="expiry_date"
                :loading="expiryLoading"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="expiryDialog = false"
          :disabled="expiryLoading"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
        <v-btn
          v-on:click="updateExpiry()"
          :disabled="expiryLoading"
          :loading="expiryLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Update
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog="deleteDialog">
      <template v-slot:title> Delete Document </template>
      <template v-slot:body>
        <v-container class="p-0" fluid>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ getTitle() }}</span
            >, you won't be able to retrieve this later. <br />Are you sure you
            want to delete
            <span class="font-weight-700">{{ getTitle() }}</span>
            ?
          </h3>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="deleteDialog = false"
          :disabled="deleteLoading"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="deleteDocument()"
          :disabled="deleteLoading"
          class="mx-2 custom-bold-button white--text"
          color="red lighten-1"
        >
          Yes! Delete
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog="recipientDialog" :dialogWidth="dialogWidth">
      <template v-slot:title> Manage recipients </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form
            ref="docForm"
            v-model.trim="formRPValid"
            lazy-validation
            v-on:submit.stop.prevent="updateRecipients()"
          >
            <v-row>
              <v-col class="py-0" md="4"
                ><label
                  for="email"
                  class="m-0 font-weight-700 font-size-16 required"
                  >Email</label
                ></v-col
              >
              <v-col class="py-0" md="4"
                ><label
                  for="first-name"
                  class="m-0 font-weight-700 font-size-16"
                  >First Name</label
                ></v-col
              >
              <v-col class="py-0" md="4"
                ><label for="last-name" class="m-0 font-weight-700 font-size-16"
                  >Last Name</label
                ></v-col
              >
            </v-row>
            <v-row v-for="(row, index) in recipients" :key="index">
              <v-col md="4">
                <v-text-field
                  :id="`email-${index}`"
                  v-model="row.email"
                  dense
                  filled
                  label="Email"
                  solo
                  :disabled="recipientLoading"
                  :loading="recipientLoading"
                  flat
                  hide-details
                  color="cyan"
                  :rules="[
                    validateRules.required(row.email, 'Email'),
                    validateRules.validEmail(row.email, 'Email'),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field
                  :id="`first-name-${index}`"
                  v-model="row.first_name"
                  dense
                  filled
                  label="First Name"
                  solo
                  :disabled="recipientLoading"
                  :loading="recipientLoading"
                  flat
                  hide-details
                  color="cyan"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field
                  :id="`last-name-${index}`"
                  v-model="row.last_name"
                  dense
                  filled
                  label="Last Name"
                  solo
                  :disabled="recipientLoading"
                  :loading="recipientLoading"
                  flat
                  append-outer-icon="mdi-delete"
                  class="has-delete-outer-icon"
                  v-on:click:append-outer="remove_recipients(index)"
                  hide-details
                  color="cyan"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              v-on:click="add_recipients()"
              color="cyan white--text"
              small
              class="custom-bold-button"
              ><v-icon left>mdi-plus</v-icon> Add Recipient</v-btn
            >
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="recipientLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="getDoc()"
        >
          Close
        </v-btn>
        <v-btn
          :disabled="!formRPValid || recipientLoading"
          :loading="recipientLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="updateRecipients()"
        >
          Save
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET, POST, PUT, DELETE } from "@/core/services/store/request.module";
import { DocumentEventBus } from "@/core/lib/document.lib";
import DocPage from "@/view/pages/docs/Doc-Page";
import {
  ErrorEventBus,
  SuccessEventBus,
  ClearEventBus,
  InitializeError,
} from "@/core/lib/message.lib";
import Dialog from "@/view/pages/partials/Dialog";
import DatePicker from "@/view/pages/partials/Datepicker";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import { validateEmail } from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "doc-list",
  data() {
    return {
      sendDialog: false,
      documentTab: "email",
      generateLoading: false,
      mailLoading: false,
      dataLoading: true,
      pagesBlock: true,
      contentBlock: true,
      propertyBlock: false,
      emailCCShow: false,
      linkGenerated: false,
      docId: null,
      detail: {},
      doc_contents: [
        {
          type: "text",
          icon: "mdi-format-letter-case",
          title: "Text",
        },
        {
          type: "checkbox",
          icon: "mdi-check",
          title: "Checkbox",
        },
        {
          type: "crossbox",
          icon: "mdi-window-close",
          title: "Crossbox",
        },
        {
          type: "date",
          icon: "mdi-calendar",
          title: "Date",
        },
        {
          type: "formatted-date",
          icon: "mdi-calendar",
          title: "Formatted Date",
        },
        {
          type: "signature",
          icon: "mdi-signature-freehand",
          title: "Signature",
        },
        {
          type: "initial",
          icon: "mdi-signature",
          title: "Initial",
        },
      ],
      doc_ele: [],
      property: {},
      page_elements: {},
      page_deleted_elements: {},
      recipients: [],
      elementProps: {
        user: null,
        placeholder: null,
        required: null,
      },
      docTemplate: DocPage,
      email: {
        recipients: [],
        cc: [],
        subject: null,
        message: null,
        attachments: [],
      },
      share: {
        recipients: [],
      },
      generatedLinks: [],
      expiry_date: false,
      expiryDialog: false,
      deleteDialog: false,
      expiryLoading: false,
      deleteLoading: false,
      recipientDialog: false,
      recipientLoading: false,
      formRPValid: true,
      removed_recipients: [],
    };
  },
  methods: {
    add_recipients() {
      this.recipients.push({
        email: null,
        first_name: null,
        last_name: null,
      });
    },
    remove_recipients(index) {
      const _this = this;
      if (_this.recipients[index] && _this.recipients[index].uuid) {
        _this.removed_recipients.push(_this.recipients[index].uuid);
      }
      _this.$nextTick(() => {
        _this.recipients.splice(index, 1);
        if (_this.recipients.length <= 0) {
          _this.add_recipients();
        }
      });
    },
    updateRecipients() {
      if (!this.$refs.docForm.validate()) {
        return false;
      }
      this.recipientLoading = true;

      this.$store
        .dispatch(PUT, {
          url: "docs/recipients",
          data: {
            id: this.docId,
            recipients: this.recipients,
            deleted_recipients: this.removed_recipients,
          },
        })
        .then(() => {
          this.recipientDialog = false;
          this.getDoc();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.recipientLoading = false;
        });
    },
    doAction(action) {
      switch (action) {
        case "update:expiry":
          this.expiryDialog = true;
          break;
        case "download:document":
          this.downloadDoc();
          break;
        case "delete:document":
          this.deleteDialog = true;
          break;
      }
    },
    downloadDoc() {
      this.$store
        .dispatch(GET, {
          url: `docs/${this.docId}/download`,
        })
        .then(({ data }) => {
          if (data && data.file_path) {
            window.open(data.file_path, "_blank");
          }
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateExpiry() {
      this.expiryLoading = true;
      this.$store
        .dispatch(POST, {
          url: `docs/${this.docId}/update-expiry`,
          data: { date: this.expiry_date },
        })
        .then(() => {
          this.expiryDialog = false;
          this.getDoc();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.expiryLoading = false;
        });
    },
    deleteDocument() {
      this.deleteLoading = true;
      this.$store
        .dispatch(DELETE, { url: `docs/${this.docId}` })
        .then(() => {
          this.deleteDialog = false;
          this.goBack();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    updateAttachment(param) {
      this.email.attachments = param;
    },
    sendMail() {
      const validateStatus = this.$refs.mail_form.validate();

      const formErrors = this.validateForm(this.$refs.mail_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      this.mailLoading = true;

      this.$store
        .dispatch(POST, {
          url: `docs/${this.docId}/send-email`,
          data: { ...this.email },
        })
        .then(() => {
          this.sendDialog = false;
          /*this.email = new Object({
            recipients: [],
            cc: [],
            subject: null,
            message: null,
            attachments: []
          });*/
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.mailLoading = false;
        });
    },
    copyText(ref) {
      const element = this.lodash.head(this.$refs[ref]);
      if (element) {
        const textToCopy = element.$el.querySelector("input");
        textToCopy.select();
        document.execCommand("copy");
        ClearEventBus.$emit("clear:success");
        SuccessEventBus.$emit("update:success", "Link Copied");
      }
    },
    getURLPlaceholder() {
      return this.$assetURL("....");
    },
    generateLinks() {
      ClearEventBus.$emit("clear:error");
      if (this.share.recipients.length <= 0) {
        ErrorEventBus.$emit(
          "update:error",
          "Select at least one recipient to view and/or sign your document."
        );
        return false;
      }
      this.generateLoading = true;
      this.generatedLinks = [];
      this.$store
        .dispatch(POST, {
          url: `docs/${this.docId}/generate-links`,
          data: { recipients: this.share.recipients },
        })
        .then(({ data }) => {
          this.generatedLinks = data;
          this.linkGenerated = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.generateLoading = false;
        });
    },
    validateTagEmail(emails) {
      for (let i = 0; i < emails.length; i++) {
        if (!validateEmail(emails[i])) {
          emails.splice(i, 1);
        }
      }
    },
    removeEmail(email, type) {
      const emails = this.email[type];
      const index = this.lodash.findIndex(emails, (row) => email == row);
      if (index >= 0) {
        this.email[type].splice(index, 1);
      }
    },
    removeShareEmail(email, type) {
      const emails = this.share[type];
      const index = this.lodash.findIndex(emails, (row) => email == row);
      if (index >= 0) {
        this.share[type].splice(index, 1);
      }
    },
    autocompleteEmails() {
      return this.lodash.map(this.recipients, (row) => row.email);
    },
    scrollPage(page) {
      const element = document.getElementById(`doc-page-${page}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    getCenterMD() {
      if (this.pagesBlock && (this.contentBlock || this.propertyBlock)) {
        return 8;
      }
      if (this.pagesBlock && !this.contentBlock && !this.propertyBlock) {
        return 10;
      }
      if (!this.pagesBlock && !this.contentBlock && !this.propertyBlock) {
        return 12;
      }
    },
    closeBlock(block) {
      if (block == "pages") {
        this.pagesBlock = false;
      } else if (block == "contents") {
        this.contentBlock = false;
      } else if (block == "property") {
        this.propertyBlock = false;
        this.contentBlock = true;
      }
    },
    makeDuplicateElement() {
      const { page, uuid } = this.property;
      DocumentEventBus.$emit(`duplicate:page-element-${page}`, uuid);
    },
    deleteElement() {
      const { page, uuid } = this.property;
      DocumentEventBus.$emit(`delete:page-element-${page}`, uuid);
      this.property = {};
      this.propertyBlock = false;
      this.contentBlock = true;
      this.elementProps = {
        user: null,
        placeholder: null,
        required: null,
      };
    },
    pageElementUserAvatar(row) {
      if (row.first_name) {
        const first_name = row.first_name
          ? row.first_name.charAt(0).toUpperCase()
          : "";
        const last_name = row.last_name
          ? row.last_name.charAt(0).toUpperCase()
          : "";
        return first_name + (last_name ? " " + last_name : "");
      } else {
        const email = row.email ? row.email.charAt(0).toUpperCase() : "";
        return email;
      }
    },
    pageElementUser() {
      const user = this.elementProps.user;
      if (user) {
        if (user.first_name) {
          return user.first_name + (user.last_name ? " " + user.last_name : "");
        } else {
          return user.email;
        }
      }
      return null;
    },
    dragStartEvent(evt, param) {
      evt.dataTransfer.setData("content", JSON.stringify(param));
    },
    getTitle() {
      const title = [];
      if (this.detail.barcode) {
        title.push(this.detail.barcode);
      }
      if (this.detail.name) {
        const name = this.detail.name.split(".");
        name.splice(name.length - 1, 1);
        title.push(name.join(""));
      }
      return title.join(" • ");
    },
    updateProperty({ element, user, property }) {
      this.property = element;
      this.propertyBlock = true;
      this.contentBlock = false;
      this.elementProps.user = user;
      this.elementProps.placeholder = property ? property.placeholder : null;
      this.elementProps.required = property ? property.required : null;
    },
    submitProperty() {
      const { page, uuid } = this.property;
      DocumentEventBus.$emit(`submit:property-${page}`, {
        uuid,
        user: this.elementProps.user,
        property: {
          placeholder: this.elementProps.placeholder,
          required: this.elementProps.required,
        },
      });
    },
    getDoc() {
      this.$store
        .dispatch(GET, { url: `docs/${this.docId}` })
        .then(({ data }) => {
          this.detail = data;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Doc", route: "doc" },
            { title: "Detail" },
            { title: data.barcode },
          ]);

          this.email.subject = data.email_template.subject;
          this.email.message = data.email_template.customer_body;

          if (data.elements && data.elements.length) {
            const elements = [];
            for (var i = 0; i < data.elements.length; i++) {
              const docEle = this.lodash.find(this.doc_contents, {
                type: data.elements[i].type,
              });
              elements.push({
                icon: docEle.icon,
                left: data.elements[i].left,
                page: data.elements[i].page,
                style: new Object({
                  w: 200,
                  x: data.elements[i].left,
                  y: data.elements[i].top,
                }),
                user: data.elements[i].recipient
                  ? data.elements[i].recipient
                  : 0,
                property: {
                  placeholder: data.elements[i].placeholder,
                  required: data.elements[i].required,
                },
                title: docEle.title,
                top: data.elements[i].top,
                type: data.elements[i].type,
                uuid: data.elements[i].uuid,
              });
            }
            this.doc_ele = elements;
          }

          if (data.recipients && data.recipients.length) {
            this.recipients = data.recipients;
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.dataLoading = false;
          this.recipientDialog = false;
        });
    },
    removePage(page, image) {
      this.$store
        .dispatch(DELETE, {
          url: `docs/${this.docId}/page`,
          data: { page, image },
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    sendDocument() {
      if (this.doc_ele.length) {
        this.sendDialog = true;
      } else {
        ClearEventBus.$emit("clear:error");
        ErrorEventBus.$emit(
          "update:error",
          "Add atleast one element then try again."
        );
      }
    },
    saveDocument(action) {
      this.dataLoading = true;

      ClearEventBus.$emit("clear:error");

      this.$store
        .dispatch(PUT, {
          url: `docs/${this.docId}`,
          data: {
            action,
            pages: this.page_elements,
            deleted_elements: this.page_deleted_elements,
            deleted_recipients: this.removed_recipients,
          },
        })
        .then(({ data }) => {
          if (data.action == "send") {
            this.sendDocument();
          }
          if (data.action == "view") {
            window.open(data.file_path, "_blank");
          }
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
          this.getDoc();
        });
    },
  },
  components: {
    Dialog,
    TinyMCE,
    DatePicker,
    FileTemplate,
  },
  beforeDestroy() {
    DocumentEventBus.$off("update:page-elements");
  },
  mounted() {
    this.docId = this.lodash.toSafeInteger(this.$route.params.id);
    if (!this.docId) {
      this.goBack();
    } else {
      this.getDoc();
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Doc", route: "doc" },
      { title: "Create" },
    ]);

    DocumentEventBus.$on(
      "update:page-elements",
      ({ elements, deletedElements }) => {
        this.page_deleted_elements = deletedElements;
        this.page_elements = elements;
        this.$nextTick(() => {
          this.saveDocument("update");
        });
      }
    );
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
