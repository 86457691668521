<template>
  <div class="ct-quotation-listing">
    <quotation is-quotation> </quotation>
  </div>
</template>

<script>
import CommonQuotation from "@/view/pages/common-quotation/Quotation";

export default {
  name: "listing-common-quotation",
  data() {
    return {};
  },
  components: {
    quotation: CommonQuotation,
  },
};
</script>
