var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CreateUpdateTemplate',{attrs:{"routePreventDialog":_vm.routePreventDialog,"customClass":"role-create create-form"},on:{"update:routePreventDialog":function($event){_vm.routePreventDialog=$event},"update:route-prevent-dialog":function($event){_vm.routePreventDialog=$event}},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('h1',{staticClass:"form-title d-flex"},[(_vm.role_id > 0)?[_vm._v("Update Role")]:[_vm._v("Create a new Role")]],2)]},proxy:true},{key:"header-action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading || _vm.pageLoading},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading || _vm.pageLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.onSubmit}},[_vm._v(" Save Role ")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"roleForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"70vh","position":"static"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"4"}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"dense":"","filled":"","label":"Role Name","solo":"","flat":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","readonly":_vm.role_id ? true : false,"rules":[
                    _vm.validateRules.required(_vm.updateRole.role_name, 'Role Name'),
                    _vm.validateRules.minLength(
                      _vm.updateRole.role_name,
                      'Role Name',
                      2
                    ),
                    _vm.validateRules.maxLength(
                      _vm.updateRole.role_name,
                      'Role Name',
                      100
                    ),
                  ]},model:{value:(_vm.updateRole.role_name),callback:function ($$v) {_vm.$set(_vm.updateRole, "role_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateRole.role_name"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"4"}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","color":"cyan","label":"Description","solo":"","readonly":_vm.role_id ? true : false,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"flat":"","rules":[
                    _vm.validateRules.minLength(
                      _vm.updateRole.description,
                      'Description',
                      1
                    ),
                    _vm.validateRules.maxLength(
                      _vm.updateRole.description,
                      'Description',
                      1024
                    ),
                  ],"row-height":"15"},model:{value:(_vm.updateRole.description),callback:function ($$v) {_vm.$set(_vm.updateRole, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateRole.description"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"custom-grey-border remove-border-radius",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4 py-2"},[_c('h3',{staticClass:"font-weight-700 custom-headline color-custom-blue",staticStyle:{"margin":"3.5px 0px"}},[_vm._v(" Permissions ")])]),_c('v-card-text',{staticClass:"p-6"},[(_vm.permissionLoading)?[_c('span',{staticClass:"py-8 d-block font-size-16 font-weight-600"},[_vm._v("Getting permissions")]),_c('v-progress-linear',{attrs:{"color":"cyan","indeterminate":"","rounded":"","height":"6"}})]:[(_vm.lodash.isEmpty(_vm.permissions) === false)?_c('table',{staticClass:"width-100 font-size-14"},[_c('tr',[_c('td',{staticClass:"custom-border-right pl-4"}),_c('td',{staticClass:"custom-border-right pl-4 py-2",attrs:{"width":"100"}},[_vm._v(" Full Access ")]),_c('td',{staticClass:"custom-border-right pl-4 py-2",attrs:{"width":"100"}},[_vm._v(" View ")]),_c('td',{staticClass:"custom-border-right pl-4 py-2",attrs:{"width":"100"}},[_vm._v(" Create ")]),_c('td',{staticClass:"custom-border-right pl-4 py-2",attrs:{"width":"100"}},[_vm._v(" Edit ")]),_c('td',{staticClass:"custom-border-right pl-4 py-2",attrs:{"width":"100"}},[_vm._v(" Delete ")]),_c('td',{staticClass:"pl-4",attrs:{"width":"100"}},[_vm._v("Approve")])]),_vm._l((_vm.permissions),function(row,index){return _c('tr',{key:index},[_c('td',{staticClass:"custom-border-right custom-border-top py-3"},[_vm._v(" "+_vm._s(_vm.get_name(index))+" ")]),_vm._l((row),function(module,ind){return [_c('td',{key:index + ind,staticClass:"custom-border-top custom-border-right pl-4",attrs:{"width":"100"}},[(
                                  !(
                                    module.name == 'update' &&
                                    _vm.get_name(index) == 'Supplier price list'
                                  )
                                )?[_c('v-checkbox',{staticClass:"my-0",attrs:{"multiple":"","disabled":_vm.pageLoading,"color":"cyan","dense":"","value":module.id,"hide-details":""},on:{"change":function($event){return _vm.changeFullAccess(module, index)}},model:{value:(_vm.formPermissions),callback:function ($$v) {_vm.formPermissions=$$v},expression:"formPermissions"}})]:_vm._e()],2)]}),(row.length != 6)?_c('td',{staticClass:"custom-border-top pl-4 py-1",attrs:{"colspan":6 - row.length}}):_vm._e()],2)})],2):[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_c('img',{staticClass:"row-not-found-image mx-2",attrs:{"width":"40","src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no permission at the moment. ")])]]],2)],1)],1)],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }