<template>
  <v-container v-if="isActive && getPermission('attachment:view')" class="p-4">
    <div class="d-none">
      <v-text-field
        type="hidden"
        v-model="isUploading"
        :rules="[validateRules.required(!isUploading)]"
      ></v-text-field>
    </div>
    <v-row>
      <v-col md="12">
        <v-checkbox
          v-if="allowUpload"
          hide-details
          class="m-0 p-0"
          v-model="has_attachment"
          color="cyan"
          :true-value="1"
          :false-value="0"
          label="Has Attachment?"
          v-on:change="init_attachment()"
        ></v-checkbox>
        <br />
        <table width="100%" v-if="has_attachment || !allowUpload">
          <tbody>
            <template v-if="getPermission('attachment:create')">
              <tr v-for="(row, index) in documentArray" :key="index">
                <td width="50%" class="px-2 py-2">
                  <template v-if="row.doc_file || (row.add && allowUpload)">
                    <v-file-input
                      prepend-inner-icon="mdi-attachment mdi-rotate-90"
                      placeholder="Click here to select file"
                      label="Click here to select file"
                      prepend-icon=""
                      color="cyan"
                      solo
                      flat
                      v-model="row.doc_file"
                      dense
                      :accept="
                        onlyImage ? 'image/png, image/jpeg, image/bmp' : '*'
                      "
                      v-on:change="(file) => uploadFile(file, index)"
                      :rules="[
                        validateRules.file_required(row.document, 'File'),
                      ]"
                      hide-details
                    ></v-file-input>
                  </template>
                  <template v-else>
                    <v-text-field
                      dense
                      solo
                      flat
                      readonly
                      color="cyan"
                      v-model="row.name"
                      hide-details
                      class="has-delete-outer-icon cursor-pointer"
                      prepend-inner-icon="mdi-attachment"
                      v-on:click="openFile(row)"
                    ></v-text-field>
                  </template>
                </td>
                <td width="50%" class="px-2 py-2">
                  <template v-if="row.doc_file">
                    <v-text-field
                      placeholder="File Name"
                      label="File Name"
                      dense
                      solo
                      flat
                      :readonly="!allowUpload"
                      color="cyan"
                      v-model="row.name"
                      hide-details
                      class="has-delete-outer-icon"
                      append-outer-icon="mdi-delete"
                      v-on:click:append-outer="
                        remove_row(row.document.id, index, documentArray)
                      "
                      :suffix="get_file_extension(row.file_type)"
                      :rules="[validateRules.required(row.name, 'File Name')]"
                    ></v-text-field>
                  </template>
                  <template v-else>
                    <v-text-field
                      placeholder="File Name"
                      label="File Name"
                      dense
                      solo
                      flat
                      :readonly="!allowUpload"
                      color="cyan"
                      v-model="row.name"
                      hide-details
                      class="has-delete-outer-icon"
                      append-outer-icon="mdi-delete"
                      v-on:click:append-outer="
                        remove_row(row.id, index, documentArray)
                      "
                      :suffix="get_file_extension(row.file_type)"
                      :rules="[validateRules.required(row.name, 'File Name')]"
                    ></v-text-field>
                  </template>
                </td>
              </tr>
              <tr>
                <td height="10px" colspan="2"></td>
              </tr>
              <tr v-if="documentArray.length < 5 && allowUpload">
                <td colspan="2" class="px-2">
                  <v-btn
                    color="cyan white--text"
                    small
                    v-on:click="add_attachment_row()"
                    class="custom-bold-button"
                    >Add More...</v-btn
                  >
                </td>
              </tr>
            </template>
            <tr>
              <td height="10px" colspan="2"></td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>

    <!-- <v-row >
            <v-col md="6">
              <label
                v-if="label"
                class="font-weight-700 font-size-16"
                style="margin: 10px"
                >Attachment
                <span v-if="field.id"
                  >(<a :href="field.file.url" target="_blank">{{
                    field.name
                  }}</a
                  >)</span
                >
              </label>
              <template>
                <v-file-input
                  label="File input"
                  class="form-control"
                  v-on:change="(file) => uploadFile(file, index)"
                  outlined
                  dense
                ></v-file-input>
              </template>
            </v-col>
            <v-col md="6">
              <template v-if="isMultiple">
                <v-layout>
                  <v-flex md11>
                    <label
                      v-if="label"
                      class="font-weight-700 font-size-16"
                      style="margin: 10px"
                      >Attachment Name</label
                    >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="!field.document"
                      v-model.trim="field.name"
                      label="Attachment Name"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex md1>
                    <label
                      v-if="label"
                      class="font-weight-700 font-size-16"
                      style="margin: 10px"
                      >&nbsp; </label
                    >
                    <v-btn
                      v-if="index == 0"
                      class="custom-bold-button white--text"
                      color="cyan"
                      v-on:click="addAttchment"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="index > 0"
                      class="custom-bold-button white--text"
                      color="red lighten-1"
                      v-on:click="deleteAttachment(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </template>
              <template v-else>
                <label
                  v-if="label"
                  class="font-weight-700 font-size-16"
                  style="margin: 10px"
                  >Attachment Name</label
                >
                <v-text-field
                  dense
                  color="cyan"
                  filled
                  :disabled="!field.document"
                  v-model.trim="field.name"
                  label="Attachment Name"
                  solo
                  flat
                  hide-details
                ></v-text-field>
              </template>
            </v-col>
          </v-row> -->
  </v-container>
</template>

<script>
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [FileManagerMixin, ValidationMixin],
  props: {
    attachments: {
      type: Array,
      default() {
        return new Array();
      },
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    label: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    onlyImage: {
      type: Boolean,
      default: false,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowUpload: {
      type: Boolean,
      default: false,
    },
    isMinDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUploading: false,
      has_attachment: 0,
      documentArray: [],
    };
  },
  watch: {
    documentArray: {
      deep: true,
      immediate: true,
      handler(param) {
        this.updateFiles(param);
      },
    },
    attachments: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && param.length > 0) {
          this.documentArray = param;
          this.has_attachment = 1;
        }
      },
    },
  },
  mounted() {
    if (this.lodash.isEmpty(this.attachments) === false) {
      this.documentArray = this.attachments;
    }
  },
  methods: {
    openFile(row) {
      window.open(row.file.url, "_blank");
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    init_attachment() {
      this.documentArray = [];
      this.$nextTick(() => {
        this.add_attachment_row();
      });
    },
    add_attachment_row() {
      this.documentArray.push({
        doc_file: null,
        add: true,
        document: null,
        name: null,
      });
    },
    remove_row(docId, index, documentArr) {
      console.log(docId);
      if (docId) {
        this.deleteAPIFile(docId, index, documentArr);
      } else {
        this.documentArray.splice(index, 1);
      }
    },
    isValidImage(file_url) {
      try {
        const _this = this;
        let validImageExtension = ["png", "jpg", "jpeg"];
        let fileExtention = _this.lodash.last(file_url.split("."));
        return _this.lodash.includes(
          validImageExtension,
          fileExtention.toLowerCase()
        );
      } catch (error) {
        this.logError(error);
        return false;
      }
    },
    updateFiles(param) {
      let filesArray = [];
      for (var i = 0; i < param.length; i++) {
        if (this.lodash.isEmpty(param[i]) === false) {
          filesArray.push({
            document:
              param[i] && param[i].document ? param[i].document.id : null,
            name: param[i].name,
          });
        }
      }
      if (this.lodash.isEmpty(filesArray) === false) {
        this.$emit("file:updated", filesArray);
      }
    },
    uploadFile(file, index) {
      this.isUploading = true;
      this.uploadAttachment(file)
        .then((response) => {
          this.documentArray[index].doc_file = file;
          this.documentArray[index].document = response[0];
          this.documentArray[index].name = response[0].file.name;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
  },
  computed: {
    isActive() {
      return (
        this.allowUpload || this.lodash.isEmpty(this.documentArray) === false
      );
    },
  },
};
</script>
